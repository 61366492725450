import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";

const PatientListforLab = () => {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const dispatchvalue = useDispatch();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    axios
      .get(`${urllink}Billing/getallpatient?location=${userRecord?.location}`)
      .then((response) => {
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setsumma(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userRecord, urllink]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();

    // Calculate date 30 days ago
    const today = new Date();
    const last30DaysDate = new Date(today);
    last30DaysDate.setDate(today.getDate() - 30); 

    // Filter the data to only include records within the last 30 days
    const recentData = summa.filter((row) => {
      const rowDate = new Date(row.UpdatedAt);
      return rowDate >= last30DaysDate;
    });

    // Apply search filtering if any search query is provided
    const filteredData = (searchQuery || searchQuery1)
      ? summa.filter((row) => {
          const lowerCasePatientName = row.Patient_Name
            ? row.Patient_Name.toLowerCase()
            : "";
          const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
          return (
            lowerCasePatientName.includes(lowerCaseNameQuery) &&
            lowerCasePhone.includes(lowerCasePhoneQuery)
          );
        })
      : recentData.slice(0, 30); // Display only the first 30 rows from recent data when no search

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, summa]);



  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    }
  };

  const handleclick = (params) => {
    dispatchvalue({ type: "forpatientdatalab", value: params });
    navigate("/Home/PatientProfile");
  };

  const dynamicColumns1 = [
    { key: "id", name: "S.No", width: 70, frozen: true },
    { key: "Patient_Id", name: "Patient ID", frozen: true },
    { key: "Patient_Name", name: "Patient Name", width: 200, frozen: true },
    { key: "Age", name: "Age" },
    { key: "Gender", name: "Gender" },
    { key: "Phone", name: "Phone" },
    {
      key: "Actions",
      name: "View",
      renderCell: (params) => (
        <Button
          onClick={() => handleclick(params.row)}
          startIcon={<VisibilityIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": { color: "var(--ProjectColorhover)" },
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Patient List</h4>
        </div>
        <br />
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1">
            <div className="inp_1">
              <label htmlFor="input">Patient Name <span>:</span></label>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e, "name")}
                placeholder="Enter Patient Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone <span>:</span></label>
              <input
                type="text"
                value={searchQuery1}
                onChange={(e) => handleSearchChange(e, "phone")}
                placeholder="Enter Phone Number"
              />
            </div>
          </div>
        </div>
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns1} RowData={filteredRows} />
        </div>
      </div>
    </>
  );
};

export default PatientListforLab;
