import React, { useCallback, useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";

function Specimen() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [specimenData, setSpecimenData] = useState([]);
  const [specimen, setSpecimen] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [specimenCode, setSpecimenCode] = useState("");
  const [selectedid, setSelectedid] = useState(null);


  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };





  const handleSubmitSpecimen = async () => {
    if (!specimen.trim() || !specimenCode.trim()) {
      userwarn("Both specimen Code Name and specimen are required.");
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
      const response = await axios.post(
        `${urllink}mainddepartment/insertspecimen`,
        {
          specimen,
          specimenCode,
        }
      );

      // Handle the response as needed
      console.log(response.data);
      successMsg('Successfully Inserted')
      fetchSpecimenCodeData();
      setSpecimen("");
      // setSpecimenCode('')
      // Optionally, you can reset the state after a successful submission

      fetchSpecimenData();
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenarios
    }
  };

  const fetchSpecimenData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getspecimen`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setSpecimenData(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]);

  const fetchSpecimenCodeData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getspecimencode`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setSpecimenCode(data.specimen_code);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]);

  React.useEffect(() => {
    fetchSpecimenData();
    fetchSpecimenCodeData();
  }, [fetchSpecimenData, fetchSpecimenCodeData]);

  const handleEdit = (row) => {
    setSpecimen(row.specimen_name);
    setSpecimenCode(row.specimen_code);
    setIsEditMode(true);
    setSelectedid(row.specimen_id); // Assuming `method_id` is the identifier
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(
        `${urllink}mainddepartment/updatespecimen`,
        {
          method_id: selectedid,
          method_name: specimen,
          method_code: specimenCode,
        }
      );
      console.log(response.data);
      successMsg(response.data.message)
      fetchSpecimenCodeData();
      setSpecimen("");
      // setSpecimenCode('')
      setIsEditMode(false);
      setSelectedid("");
      fetchSpecimenData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  const specimencolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "specimen_code",
      name: "Specimen Code",
    },
    {
      key: "specimen_name",
      name: "Specimen Name",
      width: 280,
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleEdit(params.row)}
          startIcon={<EditIcon />}
        >
        </Button>
      ),
    },
  ];



  return (
    <>
      <div className="appointment">
        <br />

        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Specimen</h2>

          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Specimen Code <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={specimenCode}
                disabled
                onChange={(e) => setSpecimenCode(e.target.value)}
                placeholder="Enter Specimen Code"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Specimen Name <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={specimen}
                onChange={(e) => setSpecimen(e.target.value)}
                placeholder="Enter Specimen Name"
              />
            </div>

            <button
              className="RegisterForm_1_btns"
              onClick={isEditMode ? handleUpdateMethod : handleSubmitSpecimen}
            >
              {isEditMode ? "Update" : <AddIcon />}
            </button>
          </div>

          <div className="Main_container_app">
          <ReactGrid columns={specimencolumns} RowData={specimenData} />
        </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Specimen;
