import React, { useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

import { FaLocationDot } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import profileImg from "../assets/profileimg.jpeg";
import "./Header.css";
import axios from "axios";

const Header = () => {
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const location = useLocation();
  const UserData = useSelector((state) => state.userRecord?.UserData);
  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
  const [Locationoptions, setLocationOptions] = useState([]);
  const SidebarToggle = useSelector((state) => state.userRecord?.SidebarToggle);
  const [newImageProfile, setNewImageProfile] = useState(null);
  const [ClinicDetails, setClinicDetails] = useState([]);


  useEffect(() => {
    // Move side effects to useEffect

    axios
      .get(`${UrlLink}usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;

          setClinicDetails(firstClinic); // Access the "Data" key
        } else {
          console.error("No record found");
        }
      })
      .catch((error) => console.error("Error fetching data"));
  }, []);


  const handleToggleClick = () => {
    dispatchvalue({ type: "SidebarToggle", value: !SidebarToggle });
  };

  useEffect(() => {
    if (UserData?.username) {
      axios
        .get(
          `${UrlLink}usercontrol/getlocationdataforlogin?username=${UserData?.username}`
        )
        .then((response) => {
          const data = response.data;
          console.log(data)
          if (data) {
            setLocationOptions(data);
          } else {
            setLocationOptions([])
          }
        })
        .catch((error) => {
          console.error("Error fetching Location options:", error);
        });
    }
  }, [UserData?.username])

  const [Locationget, setLocationget] = useState("");

  useEffect(() => {
    setLocationget(UserData?.location);
  }, [UserData?.location]);


 
  useEffect(() => {
    if (Locationget) {
      const data = { ...UserData };
      data.location = Locationget;

      axios
        .post(`${UrlLink}loginpage/location_Change`, data)
        .then((response) => {
          const resdata = response.data.token;
          localStorage.setItem("token", resdata);
          const storedToken = localStorage.getItem("token");
          if (storedToken) {
            const decodedToken = (token) => {
              const payloadBase64 = token.split(".")[1];
              const decodedPayload = atob(payloadBase64);
              return JSON.parse(decodedPayload);
            };
            const decodedTokenData = decodedToken(storedToken);

            dispatchvalue({ type: "UserData", value: decodedTokenData });
          } else {
            if (location.pathname !== "/") {
              navigate("/");
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [Locationget]);


  useEffect(() => {
    if (UserData) {
      axios
        .get(`${UrlLink}profile/getuserprofile`, {
          params: {
            user_id: UserData?.user_id,
          },
        })
        .then((response) => {
          if (response.data && response.data.Profile_image) {
            const userData = response.data;
            console.log(userData?.user_id);
            setNewImageProfile(
              `data:${userData?.contentType};base64,${userData?.Profile_image}`
            );
          } else {
            console.log("User data or Profile_image not found or empty");
            // If user data or Profile_image is empty, set the default profile image
            setNewImageProfile(profileImg);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          // If there's an error, set the default profile image
          setNewImageProfile(profileImg);
        });
    } else {
      // If userRecord is null, set the default profile image
      setNewImageProfile(profileImg);
    }
  }, [UserData]);


  return (
    <div className="Header_comp">
       <div className="menu-link-wrapper" onClick={handleToggleClick}>
      <IconButton
        color="inherit"
        className={`menu-link ${SidebarToggle ? 'menu-trigger-open' : ''}`}
      >
        <div className="menu-link-wrapper">
          <div className={`menu-link ${SidebarToggle ? 'menu-trigger-open' : ''}`}>
            <span className="lines"></span>
          </div>
        </div>
      </IconButton>
    </div>
      
      {/* <div className="menu-link-wrapper" onClick={handleClick}>
          <div className={`menu-link ${isOpen ? "menu-trigger-open" : ""}`}>
            <span className="lines"></span>
          </div>
        </div> */}

      {ClinicDetails && (
        <div className="Header_comp_2">
          <div className="Header_comp_2_img">
            <img src={`data:${ClinicDetails.contentType};base64,${ClinicDetails.clinicLogo}`} alt={ClinicDetails?.clinicName} />
          </div>

          <span className="name_of_the3">{ClinicDetails?.clinicName}</span>
        </div>
      )}
      <div className="Header_comp_3">
        <div className="Header_comp_3_loc">
          <FaLocationDot className="Header_comp_3_loc_icon" title="Location" />
          <select
            className=" Header_comp_3_loc_select"
            value={Locationget}
            onChange={((e) => setLocationget(e.target.value))}
          >
            {Locationoptions &&
              Locationoptions.map((p, index) => (
                <option key={index} value={p.id}>
                  {p.location_name}
                </option>
              ))}
          </select>
        </div>
        <div
          className="Header_comp_3_img"
          title="Profile"
          onClick={() => {
            navigate("/Home/Profile");
          }}
        >
          <img  src={newImageProfile || profileImg} alt="Profile" />
        </div>
      </div>
    </div>
  );
};

export default Header;
