import React, { useState } from "react";
import "../Pettycash/Pettycash.css";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import { useCallback } from "react";

function RoleManagement() {
  const [roleName, setRoleName] = useState("");
  const [locationName, setLocationName] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentrole, setDepartmentRole] = useState("");

  const [roleData, setRoleData] = React.useState([]);
  const [locationData, setLocationData] = React.useState([]);
  console.log(locationData);
  const [departmentData, setDepartmentData] = React.useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  

  const fetchRoleData = useCallback(() => {
    // Use Axios to make a GET request
    axios
      .get(`${urllink}usercontrol/getRoleData`)
      .then((response) => {
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));

        setRoleData(data);
      })
      .catch((error) => {
        console.error("Error fetching role data:", error);
      });
  },[urllink]);

  const fetchLocationData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setLocationData(data);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });
  },[urllink]);

  const fetchDepartmentData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getDepartment`)
      .then((response) => {
        console.log(response);
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));

        setDepartmentData(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
  },[urllink]);

  React.useEffect(() => {
    // Fetch role data from the backend and update the state
    fetchRoleData();
    fetchLocationData();
    fetchDepartmentData();
    // fetchdepartmentforrole();
  }, [fetchRoleData,fetchLocationData,fetchDepartmentData]);

  
  const handleSubmitOne = (e) => {
    e.preventDefault();

    const uppercaseRoleName = roleName.toUpperCase();

    if (uppercaseRoleName !== "" && departmentrole !== "select") {
      // Create a FormData object with the role name
      const formData = new FormData();
      formData.append("roleName", uppercaseRoleName);
      formData.append("department", departmentrole);

      // Use Axios to make a POST request
      axios
        .post(`${urllink}usercontrol/insertRole`, formData)
        .then((response) => {
          // Handle the response as needed

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error);
          }

          // Clear the form field after successful submission
          setRoleName("");
          setDepartmentRole("select");

          // Fetch the updated role data
          fetchRoleData();
        })
        .catch((error) => {
          console.error("Error:", error);
          errmsg("Error occurred while inserting role.");
        });
    } else {
      userwarn("Please enter the role name and select the department");
    }
  };
  const handleEditClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const role_id = data.role_id;
      Update_status_fun(role_id, status);
    } else {
      const status = "Inactive";
      const role_id = data.role_id;
      Update_status_fun(role_id, status);
    }
  };

  const Update_status_fun = (role_id, status) => {
    axios
      .post(`${urllink}usercontrol/statusupdate`, {
        status,
        role_id,
      })
      .then((res) => {
        fetchRoleData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.error(err));
  };

  const handleSubmitTwo = (e) => {
    e.preventDefault();
    const uppercaseLocationName = locationName.toUpperCase();

    if (uppercaseLocationName !== "") {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append("locationname", uppercaseLocationName);

      axios
        .post(`${urllink}usercontrol/insertlocation`, formData)
        .then((response) => {
          // Handle the response as needed

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error);
          }
          // Clear the form field after successful submission
          setLocationName("");

          // Fetch the updated location data
          fetchLocationData();
        })
        .catch((error) => {
          console.error("Error:", error);
          errmsg("Error occurred while inserting location.");
        });
    } else {
      userwarn("please enter the location name");
    }
  };

  const handleLocClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      const location_id = data.location_id;
      Update_loc_fun(location_id, status);
    } else {
      const status = "Inactive";
      const location_id = data.location_id;
      Update_loc_fun(location_id, status);
    }
  };

  const Update_loc_fun = (location_id, status) => {
    console.log("mjkl", location_id, status);
    axios
      .post(`${urllink}usercontrol/statusupdatelocation`, {
        status,
        location_id,
      })
      .then((res) => {
        fetchLocationData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.error(err));
  };

  const handleSubmitThree = (e) => {
    e.preventDefault();
    const uppercaseDepartmentName = department.toUpperCase();

    if (uppercaseDepartmentName !== "") {
      // Create a FormData object with the location name
      const formData = new FormData();
      formData.append("department", uppercaseDepartmentName);

      axios
        .post(`${urllink}usercontrol/insertdepartment`, formData)
        .then((response) => {
          // Handle the response as needed

          if (response.data && response.data.message) {
            successMsg(response.data.message);
          } else {
            errmsg(response.data.error);
          }
          // Clear the form field after successful submission
          setDepartment("");

          // Fetch the updated location data
          fetchDepartmentData();
        })
        .catch((error) => {
          console.error("Error:", error);
          errmsg("Error occurred while inserting location.");
        });
    } else {
      userwarn("please Enter Department name");
    }
  };

  const handleDeptClick = (data) => {
    if (data.status === "Inactive") {
      const status = "Active";
      console.log("data1", data);
      const dept_id = data.Dept_id;
      Update_dep_fun(dept_id, status);
    } else {
      const status = "Inactive";
      console.log("data3", data);
      const dept_id = data.Dept_id;
      Update_dep_fun(dept_id, status);
    }
  };

  const Update_dep_fun = (dept_id, status) => {
    console.log("2563", dept_id, status);
    axios
      .post(`${urllink}usercontrol/statusupdatefor_dept`, {
        status,
        dept_id,
      })
      .then((res) => {
        fetchDepartmentData();
        successMsg("Status Update Successfully");
      })
      .catch((err) => console.error(err));
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (error) => {
    toast.error(error, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const locationcolumn = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "location_name",
      name: "Location Name",
      width: 200
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button size="small" onClick={() => handleLocClick(params.row)}>
          {params.row.status}
        </Button>
      ),
    },
  ];

  const deptcolumn = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "Department_name",
      name: "Department",
      width: 350
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button size="small" onClick={() => handleDeptClick(params.row)}>
          {params.row.status}
        </Button>
      ),
    },
  ];

  const roleDatacolumn = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "Department",
      name: "Department",
      width: 300
    },
    {
      key: "role_name",
      name: "Designation",
      width: 350
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button size="small" onClick={() => handleEditClick(params.row)}>
          {params.row.status}
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>User Management</h4>
        </div>

        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Location</h2>
          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">
                  Location Name <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="locationName"
                  value={locationName}
                  onChange={(e) => setLocationName(e.target.value)}
                  placeholder="Enter Location Name"
                />
              </div>
              <button className="RegisterForm_1_btns" onClick={handleSubmitTwo}>
                <AddIcon />
              </button>
            </div>
          </div>
          <h4>Table</h4>
          <div className="Main_container_app">
            <ReactGrid columns={locationcolumn} RowData={locationData} />
          </div>
        </div>
        <br />

        <br />
        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Department</h2>
          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">
                  Department Name<span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  placeholder="Enter Department Name"
                />
              </div>
              <button
                className="RegisterForm_1_btns"
                onClick={handleSubmitThree}
              >
                <AddIcon />
              </button>
            </div>
          </div>

          <h4>Table</h4>

          <div className="Main_container_app">
            <ReactGrid columns={deptcolumn} RowData={departmentData} />
          </div>
        </div>
        <br />
        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Designation</h2>
          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">
                  Department <span>:</span>
                </label>
                <select
                  name="department"
                  value={departmentrole}
                  onChange={(e) => setDepartmentRole(e.target.value)}
                  required
                >
                  <option value="">Select</option>
                  {departmentData.map((department) => (
                    <option
                      key={department.Dept_id}
                      value={department.Department_name}
                    >
                      {department.Department_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="inp_1">
                <label htmlFor="input">
                  Designation <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                  placeholder="Enter Designation Name"
                />
              </div>
              <button className="RegisterForm_1_btns" onClick={handleSubmitOne}>
                <AddIcon />
              </button>
            </div>
          </div>

          <h4>Table</h4>
          <div className="Main_container_app">
            <ReactGrid columns={roleDatacolumn} RowData={roleData} />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default RoleManagement;
