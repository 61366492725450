import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ForwadIcon from "@mui/icons-material/Forward";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import "../Report/smartreport.css";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";

const DispatchList = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [selectedrow, setselectedrow] = useState([]);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const dispatchvalue = useDispatch();
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/dispatchdataforque?Location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data.map((row, index) => ({
          id: index + 1, // Assuming you want to use Patient_Id as id
          ...row,
          status: "Status",
        }));
        data.sort((a, b) => {
          const invoiceA = parseInt(a.Billing_Invoice.replace(/\D/g, ""), 10);
          const invoiceB = parseInt(b.Billing_Invoice.replace(/\D/g, ""), 10);
          return invoiceB - invoiceA;
        });
        setsumma(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [urllink, userRecord?.location]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const lowerCaseBarcodeQuery = searchQuery2.toLowerCase();

    // Filter data based on all search queries
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name
        ? row.Patient_Name.toLowerCase()
        : "";
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
      const lowerCaseBarcode = row.Barcode ? row.Barcode.toLowerCase() : "";

      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery) &&
        lowerCaseBarcode.includes(lowerCaseBarcodeQuery)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, searchQuery2, summa]);

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    } else if (type === "barcode") {
      setSearchQuery2(value);
    }
  };

  const handleSampleCapture = (params) => {
    console.log(params);
    // Directoly setting selectedrow to the new value
    const data = params;
    dispatchvalue({ type: "Capturesample", value: data });

    navigate("/Home/CompleteReports");
  };

  const handleView = (params) => {
    console.log(params);
    setselectedrow(params?.TestDetails);
    setOpenModal(true);
  };

  const dynamicColumns2 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice",
      width: 120,
      frozen: true,
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
      frozen: true,
      width: 300
    },

    {
      key: "Action",
      name: "Action",
      width: 100,
      renderCell: (params) => (
        <Button
          onClick={() => handleSampleCapture(params.row)}
          startIcon={<ForwadIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        ></Button>
      ),
    },
    {
      key: "ActionView",
      name: "View Tests",
      width: 100,
      renderCell: (params) => (
        <Button
          onClick={() => handleView(params.row)}
          startIcon={<VisibilityIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        ></Button>
      ),
    },
    {
      key: "Status",
      name: "Status",
      renderCell: (params) => {
        const serviceStatuses = params.row.Service_Statuses || []; // Logic moved outside JSX
        return (
          <Tooltip
            title={
              <>
                <h4
                  style={{
                    textAlign: "center",
                    padding: "3px",
                    fontSize: "12px",
                    borderBottom: "1px solid white",
                    color: "orange",
                  }}
                >
                  {params?.row?.Billing_Invoice}
                </h4>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto 1px auto",
                    gap: "5px 10px",
                    fontSize: "12px",
                    alignItems: "center",
                    marginTop: "3px",
                  }}
                >
                  <label>Collection Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Capture || "-"}</span>

                  <label>Result Entry Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Analysis || "-"}</span>

                  <label>Result Verified Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Verify || "-"}</span>

                  <label>Result Approve Status</label>
                  <span>:</span>
                  <span>{serviceStatuses[0]?.Approve || "-"}</span>
                </div>
              </>
            }
            arrow
          >
            <span>{params.row.status || "N/A"}</span>
          </Tooltip>
        );
      },
    },
    {
      key: "Barcode",
      name: "Sample Id",
      frozen: true,
      width: 200,
    },
    {
      key: "Refering_Doctor",
      name: "Reference Doctor",
    },
    {
      key: "Visit_Id",
      name: "Visit Id",
      width: 100,
    },
    {
      key: "UpdatedAt",
      name: "Date",
    },

    {
      key: "Age",
      name: "Age",
      width: 55,
    },
    {
      key: "Gender",
      name: "Gender",
      width: 100,
    },
    {
      key: "Phone",
      name: "Phone",
    },
  ];

  const dynamicColumns3 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Test_Name",
      name: "Test Name",
      width: 300,
      frozen: true,
    },
    {
      key: "Service_Status",
      name: "Service Status",
    },
    {
      key: "Analysis_Status",
      name: "Analysis Status",
    },
    {
      key: "Verify_Status",
      name: "Verify Status",
    },
    {
      key: "Approve_Status",
      name: "Approve Status",
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Dispatch Que List</h4>
        </div>
        <br />
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => handleSearchChange(e, "name")}
                placeholder="Enter Patient Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Phone No<span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery1}
                onChange={(e) => handleSearchChange(e, "phone")}
                placeholder="Enter Phone No"
              />
            </div>
          </div>
        </div>
        {/* <div style={{ display: "flex", alignItems: "flex-start" }}> */}
        <p
          style={{
            color: "red",
            fontSize: "13px",
            textAlign: "center",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          * Hover over the{" "}
          <span
            style={{
              color: "green",
              fontSize: "13px",
              padding: "0px 5px",
            }}
          >
            Status
          </span>{" "}
          column to view the complete report status for each entry.
        </p>
        {/* </div> */}

        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns2} RowData={filteredRows} />
        </div>

        {openModal && (
          <div
            className={
              isSidebarOpen
                ? "sideopen_showcamera_profile"
                : "showcamera_profile"
            }
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <div
              className="newwProfiles newwPopupforreason"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="Main_container_app">
                <ReactGrid columns={dynamicColumns3} RowData={selectedrow} />
              </div>
              <div className="Register_btn_con">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setOpenModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DispatchList;
