import React, { useState, useEffect, useRef } from "react";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { colors } from '@mui/material';
// import { red } from '@mui/material/colors';
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TbDelta } from "react-icons/tb";
import "../Report/Report.css";
import {Select, MenuItem } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CancelIcon from '@mui/icons-material/Cancel';


const styles = {
  cancelIcon: {
    color: 'red',
    cursor: 'pointer', // Ensure the pointer cursor on hover
    transition: 'transform 0.2s ease-in-out', // Smooth animation effect
  },
};

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function Workbenchradio() {
  const uploadAdapter = (loader) => {
    return {
        upload: () => {
            return new Promise((resolve, reject) => {
                loader.file.then((file) => {
                    const reader = new FileReader();

                    reader.onloadend = () => {
                        // Convert image file to base64
                        const base64String = reader.result.split(',')[1];
                        resolve({ default: `data:${file.type};base64,${base64String}` });
                    };

                    reader.onerror = (error) => {
                        reject(error);
                    };

                    reader.readAsDataURL(file);
                });
            });
        }
    };
};

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return uploadAdapter(loader);
    };
}
  // const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  console.log(testDetails);
  // const [visitids, setvisitids] = useState([])
  const [reflexlist, setreflexlist] = useState([]);
  // const [value, setvalue] = useState([]);
  const [page, setPage] = useState(0);
  const [content, setcontent] = useState("");
  const [openpreview, setopenpreview] = useState(false);
  const [openpreview1, setopenpreview1] = useState(false);
  const [culturevalue, setculturevalue] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [editData, seteditData] = useState("")
  // const [formatData, setFormatData] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [openeditpreview, setopeneditpreview] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [isNavigating, setIsNavigating] = useState(false)
  const pageSize = 10;
  const totalPages = Math.ceil(culturevalue.length / pageSize);
  const showdown = culturevalue.length;
  const [multipleimage, setmultipleimage] = useState([])
  const [viewcode, setviewcode] = useState("")
  const [edittestcode, setedittestcode] = useState('')
  // const [visitid, setvisitid] = useState()
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log("capturedatas", capturedatas);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [editMode, setEditMode] = useState(false);
  const dispatchvalue = useDispatch();
  const [department, setDepartment] = useState([]);

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const [report, setreport] = useState({
    Collected: "",
    Received: "",
    Reported: "",
    Head: "",
    Detail: "",
  });

  const [summa, setsumma] = useState([]);
  // const [isedited, setisedited] = useState('')
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState("");

  const contentRef = useRef(null);

  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      // Add state to prevent re-renders
      navigate("/Home/AuthorizedReportEntry");
    }
  });

  const autoResizeTextarea = (e) => {
    e.target.style.height = "auto"; // Reset the height to auto
    e.target.style.height = `${e.target.scrollHeight}px`; // Set it to the scroll height
  };

  const navigate = useNavigate();
 

  const handleSensitivityChange = (id, value) => {
    const updatedRows = culturevalue.map((row) =>
      row.id === id ? { ...row, Sensitivetype: value } : row
    );
    setculturevalue(updatedRows);
  };
  
  const handleckediter = (data, testcode) =>{
    console.log(data , "oooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooooo");
    
    setTestDetails((prevDetails) =>
        prevDetails.map((detail) =>
          detail.Test_Code === testcode
            ? {
                ...detail,
                EditContent : data,
              }
            : detail
        )
      );
}


  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=ALL`)
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas.Patient_Name}`
      )
      .then((response) => {
        console.log(response);
        setpatinetbillingbarcode(response.data.Patient_Barcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, capturedatas]);

  const handleStatusChange = (index, newValue, dept) => {
    console.log("index", index);
    const updatedTestDetails = [...testDetails];
    let summa = updatedTestDetails.filter((p) => p.department === dept);
    summa[index].status = newValue;
    setTestDetails(updatedTestDetails);

    // Check if status is 'Reflex', then call handleReflexChange
    if (newValue === "Reflex") {
      handleReflexChange(index + 1, dept); // Assuming index is the parameter required by handleReflexChange
    }
  };

  const handlereflexchange = (index, newValue) => {
    const updatedDetails = [...testDetails];
    updatedDetails[index].reflextest = newValue;
    setTestDetails(updatedDetails);
  };

  const handlereflexsamplechange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].reflexsample = newValue;
    setTestDetails(updatedTestDetails);
  };



  const handleNext = () => {
    // console.log(multipleImages)
    // if (currentIndex < multipleImages.length - 2) {
    //   setCurrentIndex(currentIndex + 2);
    // }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 2);
    }
  };

  const handleClear = () => {
    setTestDetails((prevDetails) =>
      prevDetails.map((detail) => {
        if (detail.Billing_Invoice === capturedatas.Billing_Invoice) {
          return { ...detail, Images: [] }; // Clear all images
        }
        return detail;
      })
    );
    setCurrentIndex(0)
  };


  // const handlestatuscahngeretest= (index)=>{
  //   const updatedTestDetails = [...testDetails];
  //   updatedTestDetails[index].status = '';
  //   setTestDetails(updatedTestDetails);
  //  }
  const handledeleted = (imageIndex, testcode) => {
    console.log('imageIndex:', imageIndex);
  
    setmultipleimage((prevDetails) =>
      prevDetails.filter((_, index) => index !== imageIndex) // Use index to filter correctly
    );
  };


  

  
  

  
  const handlereportedit = (data) => {
    const selectedTest = testDetails.find((p) => p.Test_Code === data);
    const isChecked = testDetails.filter((p)=> p.Test_Code === data).some((p)=> p.isChecked === true)
     if (selectedTest && !isChecked) {
      setcontent(selectedTest.EditContent);
      setmultipleimage(selectedTest.Images)
    }
  
    setopenpreview((prev) => !prev);
    setedittestcode(data);
  };





 
  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/get_for_radiologist_data?Billinginvoice=${capturedatas.Billing_Invoice}&Visitid=${capturedatas.Visit_Id}`
      )
      .then((response) => {
        console.log("ududud",response.data);
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          testname : item.Test,
          Test_Code: item.Test_Code,
          Status : item.Status,
          Visit_Id: item.Visit_Id,
          Images: item.Images,
          Department: item.Department,
          EditContent: item.EditContent,
          isChecked: false,
          visit_id : capturedatas?.Visit_Id,
          patient_id: capturedatas?.Patient_Id,
          location: userRecord?.location,
          username : userRecord?.username,
          patient_name: capturedatas.Patient_Name,
          Billing_Invoice: capturedatas.Billing_Invoice,
          barcodedata: capturedatas.Barcode_id,
          status : 'Approved'
        }));
        // setFilteredRows(data);
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas, urllink, userRecord]);

  // const ishistopathology = testDetails.some((item) => item.department === 'HISTOPATHOLOGY')

  const handleverifydata = () => {
    console.log("testDetails", testDetails);
    // Separate the testDetails into groups based on their status
    const recollectTests = testDetails.filter(
      (detail) => detail.status === "Recollect"
    );
    const retestTests = testDetails.filter(
      (detail) => detail.status === "Retest"
    );
    const verifiedTests = testDetails.filter(
      (detail) => detail.status === "Approved"
    );
    const reflexTests = testDetails.filter(
      (detail) => detail.status === "Reflex"
    );
    console.log("reflexTests", recollectTests);
    console.log("reflexTests", retestTests);
    console.log("reflexTests", verifiedTests);
    console.log("reflexTests", reflexTests);
    // Define a function for handling API calls (for demonstration)
    const performApiCall = (url, data) => {
      return axios
        .post(url, data)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          navigate("/Home/Radiologistque");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    console.log(retestTests);

    // Process Recollect Tests
    if (recollectTests.length > 0) {
      performApiCall(
        `${urllink}Phelobotomist/pathologistsampleaprove`,
        recollectTests
      );
    }

    // Process Retest Testsv
    if (retestTests.length > 0) {
      performApiCall(`${urllink}Phelobotomist/updateretest`, retestTests);
    }

    // Process Verified Tests
    if (verifiedTests.length > 0) {
      performApiCall(
        `${urllink}Phelobotomist/radiologist_aprove_data_insert`,
        verifiedTests
      );
    }
    if (testDetails.status === "Reflex") {
      if (reflexTests.length > 0 && !reflexTests.reflex === "") {
        performApiCall(`${urllink}Phelobotomist/reflextestinsert`, reflexTests);
      } else {
        alert("Please Select one Reflex Test");
      }
    }
  };



  const handleFileChange = (event, testcode) => {
    const files = event.target.files;
    if (files.length > 0) {
      const imagesArray = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          imagesArray.push(
   reader.result,
          );
          if (imagesArray.length === files.length) {
            // // Update the specific test detail with new images
            // setTestDetails((prevDetails) =>
            //   prevDetails.map((detail) =>
            //     detail.Test_Code === testcode
            //       ? {
            //           ...detail,
            //           Images: [...detail.Images, ...imagesArray],
            //         }
            //       : detail
            //   )
            // );

            setmultipleimage((prev)=>([
              ...prev,
              ...imagesArray
            ]
            ))
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSavedoc = (testcode) => {
    setTestDetails((prev) =>
      prev.map((item) =>
        item.Test_Code === testcode 
          ? { ...item, 
            isChecked: true, 
            Images: [...multipleimage] } 
          : item
      )
    );

    const selectedTest = testDetails.filter((p) => p.Test_Code === testcode).map((item)=>
    item.EditContent);
    console.log("selectedTest", selectedTest);
    
    setcontent(selectedTest)
    setopenpreview((prev) => !prev); // Toggle preview safely
  };
  

  const handleReflexChange = (index, depart) => {
    console.log(index);
    const reflexTestName = testDetails
      .filter((item) => item.id === index && item.department === depart)
      .map((item) => item.testname); // Filter the array based on the index
    const testname = reflexTestName[0];
    console.log("reflexTestName", testname);

    axios
      .get(`${urllink}usercontrol/getforallreflex?testname=${testname}`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        // const nodata = response.error

        if (Array.isArray(data)) {
          setreflexlist(data);
        } else {
          setreflexlist([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("reflexlist", reflexlist);
  //   useEffect(() => {
  //     if (testDetails && testDetails.status === 'Reflex') {
  //         console.log('reflexTestName', reflexTestName);

  //         // axios.get(`${urllink}Billing/getforvisitid`)
  //         //     .then((response) => {
  //         //         console.log(response);
  //         //     })
  //         //     .catch((error) => {
  //         //         console.log(error);
  //         //     });
  //     }
  // }, [testDetails]);

  // const culturetests = testDetails?.some((item) => item.Culturetest === 'Yes');
  const hasRecollect = testDetails?.some(
    (detail) => detail.status === "Reflex"
  );

  const [formatData, setFormatData] = useState([]);

  


  const handleSubmitAnalyseReport = () => {
    const formData1 = new FormData();
  
    // Append regular data
    formData1.append("Billing_Invoice", capturedatas.Billing_Invoice);
    formData1.append("Visit_Id", capturedatas.Visit_Id);
    formData1.append("Patient_Id", capturedatas.Patient_Id);
  
 // Define testCode here
  
    // Find and set testCode from format field
    
  
    // Iterate over formatData to append fields to formData1
    formatData.forEach((item) => {
      console.log("testCode:", editData);
  
      if (item.name === "imageData") {
        // If the item is an image, append it as a file
        formData1.append(`${editData}_imageData`, item.value); // Append the testCode to differentiate images
      }else if(item.format){
        formData1.append(`${editData}_format`, item.format)
      }
      
      else if (item.name !== "format") {
        // For other items, append their name and value with the testCode prefix (skip format itself)
        formData1.append(`${editData}_${item.name}`, item.value);
      }
    });
  
    // Send formData1 to your backend using Axios
    axios
      .post(`${urllink}Phelobotomist/insert_Histo_Template`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setopeneditpreview(!openeditpreview);
        // navigate("/Home/AnalayseReportEntry");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlepdfpreview = (testcode) => {

    setviewcode(testcode)
    setopenpreview1(!openpreview1)
    
  };




  const handleeditpreview = (params) =>{
    
    console.log(params);

    axios
      .get(
        `${urllink}Phelobotomist/Get_Template?Billing_Invoice=${params?.Billing_Invoice}&patient_id=${params?.patient_id}&visitid=${params?.visitid}&Test_Code=${params?.Test_Code}`
      )

      .then((res) => {
        console.log(res.data.format);
        // Assuming res.data.format contains the format array
        setFormatData(res.data.format);
        seteditData(params?.Test_Code)
        setopeneditpreview(!openeditpreview)
        autoResizeTextarea()
      })

      .catch((err) => {
        console.error(err);
      });

 }




  useEffect(() => {
    let testname = testDetails[0]?.testname;
    axios
      .get(
        `${urllink}Phelobotomist/get_for_preview_examinations_pathologist?Visitid=${capturedatas?.Visit_Id}&patientid=${capturedatas.Patient_Id}&Billinginvoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response);
        const groupsData = response.data;
        console.log("groupsData", groupsData);
        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        groupsData.forEach((group) => {
          const analyseResultDate = new Date(
            group.Updated_At.Sample_Capture_in_Phelobotomist
          );
          const analyseSeniorDate = group.Updated_At.AnalyseInvestigationSenior;
          const approvePathologistDate = new Date(
            group.Updated_At.Approve_Test_Pathologist
          );

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = "" + d.getHours(),
            minutes = "" + d.getMinutes();

          if (day.length < 2) day = "0" + day;
          if (month.length < 2) month = "0" + month;
          if (hours.length < 2) hours = "0" + hours;
          if (minutes.length < 2) minutes = "0" + minutes;

          return (
            [day, month, year].join("/") + " " + [hours, minutes].join(":")
          );
        };

        setreport((prev) => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map((group) => {
          return {
            method: group.Method,
            Department: group.Department,
            groupName: group.Group_Name,
            Tests: group.Tests.map((test) => ({
              Test_Name: test.Test_Name || "",
              Method_Name: test.Method_Name || "",
              Department: test.Department || "",
              Sample: test.Specimen || "",
              Samplepicture: test.Samplepic
                ? `data:image/jpeg;base64,${test.Samplepic}`
                : null,
              Content: test.EditContent || "",
            })),
          };
        });

        setsumma(reportsData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [
    testDetails,
    urllink,
    capturedatas?.Visit_Id,
    capturedatas?.Billing_Invoice,
  ]);



  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_for_pending_result_department?invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response);
        setDepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink]);

  const reportnogrowth = testDetails.some((p) => p.Report_Type === "NoGrowth");
  const reportot = testDetails.some((p) => p.Report_Type === "Ot");

  console.log(testDetails, "..................testdetatils");

  return (
    <>
      {department.map((dept, deptIndex) => (
        <div key={deptIndex}>
          <h3>{dept}</h3>
          <div className="samplecapture_component">
            <div className="Selected-table-container">
              
              {(
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Imaging</th>
                      <th>Preview</th>
                      <th>Edit</th>
                      <th>Status</th>
                      <th>Verification</th>
                    </tr>
                  </thead>

                  <tbody>
                    {testDetails
                      ?.filter((p) => p.Department === dept)
                      .map((detail, index) => (
                        <tr key={index}>
                          <td>{detail.testname}</td>
                          <td>{detail.Department}</td>

                          <td>
                            <span>
                              <VisibilityIcon
                                onClick={() => handlepdfpreview(detail.Test_Code)}
                              />
                            </span>
                          </td>
                          <td>
                          <span onClick={()=>  handlereportedit(detail.Test_Code)}>
                            <EditIcon />
                          </span>
                          </td>
                          <td>{detail.Status}</td>
                          <td>
                            {detail.Status === "Retest" ? null : (
                              <select
                                name="status"
                                id="status"
                                className="Capture_Status_select"
                                value={detail.status}
                                onChange={(e) =>
                                  handleStatusChange(index, e.target.value)
                                }
                              >
                                {/* <option value="">Select Status</option> */}
                                <option value="Approved">Approved</option>
                                {/* <option value="Reflex">Reflex</option> */}
                                <option value="Retest">Retest</option>
                                <option value="Recollect">Recollect</option>
                              </select>
                            )}
                          </td>
                          {detail.status === "Reflex" ? (
                            <>
                              <td>
                                <select
                                  name="reflextest"
                                  id="reflextest"
                                  className="Capture_Status_select"
                                  value={detail.reflextest}
                                  onChange={(e) =>
                                    handlereflexchange(index, e.target.value)
                                  }
                                >
                                  <option value="">Select Reflex</option>
                                  {reflexlist?.map((item, idx) => (
                                    <option key={idx} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  name="reflexsample"
                                  id="reflexsample"
                                  className="Capture_Status_select"
                                  value={detail.reflexsample}
                                  onChange={(e) =>
                                    handlereflexsamplechange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Sample</option>
                                  <option value="Same Sample">
                                    Same Sample
                                  </option>
                                  <option value="New Sample">New Sample</option>
                                </select>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleverifydata}>
          Save
        </button>
      </div>

   


      {openpreview && (
                <div
                    className={
                        isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
                    }
                    onClick={() => { setopenpreview(false) }}
                >
                    <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>



                        <div ref={contentRef} className='editor_life'>


                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    extraPlugins: [uploadPlugin],
                                }}
                                data={content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleckediter(data, edittestcode);
                                }}
                            />

                            
                        </div>
                        <div className="RegisterForm_2">
                      <input
                        type="file"
                        id="CapturedFile"
                        name="CapturedFile"
                        accept="image/*"
                        multiple
                        onChange={
                            (e)=>handleFileChange(e, edittestcode)}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="CapturedFile"
                        className="RegisterForm_1_btns"
                      >
                        Choose File
                      </label>
                    </div>

                        <div className="treatcon_1">
                        <div className="multiple-images-container">
      { 

multipleimage.length !== 0 && (
    multipleimage.slice(currentIndex, currentIndex + 6).map((imageData, ind) => (
      <div key={imageData.id} className="treatcon_2">
        <span onClick={() => handledeleted(ind)} style={{ cursor: 'pointer' }}>
          <CancelIcon style={styles.cancelIcon} />
        </span>

        <div className="treatcon_image">
          <div className="treatcon_image_1">
            <img
              src={imageData}
              alt={`Captured Image ${ind + 1}`}
              onError={(e) => console.error("Error loading image:", e)}
            />
          </div>
          <div className="treatcon_label">
            <label>{`Captured Image ${ind + 1}`}</label>
          </div>
        </div>
      </div>
    ))
  )


     }
    </div>
{multipleimage.length > 1 && (

<div className="Register_btn_con">
  {currentIndex > 0 && (
    <button onClick={handlePrevious} className="RegisterForm_1_btns">
      Previous
    </button>
  )}

  {currentIndex < multipleimage.length - 1 && (
    <button onClick={handleNext} className="RegisterForm_1_btns">
      Next
    </button>
  )}

  <button onClick={handleClear} className="RegisterForm_1_btns">
    Clear All
  </button>
</div>
)}

<div>

</div>

</div>


                   


                        <div className="Register_btn_con regster_btn_contsai">
                            <button
                                style={{backgroundColor: 'red'}}
                                className="RegisterForm_1_btns"
                                onClick={() => setopenpreview(false)}
                            >
                                Close
                            </button>
                            <button style={{backgroundColor: 'green'}} className="RegisterForm_1_btns" onClick={()=>handleSavedoc(edittestcode)}>
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            )}
      {openpreview1 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopenpreview1(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Report_master_preview" id={`reactprintcontent`}>
              <div className="Report_master report_preview">
                <div className="New_billlling_invoice_head new_report_con">
                  <div className="new_billing_logo_con">
                    <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                  </div>
                  <div className="new_billing_address_1 ">
                    <span>{ClinicDetials.ClinicName}</span>
                    <div>
                      <span>{ClinicDetials.ClinicAddress},</span>
                      <span>
                        {ClinicDetials.ClinicCity +
                          "," +
                          ClinicDetials.ClinicState +
                          "," +
                          ClinicDetials.ClinicCode}
                      </span>
                    </div>
                    <div>
                      <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
                      <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
                      <span>{ClinicDetials.ClinicMailID}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="Register_btn_con"
                  style={{ color: "var(--labelcolor)", fontWeight: 600 }}
                >
                  Test Report
                </div>
                

                {testDetails
                  .filter((p) => p.Test_Code === viewcode)
                  .map((group, index) => (
                    <table
                      className="report_table report_table_for_micro"
                      key={index}
                    >
                      <tbody className="print_body Selected-table-container">
                        <tr>
                          <td>
                            <h3>{group.testname}</h3>

                            <div className="completed_report_1111">
                              <div className="completed_report completed_report04948 gghbuy_o9">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: group.EditContent,
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                            
                        <div className="treatcon_1">
                            <div className="multiple-images-container">
                                {group.Images && group.Images.length > 0 ? (
                                group.Images.slice(0, 6).map((imageData, index) => (
                                    <div key={imageData.id} className="treatcon_2">
                                    <div className="treatcon_image">
                                        <div className="treatcon_image_1">
                                        <img
                                            src={imageData}
                                            alt={`Captured Image ${index +1}`}
                                            onError={(e) => console.error("Error loading image:", e)}
                                        />
                                        </div>
                                        <div className="treatcon_label">
                                        <label>{`Captured Image ${index +1}`}</label>
                                        </div>
                                    </div>
                                    </div>
                                ))
                                ) : (
                                <p>No images available</p>
                                )}
                            </div>
                            </div>


                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>

              <div className="Register_btn_con regster_btn_contsai">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setopenpreview1(false)}
                >
                  Close
                </button>
                
              </div>
            </div>
          </div>
        </div>
      )}


    </>
  );
}

export default Workbenchradio;
