import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Barcode from "react-barcode";
import ReactToPrint from "react-to-print";
import "../Report/barcode.css";

const printStyles = `
@page {
  size: 5cm 2.5cm !important;
  margin: 0 !important;
}
@media print {
  .pageBreak {
    page-break-before: always !important;
  }
  .barcode_container_print_data {
    margin: 0 !important;
    page-break-inside: avoid !important;
  }
  .parent_barcode_div{
  margin: 0 !important;
  padding : 0 !important;
  gap: 0px !important;
  }
}
`;

export const BarcodePrint = () => {
  const componentRef = useRef();
  const [department, setDepartment] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(""); // State for selected department
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const Printdata = useSelector((state) => state.userRecord?.PrintBarcode);

  useEffect(() => {
    axios
      .get(`${urllink}Billing/getfor_barcode_details_print?invoice=${Printdata?.Billing_Invoice}`)
      .then((response) => {
        console.log(response);
        setDepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [Printdata, urllink]);

  const getCurrentDateTime = () => {
    const today = new Date();

    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const yy = yyyy.toString().slice(-2);

    const hh = String(today.getHours()).padStart(2, "0");
    const min = String(today.getMinutes()).padStart(2, "0");
    const sec = String(today.getSeconds()).padStart(2, "0");

    return `${dd}-${mm}-${yy}  ${hh}:${min}:${sec}`;
  };

  const currentDateTime = getCurrentDateTime();

  return (
    <>
      <div className="div_grand_parent">
        {/* Input to select department */}
        <div className="department-select">
          <label htmlFor="department">Select Department: </label>
          <select
            id="department"
            value={selectedDepartment}
            onChange={(e) => setSelectedDepartment(e.target.value)}
          >
            <option value="">All</option>
            {department.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        </div>

        <div className="parent_barcode_div" id="requestbarcode" ref={componentRef}>
          {department
            .filter((dept) =>
              selectedDepartment === "" ? true : dept === selectedDepartment
            )
            .map((test, index) => (
              <div className="barcode_container_print_data" key={index}>
                <div className="barcode_patient">
                  <p>{Printdata?.Patient_Name}</p>/<p>{Printdata?.Age}</p>/
                  <p>{Printdata?.Gender}</p>
                </div>
                <div className="barcode_image_content">
                  <Barcode
                    value={Printdata?.Barcode}
                    lineColor="Black"
                    height={35}
                    width={1}
                    fontSize={11}
                    displayValue={false}
                  />
                </div>
                <div className="barcode_dept">
                  <p>{test}</p>/
                  <p>{currentDateTime}</p>
                </div>
              </div>
            ))}
        </div>

        <ReactToPrint
          trigger={() => (
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns">Print</button>
            </div>
          )}
          content={() => componentRef.current}
          pageStyle={printStyles}
        />
      </div>
    </>
  );
};
