import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ToastContainer, toast } from "react-toastify";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import AddCardIcon from "@mui/icons-material/AddCard";
import "../AppointmentManagement/RegisReqList.css";
import "../TreatmentRecords/Prescription.css";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
// import "../RegistrationForm/Registration.css";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

function AppoinmentRequestLab() {
  // const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [department, setdepartment] = useState([]);
  const [testnamebydepartment, settestnamebydepartment] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [checkedTests, setCheckedTests] = useState([]);
  const [testdetails, settestdetails] = useState([]);
  const [testname, setTestName] = useState([]);
  // const [Billing_date, setBilling_date] = useState("");
  const [locationData, setLocationData] = React.useState([]);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());

  console.log(testdetails);
  const [requestdata, setrequestdata] = useState({
    Appointment_Request_Id: "",
    Title: "",
    PatientName: "",
    PhoneNo: "",
    Gender: "",
    DateofBirth: "",
    TimePeriod: "",
    Age: "",
    PhelobotomistName: "",
    ServiceType: "Individual",
    Department: "",
    GroupName: "",
    BranchLocation: "",
    PatientLocation: "",
    CommunicationAddress: "",
    Date: null,
  });

  console.log(requestdata);

  // const handleChange = (date) => {
  //   setrequestdata(prevState => ({
  //     ...prevState,
  //     Date: date, // Set the selected date
  //   }));
  // };




  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateYear = (age) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearOfBirth = currentYear - age;
    const dob = new Date(yearOfBirth, 1, -29);
    return dob;
  };

  useEffect(() => {
    axios
      .get(`${urllink}Billing/get_alldepartment_for_billing`)
      .then((response) => {
        const data = response.data;
        setdepartment(data);
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        console.log(response);
        const data = response.data;

        // Filter out the object where location_name is 'ALL'
        const filteredData = data.filter(
          (location) => location.location_name !== "ALL"
        );

        // Update the state with the filtered data
        setLocationData(filteredData);
      })
      .catch((error) => {
        console.error("Error fetching location data:", error);
      });

    // const currentDate = new Date();
    // setBilling_date(format(currentDate, "dd-MM-yyyy"));
  }, [urllink, requestdata]);

  const fetchrequestid = React.useCallback(() => {
    axios
      .get(`${urllink}Billing/generateRequest_Id`)
      .then((response) => {
        const data = response.data;
        setrequestdata((prevState) => ({
          ...prevState,
          Appointment_Request_Id: data.next_id,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urllink]);

  useEffect(() => {
    fetchrequestid();
  }, [fetchrequestid]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "Date") {
      if (value) {
        setrequestdata((prevState) => ({
          ...prevState,
          Date: value,
        }));
      } else {
        setrequestdata((prevState) => ({
          ...prevState,
          Date: "",
        }));
      }
    }

    if (name === "DateofBirth") {
      if (value !== "") {
        setrequestdata((prevState) => ({
          ...prevState,
          Age: calculateAge(value),
        }));
      } else {
        setrequestdata((prevState) => ({
          ...prevState,
          Age: "",
        }));
      }
    }

    if (name === "Age") {
      if (value !== "") {
        setrequestdata((prevState) => ({
          ...prevState,
          DateofBirth: calculateYear(value).toISOString().split("T")[0],
        }));
      } else {
        setrequestdata((prevState) => ({
          ...prevState,
          DateofBirth: "",
        }));
      }
    }

    if (name === "Title") {
      const genderValue =
        value.toLowerCase() === "mr"
          ? "Male"
          : value.toLowerCase() === "mrs" || value.toLowerCase() === "ms"
          ? "Female"
          : value.toLowerCase().includes("baby")
          ? "Baby"
          : value.toLowerCase().includes("child")
          ? "Child"
          : "";
      setrequestdata((prevState) => ({
        ...prevState,
        Gender: genderValue,
        Age: "",
      }));
    }

    if (name === "ServiceType") {
      if (value === "Group") {
        axios
          .get(`${urllink}usercontrol/getallgroupname`)
          .then((response) => {
            console.log(response.data);
            const data = response.data;
            setTestName(data);
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (value === "Offers") {
        axios
          .get(`${urllink}usercontrol/get_all_package_name`)
          .then((response) => {
            console.log(response.data);
            setTestName(response.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }

    setrequestdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const openviewtest = () => {
    console.log("hai");
    if (requestdata.Gender !== "" && requestdata?.Department) {
      axios
        .get(
          `${urllink}Billing/getalltestnamebydepartment?gender=${requestdata.Gender}&selecteddepartment=${requestdata?.Department}`
        )
        .then((response) => {
          console.log(response);
          const data = response.data;
          settestnamebydepartment(data);
          if (data.length > 0) {
            setOpenModal(true);
          } else {
            userwarn(`No Test  Availbale in This Deprtament`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("else");
      if (!requestdata.Gender) {
        userwarn("Select Gender");
      } else if (!requestdata?.Department) {
        userwarn("Select Department");
      }
    }
  };

  const handleclosemodal1 = () => {
    setOpenModal(false);
  };

  const handleCheckboxChange = (event, test) => {
    if (event.target.checked) {
      // Add test to checkedTests array
      setCheckedTests([...checkedTests, test]);
    } else {
      // Remove test from checkedTests array
      setCheckedTests(
        checkedTests.filter(
          (checkedTest) => checkedTest.Test_Code !== test.Test_Code
        )
      );
    }
  };

  // Check if a test is in the checkedTests array
  const isChecked = (test) => {
    return checkedTests.some(
      (checkedTest) => checkedTest.Test_Code === test.Test_Code
    );
  };

  const handleSearch1 = async () => {
    // Ensure that tests are selected
    if (checkedTests.length === 0) {
      alert("Please select at least one test.");
      return;
    }

    // Ensure patient details are filled
    if (requestdata.ServiceType === "Individual") {
      try {
        // Aggregate test codes from checkedTests
        const testCodes = checkedTests.map((test) => test.Test_Code);
        console.log("testCodes :", testCodes);
        console.log("testnamebydepartment :", testnamebydepartment);

        // Create a new array with the selected tests from testnamebydepartment without duplicates
        const testdetails1 = testnamebydepartment.filter((test) =>
          testCodes.includes(test.Test_Code)
        );

        // Remove duplicates based on Test_Code
        const uniqueTestdetails = testdetails1.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.Test_Code === value.Test_Code)
        );

        const updatedTestdetails = uniqueTestdetails.map((detail) => ({
          ...detail,
          ServiceType: requestdata?.ServiceType, // Add your desired ServiceType value here
        }));

        settestdetails(updatedTestdetails);
        setOpenModal(false);
        console.log("Unique test details:", uniqueTestdetails);

        // Your logic to use uniqueTestdetails, e.g., making an API call or updating state
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleRemoveTests = (request) => {
    // Remove the test from the testdetails array
    console.log(request);

    // Determine the code based on the ServiceType
    let code;

    if (requestdata.ServiceType === "Group") {
      code = request.GroupCode;
    } else if (requestdata.ServiceType === "Offers") {
      code = request.OfferCode;
    } else {
      code = request.Test_Code;
    }

    // Update the testdetails array by filtering out the test with the determined code
    const updatedTestdetails = testdetails.filter((test) => {
      if (requestdata.ServiceType === "Group") {
        return test.GroupCode !== code;
      } else if (requestdata.ServiceType === "Offers") {
        return test.OfferCode !== code;
      } else {
        return test.Test_Code !== code;
      }
    });
    settestdetails(updatedTestdetails);

    // Uncheck the test by removing it from the checkedTests array
    const updatedCheckedTests = checkedTests.filter((checkedTest) => {
      if (requestdata.ServiceType === "Group") {
        return checkedTest.GroupCode !== code;
      } else if (requestdata.ServiceType === "Offers") {
        return checkedTest.OfferCode !== code;
      } else {
        return checkedTest.Test_Code !== code;
      }
    });
    setCheckedTests(updatedCheckedTests);
  };

  const handleAddToCart = () => {
    // Find the selected item based on the input value
    let selectedItem;
    if (requestdata.ServiceType === "Offers") {
      selectedItem = testname.find(
        (item) => item.package_name === requestdata.GroupName
      );
    } else {
      selectedItem = testname.find(
        (item) => item.groupcode === requestdata.GroupName
      );
    }
    console.log(selectedItem);

    if (selectedItem) {
      // Create the new object to append to the testdetails array
      const newTestDetail = {
        ServiceType: requestdata.ServiceType,
        [requestdata.ServiceType === "Offers" ? "PackageName" : "Group_Name"]:
          requestdata.ServiceType === "Offers"
            ? selectedItem.package_name
            : selectedItem.groupname,
        [requestdata.ServiceType === "Offers" ? "OfferCode" : "GroupCode"]:
          requestdata.ServiceType === "Offers"
            ? selectedItem.OfferCode
            : selectedItem.groupcode,
      };

      // Update the testdetails array by appending the new object
      settestdetails([...testdetails, newTestDetail]);
      setrequestdata((prevState) => ({
        ...prevState,
        GroupName: "",
      }));
    } else {
      console.error("Selected item not found.");
    }
  };

  const handleSubmit = () => {
    const requiredFields = [
      "Title",
      "PatientName",
      "PhoneNo",
      "Gender",
      "DateofBirth",
      "Age",
      "PhelobotomistName",
      "CommunicationAddress",
    ];

    // Check for missing fields
    const missingFields = requiredFields.filter((field) => !requestdata[field]);

    // Check for BranchLocation and Location
    const branchLocationOrLocationMissing =
      !requestdata.BranchLocation && !requestdata.PatientLocation;

    // Combine the missing fields list
    if (missingFields.length > 0 || branchLocationOrLocationMissing) {
      let alertMessage = "";

      if (missingFields.length > 0) {
        alertMessage += `Missing required fields: ${missingFields.join(
          ", "
        )}. `;
      }

      if (branchLocationOrLocationMissing) {
        alertMessage +=
          "Either BranchLocation or PatientLocation must be provided.";
      }

      userwarn(alertMessage);
      return; // Stop execution if there are missing fields
    }

    if (testdetails.length === 0) {
      userwarn("Select Test !");
      return;
    }

    const datatosend = new FormData();

    Object.keys(requestdata).forEach((key) => {
      if (requestdata[key]) {
        // Only add fields that have values
        datatosend.append(key, requestdata[key]);
      }
    });

    // Append testdetails to FormData as a JSON string
    datatosend.append("testdetails", JSON.stringify(testdetails));

    axios
      .post(`${urllink}Billing/insert_Request_data`, datatosend)
      .then((res) => {
        console.log(res.data);

        if (res.data.message) {
          successMsg("Saved Successfully");
          navigate("/Home/Appoinment-RequestList");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const dynamicColumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "ServiceType",
      name: "Service Type",
      frozen: true,
      width: 200,
    },
    {
      key: "TestCount",
      name: "Test Name",
      width: 350,
      renderCell: (params) => (
        <>
          {params?.row?.ServiceType === "Group"
            ? params?.row?.Group_Name
            : params?.row?.ServiceType === "Individual"
            ? params?.row?.Test_Name
            : params?.row?.PackageName}{" "}
        </>
      ),
    },

    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          onClick={() => handleRemoveTests(params.row)}
          startIcon={<DeleteOutlineTwoToneIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        ></Button>
      ),
    },
  ];

  const preprocessData = (data) => {
    return data.map((item, index) => ({
      ...item,
      id: index + 1, // Auto-incrementing ID starting from 1
    }));
  };

  // Use this preprocessed data in your component
  const testdetailsWithId = preprocessData(testdetails);

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Request Form - {requestdata?.Appointment_Request_Id}</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1 smalefhiu">
          <label htmlFor="PatientName">
            Patient Name<span>:</span>
          </label>
          <select
            type="text"
            id="Title"
            name="Title"
            onChange={handleInputChange}
            required
            value={requestdata?.Title}
            className="krfekj_09"
          >
            <option value="">Title</option>
            <option value="Mr">Mr.</option>
            <option value="Ms">Ms.</option>
            <option value="Mrs">Mrs.</option>
            <option value="Baby">Baby.</option>
            <option value="Dr">Dr.</option>
            <option value="Babyof">Baby.of.</option>
            <option value="Child">Child.</option>
            <option value="Others">Others.</option>
          </select>
          <input
            type="text"
            id="PatientName"
            name="PatientName"
            title="Only letters and spaces are allowed"
            onChange={handleInputChange}
            value={requestdata?.PatientName}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="PhoneNo">
            Phone No<span>:</span>
          </label>
          <input
            type="text"
            id="PhoneNo"
            name="PhoneNo"
            onChange={(e) => {
              const value = e.target.value;
              // Allow only numbers
              if (/^\d*$/.test(value)) {
                handleInputChange(e); // Update state only if the input is numeric
              }
            }}
            value={requestdata?.PhoneNo}
            required
            maxLength={10} // Restricts input to 10 characters
            pattern="\d{10}" // Ensures input must be exactly 10 digits
            title="Phone number must be exactly 10 digits"
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="Gender">
            Gender<span>:</span>
          </label>
          <input
            type="text"
            id="Gender"
            name="Gender"
            onChange={handleInputChange}
            value={requestdata?.Gender}
            required
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="DateofBirth">
            Date of Birth<span>:</span>
          </label>
          <input
            type="date"
            id="DateofBirth"
            name="DateofBirth"
            value={requestdata?.DateofBirth}
            onChange={handleInputChange}
            required
            dateFormat="DD/MM/YYYY"
          />
        </div>
        {requestdata?.Title === "Baby" && (
          <div className="RegisForm_1 smalefhiu">
            <label htmlFor="Age">
              Age<span>:</span>
            </label>
            <input
              type="number"
              id="Age"
              name="Age"
              value={requestdata?.Age}
              onChange={handleInputChange}
              required
            />
            <select
              id="TimePeriod"
              name="TimePeriod"
              value={requestdata.TimePeriod}
              onChange={handleInputChange}
              className="krfekj_09"
            >
              <option value="hour">Hours</option>
              <option value="day">Days</option>
              <option value="month">Months</option>
              <option value="week">Weeks</option>
              <option value="year">Years</option>
            </select>
          </div>
        )}

        {requestdata?.Title !== "Baby" && (
          <div className="RegisForm_1">
            <label htmlFor="Age">
              Age<span>:</span>
            </label>
            <input
              type="number"
              id="Age"
              name="Age"
              onChange={handleInputChange}
              value={requestdata?.Age}
              required
            />
          </div>
        )}

        <div className="RegisForm_1">
          <label htmlFor="Date">
            Date<span>:</span>
          </label>
          <input
            type="date"
            id="Date"
            name="Date"
            onChange={handleInputChange}
            value={requestdata?.Date}
            required
          />
        </div>
{/* 
        <div className="RegisForm_1">
          <label htmlFor="Date">
            Date<span>:</span>
          </label>
          <DatePicker
            id="Date"
            name="Date"
            selected={requestdata?.Date} // Ensure this is a Date object
            onChange={handleChange} // Pass the selected date and name
            dateFormat="dd/MM/yyyy" // Specify the desired display format here
            required
          />
        </div> */}

        <div className="RegisForm_1">
          <label htmlFor="PhelobotomistName">
            Phelobotomist Name<span>:</span>
          </label>
          <input
            type="text"
            id="PhelobotomistName"
            name="PhelobotomistName"
            onChange={handleInputChange}
            value={requestdata?.PhelobotomistName}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="BranchLocation">
            Branch Location<span>:</span>
          </label>
          <select
            id="BranchLocation"
            name="BranchLocation"
            onChange={handleInputChange}
            value={requestdata?.BranchLocation}
            required
          >
            <option value="">Select</option>
            {Array.isArray(locationData) &&
              locationData.map((location) => (
                <option
                  key={location.location_id}
                  value={location.location_name}
                >
                  {location.location_name}
                </option>
              ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="PatientLocation">
            Patient Location<span>:</span>
          </label>
          <input
            type="text"
            id="PatientLocation"
            name="PatientLocation"
            onChange={handleInputChange}
            value={requestdata?.PatientLocation}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="CommunicationAddress">
            Communication Address<span>:</span>
          </label>
          <textarea
            type="text"
            id="CommunicationAddress"
            name="CommunicationAddress"
            onChange={handleInputChange}
            value={requestdata?.CommunicationAddress}
            required
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="ServiceType">
            Service Type <span>:</span>
          </label>
          <select
            id="ServiceType"
            name="ServiceType"
            value={requestdata.ServiceType}
            onChange={handleInputChange}
          >
            {/* <option value="">Select</option> */}
            <option value="Individual">Individual</option>
            <option value="Group">Group</option>
            <option value="Offers">Packages</option>
          </select>
        </div>
        {requestdata?.ServiceType === "Individual" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="Department">
                Department <span>:</span>
              </label>
              <select
                id="Department"
                name="Department"
                value={requestdata.Department}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {Array.isArray(department) &&
                  department.map((department) => (
                    <option
                      key={department.sub_department_code}
                      value={department.sub_department_code}
                    >
                      {department.sub_department_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="RegisForm_1">
              <label htmlFor="">
                View <span>:</span>
              </label>
              <Button className="cell_btn" onClick={openviewtest}>
                <VisibilityIcon className="check_box_clrr_cancell" />
              </Button>
            </div>
          </>
        )}

        {requestdata?.ServiceType !== "Individual" && (
          <div className="RegisForm_1 smalefhiu">
            <label htmlFor="GroupName">
              {requestdata.ServiceType === "Group"
                ? "Group Name"
                : "Package Name"}
              <span>:</span>
            </label>
            <input
              id="GroupName"
              name="GroupName"
              list="browsers1"
              value={requestdata.GroupName}
              onChange={handleInputChange}
              required
            />
            {console.log(testname)}
            {requestdata.ServiceType === "Offers" ? (
              <datalist id="browsers1">
                {Array.isArray(testname) &&
                  testname?.map((item, index) => (
                    <option key={index} value={item.package_name}>
                      {item.package_name}
                    </option>
                  ))}
              </datalist>
            ) : (
              <datalist id="browsers1">
                {Array.isArray(testname) &&
                  testname?.map((item, index) => (
                    <option key={index} value={item.groupcode}>
                      {item.groupname} - {item.groupcode}{" "}
                      {/* Show groupname with groupcode */}
                    </option>
                  ))}
              </datalist>
            )}
            {/* </div> */}
            {/* <div className="Register_btn_con"> */}
            <button className="krfekj_09" onClick={handleAddToCart}>
              <AddCardIcon />
            </button>
            {/* </div> */}
          </div>
        )}
      </div>
      <br />
      {testdetails.length > 0 && (
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns} RowData={testdetailsWithId} />
        </div>
      )}

      <br />
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <ToastContainer />

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={handleclosemodal1}
        >
          <div
            className="newwProfiles ahefkbsej"
            onClick={(e) => e.stopPropagation()}
          >
            <br />
            <h3>Test Names</h3>
            <div className="ghvyt67_9o0">
              {testnamebydepartment.map((test, index) => (
                <div key={index} className="antibioticnames_list33">
                  <label htmlFor={`test-${index}`}>
                    <input
                      type="checkbox"
                      name="testArray"
                      id={`test-${index}`}
                      value={test.Test_Code}
                      checked={isChecked(test)}
                      onChange={(e) => handleCheckboxChange(e, test)}
                    />
                    {test.Test_Name}
                  </label>
                </div>
              ))}
            </div>
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={handleclosemodal1}
              >
                Close
              </button>
              <button className="RegisterForm_1_btns" onClick={handleSearch1}>
                Add
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AppoinmentRequestLab;
