import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function LabIndentIssueList() {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const navigate = useNavigate();
  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  const [selectedShow1, setSelectedShow1] = useState(false);
  const [selectedShow2, setSelectedShow2] = useState(false);
  const [statustype, setstatustype] = useState("");
  const newlocation = "CentralStore";
  const [selectedRowreject, setSelectedRowreject] = useState({
    index: "",
    Productype: "",
    Reason: "",
  });
  const [Rowdata, setRowdata] = useState([]);

  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  useEffect(() => {
    if ((statustype === "Issued" || statustype === "Approved" || statustype === "Received" )&& Productype !== "") {
      console.log("234", statustype);

      axios
        .get(
          `${urllink}LabInventory/get_All_Issue_indent_data?Location=${newlocation}&Status=${statustype}&product_category=${Productype}`
        )
        .then((response) => {
          const data = response.data.data;
          console.log("out22", data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching patients data:", error);
        });
    } else {
      if (Productype === "Medical" && statustype !=='') {
        axios
          .get(
            `${urllink}LabInventory/get_medical_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}&Productype=${Productype}`
          )
          .then((response) => {
            const data = response.data.medical_purchase_orders;
            console.log("out", data);
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      
      } else if  ((Productype === "NonMedical" || Productype === "Stationary" ) && statustype !=='') {
        axios
          .get(
            `${urllink}LabInventory/get_Nonmedical_purchase_Raise_indent_data?Location=${newlocation}&Status=${statustype}&Productype=${Productype}`
          )
          .then((response) => {
            const data = response.data.medical_purchase_orders;
            console.log("out", data);
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      }
    }
  }, [Productype, selectedShow1,selectedShow2,statustype]);

  useEffect(() => {
    setSelectedRowreject({
      Reason: "",
      Productype: "",
      index: "",
      Approved_By: userRecord?.username,
    });
  }, [Productype]);

  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    const index = params.row.items;
    console.log("index:", index);
    let B_data = index
      // .filter((item) => item.Status === "Waiting")
      .map((p, index) => ({
        id: index + 1,
        ...p,
      }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const handleIssueView = (params) => {
    const index = params.row.items;
    console.log("index:", index);
    let B_data = index.map((p, index) => ({
      id: index + 1,
      ...p,
    }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const handleRejectedEdit = (params) => {
    

    const index = params.row.IndentRaise_InvoiceNo;
    console.log("index:", index);
    console.log("Productype:", Productype);
    if (params.row.Status === "Waiting") {
      setSelectedShow1(true);
      setSelectedRowreject((prev) => ({
        ...prev,
        index: index,
        Productype: Productype,
      }));
    } else {
      alert("The Invoice is Rejected ");
    }

  };


  const handleIssueEdit = (params) => {
    const index = params.row;
    const updatedIndex = { ...index, Productype };
    console.log("index", updatedIndex);
    dispatchvalue({ type: "IndentIssueListData", value: updatedIndex });
    navigate("/Home/LabIndentIssueMaster");
  };

  const handleRejectedSubmit = () => {
    if (selectedRowreject.Reason !== "") {
      const resultReject = window.confirm(
        "Are you sure ,you want to reject it ?"
      );

      if (resultReject) {
        axios
          .post(
            `${urllink}LabInventory/Update_indent_issue_Rejection`,
            selectedRowreject
          )
          .then((response) => {
            console.log(response.data);
            setSelectedShow1(false);
            setSelectedRowreject((prev) => ({
              ...prev,
              index: "",
              Productype: "",
              Reason: "",
            }));
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        setSelectedShow1(false);
      }
    } else {
      alert("Please Give the Reason ");
    }
  };



  const handleApproveEdit=(params)=>{
    console.log(params.row)

    const resultReject = window.confirm('Are you sure ,you want to Approve it ?')

   if(resultReject){
    const data={
      ...params.row,
      Approved_By:userRecord?.username,
      Status:'Approved'
    }

    console.log('data',data)

    axios.post(`${urllink}LabInventory/Update_indent_issue_to_approve`,data)
      .then((response)=>{
          console.log(response.data)
          setSelectedShow2(!selectedShow2)
      })
      .catch((error)=>{
          console.log(error)
      })
    console.log(data)

   }
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    console.log("ss", summa);

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName =
        statustype === "Issued" || statustype === "Approved" || statustype === "Received"
          ? row.IndentIssue_InvoiceNo.toLowerCase()
          : row.IndentRaise_InvoiceNo.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);

  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: "IndentRaise_InvoiceNo",
      headerName: "IndentRaise InvoiceNo",
      width: 170,
    },
    { field: "RequestDate", headerName: "Request Raise Date", width: 170 },
    { field: "RequestFrom", headerName: "Request From", width: 140 },
    { field: "RequestTo", headerName: "Request To", width: 140 },
    { field: "Reason", headerName: "Reason", width: 130 },
    { field: "Status", headerName: "Status", width: 100 },
    ...(statustype=== 'Rejected' ? [{ field: "Reject_Reason", headerName: "Reject Reason", width: 120 }] : []),
    {
      field: "View",
      headerName: "Action",
      width: 180,
      renderCell: (params) => (
        <div>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
            title="View"
          >
            <VisibilityIcon />
          </Button> 
          
          {params.row.Status === "Waiting" && (
            <Button
              className="cell_btn"
              onClick={() => handleRejectedEdit(params)}
              title="Reject"
            >
              <DeleteForeverIcon />
            </Button>
          )}
    
          {params.row.Status === "Waiting" && (
            <Button
              className="cell_btn"
              onClick={() => handleIssueEdit(params)}
              title="Issue"
            >
              <ArrowForwardIcon/>
            </Button>
          )}
        </div>
      )
    }
    
   
      
  ];

  const dynamicColumns1 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Generic_Name", headerName: "Generic Name", width: 150 },
    { field: "Strength", headerName: "Strength", width: 120 },
    { field: "UOM", headerName: "UOM", width: 120 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Order_Quantity", headerName: "Ordered Quantity", width: 120 },
    ...(statustype === 'Issued' || statustype === "Approved" || statustype === "Received"? [{ field: "Issue_Quantity", headerName: "Issue Quantity", width: 120 }] : []),
    ...(statustype === 'Issued' || statustype === "Approved" || statustype === "Received"? [{ field: "Issue_TotalQuantity", headerName: "Issue TotalQuantity", width: 150 }] : []),
    ...(statustype === "Issued" || statustype === "Approved"|| statustype === "Received"? [] : [{ field: "Status", headerName: "Status", width: 120 }]),
  ];

  const dynamicColumns2 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Order_Quantity", headerName: "Ordered Quantity", width: 120 },
    ...(statustype === 'Issued' || statustype === "Approved" || statustype === "Received"? [{ field: "Issue_Quantity", headerName: "Issue Quantity", width: 120 }] : []),
    ...(statustype === 'Issued' || statustype === "Approved" || statustype === "Received"? [{ field: "Issue_TotalQuantity", headerName: "Issue TotalQuantity", width: 150 }] : []),
    ...(statustype === "Issued" || statustype === "Approved" || statustype === "Received"? [] : [{ field: "Status", headerName: "Status", width: 120 }]),
  ];

  const IssuedColumns = [
    {
      field: "IndentIssue_InvoiceNo",
      headerName: "IndentIssue InvoiceNo",
      width: 200,
    },
    { field: "Indent_Issue_Date", headerName: "Indent Issue Date", width: 150 },
    { field: "Issue_From", headerName: "Issue From", width: 150 },
    { field: "Issue_To", headerName: "Issue To", width: 150 },
    {
      field: "Raise_Invoice_No",
      headerName: "IndentRaise InvoiceNo",
      width: 170,
    },
    { field: "Request_Rise_Date", headerName: "IndentRaise Date", width: 170 },
    { field: "Finel_NetAmount", headerName: "NetAmount", width: 150 },
    { field: "Status", headerName: "Status", width: 120 },
    {
      field: "View",
      headerName: "View",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleIssueView(params)}
            title="View"
          >
            <VisibilityIcon />
          </Button>

          {
            params.row.Status ==='Issued' &&
            (<Button className='cell_btn' onClick={() => handleApproveEdit(params)}
            title="Approve" >
                <CheckCircleOutlineIcon />
            </Button>)
          }
        </>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>Indent Issue List L</h4>
          <div className="doctor_select_1_90">
            <label htmlFor="Calender"> Product Type <span>:</span></label>
            <select
  
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }}
            >
             <option value="">Select</option>
              <option value="Medical">Medical</option>
              <option value="NonMedical">Non Medical</option>
              <option value="Stationary">Stationary</option>
            </select>
          </div>
        </div>
        <br />

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Invoice No <span>:</span></label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter Supplier Code"
            />
          </div>

          <div className="inp_1">
            <label htmlFor="input">Status <span>:</span></label>
            <select
              value={statustype}
              onChange={(e) => setstatustype(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Waiting">Waiting</option>
              <option value="Rejected">Rejected</option>
              <option value="Issued">Issued</option>
              <option value="Approved">Approved</option>
              <option value="Received">Completed</option>

            </select>
          </div>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={
                  statustype === "Issued" ||  statustype === "Approved" || statustype === "Received" ? IssuedColumns : dynamicColumns
                } // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                    className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
          {selectedShow1 && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow1(false);
              }}
            >
              <div
                className="appointment_rejectIndent"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="appointment_rejectIndent_head">Reason</div>
                  <textarea
                    style={{ height: "60px", padding: "5px" }}
                    className="appointment_rejectIndent_text"
                    placeholder="Reason for rejection"
                    value={selectedRowreject.Reason}
                    onChange={(e) =>
                      setSelectedRowreject((prev) => ({
                        ...prev,
                        Reason: e.target.value,
                      }))
                    }
                  />

                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={handleRejectedSubmit}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype} Item List</h3>
                  </div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={
                           ( Productype === "MedicalConsumable" ||
                            Productype === "MedicalNonConsumable" || 
                            Productype === "NonMedicalConsumable" ||
                            Productype === "NonMedicalNonConsumable" ||
                            Productype === "Surgical")
                              ? dynamicColumns1
                              : dynamicColumns2
                          } // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
