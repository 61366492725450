import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from 'axios'
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const AddvaceApproval = () => {

  const isSidebarOpen = useSelector(state => state.userRecord?.isSidebarOpen)

  const userRecord = useSelector(state => state.userRecord?.UserData)
  const urllink = useSelector(state => state.userRecord?.UrlLink);

  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [status, setStatus] = useState('');
  const [oldStatus, setOldStatus] = useState('');
  const [reason, setReason] = useState('');
  const [issuedby, setIssued] = useState('');
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [installment, setInstallment] = useState(null)
  const [issuedDate, setIssuedDate] = useState('');
  const [showsudden, setshowsudden] = useState(false);
  const [filteredRows, setFilteredRows] = useState([]);
  const [AmountDeductPerMonth, setAmountDeductPerMonth] = useState(0);


  const handleEditClick = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)
    setOldStatus(params.status);
    setStatus(params.status);

  };








  const [rolename, setRolename] = useState([])
  useEffect(() => {
    axios
      .get(
        `${urllink}HRmanagement/getRole_all`
      )
      .then((response) => {
        console.log(response.data);
        setRolename(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  }, [userRecord?.location]);


  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    fetch(`${urllink}HRmanagement/get_req_advance_register?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {

        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.Designation,
            reqdate: userdata.RequestDate ? new Date(userdata.RequestDate).toISOString().split('T')[0] : null,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
          }));
          setRows(Records);
          // console.log(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReason(newReason);
  };
  const handleInstallmentChange = (e) => {
    const newReason = e.target.value;
    console.log(newReason)
    setInstallment(newReason);

    if (newReason) {
      const monthamount = (parseInt(selectedRowData.reqamount) / newReason).toFixed(2)
      setAmountDeductPerMonth(monthamount)
    } else {
      setAmountDeductPerMonth(0)
    }

  };

  // const handleAmountDeductPerMonthchange = (e) => {
  //   const newReason = e.target.value;
  //   setAmountDeductPerMonth(newReason);
  // };



  const handleissuedByChange = (e) => {
    const issued = e.target.value;
    setIssued(issued)
  }
  const handleissuedDateChange = (e) => {
    const issueddate = e.target.value
    setIssuedDate(issueddate)
  }

  const handleSubmit = () => {
    // Prepare data to be sent to the backend
    const finaldata = new FormData();
    const postData = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      installment: installment,
      rejectReason: reason, 
      issuedBy: issuedby,
      issuedDate: issuedDate, // Corrected the key here
      AmountDeductPerMonth: AmountDeductPerMonth
    };


    const alertdata = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      installment: installment, 
      issuedBy: issuedby,
      issuedDate: issuedDate, // Corrected the key here
      AmountDeductPerMonth: AmountDeductPerMonth
    };

    const missingFields = Object.entries(alertdata).filter(([key, value]) => !value && value !== 0);
    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map(([key]) => key).join(', ');
      alert(`The following fields are missing: ${missingFieldNames}`);
      return; // Stop the function if any fields are missing
    }
    
    Object.entries(postData).forEach(([key, value]) => {
      finaldata.append(key, value);
    });

    // console.log(postData);

    // Post data to the backend using Axios
    axios.post(`${urllink}HRmanagement/update_advance_approval`, finaldata)
      .then((response) => {
        // Handle success
        console.log(response.data);
        alert('Data submitted successfully');
        setOpenModal(false);
        setshowsudden(!showsudden);
        // window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        alert('Error submitting data');
      });
  };


  const [searchQuery, setsearchQuery] = useState('')
  const [searchQuery1, setsearchQuery1] = useState('')
  //  const showdown = advanceRows.length;

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseQuery1 = searchQuery1.toLowerCase();

    const filterRow = (row) => {
      const lowerCaseEmployeeName = row.employeename.toLowerCase();
      const lowerCaseDesignation = row.designation.toLowerCase();
      const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
      const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

      if (!searchQuery && !searchQuery1) {
        // If both search queries are empty, do not filter out any data
        return true;
      }

      return nameMatches && designationMatches;
    };

    const filteredData = rows.filter(filterRow);


    setFilteredRows(filteredData);

  }, [searchQuery, rows, searchQuery1]);

  const [advanceamountdata, setadvanceamountdata] = useState([]);


  const handleVisibilityClick = (params) => {
    axios.get(`${urllink}HRmanagement/get_previousapprovedadvanceampunt?EmployeeId=${params}`)
      .then((response) => {
        console.log(response.data)
        setadvanceamountdata(response.data)
        setOpenModal1(true);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const closemodal = () => {
    setOpenModal(false)
    setSelectedRowData({})
    setInstallment(null)
    setAmountDeductPerMonth('')
  }

  return (

      <div className="appointment">
        <div className="h_head">
          <h4>Advance Approval </h4>
        </div>
        <br />
        
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Employee Name <span>:</span></label>
            <input
              type="text"
              id="date"
              name='employeeName'
              placeholder='Enter Employee Name'
              value={searchQuery}
              onChange={(e) => setsearchQuery(e.target.value)}
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Designation <span>:</span></label>
            <select
              name="designation"
              value={searchQuery1}
              onChange={(e) => setsearchQuery1(e.target.value)}
              className="new-custom-input-phone wei32j"
              required
            >
              <option value="select">Select </option>
              {rolename.map((role) => (
                <option key={role.role_id} value={role.role_name}>
                  {role.role_name}
                </option>
              ))}
            </select>
          </div>

        </div>
        <div className="Selected-table-container">
          <table className="selected-medicine-table2">
            <thead>
              <tr>
                <th id="slectbill_ins">Employee ID</th>
                <th id="slectbill_ins">Employee Name</th>
                <th id="slectbill_ins">Designation</th>
                <th id="slectbill_ins">Request Date</th>
                <th id="slectbill_ins">Request Amount</th>
                <th id="slectbill_ins">Reason</th>
                <th id="slectbill_ins">Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredRows.length > 0 ? filteredRows.map((leave, index) => (
                <tr key={leave.id}>
                  <td>{leave.employeeid}</td>
                  <td>{leave.employeename}</td>
                  <td>{leave.designation}</td>
                  <td>{leave.reqdate}</td>
                  <td>{leave.reqamount}</td>
                  <td>{leave.reason}</td>
                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleVisibilityClick(leave.employeeid)}
                      startIcon={<VisibilityIcon />}
                    >
                      {/* View */}
                    </Button>
                    <Button variant="contained"
                      color="warning"
                      size="small"
                      onClick={() => handleEditClick(leave)}
                      startIcon={<CheckCircleOutlineIcon />}
                    >
                      {/* Approve */}
                    </Button>
                  </td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="4">No  records found</td>
                </tr>
              )}
            </tbody>
          </table>

        </div>
   

      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal(false) }}
        >
          <div className="newwProfiles newwPopupforreason uwagduaguleaveauto" onClick={(e) => e.stopPropagation()}>
            {/* <div className="editcont"> */}
              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="issued">Status<span>:</span></label>
                  <select
                    name="approval"
                    id="approval"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select</option>
                    {oldStatus !== 'Approved' && (
                      <option value="Approved">Approved</option>
                    )}
                    {oldStatus !== 'Not Approved' && (
                      <option value="Not Approved">Not Approved</option>
                    )}
                  </select>
                </div>
              </div>
              {console.log(selectedRowData)}
              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="installment">Request Amount<span>:</span></label>
                  <input
                    type="text" name="RequestAmount" id="RequestAmount"
                    value={selectedRowData?.reqamount}
                  />
                </div>
              </div>

              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="installment">No.of Installment <span>:</span></label>
                  <input
                    type="num" name="installment" id="installment"
                    value={installment}
                    onChange={handleInstallmentChange}
                  />
                </div>
              </div>
              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="AmountDeductPerMonth">Amount Deduct Per Month <span>:</span></label>
                  <input
                    type="text" name="AmountDeductPerMonth" id="AmountDeductPerMonth"
                    value={AmountDeductPerMonth || 0}
                  // onChange={handleAmountDeductPerMonthchange}
                  />
                </div>
              </div>

            {/* </div> */}

            {/* <div className="editcont"> */}
              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="issuedate">Issued Date <span>:</span></label>
                  <input
                    type="date" name="issuedate" id="issuedate"
                    value={issuedDate}
                    onChange={handleissuedDateChange}
                  />
                </div>
              </div>



              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="issued">Issued By <span>:</span></label>
                  <input
                    type="text" name="issued" id="issued"
                    value={issuedby}
                    onChange={handleissuedByChange}
                  />
                </div>
              </div>
            {/* </div> */}
            {status === 'Not Approved' && (
              <div className="RegisFormcon leavecon1" >
                <div className="RegisForm_1 leaveform_1 leaveaiftesatg">
                  <label htmlFor="reason">Reason <span>:</span></label>
                  <textarea
                    type="text" name="reason" id="" onChange={handleReasonChange}></textarea>
                </div>
              </div>
            )}
            <div className="Register_btn_con regster_btn_contsai">
              <button className="RegisterForm_1_btns" onClick={handleSubmit}>Submit</button>
              <button
                className="RegisterForm_1_btns"
                onClick={closemodal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {openModal1 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal1(false) }}
        >
          <div className="newwProfiles newwPopupforreason uwagduaguleaveauto" onClick={(e) => e.stopPropagation()}>
            <div className="appointment">
              <div className='h_head'>
                <h4>Previous Advance Amount</h4>
              </div>
              <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th id="slectbill_ins">Request Date</th>
                      <th id="slectbill_ins">Advance Amount</th>
                      <th id="slectbill_ins">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {advanceamountdata.length > 0 ? advanceamountdata.map((leave, index) => (
                      <tr key={index}>
                        <td>{leave.RequestDate}</td>
                        <td>{leave.RequestAmount}</td>
                        <td>{leave.Status}</td>
                      </tr>
                    )) : (
                      <tr>
                        <td colSpan="4">No Records found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

              </div>
            </div>
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={() => setOpenModal1(false)}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddvaceApproval;




