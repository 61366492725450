import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
  Filler,
  DoughnutController,
  PieController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Doughnut, Pie, PolarArea } from "react-chartjs-2";
import { Card, CardContent, Typography, Box } from "@mui/material";
import {
  faCalendarCheck,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./MainDashboard.css";
import { useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../ClinicMetrics/ClinicMetrics.css";

// Register components used in the chart
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  LineElement,
  LineController,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  RadialLinearScale,
  Filler,
  DoughnutController,
  PieController
);

const MainDashboard = () => {
  const [alignment, setAlignment] = React.useState("Monthly");
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [pieData, setPieData] = useState({
    labels: [],
    datasets: [{ data: [], backgroundColor: [] }],
  });
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log(alignment);
  const handleChange = (event) => {
    setAlignment(event.target.value);
    console.log(alignment);
  };

  const data = {
    labels: [
      "Pending",
      "Sample Collected",
      "Sample Received",
      "Result Completed",
      "Validated",
      "Approved",
      "Dispatched",
    ],
    datasets: [
      {
        data: [10, 20, 15, 25, 5, 15, 10],
        backgroundColor: [
          "#f44336",
          "#2196f3",
          "#4caf50",
          "#ff9800",
          "#9c27b0",
          "#00bcd4",
          "#8bc34a",
        ],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const data = tooltipItem.dataset.data;
            const total = data.reduce((acc, curr) => acc + curr, 0);
            const value = data[tooltipItem.dataIndex];
            const percentage = ((value / total) * 100).toFixed(2);
            return `${tooltipItem.label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  const totalData = data.datasets[0].data.reduce((acc, curr) => acc + curr, 0);
  const customLegend = data.labels.map((label, index) => {
    const value = data.datasets[0].data[index];
    const percentage = ((value / totalData) * 100).toFixed(2);
    return { label, value, percentage };
  });

  const doughnutData = {
    labels: [
      "Biochemistry",
      "Hematology",
      "Clinical Pathology",
      "Microbiology",
      "Molecular Biology",
      "Virology",
      "Histopathology",
      "Cytopathology",
    ],
    datasets: [
      {
        data: [500, 400, 300, 200, 150, 100, 50, 30],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#E7E9ED",
          "#4BC0C0",
          "#F7464A",
          "#FFB6C1",
          "#C2C2C2",
        ],
      },
    ],
  };

  useEffect(() => {
    axios
      .get(`${urllink}clinicmetrics/getPatientGenderpieData?Location=${userRecord?.location}`)
      .then((res) => {
        console.log(res.data);
        setPieData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(
        `${urllink}clinicmetrics/getappointmentdata?Timeperiod=${alignment}&Location=${userRecord?.location}`
      )
      .then((res) => {
        console.log(res.data);  
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink, alignment,userRecord]);

  const pieData2 = {
    labels: ["Total Billed", "Collection", "Due"],
    datasets: [
      {
        data: [400, 50, 150],
        backgroundColor: ["#ff6384", "#36a2eb", "#ffce56"],
      },
    ],
  };

  const employeesData = {
    labels: ["Shift 1", "Shift 2", "Night Shift"],
    datasets: [
      {
        label: "Male",
        data: [10, 12, 6],
        type: "bar",
        backgroundColor: "#007bff",
        stack: "stack1",
      },
      {
        label: "Female",
        data: [8, 7, 5],
        type: "bar",
        backgroundColor: "#dc3545",
        stack: "stack1",
      },
    ],
  };

  const employeesOptions = {
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Dashboard</h4>
      </div>
      <div className="chart_body_1_head">
        <ToggleButtonGroup
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton
            value="Monthly"
            style={{
              backgroundColor:
                alignment === "Monthly" ? "var(--ProjectColor)" : "inherit",
              width: "100px",
            }}
            className="togglebutton_container"
          >
            Monthly
          </ToggleButton>
          <ToggleButton
            value="Yearly"
            style={{
              width: "100px",
              backgroundColor:
                alignment === "Yearly" ? "var(--ProjectColor)" : "inherit",
            }}
            className="togglebutton_container"
          >
            Yearly
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
      <br />
      <div className="rgrdfg_7u">
        <div className="djfhueiki_ui">
          <div className="patient-details">
            <h3>Patient Details</h3>
            <div className="pie-chart">
              <Pie
                data={pieData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      position: "top",
                    },
                    tooltip: {
                      callbacks: {
                        label: function (tooltipItem) {
                          const label = tooltipItem.label || "";
                          return `${label}`;
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className="cards-container">
          <h3>Appointment Status</h3>
          <div className="dsjje_p03w">
            <div className="char_Mnewx Completed-sales">
              <h4>Booked</h4>
              <h3>486</h3>
              <FontAwesomeIcon
                icon={faCalendarCheck}
                style={{ fontSize: "30px" }}
              />
            </div>
            <div className="char_Mnewx Canceled">
              <h4>Canceled</h4>
              <h3>42</h3>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ fontSize: "30px" }}
              />
            </div>
          </div>

          <div className="char_Mnewx Booked-Booked4">
            <h4>Completed</h4>
            <h3>1641</h3>
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{ fontSize: "30px" }}
            />
          </div>
        </div>

        <div className="djfhueiki_ui">
          <div className="patient-details">
            <h3>Revenue</h3>
            <div className="pie-chart">
              <Pie data={pieData2} />
            </div>
          </div>
        </div>
      </div>

      <div className="charts-container">
        <div className="chart_MM unique-visitors">
          <h3>Test Status</h3>
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            <Box>
              {customLegend.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" mb={1}>
                  <Box
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <Typography>
                    {item.label}: {item.value} ({item.percentage}%)
                  </Typography>
                </Box>
              ))}
            </Box>

            <div className="pie-chart-polar">
              <PolarArea
                className="pie-chart-polar-olk"
                data={data}
                options={options}
              />
            </div>
          </Box>
        </div>

        <div className="chart_MM customers_p93">
          <h3>Revenue by Department</h3>
          <div className="customers_p93_Doughnt">
            <Doughnut
              className="customers_p93_Doughnt_od4"
              data={doughnutData}
            />
          </div>
        </div>

        <div className="chart_MM employees_pp">
          <h3>Employees</h3>
          <Chart type="bar" data={employeesData} options={employeesOptions} />
        </div>
      </div>
      <br />
    </div>
  );
};

export default MainDashboard;
