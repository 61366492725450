import React, { useEffect, useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import Button from "@mui/material/Button";
import "jspdf-autotable";
import DownloadIcon from "@mui/icons-material/Download";

function RegisReport() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [datetype, setdatetype] = useState("");
  const [currentdate, setcurrentdate] = useState("");
  const [fromdate, setfromdate] = useState("");
  const [todate, settodate] = useState("");
  const [ClinicDetials, setClinicDetials] = useState([]);

  const handleonchange = (e) => {
    const { name, value, files } = e.target;
    if (name === "datetype") {
      setdatetype(value);
      setfromdate("");
      settodate("");
      setcurrentdate("");
    } else if (name === "CurrentDate") {
      setcurrentdate(value);
      setfromdate("");
      settodate("");
    } else if (name === "FromDate") {
      setfromdate(value);
      setcurrentdate("");
    } else if (name === "ToDate") {
      settodate(value);
      setcurrentdate("");
    }
  };

  const handledownload = () => {
    const datatosend = {
      currentdate: currentdate,
      datetype: datetype,
      fromdate: fromdate,
      todate: todate,
    };

    if (datetype) {
      if (datetype === "Currentdate" && !currentdate) {
        alert("Current date must be provided.");
        return;
      } else if (datetype === "Customize" && (!fromdate || !todate)) {
        alert("From date and To date must be provided.");
        return;
      }
    } else {
      alert("Select Date Type");
      return;
    }

    axios
      .get(`${urllink}Billing/get_RegisReport_For_Download`, {
        params: datatosend,
      })
      .then((res) => {
        const data = res.data; // Your data
        console.log(data);
        // Create a new jsPDF instance
        const doc = new jsPDF({ orientation: "landscape" });

        // Fetch clinic details
        axios
          .get(
            `${urllink}usercontrol/getClinic?location=${userRecord?.location}`
          )
          .then((response) => {
            const clinicData = response.data[0];
            if (clinicData) {
              const {
                ClinicName,
                doorNo,
                street,
                area,
                city,
                state,
                country,
                pincode,
              } = clinicData;

              // Header
              doc.setFontSize(16);
              doc.setFont("helvetica", "bold");
              doc.text("REGISTER OF LABORATORY TEST CONDUCTED", 90, 10);

              doc.setFontSize(12);
              doc.text(
                `Clinical Laboratory ${" "} : ${" "} ${" "} ${ClinicName}`,
                12,
                20
              );

              doc.setFontSize(9);
              doc.text(`${doorNo}, ${street}, ${area}, ${city},`, 60, 25);
              doc.text(`${state}, ${country} - ${pincode}`, 60, 30);
              doc.setFontSize(12);
              doc.text(`System Of Medicine ${" "} : `, 10, 40);
              doc.text(`Date ${" "} : `, 41, 46);

              doc.text(`TamilNadu Clinical Establishment Regulation`, 180, 20);
              doc.text(`Act Registration No ${" "} : `, 180, 30);

              // Prepare the data for AutoTable
              const rows = data.map((item, index) => {
                const patientName = item.Patient_Name || "-";
                const address = item.communicationAddress || "-";
                const ageSex = `${item.Age || ""}/${item.Gender || ""}` || "-";
                const idNum = "-";
                const refDoctor =
                  item.Testdetails && Object.values(item.Testdetails).length
                    ? Object.values(item.Testdetails)
                        .flat()
                        .find((details) => details.doctor_name)?.doctor_name ||
                      "-"
                    : "-";
                const provisionalDiagnosis = "-";

                const testNames = Object.keys(item.Testdetails).length
                  ? Object.keys(item.Testdetails)
                      .map((invoice) => {
                        const tests = item.Testdetails[invoice];
                        console.log("teststeststests : ", tests);
                        if (tests) {
                          return tests.tests
                            .map((test) => test.testdetails?.Test_Name || "- ")
                            .join(", ");
                        }
                        return "-";
                      })
                      .join(", ")
                  : "";
                console.log("testNames :", testNames);

                const specimenList = Object.keys(item.Testdetails).length
                  ? Object.keys(item.Testdetails)
                      .map((invoice) => {
                        const tests = item.Testdetails[invoice];
                        if (tests) {
                          return tests.tests
                            .map((test) => test.testdetails?.Specimen || "-")
                            .join(", ");
                        }
                        return "-";
                      })
                      .join(", ")
                  : "";

                const methodList = Object.keys(item.Testdetails).length
                  ? Object.keys(item.Testdetails)
                      .map((invoice) => {
                        const tests = item.Testdetails[invoice];
                        if (tests) {
                          return tests.tests
                            .map((test) => test.testdetails?.Method || "-")
                            .join(", ");
                        }
                        return "-";
                      })
                      .join(", ")
                  : "";

                const captureValues = Object.keys(item.Testdetails).length
                  ? Object.keys(item.Testdetails)
                      .map((invoice) => {
                        const tests = item.Testdetails[invoice];
                        if (tests) {
                          return tests.tests
                            .map((test) => test.capture_value || "Pending")
                            .join(", ");
                        }
                        return "-";
                      })
                      .join(", ")
                  : "";

                const result = "-";
                const additionalInfo = "-";
                const LabOfficerSign = "";

                return [
                  index + 1, // Serial number
                  `${patientName}\n${address}`, // Combined patient info
                  ageSex,
                  idNum,
                  refDoctor,
                  provisionalDiagnosis,
                  specimenList,
                  testNames, // Test names column
                  methodList, // Test method column
                  captureValues, // Capture values column
                  result,
                  additionalInfo,
                  LabOfficerSign,
                ];
              });

              // Add the AutoTable to the PDF
              doc.autoTable({
                head: [
                  [
                    "S.No.",
                    "Patient Info",
                    "Age/Sex",
                    "ID Num",
                    "Ref Doctor",
                    "Diagnosis",
                    "Specimen",
                    "Test Names",
                    "Test Method",
                    "Result",
                    "Additional Information",
                    "Lab Officer Sign",
                  ],
                ],
                body: rows,
                margin: { top: 60 }, // Adjust margin to accommodate header
                theme: "striped",
                styles: { cellPadding: 2, fontSize: 6 },
                columnStyles: {
                  1: { cellWidth: "wrap" },
                },
              });

              // Save the PDF
              doc.save("report.pdf");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Register Report</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="datetype">
            Date Type<span>:</span>
          </label>
          <select
            type="text"
            name="datetype"
            id="datetype"
            required
            onChange={handleonchange}
          >
            <option value="">Select</option>
            <option value="Currentdate">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>
        {datetype === "Currentdate" && (
          <div className="RegisForm_1">
            <label htmlFor="CurrentDate">
              Current Date<span>:</span>
            </label>
            <input
              type="date"
              name="CurrentDate"
              id="CurrentDate"
              required
              onChange={handleonchange}
            ></input>
          </div>
        )}

        {datetype === "Customize" && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="FromDate">
                From Date<span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                id="FromDate"
                required
                onChange={handleonchange}
              ></input>
            </div>
            <div className="RegisForm_1">
              <label htmlFor="ToDate">
                To Date<span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                id="ToDate"
                required
                onChange={handleonchange}
              ></input>
            </div>
          </>
        )}
      </div>
      <br />
      <div className="Register_btn_con">
        <button onClick={handledownload} className="RegisterForm_1_btns">
          <DownloadIcon />
        </button>
      </div>
    </div>
  );
}

export default RegisReport;
