import * as React from "react";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ForwardIcon from "@mui/icons-material/Forward";
import ReactGrid from "../../OtherComponent/ReactGrid/ReactGrid";

const DueHistoryList = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [summa, setsumma] = useState([]);
  const dispatchvalue = useDispatch();
  const [filteredRows, setFilteredRows] = useState([]);

  const handleRequestEdit = (params) => {
    const Data = summa.find((p) => p.id === params.id);

    console.log("Data", Data);

    dispatchvalue({ type: "SelectedPateintDeuBilling", value: Data });

    navigate("/Home/Deu-History");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    // if(Object.keys(userRecord).length !==0){
    console.log(urllink);
    axios
      .get(
        `${urllink}Billing/get_General_billing_due_list?location=${userRecord.location}`
      )
      .then((response) => {
        const Datas = response.data;

        console.log("Daaa", Datas);

        setsumma(
          Datas.map((row, ind) => ({
            id: ind + 1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // }
  }, [userRecord, urllink]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  // Define the columns dynamically

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Invoice_NO",
      name: "Invoice No",
      frozen: true,
      width: 200,
    },
    {
      key: "PatientID",
      name: "Patient Id",
    },
    {
      key: "PatientName",
      name: "Patient Name",
    },
    {
      key: "PhoneNumber",
      name: "Phone Number",
    },
    {
      key: "VisitId",
      name: "Visit Id",
    },
    {
      key: "Billing_Date",
      name: "Billing Date",
    },
    {
      key: "NetAmount",
      name: "Net Amount",
    },
    {
      key: "PaidAmount",
      name: "Paid Amount",
    },
    {
      key: "BalanceAmount",
      name: "Balance Amount",
    },

    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          onClick={() => handleRequestEdit(params.row)}
          startIcon={<ForwardIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        ></Button>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Due Billing List</h4>
        </div>
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Enter the Patient Name"
              />
            </div>

            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
          </div>
        </div>
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns1} RowData={filteredRows} />
        </div>
      </div>
    </>
  );
};
export default DueHistoryList;
