import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../Billing/OverallBilling/GeneralBilling.css";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import AddCardIcon from "@mui/icons-material/AddCard";

const BillEdit = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [testname, setTestName] = useState([]);
  const [testmethod, settestmethod] = useState("Individual");
  const [selecteddepartment, setselecteddepartment] = useState("");
  const [department, setdepartment] = useState([]);
  const [testnamebydepartment, settestnamebydepartment] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selecttest, setselecttest] = useState("");
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const [checkedTests, setCheckedTests] = useState([]);
  const [invoicedata, setinvoicedata] = useState([]);
  console.log("invoicedata :", invoicedata);
  const formatAmountWithCommas = (amount) => {
    if (isNaN(amount)) return amount; // Return if not a valid number

    // Convert amount to a number and format it with commas
    const number = Number(amount);
    const formatted = number.toLocaleString("en-US", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });

    return formatted;
  };
  const [formData, setFormData] = useState({
    totalAmount: 0,
    Billpay_method: "",
    CardType: "",
    CardNumber: "",
    CardName: "",
    UPI_Mob: "",
    TransactionId: "",
    // Add other fields if necessary
  });
  const [Amountdetails, setAmountdetails] = useState([]);
  console.log(Amountdetails);
  console.log(formData);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  // const [selectrow, setselectrow] = useState({});
  console.log("receivedAmount :", receivedAmount);
  //   const [checkedTests1, setCheckedTests1] = useState([]); // Track checked tests
  const [selectedTests, setSelectedTests] = useState([]);
  const [isEdit, setisEdit] = useState(false);
  console.log(selectedTests);

  const totalAmount1 = selectedTests.reduce(
    (total, test) => total + Number(test.amount),
    0
  );

  const totalPaidAmount = Amountdetails.reduce(
    (sum, item) => sum + Number(item.paidamount),
    0
  );

  console.log("totalPaidAmount :", totalPaidAmount);

  const Deuamount = +totalAmount1 - +totalPaidAmount;
  console.log("Deuamount", Deuamount);
  // const isChecked = (test) => {
  //   return selectedTests.some(
  //     (checkedTest) => checkedTest.Test_Code === test.Test_Code
  //   );
  // };
  const [Billpay_method, setBillpay_method] = useState("Cash");
  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  // const [Dname, setDname] = useState("");

  const [Billing_date, setBilling_date] = useState("");
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [searchQuery, setSearchQuery] = useState({
    BillType: "",
    SearchID: "",
    SearchInvoiceNo: "",
    SearchBillingDate: "",
  });
  // const [amount, setamount] = useState();
  // const [data, setData] = useState([])

  const [EditList, setEditList] = useState([]);
  console.log(EditList);
  // const [discount, setdiscount] = useState();
  // const [Reason, setReason] = useState({
  //   reason: "",
  //   ApprovedBy: userRecord?.username,
  //   refundAmount: 0,
  //   RefundBilledBy: "",
  //   Billpay_method: "",
  // });

  console.log();
  // const [selectedBillType, setSelectedBillType] = useState("");
  // const [selectDoctorList, setselectDoctorList] = useState([]);
  // const [labcancellist, setlabcancellist] = useState([]);
  // useEffect(() => {
  //   setReason((prev) => ({
  //     ...prev,
  //     RefundBilledBy: userRecord.username,
  //   }));
  // }, [userRecord.username]);

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${urllink}Billing/get_payment_discout_value?invoice=${searchQuery.SearchInvoiceNo}&location=${userRecord?.location}`
  //     )
  //     .then((response) => {
  //       // console.log(response);
  //       const data = response.data;
  //       setdiscount(data);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }, [searchQuery.SearchInvoiceNo, userRecord?.location, urllink]);

  // console.log('labcancellist..................', discount)

  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
      return "Zero Rupees Only";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return (
          units[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convert(num % 100) : "")
        );
      if (num < 100000)
        return (
          convert(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
        );
      if (num < 10000000)
        return (
          convert(Math.floor(num / 100000)) +
          " Lakh" +
          (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
        );
    };

    return convert(num) + " Rupees  Only";
  };

  // --
  useEffect(() => {
    const currentDate = new Date();
    setBilling_date(format(currentDate, "dd-MM-yyyy"));
    // clinic detials
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord.location}`)
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        // console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.area + "," + data.street,
            ClinicGST: data.gstno,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchQuery.SearchInvoiceNo, urllink, userRecord]);

  const handlechange = (e) => {
    const { name, value } = e.target;

    setSearchQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    // setamount("");

    if (!searchQuery.SearchInvoiceNo) {
      userwarn("Enter Invoice No");
      return;
    }
    if (!searchQuery.SearchBillingDate) {
      userwarn("Select Date");
      return;
    }

    // setReason((prev) => ({
    //   ...prev,
    //   reason: "",
    //   ApprovedBy: userRecord?.username,
    // }));
    const newSearchQuery = {
      billing_invoice: searchQuery.SearchInvoiceNo
        ? searchQuery.SearchInvoiceNo
        : "Null",
      bill_date: searchQuery.SearchBillingDate
        ? searchQuery.SearchBillingDate
        : "Null",
    };
    // console.table(newSearchQuery);
    if (
      newSearchQuery.billing_invoice !== "Null" ||
      newSearchQuery.bill_date !== "Null"
    ) {
      if (
        newSearchQuery.bill_date !== "Null" &&
        newSearchQuery.billing_invoice !== "Null"
      ) {
        axios
          .get(
            `${urllink}Billing/get_billing_details_by_invoice_forbilledit?invoiceno=${newSearchQuery.billing_invoice}&Date=${newSearchQuery.bill_date}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response.data);
            const data = response.data;
            console.log("data :", data);
            if (data.length === 0) {
              userwarn(
                `No data Available for this  Invoice: ${searchQuery?.SearchInvoiceNo} Date: ${searchQuery.SearchBillingDate}`
              );
            } else {
              setinvoicedata(data);
              setEditList(data[0]?.testdetails);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      alert("please Enter the fields");
    }
  };

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontentcancel"),
    onBeforePrint: () => {
      // console.log('Before');
    },
    onAfterPrint: async () => {
      // setPdfBlob(null);
      // console.log('After');
      const printdata = document.getElementById("reactprintcontentcancel");
      // console.log('printdata', printdata);

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20;
          const pdfWidth = contentWidth + 2 * padding;
          const pdfHeight = contentWidth * 1.5;
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });
          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content
            callback: () => {
              const generatedPdfBlob = pdf.output("blob");
              console.log("generatedPdfBlob", generatedPdfBlob);

              const formData = new FormData();

              // Convert arrays to JSON strings for easier inclusion in FormData
              formData.append("selectedTests", JSON.stringify(selectedTests));
              formData.append("Amountdetails", JSON.stringify(Amountdetails));
              formData.append("EditedBill", generatedPdfBlob);
              formData.append("Patient_Id", invoicedata[0]?.Patient_Id);
              formData.append("Visit_Id", invoicedata[0]?.Visit_Id);
              formData.append(
                "Billing_Invoice",
                invoicedata[0]?.Billing_Invoice
              );
              formData.append("Patient_Name", invoicedata[0]?.Patient_Name);
              formData.append("Item_Count", selectedTests.length);
              formData.append("Status", Deuamount === 0 ? "Paid" : "Deu");
              formData.append("Barcode_Id", invoicedata[0]?.Barcode_Id);
              formData.append("totalPaidAmount", totalPaidAmount);
              formData.append("Deuamount", Deuamount);
              formData.append("Location", userRecord?.location);
              formData.append("CreatedBy", userRecord?.username);
              formData.append("totalAmount1", totalAmount1);
              formData.append("RateCard_Name", invoicedata[0]?.RateCard_Name);
              axios
                .post(`${urllink}Billing/Insert_EditedBill`, formData)
                .then((res) => {
                  console.log(res);
                  successMsg("Saved Successfully");
                  // Navigate('/Home/')
                  setinvoicedata([])
                })
                .catch((err) => {
                  console.error(err);
                });

              setIsPrintButtonVisible(true);
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
        // setpreview(false)
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const handleSubmit = () => {
    setIsPrintButtonVisible(false);
    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  useEffect(() => {
    axios
      .get(`${urllink}Billing/get_alldepartment_for_billing`)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setdepartment(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urllink]);

  const handleCheckboxChange1 = (event, test) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setCheckedTests((prevCheckedTests) => [...prevCheckedTests, test]);
    } else {
      setCheckedTests((prevCheckedTests) =>
        prevCheckedTests.filter((t) => t.Test_Code !== test.Test_Code)
      );
    }
  };

  const handleAddCheckedTests = () => {
    let testPromises;

    console.log(selecttest);

    if (testmethod !== "Individual") {
      // For group testmethod, use a single axios request without Promise.all
      axios
        .get(
          `${urllink}Billing/get_All_billing_amount_data_forbilledit?testcode=${selecttest}&RateCard_Name=${invoicedata[0]?.RateCard_Name}&testmethod=${testmethod}`
        )
        .then((response) => {
          const newTest = {
            id: selectedTests.length + 1,
            testCode: selecttest, // Since selecttest is used in group case
            amount: response.data[0].Amount,
            testname: response.data[0]?.Testname,
            testmethod: testmethod,
          };

          // Check if the test is already in selectedTests
          if (
            !selectedTests.some((test) => test.testCode === newTest.testCode)
          ) {
            setSelectedTests((prevSelectedTests) => [
              ...prevSelectedTests,
              newTest,
            ]);
          }

          // Update total amount
          const updatedTotalAmount = totalAmount + Number(newTest.amount);
          setFormData((prevFormData) => ({
            ...prevFormData,
            totalAmount: updatedTotalAmount,
          }));

          // Update modal and clear checked tests
          setOpenModal(false);
          setCheckedTests([]);
        })
        .catch((error) => {
          console.log(error);
          // Handle errors
        });
    } else {
      // For "Individual", handle multiple promises
      testPromises = checkedTests.map((test) =>
        axios.get(
          `${urllink}Billing/get_All_billing_amount_data_forbilledit?testcode=${test.Test_Code}&RateCard_Name=${invoicedata[0]?.RateCard_Name}&testmethod=${testmethod}`
        )
      );

      Promise.all(testPromises)
        .then((responses) => {
          const newTests = responses.map((response, index) => ({
            id: selectedTests.length + index + 1,
            testCode: checkedTests[index].Test_Code,
            amount: response.data[0].Amount,
            testname: response.data[0]?.Testname,
            testmethod: testmethod,
          }));

          // Check for duplicates in selectedTests
          const existingTestCodes = new Set(
            selectedTests.map((test) => test.testCode)
          );

          const uniqueNewTests = newTests.filter(
            (test) => !existingTestCodes.has(test.testCode)
          );

          setSelectedTests((prevSelectedTests) => [
            ...prevSelectedTests,
            ...uniqueNewTests,
          ]);

          settestnamebydepartment((prevTests) =>
            prevTests.filter(
              (test) =>
                !uniqueNewTests.some(
                  (newTest) => newTest.testCode === test.Test_Code
                )
            )
          );

          // Recalculate total amount
          const updatedTotalAmount = uniqueNewTests.reduce(
            (total, test) => total + Number(test.amount),
            totalAmount
          );

          setFormData((prevFormData) => ({
            ...prevFormData,
            totalAmount: updatedTotalAmount,
          }));

          setOpenModal(false);
          setCheckedTests([]);
        })
        .catch((error) => {
          console.log(error);
          // Handle errors
        });
    }
  };

  const handleRemoveTests = (testCode) => {
    // Remove the test from selectedTests
    const updatedSelectedTests = selectedTests.filter(
      (test) => test.testCode !== testCode
    );

    // Remove the test from checkedTests
    const updatedCheckedTests = checkedTests.filter(
      (test) => test.Test_Code !== testCode
    );

    // Find the removed test for adding it back to testnamebydepartment
    const removedTest = selectedTests.find(
      (test) => test.testCode === testCode
    );
    if (removedTest) {
      settestnamebydepartment((prevTests) => [
        ...prevTests,
        {
          Test_Code: removedTest.testCode,
          Test_Name: removedTest.testname,
        },
      ]);
    }

    // Update selectedTests state
    setSelectedTests(updatedSelectedTests);

    // Update checkedTests state
    setCheckedTests(updatedCheckedTests);

    // Recalculate total amount
    const updatedTotalAmount = updatedSelectedTests.reduce(
      (total, test) => total + Number(test.amount),
      0
    );

    // Update total amount state
    setTotalAmount(updatedTotalAmount);

    // Update formData with the new total amount
    setFormData((prevFormData) => ({
      ...prevFormData,
      totalAmount: updatedTotalAmount,
    }));
    setAmountdetails([]);
  };

  const handleBillingModeChange = (event) => {
    const { value } = event.target;
    console.log(value);
    console.log("value", value);

    settestmethod(value);
    if (value === "Individual") {
      axios
        .get(
          `${urllink}usercontrol/getalltestname?gender=${invoicedata[0]?.gender}`
        )
        .then((response) => {
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (value === "Group") {
      axios
        .get(`${urllink}usercontrol/getallgroupname`)
        .then((response) => {
          console.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .get(`${urllink}usercontrol/get_all_package_name`)
        .then((response) => {
          console.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "Department") {
      setselecteddepartment(value);
    }
  };

  const openviewtest = () => {
    console.log("hai");
    if (selecteddepartment) {
      axios
        .get(
          `${urllink}Billing/getalltestnamebydepartment?selecteddepartment=${selecteddepartment}`
        )
        .then((response) => {
          console.log(response);
          const data = response.data;
          settestnamebydepartment(data);
        })
        .catch((error) => {
          console.error(error);
        });
      setOpenModal(true);
    } else {
      console.log("else");
      userwarn("Select Department");
    }
  };

  // const handleclosemodal1 = () => {
  //   setOpenModal(false);
  //   // setCheckedTests([]);
  // };
  // const handleSearch1 = () => {};

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleInputChangePay = (event) => {
    const { name, value } = event.target;

    if (name === "paidamount") {
      const amount = Number(value);
      setReceivedAmount(amount);

      // if (!isEdit) {

      const newamount = totalAmount1 - formData?.totalAmount;

      if (newamount !== 0) {
        if (amount > newamount) {
          userwarn(
            `Received amount cannot be greater than the Total amount: ${newamount}.`
          );
          setReceivedAmount("");
          return;
        }
      }
      // } else{

      // }

      // Validate received amount
    } else if (name === "Billpay_method") {
      setBillpay_method(value);
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleAdd = () => {
    const receivedAmountNumber = Number(receivedAmount);

    // Validation logic based on payment method
    if (formData.Billpay_method === "Cash") {
      if (receivedAmountNumber <= 0) {
        userwarn("Received amount must be greater than zero.");
        return;
      }
    } else if (formData.Billpay_method === "Card") {
      if (!formData.CardType || !formData.CardNumber || !formData.CardName) {
        userwarn(
          "Card Type, Card Number, and Card Name are required for Card payments."
        );
        return;
      }
    } else if (formData.Billpay_method === "OnlinePayment") {
      if (!formData.UPI_Mob || !formData.TransactionId) {
        userwarn(
          "UPI Mobile Number and Transaction ID are required for Online Payments."
        );
        return;
      }
    } else {
      userwarn("Please select a valid payment mode.");
      return;
    }

    // Auto-increment ID based on the length of the current Amountdetails array
    const newId = Amountdetails.length + 1;

    // Append the current formData to the existing Amountdetails array, with auto-incrementing id
    setAmountdetails((prevAmountdetails) => [
      ...prevAmountdetails,
      { ...formData, totalAmount: receivedAmountNumber, id: newId },
    ]);

    setReceivedAmount("");

    // For debugging purposes
    console.log("Updated Amountdetails:", [
      ...Amountdetails,
      { ...formData, totalAmount: receivedAmountNumber, id: newId },
    ]);
  };

  const handleUpdate = () => {
    const receivedAmountNumber = Number(receivedAmount);
    console.log(receivedAmountNumber);

    setAmountdetails((prevAmountdetails) =>
      prevAmountdetails.map((item) =>
        item.Billpay_method === formData.Billpay_method
          ? {
              ...formData,
              Billpay_method: formData?.Billpay_method,
              totalAmount: receivedAmountNumber,
            }
          : item
      )
    );

    // Clear form fields after updating
    setReceivedAmount("");
    // setBillpay_method("");
    setFormData((prevFormData) => ({
      ...prevFormData, // Spread the previous formData to keep existing values
      totalAmount: receivedAmountNumber,
      CardType: "",
      CardNumber: "",
      CardName: "",
      UPI_Mob: "",
      TransactionId: "",
    }));

    // setselectrow(null); // Reset the selected row
    setisEdit(false); // Reset the edit mode

    console.log("Updated Amountdetails:", Amountdetails);
  };

  const handleeditamount = (row) => {
    setFormData({
      totalAmount: row.totalAmount, // Set this based on your requirements
      Billpay_method: row.Billpay_method,
      CardType: row.CardType || "", // Ensure optional fields are handled
      CardNumber: row.CardNumber || "",
      CardName: row.CardName || "",
      UPI_Mob: row.UPI_Mob || "",
      TransactionId: row.TransactionId || "",
    });

    setReceivedAmount(row.totalAmount); // Set receivedAmount based on the row
    setBillpay_method(row.Billpay_method); // Optional, depends on your form structure

    // Set the selected row for updating
    // setselectrow(row);
    setisEdit(true);
  };

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Patient_Id",
      name: "Patient Id",
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice No",
      frozen: true,
    },
    {
      key: "Test_Name",
      name: "Service Name",
      width: 300,
    },
    {
      key: "Test_Method",
      name: "Service Method",
    },
    {
      key: "CreatedAt",
      name: "Biling Date",
    },
    {
      key: "Gross_Amount",
      name: "Amount",
    },
  ];

  const dynamicColumns2 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "testname",
      name: "Service Name",
      width: 350,
      frozen: true,
    },
    {
      key: "amount",
      name: "Amount",
      renderCell: (params) => formatAmountWithCommas(params.row.amount),
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleRemoveTests(params.row.testCode)}
          startIcon={<DeleteOutlineTwoToneIcon />}
        ></Button>
      ),
    },
  ];

  const dynamicColumns3 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billpay_method",
      name: "Payment Mode",
      width: 350,
      frozen: true,
    },
    {
      key: "amount",
      name: "Amount",
      renderCell: (params) => formatAmountWithCommas(params.row.paidamount),
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleeditamount(params.row)}
          startIcon={<EditTwoToneIcon />}
        ></Button>
      ),
    },
  ];

  const forPrintData = () => {
    return (
      <div
        className="billing-invoice fhfghf_u_hhhh"
        id="reactprintcontentcancel"
      >
        <div className="New_billlling_invoice_head gnfghhfgh">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new__jhtf55">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <br />

        <div className="drefgrgree">
          <div className="drefgrgree_head">
            <div className="drefgrgree2">
              <div className="drefgrgree33">
                <label>
                  Patient Name <span>:</span>
                </label>
                <h4>
                  {" "}
                  {`${invoicedata[0]?.Patient_Name} - (${invoicedata[0]?.Gender} / ${invoicedata[0]?.Age})`}{" "}
                </h4>
              </div>
              <div className="drefgrgree33">
                <label>
                  Patient ID <span>:</span>
                </label>
                <h4>{invoicedata[0]?.Patient_Id}</h4>
              </div>
              <div className="drefgrgree33">
                <label>
                  Patient Mobile No <span>:</span>
                </label>
                <h4>{invoicedata[0]?.Phone}</h4>
              </div>
            </div>
          </div>
          <div>
            <div className="drefgrgree33">
              <label>
                Date <span>:</span>
              </label>
              <h4>{Billing_date}</h4>
            </div>
            <div className="drefgrgree33">
              <label>
                Invoice No <span>:</span>
              </label>
              <h4>{searchQuery.SearchInvoiceNo}</h4>
            </div>
            <div className="drefgrgree33">
              <label>
                SID No <span>:</span>
              </label>
              <h4>{invoicedata[0]?.Barcode_Id}</h4>
            </div>
          </div>
        </div>

        <h3
          style={{
            display: "flex",
            justifyContent: "center",
            color: "var(--labelcolor)",
            fontWeight: 600,
            padding: "10px",
          }}
        >
          Bill Edit
        </h3>

        <br />

        <div
          className="kkk_ghfg"
          // style={{ width: "85%", marginTop: "10px" }}
        >
          <table>
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Service Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {selectedTests.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.testname}</td>
                  <td>{row.amount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
          <div className="fdfgdfgd">
            <div className="total_con_bill_jj">
              <div className="nhbyh_5443f7">
                <label>
                  {" "}
                  Items Count <span>:</span>
                </label>
                <span>{selectedTests.length}</span>
              </div>

              <div className="nhbyh_5443f7">
                <label>
                  Total Amount <span>:</span>
                </label>
                <span>{totalAmount1}</span>
              </div>
            </div>

            <div className="total_con_bill_jj">
              {Amountdetails.map((row, index) => (
                <>
                  <div key={index} className="nhbyh_5443f7">
                    {/* <div className="item-index">{index + 1}</div> */}
                    <label>
                      {row.Billpay_method}
                      <span>:</span>
                    </label>
                    <span>{formatAmountWithCommas(row.paidamount)}</span>
                  </div>

                  {Deuamount > 0 && (
                    // <div className="total_con_bill_jj">
                    <div className="nhbyh_5443f7">
                      {/* <div className="item-index">{index + 1}</div> */}
                      <label>
                        Deu Amount
                        <span>:</span>
                      </label>
                      <span>{formatAmountWithCommas(Deuamount)}</span>
                    </div>
                    // </div>
                  )}
                </>
              ))}
            </div>

            <div className="total_con_bill_jj">
              <div className="nhbyh_5443f7">
                <label>
                  {" "}
                  Billed By <span>:</span>
                </label>
                <span>{userRecord?.username}</span>
              </div>
            </div>

            <div className="fdfgdfgd">
              <div className="total_con_bill_jj">
                <div className="nhbyh_5443f7">
                  <label>
                    {" "}
                    Amount In Words<span>:</span>
                  </label>
                  <span style={{ color: "grey" }}>
                    {numberToWords(+totalPaidAmount)}{" "}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="signature-section909">
            <p className="disclaimer23">
              This page is created automatically without a signature.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPrintButtonVisible ? (
        <>
          <div className="appointment">
            <div className="h_head">
              <h4> Bill Edit</h4>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px 10px",
              }}
            >
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Billing Invoice No <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="SearchInvoiceNo"
                    value={searchQuery.SearchInvoiceNo}
                    onChange={handlechange}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Billing Date <span>:</span>{" "}
                  </label>
                  <input
                    type="date"
                    name="SearchBillingDate"
                    value={searchQuery.SearchBillingDate}
                    onChange={handlechange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSearch}>
                Search
              </button>
            </div>
            <br />
            {console.log(EditList)}

            {EditList && EditList[0]?.Test_Method === "Group" && (
              <div className="Add_items_Purchase_Master">
                <span>Group Name : {EditList[0]?.Group_Name}</span>
              </div>
            )}

            {EditList && EditList.length !== 0 && (
              <>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label
                      // className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor=""
                    >
                      Billing Mode <span>:</span>
                    </label>
                    <select
                      id="testmethod"
                      name="testmethod"
                      // className="Supplier_Master_form_Con_div_Row_input"
                      value={testmethod}
                      onChange={handleBillingModeChange}
                    >
                      {/* <option value="">Select</option> */}
                      <option value="Individual">Individual</option>
                      <option value="Group">Group</option>
                      <option value="Offers">Packages</option>
                    </select>
                  </div>

                  {testmethod === "Individual" && (
                    <>
                      <div className="RegisForm_1">
                        <label
                          // className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor=""
                        >
                          Department:
                        </label>
                        <select
                          id="Department"
                          name="Department"
                          // className="Supplier_Master_form_Con_div_Row_input"
                          value={selecteddepartment}
                          onChange={handleInputChange}
                        >
                          <option value="">Select</option>
                          {console.log(department)}
                          {Array.isArray(department) &&
                            department.map((department) => (
                              <option
                                key={department.sub_department_code}
                                value={department.sub_department_code}
                              >
                                {department.sub_department_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="RegisForm_1">
                        <label
                          // className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor=""
                        >
                          View <span>:</span>
                        </label>
                        {/* <VisibilityIcon  /> */}
                        <Button className="cell_btn" onClick={openviewtest}>
                          <VisibilityIcon className="check_box_clrr_cancell" />
                        </Button>
                      </div>
                    </>
                  )}

                  {testmethod !== "Individual" && (
                    <div className="RegisForm_1 smalefhiu">
                      <label
                        // className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Group Name<span>:</span>
                      </label>
                      <input
                        id="testname"
                        name="testname"
                        list="browsers1"
                        value={selecttest}
                        onChange={(e) => setselecttest(e.target.value)}
                        // placeholder="Enter your Address"
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                      {console.log(testname)}
                      {testmethod === "Offers" ? (
                        <datalist id="browsers1">
                          {Array.isArray(testname) &&
                            testname?.map((item, index) => (
                              <option key={index} value={item.package_code}>
                                {item.package_name}
                              </option>
                            ))}
                        </datalist>
                      ) : (
                        <datalist id="browsers1">
                          {Array.isArray(testname) &&
                            testname?.map((item, index) => (
                              <option key={index} value={item.groupcode}>
                                {item.groupname}
                              </option>
                            ))}
                        </datalist>
                      )}

                      <button
                        className="krfekj_09"
                        onClick={handleAddCheckedTests}
                      >
                        <AddCardIcon />
                      </button>
                    </div>
                  )}
                </div>
                <br />
                <div className="Add_items_Purchase_Master">
                  <span>Test Details</span>
                </div>
                <div className="Main_container_app">
                  <ReactGrid columns={dynamicColumns1} RowData={EditList} />
                </div>

                <br />
                {console.log(selectedTests)}
                {selectedTests.length > 0 && (
                  <>
                    <div className="Add_items_Purchase_Master">
                      <span>New Test</span>
                    </div>
                    <div className="Main_container_app">
                      <ReactGrid
                        columns={dynamicColumns2}
                        RowData={selectedTests}
                      />
                    </div>
                    <br />
                    {selectedTests.length > 0 && (
                      <tr
                        style={{
                          display: "flex",
                          gap: "27px",
                          justifyContent: "center",
                          color: "red",
                          fontFamily: "auto",
                          fontSize: "20px",
                        }}
                      >
                        <td>Total :</td>
                        <td>{formatAmountWithCommas(totalAmount1)}</td>
                      </tr>
                    )}
                    <br />
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          htmlFor="Billpay_method"
                          className="Supplier_Master_form_Con_div_Row_label"
                        >
                          Payment Mode<span>:</span>
                        </label>
                        <select
                          id="dispatchMode"
                          name="Billpay_method"
                          value={Billpay_method}
                          onChange={handleInputChangePay}
                          className="Supplier_Master_form_Con_div_Row_input"
                        >
                          <option value="">Select</option>
                          <option value="Cash">Cash</option>
                          <option value="Card">Card</option>
                          <option value="OnlinePayment">Online Payment</option>
                        </select>
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          htmlFor="webSite"
                          className="Supplier_Master_form_Con_div_Row_label"
                        >
                          Received Amount<span>:</span>
                        </label>

                        <input
                          type="number"
                          name="paidamount"
                          value={receivedAmount}
                          onChange={handleInputChangePay}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>
                    {console.log(Billpay_method)}
                    <br />
                    {Billpay_method === "Card" ? (
                      <>
                        <div className="Supplier_Master_form_Con_Row">
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              htmlFor="CardName"
                              className="Supplier_Master_form_Con_div_Row_label"
                            >
                              Card Type <span>:</span>
                            </label>
                            <select
                              name="CardType"
                              value={Billpay_method}
                              onChange={handleInputChangePay}
                              className="Supplier_Master_form_Con_div_Row_input"
                            >
                              <option value="">Select</option>
                              <option value="Credit">Credit</option>
                              <option value="Debit">Debit</option>
                            </select>
                          </div>
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              htmlFor="CardNumber"
                              className="Supplier_Master_form_Con_div_Row_label"
                            >
                              Card Number<span>:</span>
                            </label>
                            <input
                              type="text"
                              name="CardNumber"
                              value={formData?.CardNumber}
                              onChange={handleInputChangePay}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                        </div>
                        <div className="Supplier_Master_form_Con_Row">
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="CardName"
                            >
                              Card Name <span>:</span>
                            </label>
                            <input
                              type="text"
                              name="CardName"
                              // placeholder="Enter Discount"
                              value={formData.CardName}
                              onChange={handleInputChangePay}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                        </div>
                      </>
                    ) : null}
                    {Billpay_method === "OnlinePayment" && (
                      <>
                        <div className="Supplier_Master_form_Con_Row">
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              htmlFor="TransactionId"
                              className="Supplier_Master_form_Con_div_Row_label"
                            >
                              OnlinePayment Number/ UPI Id <span>:</span>
                            </label>
                            <input
                              type="text"
                              name="UPI_Mob"
                              value={formData?.UPI_Mob}
                              onChange={handleInputChangePay}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              htmlFor="TransactionId"
                              className="Supplier_Master_form_Con_div_Row_label"
                            >
                              Transaction Id <span>:</span>
                            </label>
                            <input
                              type="text"
                              name="TransactionId"
                              onChange={handleInputChangePay}
                              value={formData?.TransactionId}
                              required
                              className="Supplier_Master_form_Con_div_Row_input"
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="Register_btn_con">
                      <button
                        className="RegisterForm_1_btns"
                        onClick={isEdit ? handleUpdate : handleAdd}
                      >
                        {isEdit ? "Update" : "Add"}
                      </button>
                    </div>
                    {Amountdetails?.length > 0 && (
                      <div className="Main_container_app">
                        <ReactGrid
                          columns={dynamicColumns3}
                          RowData={Amountdetails}
                        />
                      </div>
                    )}
                    <br />
                    <div className="Register_btn_con">
                      <button
                        className="RegisterForm_1_btns"
                        onClick={handleSubmit}
                      >
                        Print
                      </button>
                    </div>
                  </>
                )}
              </>
            )}

            {openModal && (
              <div
                className={
                  isSidebarOpen
                    ? "sideopen_showcamera_profile"
                    : "showcamera_profile"
                }
                onClick={() => setOpenModal(false)}
              >
                <div
                  className="newwProfiles"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="ghvyt67_9o0">
                    {testnamebydepartment.map((test, index) => (
                      <div key={index} className="antibioticnames_list33">
                        <label htmlFor={`test-${index}`}>
                          <input
                            type="checkbox"
                            name="testArray"
                            id={`test-${index}`}
                            value={test.Test_Code}
                            checked={checkedTests.some(
                              (t) => t.Test_Code === test.Test_Code
                            )}
                            onChange={(e) => handleCheckboxChange1(e, test)}
                            disabled={selectedTests.some(
                              (t) => t.testCode === test.Test_Code
                            )}
                          />
                          {test.Test_Name}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="Register_btn_con">
                    <button
                      className="RegisterForm_1_btns"
                      onClick={() => setOpenModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="RegisterForm_1_btns"
                      onClick={handleAddCheckedTests} // Add all checked tests when button is clicked
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        </>
      ) : (
        forPrintData()
      )}
    </>
  );
};
export default BillEdit;
