import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import axios from "axios";
import Button from '@mui/material/Button';




function EmployeeAppraisalList() {

    // const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const urllink = useSelector((state) => state.userRecord?.UrlLink);
    const [searchQuery1, setSearchQuery1] = useState("");
    const [searchQuery2, setSearchQuery2] = useState("");
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [rolename, setRolename] = useState([]);
    // const [openModal, setOpenModal] = useState(false);
    // const [selectedrows, setselectedrows] = useState([]);
    const [page, setPage] = useState(0);
    const pageSize = 10;

    const totalPages = Math.ceil(filteredRows.length / pageSize);

    const paginatedData = filteredRows.slice(page * pageSize, (page + 1) * pageSize);



    const [performanceformdata, setperformanceformdata] = useState({
        employeeId: "",
        employeeName: "",
        date: "",
        performance: "",
        hike: "",
        amount: "",
        remarks: "",
        current: "",
        newpay: "",
        approvedby: '',
        location: userRecord?.location,
        createdby: userRecord?.username,
    })


    const resettheformdata = () => {
        setperformanceformdata({
            EmployeeID: "",
            EmployeeName: "",
            AppraisalDate: "",
            PerformanceRate: "",
            HikePercentage: "",
            HikeAmount: "",
            NewPay: "",
        });
    };

    console.log(performanceformdata)


    useEffect(() => {

        axios.get(`${urllink}HRmanagement/getRole_all`)
            .then((response) => {
                setRolename(response.data);
            })
            .catch((error) => {
                console.error(error);
            });

    }, [userRecord?.location, urllink]);


    const fetchappraisallist = () => {
        axios
            .get(`${urllink}HRmanagement/getemployeelistforappraisal?location=${userRecord?.location}`)
            .then((response) => {
                console.log(response)
                setRows(response.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        fetchappraisallist()
    },[])


    useEffect(() => {
        const filteredData = rows.filter((row) => {
            const lowerCaseDesignation = row.Designation.toLowerCase();
            const lowerCaseEmployeeID = row.EmployeeID.toLowerCase();


            const matchesemployeeid = lowerCaseEmployeeID.includes(
                searchQuery2.toLowerCase()
            );
            const matchesdegination = lowerCaseDesignation.includes(
                searchQuery1.toLowerCase()
            );


            return (
                (matchesemployeeid || !searchQuery2)
                &&
                (matchesdegination || !searchQuery1)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery1, searchQuery2, rows]);


    const handleEditClick = (params) => {

        setperformanceformdata((prevState) => ({
            ...prevState,
            employeeId: params.EmployeeID,
            employeeName: params.EmployeeName + ' ' + params.FatherName,
            current: params.Basic_Salary

        }));

    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setperformanceformdata((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };


    useEffect(() => {
        const employeeId = performanceformdata.employeeId;
        const hikepercentage = performanceformdata.hike;
        if (hikepercentage && hikepercentage >= 1) {
            axios
                .get(
                    `${urllink}HRmanagement/employee_performanceamount?employeeid=${employeeId}&location=${userRecord?.location}&hikepercentage=${hikepercentage}`
                )
                .then((response) => {
                    console.log(response.data);
                    if (response.data.error) {
                        alert(response.data.error);
                        setperformanceformdata((prevState) => ({
                            ...prevState,
                            amount: "",
                            newpay: "",
                        }));
                    } else {
                        setperformanceformdata((prevState) => ({
                            ...prevState,
                            amount: response.data[0]?.amount || "",
                            newpay: response.data[0]?.newpay || "",
                        }));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            console.log("EmployeeId must be 9 characters in length");
        }
    }, [performanceformdata.employeeId, performanceformdata.hike, userRecord, urllink]);


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(performanceformdata);

        const senddata = new FormData();

        Object.keys(performanceformdata).forEach((key) => {
            senddata.append(key, performanceformdata[key]);
        });

        axios
            .post(
                `${urllink}HRmanagement/insert_employee_performance`,
                senddata,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                console.log("response", response);
                alert("Performance Updated Sucessfully");
                fetchappraisallist();
                resettheformdata();
            })
            .catch((error) => {
                console.error(error);
            });
    };



    return (
        <div className="appointment">
            <div className="RegisFormcon">
                <div className="RegisForm_1">
                    <label htmlFor="employeeId">
                        Employee ID <span>:</span>{" "}
                    </label>
                    <input
                        type="text"
                        id="employeeId"
                        name="employeeId"
                        value={performanceformdata.employeeId}
                        onChange={handleChange}
                    />
                </div>

                <div className="RegisForm_1">
                    <label htmlFor="employeeName">
                        Employee Name <span>:</span>{" "}
                    </label>
                    <input
                        type="text"
                        id="employeeName"
                        name="employeeName"
                        value={performanceformdata.employeeName}
                        onChange={handleChange}
                        readOnly
                    />
                </div>

                <div className="RegisForm_1">
                    <label htmlFor="current">
                        Current Pay <span>:</span>
                    </label>
                    <input
                        type="text"
                        name="current"
                        onChange={handleChange}
                        value={performanceformdata.current}
                    />
                </div>
                <div className="RegisForm_1">
                    <label htmlFor="date">
                        Date <span>:</span>{" "}
                    </label>
                    <input
                        type="date"
                        id="date"
                        name="date"
                        value={performanceformdata.date}
                        onChange={handleChange} />
                </div>



                <div className="RegisForm_1">
                    <label htmlFor="performance">
                        Performance Rate <span>:</span>{" "}
                    </label>
                    <select id="performance" name="performance" onChange={handleChange}>
                        {[...Array(11)].map((_, index) => (
                            <option key={index} value={index}>
                                {index}/10
                            </option>
                        ))}
                    </select>
                </div>
                <div className="RegisForm_1">
                    <label htmlFor="hike">
                        Hike Percentage <span>:</span>
                    </label>
                    <input
                        type="text"
                        name="hike"
                        value={performanceformdata.hike}
                        onChange={handleChange}
                    />
                </div>


                <div className="RegisForm_1">
                    <label htmlFor="amount">
                        Hike Amount <span>:</span>
                    </label>
                    <input
                        type="text"
                        name="amount"

                        value={performanceformdata.amount}
                        onChange={handleChange}
                    />
                </div>
                <div className="RegisForm_1">
                    <label htmlFor="newpay">
                        New Pay <span>:</span>
                    </label>
                    <input
                        type="text"
                        name="newpay"
                        onChange={handleChange}
                        value={performanceformdata.newpay}
                    />
                </div>


                <div className="RegisForm_1">
                    <label htmlFor="remarks">
                        Remarks <span>:</span>
                    </label>
                    <textarea
                        name="remarks"
                        id="remarks"
                        cols="15"
                        rows="3"
                        value={performanceformdata.remarks}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="RegisForm_1">
                    <label htmlFor="approvedby">
                        Approved By <span>:</span>{" "}
                    </label>
                    <input
                        type="text"
                        id="approvedby"
                        name="approvedby"
                        value={performanceformdata.approvedby}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={handleSubmit}>
                    Save
                </button>
            </div>
            <br />
            <div className="con_1 ">
                <div className="inp_1">
                    <label htmlFor="input">Employee ID<span>:</span></label>
                    <input
                        type="text"
                        id="employeeID"
                        name="employeeID"
                        placeholder="Enter Employee ID"
                        value={searchQuery2}
                        onChange={(e) => setSearchQuery2(e.target.value)}
                    />
                </div>

                <div className="inp_1">
                    <label htmlFor="input">Designation <span>:</span></label>
                    <select
                        name="designation"
                        value={searchQuery1}
                        onChange={(e) => setSearchQuery1(e.target.value)}
                        className="new-custom-input-phone vital_select"
                        required
                    >
                        <option value="">Select </option>
                        {rolename.map((role) => (
                            <option key={role.role_id} value={role.role_name}>
                                {role.role_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                    <thead>
                        <tr>
                            <th id="slectbill_ins">Employee ID</th>
                            <th id="slectbill_ins">Employee Name</th>
                            <th id="slectbill_ins">Designation</th>
                            <th id="slectbill_ins">Date of Joining</th>
                            <th id="slectbill_ins">Current BasicPayment</th>
                            <th id="slectbill_ins">Action</th>

                        </tr>
                    </thead>
                    <tbody>
                        {paginatedData?.length > 0 && paginatedData?.map((leave, index) => (
                            <tr key={index}>
                                <td>{leave.EmployeeID}</td>
                                <td>{leave.EmployeeName}</td>
                                <td>{leave.Designation}</td>
                                <td>{leave.DateofJoining}</td>
                                <td>{leave.Designation === 'TRAINEE' ? leave.StipendAmount : leave.Basic_Salary}</td>
                                <td>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        size="small"
                                        onClick={() => handleEditClick(leave)}
                                        startIcon={<EditIcon />}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <div className="grid_foot">
                    <button
                        onClick={() =>
                            setPage((prevPage) => Math.max(prevPage - 1, 0))
                        }
                        disabled={page === 0}
                    >
                        Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                        onClick={() =>
                            setPage((prevPage) =>
                                Math.min(prevPage + 1, totalPages - 1)
                            )
                        }
                        disabled={page === totalPages - 1}
                    >
                        Next
                    </button>
                </div>
            )}
        </div>
    )
}

export default EmployeeAppraisalList;

