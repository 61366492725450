import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import "./Department.css";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";

function Department() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [departmentrole, setDepartmentRole] = useState([]);

  const [roleData, setRoleData] = React.useState([]);
  const [mainDepartmentName, setMainDepartmentName] = useState("");
  const [mainDepartmentCode, setMainDepartmentCode] = useState("");
  const [subdepartment, setSubDepartment] = useState("");
  const [subdepartmentCode, setSubDepartmentCode] = useState("");
  const [selectdept, setSelectdept] = useState("");
  const [isedit, setisedit] = useState(false);
  const [isedit1, setisedit1] = useState(false);

  const handleSubmitMainDepartment = async () => {
    if (!mainDepartmentName.trim() || !mainDepartmentCode.trim()) {
      alert("Both mainDepartmentName and mainDepartmentCode are required.");
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
      const response = await axios.post(
        `${urllink}mainddepartment/insertdepartment`,
        {
          mainDepartmentName,
          mainDepartmentCode,
        }
      );
      console.log(response);

      // Handle the response as needed
      fetchDepartmentCodeData("");
      // Optionally, you can reset the state after a successful submission
      setMainDepartmentName("");
      fetchDepartmentData();
      setisedit(false);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchDepartmentCodeData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/Get_DeprtmentCodeForMaster`)
      .then((res) => {
        setMainDepartmentCode(res.data.department_code);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink]); // Empty dependency array to memoize the function

  const fetchSubDepartmentCodeData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/Get_SubDeprtmentCodeForMaster`)
      .then((res) => {
        setSubDepartmentCode(res.data.Subdepartment_code);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink]); // Empty dependency array to memoize the function

  useEffect(() => {
    fetchDepartmentCodeData();
    fetchSubDepartmentCodeData();
  }, [fetchDepartmentCodeData, fetchSubDepartmentCodeData]); // Now these are stable dependencies

  const handleSubmitSubDepartment = async () => {
    if (
      !subdepartment.trim() ||
      !subdepartmentCode.trim() ||
      !selectdept.trim()
    ) {
      alert(
        " subdepartment and subdepartmentCode and selectdept are required."
      );
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
      const response = await axios.post(
        `${urllink}mainddepartment/insertsubdepartment`,
        {
          subdepartment,
          subdepartmentCode,
          selectdept,
        }
      );
      console.log(response);

      // Handle the response as needed
      fetchSubDepartmentCodeData("");
      setSubDepartment("");
      fetchSubDepartmentData();
      setisedit1(false)
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenarios
    }
  };

  const fetchDepartmentData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getdepartmentdata`)
      .then((response) => {
        const data = response.data;

        setRoleData(data);
      })
      .catch((error) => {
        console.error("Error fetching Department data:", error);
      });
  }, [urllink]);

  const fetchSubDepartmentData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/get_sub_department_data_master`)
      .then((response) => {
        const data = response.data;

        setDepartmentRole(data);
      })
      .catch((error) => {
        console.error("Error fetching SubDepartment data:", error);
      });
  }, [urllink]);

  React.useEffect(() => {
    fetchSubDepartmentData();
    fetchDepartmentData();
  }, [fetchSubDepartmentData, fetchDepartmentData]);

  const handleEdit = (row) => {
    console.log(row);
    setMainDepartmentCode(row?.department_code);
    setMainDepartmentName(row?.department_name);
    setisedit(true);
  };

  const handleEdit1 = (row) => {
    console.log(row);
    setSelectdept(row.main_department_name);
    setSubDepartmentCode(row.subdepartment_code);
    setSubDepartment(row.subdepartment_name);
    setisedit1(true);
  };



  const hadleeditstatus = (params) => {
    console.log(params);

    let newstatus;
    if (params.Status === "Active") {
      newstatus = "Inactive";
    } else if (params.Status === "Inactive") {
      newstatus = "Active";
    }

    axios
      .post(
        `${urllink}usercontrol/update_departmentStatus?department_code=${params.department_code}&newstatus=${newstatus}`
      )
      .then((res) => {
        console.log(res);
        // successMsg(res.data.Message);
      })

      .catch((err) => {
        console.error();
      });
  };




  const hadleeditstatus1 = (params) => {
    console.log(params);

    let newstatus;
    if (params.Status === "Active") {
      newstatus = "Inactive";
    } else if (params.Status === "Inactive") {
      newstatus = "Active";
    }

    axios
      .post(
        `${urllink}usercontrol/update_subdepartmentStatus?subdepartment_code=${params.subdepartment_code}&newstatus=${newstatus}`
      )
      .then((res) => {
        console.log(res);
        // successMsg(res.data.Message);
      })

      .catch((err) => {
        console.error();
      });
  };


  const Maindepartmentcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "department_name",
      name: "Department",
    },
    // {
    //   key: "department_code",
    //   name: "Department Code",
    // },

    {
      key: "Status",
      name: "Status",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => hadleeditstatus(params.row)}
          style={{
            color: params.row.Status === "Active" ? "blue" : "red",
          }}
        >
          {params.row.Status}
        </Button>
      ),
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleEdit(params.row)}
          startIcon={<EditIcon />}
        ></Button>
      ),
    },
  ];

  const SubMaindepartmentcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "main_department_name",
      name: "Main Department",
    },
    {
      key: "subdepartment_name",
      name: "Department",
      width: 280,
    },
    // {
    //   key: "subdepartment_code",
    //   name: "Department Code",
    // },
    {
      key: "Status",
      name: "Status",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => hadleeditstatus1(params.row)}
          style={{
            color: params.row.Status === "Active" ? "blue" : "red",
          }}
        >
          {params.row.Status}
        </Button>
      ),
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleEdit1(params.row)}
          startIcon={<EditIcon />}
        ></Button>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <br />
        <div className="ShiftClosing_Contadiner">
          <h2 style={{ textAlign: "center" }}>Main Department</h2>
          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">
                  {" "}
                  Department Code <span>:</span>
                </label>
                <input
                  type="text"
                  id="MainDepartmentCode"
                  name="MainDepartmentCode"
                  value={mainDepartmentCode}
                  disabled
                  onChange={(e) => setMainDepartmentCode(e.target.value)}
                  placeholder="Main Department Code"
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input">
                  Department <span>:</span>
                </label>

                <input
                  type="text"
                  id="MainDepartmentName"
                  name="MainDepartmentName"
                  value={mainDepartmentName}
                  onChange={(e) => setMainDepartmentName(e.target.value)}
                  placeholder="Main Department Name"
                />
              </div>

              <button
                className="RegisterForm_1_btns"
                onClick={handleSubmitMainDepartment}
              >
                {isedit ? "Update" : <AddIcon />}
              </button>
            </div>
          </div>

          <div className="Main_container_app">
            <ReactGrid columns={Maindepartmentcolumns} RowData={roleData} />
          </div>
        </div>

        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Department</h2>
          <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input" RegisForm_1>
                  Main Department <span>:</span>
                </label>
                <select
                  name="department"
                  value={selectdept}
                  onChange={(e) => setSelectdept(e.target.value)}
                  className="deprtsele"
                  required
                >
                  <option value="select">Select </option>
                  {roleData.map((row, index) => (
                    <option key={row.id} value={row.department_name}>
                      {row.department_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inp_1">
                <label htmlFor="input">
                  Department Code <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={subdepartmentCode}
                  disabled
                  onChange={(e) => setSubDepartmentCode(e.target.value)}
                  placeholder="Enter Department Code"
                />
              </div>
            </div>
            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">
                  Department <span>:</span>
                </label>
                <input
                  type="text"
                  id="FirstName"
                  name="roleName"
                  value={subdepartment}
                  onChange={(e) => setSubDepartment(e.target.value)}
                  placeholder="Enter Department Name"
                />
              </div>

              <button
                className="RegisterForm_1_btns"
                onClick={handleSubmitSubDepartment}
              >
                {isedit1 ? "Update" : <AddIcon />}
              </button>
            </div>
          </div>
          <div className="Main_container_app">
            <ReactGrid
              columns={SubMaindepartmentcolumns}
              RowData={departmentrole}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Department;
