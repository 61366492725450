import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import LoupeIcon from "@mui/icons-material/Loupe";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function LabIndentRaiseList() {
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const navigate = useNavigate();
  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  const [statustype, setstatustype] = useState("");
  const [Rowdata, setRowdata] = useState([]);

  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  useEffect(() => {
    if (Productype === "Medical" && statustype !=='') {
      axios
        .get(
          `${urllink}LabInventory/get_medical_purchase_Raise_indent_data?Location=${userRecord?.location}&Status=${statustype}&Productype=${Productype}`
        )
        .then((response) => {
          const data = response.data.medical_purchase_orders;
          console.log("out", data.medical_purchase_orders);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching patients data:", error);
        });
    
    } 
    
    else if ((Productype === "NonMedical" || Productype === "Stationary" ) && statustype !=='') {
      axios
        .get(
          `${urllink}LabInventory/get_Nonmedical_purchase_Raise_indent_data?Location=${userRecord?.location}&Status=${statustype}&Productype=${Productype}`
        )
        .then((response) => {
          const data = response.data.medical_purchase_orders;
          console.log("out", data.assets_purchase_orders);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching patients data:", error);
        });
    } 
  }, [Productype, statustype]);

  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    const index = params.row.items;
    console.log("index:", index);
    let B_data = index.map((p, index) => ({
      id: index + 1,
      ...p,
    }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    console.log("ss", summa);

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.IndentRaise_InvoiceNo.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handleRequestForm = () => {
    navigate("/Home/LabIndentRaiseMaster");
  };

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);

  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: "IndentRaise_InvoiceNo",
      headerName: "IndentRaise InvoiceNo",
      width: 170,
    },
    { field: "RequestDate", headerName: "Request Raise Date", width: 200 },
    { field: "RequestFrom", headerName: "Request From", width: 150 },
    { field: "RequestTo", headerName: "Request To", width: 130 },
    { field: "Reason", headerName: "Reason", width: 120 },
    { field: "Status", headerName: "Status", width: 100 },
    {
      field: "View",
      headerName: "View",
      width: 100,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];

  const dynamicColumns1 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Generic_Name", headerName: "Generic Name", width: 150 },
    { field: "Strength", headerName: "Strength", width: 120 },
    { field: "UOM", headerName: "UOM", width: 120 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },    
    { field: "Status", headerName: "Status", width: 100 },
  ];

  const dynamicColumns2 = [
    { field: "Item_Code", headerName: "Item Code", width: 180 },
    { field: "Item_Name", headerName: "Item Name", width: 220 },
    { field: "Pack_type", headerName: "Pack Type", width: 180 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Status", headerName: "Status", width: 100 },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>Indent Raise List Lab</h4>
          <div className="doctor_select_1_90">
            <label htmlFor="Calender"> Product Type <span>:</span></label>
            <select
              className="Product_Master_div_select_opt"
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }}
            >
              <option value="">Selec</option>
                <option value="Medical">Medical</option>
                <option value="NonMedical">Non Medical</option>
                <option value="Stationary">Stationary</option>
            </select>
          </div>
        </div>
        <br />

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Invoice No <span>:</span></label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter Supplier Code"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Status <span>:</span></label>
            <select
              value={statustype}
              onChange={(e) => setstatustype(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Waiting">Waiting</option>
              <option value="Rejected">Rejected</option>
              <option value="Issued">Issued</option>
            </select>
          </div>
          <button
            className="Supplier_Master_btn_1"
            type="submit"
            onClick={() => handleRequestForm()}
            title="New Supplier"
          >
            <LoupeIcon />
          </button>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
          <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
    className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype}List</h3>
                  </div>
                  <div className="con_1 "></div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={
                            Productype === "Medical"
                              ? dynamicColumns1
                              : dynamicColumns2
                          } // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
