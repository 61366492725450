import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import ForwardIcon from "@mui/icons-material/Forward";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import { useCallback } from "react";

function CancelAppointmentDialog({
  open,
  onClose,
  onConfirm,
  cancelReason,
  setCancelReason,
}) {
  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
  };

  const handleClose = () => {
    setCancelReason("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Cancellation Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function RequestList() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [RequestData, setRequestData] = useState([]);
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [viewtest, setviewtest] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const dispatchvalue = useDispatch();
  const [Status, setStatus] = useState("Pending");
  const [SearchQuery2, setSearchQuery2] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelAppointment_Request_Id, setcancelAppointment_Request_Id] =
    useState(null);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_AppoinmentRequest_data?Status=${Status}&Location=${userRecord?.location}&SearchQuery2=${SearchQuery2}`
      )
      .then((res) => {
        console.log(res);
        setRequestData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink, Status, userRecord?.location, SearchQuery2]);

  const handlenavigate = () => {
    navigate("/Home/Appoinment-Request-Lab");
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    } else if (id === "RequestDate") {
      setSearchQuery2(value);
    }
  };

  useEffect(() => {
    const filteredData = RequestData.filter((RequestData) => {
      const lowerCaseSupplierName = RequestData?.Patient_Name.toLowerCase();
      const lowerCasePhoneNo = RequestData?.Phone_Number.toString();

      const matchesFirstName = lowerCaseSupplierName.includes(
        searchQuery?.toLowerCase()
      );
      const matchesPhoneNo = lowerCasePhoneNo.includes(
        searchQuery1?.toLowerCase()
      );

      return (
        (matchesFirstName || !searchQuery) && (matchesPhoneNo || !searchQuery1)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, RequestData]);

  const handleViewTest = (params) => {
    console.log(params);
    setviewtest(params.testdetails);
    setOpenModal(true);
  };

  const handlenavigatetobilling = (params) => {
    dispatchvalue({ type: "RequestDataforBilling", value: params });
    navigate("/Home/ReceptionistBilling");
  };

  const handlenavigatecalendar = (params) => {
    navigate("/Home/Lab-Calendar");
  };

  const handleDelete = (params) => {
    const selectedId = params.Appointment_Request_Id;
    console.log("Selected ID:", selectedId);
    setcancelAppointment_Request_Id(selectedId);
    setOpenDialog(true);
  };

  const handleCancelAppointment = () => {
    console.log("Cancellation Reason:", cancelReason);
    console.log("cancelAppointment_Request_Id:", cancelAppointment_Request_Id);

    const confirmation = window.confirm(
      "Are you sure you want to cancel the appointment?"
    );
    console.log(confirmation);

    if (confirmation) {
      axios
        .post(
          `${urllink}Billing/Post_deleted_appointment_patientrequest?cancelAppointment_Request_Id=${cancelAppointment_Request_Id}&cancelReason=${cancelReason}`
        )
        .then((response) => {
          console.log(response.data.message);
          alert("successfully deleted");
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
      setCancelReason("");
      setOpenDialog(false);
    } else {
      setCancelReason("");
      setOpenDialog(false);
    }
  };

  const dynamicColumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Appointment_Request_Id",
      name: "Request ID",
      frozen: true,
      width: 150,
    },
    {
      key: "RequestDate",
      name: "Request Date",
      frozen: true,
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
    },

    {
      key: "Phone_Number",
      name: "Phone",
    },

    {
      key: "BranchLocation",
      name: "Branch Location",
    },

    {
      key: "PatientLocation",
      name: "Patient Location",
    },
    {
      key: "TestCount",
      name: "View Tests",
      width: 250,
      renderCell: (params) => (
        <>
          {params.row.testdetails.length > 1 ? (
            <Button
              onClick={() => handleViewTest(params.row)}
              startIcon={<VisibilityIcon />}
              sx={{
                color: "var(--ProjectColor)",
                "&:hover": {
                  color: "var(--ProjectColorhover)",
                },
              }}
            ></Button>
          ) : (
            params.row.testdetails[0]?.Test_Name
          )}
        </>
      ),
    },

    {
      key: "DeletAction",
      name: "Cancel",
      renderCell: (params) =>
        params.row.Status === "Pending" ? (
          <Button
            onClick={() => handleDelete(params.row)}
            startIcon={<DeleteForeverIcon />}
            sx={{
              // color: "var(--ProjectColor)",
              color: "red",
              "&:hover": {
                // color: "var(--ProjectColorhover)",
                color: "#ff5959",
              },
            }}
          ></Button>
        ) : (
          <div>-</div>
        ),
    },

    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) =>
        params.row.Status === "Pending" ? (
          <Button
            onClick={() => handlenavigatetobilling(params.row)}
            startIcon={<ForwardIcon />}
            sx={{
              // color: "var(--ProjectColor)",
              color: "#00c94a",
              "&:hover": {
                // color: "var(--ProjectColorhover)",
                color: "green",
              },
            }}
          ></Button>
        ) : (
          <div>-</div>
        ),
    },
  ];

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Test_Name",
      name: "Test Name",
      frozen: true,
      width: 250,
    },
  ];

  return (
    <div className="appointment">
      <div className="h_head h_head_h_2">
        <h4>Request List</h4>
        <div className="doctor_select_1_90">
          <label htmlFor="Status">
            {" "}
            Product Type <span>:</span>
          </label>
          <select
            id="Status"
            name="Status"
            value={Status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        </div>
      </div>
      <br />
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">
              Patient Name <span>:</span>
            </label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Phone No <span>:</span>
            </label>
            <input
              type="number"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter Phone No"
            />
          </div>

          <div className="inp_1">
            <label htmlFor="RequestDate">
              Request Date <span>:</span>
            </label>
            <input
              type="date"
              id="RequestDate"
              value={SearchQuery2}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <div className="con_1 ">
          <button className="btn_1" type="submit" onClick={handlenavigate}>
            New Request
          </button>
          {/* </div>
        <div className="con_1 "> */}
          <button
            className="btn_1"
            type="submit"
            onClick={handlenavigatecalendar}
          >
            Calendar
          </button>
        </div>
      </div>

      <div className="Main_container_app">
        <ReactGrid columns={dynamicColumns} RowData={filteredRows} />
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason uwagduaguleaveauto"
            onClick={(e) => e.stopPropagation()}
          >
            <br />
            <div className="appointment">
              <div className="h_head">
                <h4>Tests</h4>
              </div>
              <div className="Main_container_app">
                <ReactGrid columns={dynamicColumns1} RowData={viewtest} />
              </div>
            </div>

            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <CancelAppointmentDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleCancelAppointment}
        setCancelReason={setCancelReason}
        cancelReason={cancelReason}
      />
    </div>
  );
}

export default RequestList;
