import React from 'react';
import './Confirmationmodal.css';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <p>{message}</p>
        <div className="modal-actions">
          <button onClick={onConfirm} className="modal-button yes-button">Yes</button>
          <button onClick={onCancel} className="modal-button no-button">No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;

