import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";



const PaymentHistory = () => {
  const [filteredRows, setFilteredRows] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [serachBranch, setserachBranch] = useState([]);
  const [GETData, setGETData] = useState({
    Location: "",
    BilledBy: "ALL",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });
  useEffect(() => {
    // const username = userRecord.username;
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setserachBranch(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  }, [urllink]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setGETData({
          ...GETData,
          CurrentDate: format(new Date(), "yyyy-MM-dd"),
          [name]: value,
        });
      } else {
        setGETData({
          ...GETData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setGETData({
        ...GETData,
        [name]: value,
      });
    }
  };

  const handletoSearch = () => {
    let req = ["Location", "BilledBy", "DateType"];
    if (GETData.DateType === "Customize") {
      req = ["FromDate", "ToDate"];
    }
    const exist = req.filter((f) => !GETData[f]);
    if (exist.length === 0) {
      axios
        .get(
          `${urllink}Billing/getpatienthistorydata?DateType=${GETData.DateType}&FromDate=${GETData.FromDate}&ToDate=${GETData.ToDate}&CurrentDate=${GETData.CurrentDate}&BilledBy=${GETData.BilledBy}&Location=${GETData.Location}`
        )
        .then((response) => {
          if (response.data?.error) {
            alert(response.data.error);
            setFilteredRows([]);
          } else {
            const data = response.data || [];
            console.log(data);
            setFilteredRows(
              data.map((row, index) => ({
                id: index + 1,
                ...row,
              }))
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching payment history data:", error);
        });
    } else {
      alert(`plese Fill the required Fields : ${exist.join(",")}`);
    }
  };
  const handleExportToExcel = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "S.NO", dataKey: "id" },
        { header: "Billing Invoice", dataKey: "Billing_Invoice" },
        { header: "Patient Id", dataKey: "Patient_Id" },
        { header: "Patient Name", dataKey: "Patient_Name" },
        { header: "Visit Id", dataKey: "Visit_Id" },
        { header: "Phone", dataKey: "Phone" },
        { header: "Reference Doctor", dataKey: "Refering_Doctor" },
        { dataKey: "Discount_Type", header: "Discount Type" },
        { dataKey: "Discount", header: "Discount" },
        { dataKey: "Net_Amount", header: "Net Amount" },
        { dataKey: "Cash", header: "Cash" },
        { dataKey: "Card", header: "Card" },
        { dataKey: "OnlinePayment", header: "Online Payment" },
        { dataKey: "Status", header: "Bill Status" },
        { dataKey: "Total_Charge", header: "Charge" },
      ];

      const header = columns.map((col) => col.header);

      const csv = [
        "\ufeff" + header.join(","), // BOM + header row first
        ...filteredRows.map((row) => columns.map((col) => row[col.dataKey])),
        "", // Empty row for spacing
      ].join("\r\n");

      var data = new Blob([csv], { type: "text/csv" });
      saveAs(data, `${format(new Date(), "yyyy-MM-dd")}Report.csv`);
    } else {
      alert("No Data to Save");
    }
  };

  const handlePDF = () => {
    if (filteredRows.length !== 0) {
      const columns = [
        { header: "S.NO", dataKey: "id" },
        { header: "Billing Invoice", dataKey: "Billing_Invoice" },
        { header: "Patient Id", dataKey: "Patient_Id" },
        { header: "Patient Name", dataKey: "Patient_Name" },
        { header: "Visit Id", dataKey: "Visit_Id" },
        { header: "Phone", dataKey: "Phone" },
        { header: "Reference Doctor", dataKey: "Refering_Doctor" },
        { dataKey: "Discount_Type", header: "Discount Type" },
        { dataKey: "Discount", header: "Discount" },
        { dataKey: "Net_Amount", header: "Net Amount" },
        { dataKey: "Cash", header: "Cash" },
        { dataKey: "Card", header: "Card" },
        { dataKey: "OnlinePayment", header: "Online Payment" },
        { dataKey: "Status", header: "Bill Status" },
        { dataKey: "Total_Charge", header: "Charge" },
      ];

      const doc = new jsPDF({ orientation: "landscape" });

      // Title
      doc.setFontSize(18);
      doc.text("Payment History", 14, 22);

      const totalNetSalary = filteredRows.reduce((sum, row) => {
        return sum + (row.Total_Charge ? parseFloat(row.Total_Charge) : 0);
      }, 0);

      // Define autoTable options
      const autoTableOptions = {
        startY: 30,
        head: [columns.map((col) => col.header)],
        body: [
          ...filteredRows.map((row) =>
            columns.map((col) =>
              row[col.dataKey] != null && row[col.dataKey] !== ""
                ? row[col.dataKey].toString()
                : "-"
            )
          ),
          columns.map((col, index) =>
            index === 14
              ? {
                  content: `Total: ${totalNetSalary.toFixed(2)}`,
                  styles: { fontStyle: "bold", fontSize: 14 },
                }
              : ""
          ), // Add the total row with bold style and increased font size
        ],
        theme: "grid",
        styles: { fontSize: 10 },
        headStyles: { fillColor: [173, 216, 230], textColor: [0, 0, 0] },
        columnStyles: columns.reduce((styles, col, index) => {
          styles[index] = { cellWidth: "auto" };
          return styles;
        }, {}),
      };

      // Create table
      doc.autoTable(autoTableOptions);

      // Convert to Blob and use saveAs
      const blob = doc.output("blob");
      saveAs(blob, `Paymenthistory_${format(new Date(), "yyyy-MM-dd")}.pdf`);
    } else {
      alert("No Data to Save");
    }
  };

  const dynamicColumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Billing Invoice",
      frozen: true,
    },{
      key: "Patient_Name",
      name: "Patient Name",
      frozen: true,
    },
    {
      key: "Patient_Id",
      name: "Patient Id",
      width: 100,
    },
    

    {
      key: "Visit_Id",
      name: "Visit Id",
    },
    {
      key: "Phone",
      name: "Phone",
    },
    {
      key: "Refering_Doctor",
      name: "Reference Doctor",
    },
    {
      key: "Total_Charge",
      name: "Charge",
    },
    {
      key: "Discount_Type",
      name: "Discount Type",
    },
    {
      key: "Discount",
      name: "Discount",
    },
    {
      key: "Net_Amount",
      name: "Net Amount",
    },
    {
      key: "Cash",
      name: "Cash",
    },
    {
      key: "Card",
      name: "Card",
    },
    {
      key: "OnlinePayment",
      name: "Online Payment",
    },
    {
      key: "Status",
      name: "Bill Status",
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Payment History</h4>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="Location">
              Location <span>:</span>
            </label>
            <select
              name="Location"
              id="Location"
              value={GETData.Location}
              onChange={handleChange}
            >
              <option value="">Select</option>{" "}
              {/* Optional: add a default "select" option */}
              {serachBranch.map((loc) => (
                <option key={loc.location_id} value={loc.location_name}>
                  {loc.location_name}
                </option>
              ))}
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="">
              Date Type <span>:</span>
            </label>
            <select
              name="DateType"
              value={GETData.DateType}
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="Current">Current Date</option>
              <option value="Customize">Customize</option>
            </select>
          </div>

          {GETData.DateType === "Customize" && (
            <div className="RegisForm_1">
              <label htmlFor="">
                From Date <span>:</span>
              </label>
              <input
                type="date"
                name="FromDate"
                value={GETData.FromDate}
                onChange={handleChange}
              />
            </div>
          )}

          {GETData.DateType === "Customize" && (
            <div className="RegisForm_1">
              <label htmlFor="ToDate">
                To Date <span>:</span>
              </label>
              <input
                type="date"
                name="ToDate"
                value={GETData.ToDate}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
        <br />

        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handletoSearch}>
            Search
          </button>
        </div>
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns} RowData={filteredRows} />
        </div>

        {filteredRows.length !== 0 && (
          <div className="PrintExelPdf">
            <button onClick={handleExportToExcel}>Save Excel</button>
            <button onClick={handlePDF}>Save PDF</button>
          </div>
        )}
      </div>
    </>
  );
};

export default PaymentHistory;
