import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";

function SrValidatedQueue() {
  const [filteredRows, setFilteredRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [summa, setsumma] = useState([]);
  const [selectedrow, setselectedrow] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  useEffect(() => {
    // Fetch payment history data from the backend using Axios
    axios
      .get(`${urllink}Phelobotomist/get_record_from_lab_seniortechy_completed`)
      .then((response) => {
        const data = response.data.map((row, index) => ({
          id: index + 1, // Assuming you want to use Patient_Id as id
          ...row,
        }));
        setsumma(data);
        console.log("Backend Data:", data);
      })
      .catch((error) => {
        console.error("Error fetching payment history data:", error);
      });
  }, [urllink]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name
        ? row.Patient_Name.toLowerCase()
        : "";
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery)
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, summa]);

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    }
  };

  const handleView = (params) => {
    console.log(params);
    const data = params?.testdetails.map((row, index) => ({
      id: index + 1,
      ...row,
    }));
    setselectedrow(data);
    setOpenModal(true);
  };

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice",
      frozen: true,
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
      frozen: true,
      width: 300,
    },
    {
      key: "Patient_Id",
      name: "Patient Id",
      width: 100,
    },

    {
      key: "Test_Name",
      name: "View",
      renderCell: (params) => (
        <Button
          onClick={() => handleView(params.row)}
          startIcon={<VisibilityIcon />}
          sx={{
            color: "var(--ProjectColor)",
            "&:hover": {
              color: "var(--ProjectColorhover)",
            },
          }}
        ></Button>
      ),
    },
    {
      key: "Status",
      name: "Status",
    },
  ];

  const dynamicColumns2 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Test",
      name: "Test Name",
      frozen: true,
      width: 250,
    },
    {
      key: "Method",
      name: "Method",
    },
    {
      key: "Capture_Value",
      name: "Capture Value",
      width: 120,
    },

    {
      key: "Medical_Remarks",
      name: "Medical Remarks",
      width: 200,
    },
    {
      key: "Technical_Remark",
      name: "Technical Remark",
      width: 200,
    },
  ];

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Verification Completed List</h4>
      </div>
      <br />
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Patient Name <span>:</span></label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e, "name")}
              placeholder="Enter Patient Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone Number <span>:</span></label>
            <input
              type="text"
              value={searchQuery1}
              onChange={(e) => handleSearchChange(e, "phone")}
              placeholder="Enter Phone Number"
            />
          </div>
        </div>
      </div>
      <div className="Main_container_app">
        <ReactGrid columns={dynamicColumns1} RowData={filteredRows} />
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Main_container_app">
              <ReactGrid columns={dynamicColumns2} RowData={selectedrow} />
            </div>
            <div className="Register_btn_con">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SrValidatedQueue;
