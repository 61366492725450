import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function JournalentryFinance() {

  
  const cu_date = format(new Date(), "yyyy-MM-dd");

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [isedit, setisedit] = useState(false);

  const [Paymentlist, setPaymentlist] = useState([]);

  const [ExpensLiblitydata, SetExpensLiblitydata] = useState([]);

  const [FromAccountState, setFromAccountState] = useState({
    VoucherNo: "",
    VoucherDate: cu_date,
    VoucherNarration: "",
    FromAccountID: "", 
    FromAccount: "",        
    FromAccountOpnBalance: "",
    FromAccountCurBalance: "",
    DebitAmount: "",
  });

  const [ToAccountstate, setToAccountstate] = useState({
    ToAccountID: "",
    ToAccount: "",
    ToAccountCurBalance: "",
    ToAccountOpnBalance: "",
    BankDetailes: "",
    CreditAmount: "",
  });


  useEffect(() => {
    getContraNum();
  }, []);

  const getContraNum = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Journal_Voucher_invoice_no`)
      .then((response) => {
        const data = response.data.nextContraInvoiceNo;

        setFromAccountState((prev) => ({
          ...prev,
          VoucherNo: data,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    Allleddetailes();
  }, []);

 

  const Allleddetailes = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_for_PaymentVocher_Cur_balance`)
      .then((response) => {
        const data = response.data.LedgerEntries;

        SetExpensLiblitydata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const PaymentstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "FromAccount" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => ele.Account_Name === value);

      if (findData) {
        setFromAccountState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: findData.Current_Balance,
          FromAccountCurBalance: findData.Current_Balance,
          FromAccountID:findData.AccountID,
        }));
      } else {
        setFromAccountState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: "",
          FromAccountCurBalance: "",
          FromAccountID:"",
        }));
      }
    } 
    else if (name === "FromAccountID" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => +ele.AccountID === +value);

      if (findData) {
        setFromAccountState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: findData.Current_Balance,
          FromAccountCurBalance: findData.Current_Balance,
          FromAccount:findData.Account_Name,
        }));
      } else {
        setFromAccountState((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: "",
          FromAccountCurBalance: "",
          FromAccount:"",
        }));
      }
    } else {
      setFromAccountState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const ToAccountstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "ToAccount" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => ele.Account_Name === value);

      if (findData) {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: findData.Current_Balance,
          ToAccountCurBalance: findData.Current_Balance,
          BankDetailes: findData.BankDetailes,
          ToAccountID: findData.AccountID,

        }));
      } else {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountCurBalance: "",
          ToAccountOpnBalance: "",
          CreditAmount: "",
          ToAccountID:'',
        }));
      }
    }else if (name === "ToAccountID" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => +ele.AccountID === +value);

      if (findData) {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: findData.Current_Balance,
          ToAccountCurBalance: findData.Current_Balance,
          BankDetailes: findData.BankDetailes,
          ToAccount: findData.Account_Name,

        }));
      } else {
        setToAccountstate((prev) => ({
          ...prev,
          [name]: value,
          ToAccountCurBalance: "",
          ToAccountOpnBalance: "",
          CreditAmount: "",
          ToAccount:'',
        }));
      }
    } 
     else {
      setToAccountstate((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

 

  useEffect(() => {
    if (ToAccountstate.CreditAmount !== "") {
      const CrdAmo =
        +ToAccountstate.ToAccountOpnBalance +
        +ToAccountstate.CreditAmount;

      setToAccountstate((prev) => ({
        ...prev,
        ToAccountCurBalance: CrdAmo,
      }));
    } else {
      setToAccountstate((prev) => ({
        ...prev,
        ToAccountCurBalance: +ToAccountstate.ToAccountOpnBalance,
      }));
    }
  }, [ToAccountstate.CreditAmount]);

  useEffect(() => {
    if (FromAccountState.DebitAmount !== "") {
      const balAmo =
        +FromAccountState.FromAccountOpnBalance - +FromAccountState.DebitAmount;

      if (balAmo) {
        setFromAccountState((prev) => ({
          ...prev,
          FromAccountCurBalance: balAmo,
        }));
      } else {
        setFromAccountState((prev) => ({
          ...prev,
          FromAccountCurBalance: FromAccountState.FromAccountOpnBalance,
        }));
      }
    }
  }, [FromAccountState.DebitAmount]);

  useEffect(() => {
    if (Paymentlist.length !== 0) {
      const Amounttotal = Paymentlist.reduce(
        (total, ele) => total + +ele.CreditAmount,
        0
      );

      setFromAccountState((prev) => ({
        ...prev,
        DebitAmount: Amounttotal,
      }));
    } else {
      setFromAccountState((prev) => ({
        ...prev,
        DebitAmount: 0,
      }));
    }
  }, [Paymentlist]);



  const ClearContradata = () => {
    setFromAccountState({
      VoucherNo: "",
      VoucherDate: cu_date,
      VoucherNarration: "",
      FromAccount: "",
      FromAccountID: "", 
      FromAccountOpnBalance: "",
      FromAccountCurBalance: "",
      DebitAmount: "",
    });
  };

  const handlecleardata = () => {
    setToAccountstate({
      ToAccount: "",
      ToAccountCurBalance: "",
      ToAccountOpnBalance: "",
      BankDetailes: "",
      CreditAmount: "",
      ToAccountID: "",
    });
  };

  

 

  const add_data = () => {
    const requiredfields = [
      "ToAccount",
      "ToAccountCurBalance",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !ToAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.find(
        (ele) =>
          ele.ToAccount.toLowerCase() ===
          ToAccountstate.ToAccount.toLowerCase()
      );

      if (CheckName) {
        alert("ToAccount Already Exists");
      } else {
        setPaymentlist((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            ...ToAccountstate,
          },
        ]);

        handlecleardata();
      }

    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Editdataform = (row) => {
    if (row) {
      let paydatas = row.MulitPayment;
      setToAccountstate((prev) => ({
        ...prev,
        id: row.id,
        ToAccount: row.ToAccount,
        ToAccountCurBalance: row.ToAccountCurBalance,
        ToAccountOpnBalance: row.ToAccountOpnBalance,
        BankDetailes: row.BankDetailes,
        CreditAmount: row.CreditAmount,
        ToAccountID: row.ToAccountID,
      }));
    }
  };

  const Deletedataform = (row) => {
    setPaymentlist((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  

  const update_data = () => {
    const requiredfields = [
      "ToAccount",
      "ToAccountCurBalance",
      "CreditAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !ToAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.filter(
        (ele) => ele.id !== ToAccountstate.id
      ).find(
        (ele) =>
          ele.ToAccount.toLowerCase() ===
          ToAccountstate.ToAccount.toLowerCase()
      );

      if (CheckName) {
        alert("ToAccount Already Exists");
      } else {
        setPaymentlist((prevList) => {
          const updatedList = prevList.filter(
            (ele) => ele.ToAccount !== ToAccountstate.ToAccount
          );
          return [
            ...updatedList,
            { ...ToAccountstate},
          ];
        });

        handlecleardata();
        setisedit(false);
      }
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Postalldata = () => {
    const requiredfields = [
      "VoucherNo",
      "VoucherDate",
      "VoucherNarration",
      "FromAccount",
      "FromAccountCurBalance",
      "DebitAmount",
    ];

    const existing = requiredfields.filter((field) => !FromAccountState[field]);

    if (existing.length === 0) {
      let Insertdata = {
        FromAccountState: FromAccountState,
        Paymentlist: Paymentlist,
        Location: userRecord.location,
        CreatedBy: userRecord.username,
      };

      axios
        .post(
          `${urllink}FinanceMagement/insert_Journal_Voucher_Entry_details`,
          Insertdata
        )
        .then((response) => {
          alert(response.data.Message);
          ClearContradata();
          setPaymentlist([]);
          getContraNum();
          Allleddetailes();
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Journal Voucher F</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="VoucherNo">
            Voucher No <span>:</span>
          </label>
          <input
            type="text"
            name="VoucherNo"
            required
            readOnly
            value={FromAccountState.VoucherNo}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherDate">
            Voucher Date <span>:</span>
          </label>
          <input
            type="date"
            name="VoucherDate"
            required
            value={FromAccountState.VoucherDate}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherNarration">
            Short Narration <span>:</span>
          </label>
          <textarea
            type="text"
            name="VoucherNarration"
            required
            value={FromAccountState.VoucherNarration}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccount">
            From Account ID<span>:</span>
          </label>
          <input
            type="text"
            name="FromAccountID"
            list="DrAC_listID"
            required
            value={FromAccountState.FromAccountID}
            onChange={PaymentstateFun}
            autoComplete="off"
          />
           <datalist id="DrAC_listID">
            {ExpensLiblitydata.map((item, index) => (
              <option key={index+'key'} value={item.AccountID}></option>
            ))}
          </datalist>
          
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccount">
            From Account <span>:</span>
          </label>
          <input
            type="text"
            name="FromAccount"
            list="DrAC_list"
            required
            value={FromAccountState.FromAccount}
            onChange={PaymentstateFun}
            autoComplete="off"
          />
           <datalist id="DrAC_list">
            {ExpensLiblitydata.map((item, index) => (
              <option key={index+'key'} value={item.Account_Name}></option>
            ))}
          </datalist>
          
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="FromAccountCurBalance"
            id="FromAccountCurBalance"
            required
            readOnly
            value={FromAccountState.FromAccountCurBalance}
            onChange={PaymentstateFun}
          />
        </div>


        <div className="RegisForm_1">
          <label htmlFor="DebitAmount">
            Debit <span>:</span>
          </label>
          <input
            type="text"
            name="DebitAmount"
            id="DebitAmount"
            required
            readOnly
            value={FromAccountState.DebitAmount}
            onChange={PaymentstateFun}
          />
        </div>
        <div className="RegisForm_1">
          <label>
            To Account ID<span>:</span>
          </label>
          <input
            type="text"
            name="ToAccountID"
            id="ToAccount"
            list="CrAC_listID"
            required
            value={ToAccountstate.ToAccountID}
            onChange={ToAccountstateFun}
          />
          <datalist id="CrAC_listID">
            {ExpensLiblitydata.filter(
              (item) => +item.AccountID !== +FromAccountState.FromAccountID
            ).map((item, index) => (
              <option key={index +'key'} value={item.AccountID}></option>
            ))}
          </datalist>
         
        </div>

        <div className="RegisForm_1">
          <label>
            To Account <span>:</span>
          </label>
          <input
            type="text"
            name="ToAccount"
            id="ToAccount"
            list="CrAC_list"
            required
            value={ToAccountstate.ToAccount}
            onChange={ToAccountstateFun}
          />
          <datalist id="CrAC_list">
            {ExpensLiblitydata.filter(
              (item) => item.Account_Name !== FromAccountState.FromAccount
            ).map((item, index) => (
              <option key={index +'key'} value={item.Account_Name}></option>
            ))}
          </datalist>
         
        </div>

        <div className="RegisForm_1">
          <label htmlFor="ToAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="ToAccountCurBalance"
            required
            readOnly
            value={ToAccountstate.ToAccountCurBalance}
            onChange={ToAccountstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="CreditAmount">
            Credit <span>:</span>
          </label>
          <input
            type="number"
            name="CreditAmount"
            required
            value={ToAccountstate.CreditAmount}
            onChange={ToAccountstateFun}
          />
        </div>
      </div>

      <br />

     
        <>
        <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={isedit ? update_data : add_data}
        >
          {isedit ? "Update" : "Add"}
        </button>
        </div>          
        </>


      

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Particulars</th>
              <th>Current Balance</th>
              <th>CreditAmount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Paymentlist.map((row, index) => (
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.ToAccount}</td>
                <td>{row.ToAccountCurBalance}</td>
                <td>{row.CreditAmount}</td>
                <td>
                  <EditIcon
                    onClick={() => {
                      Editdataform(row);
                      setisedit(true);
                    }}
                  />

                  <DeleteIcon
                    onClick={() => {
                      Deletedataform(row);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />

      <div className="for_total9">
        <label className="for_total9_xyz">
          Total<span>:</span>
        </label>
        <div className="for_total9_xyz">
          {FromAccountState.DebitAmount || 0}
        </div>
      </div>

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={Postalldata}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default JournalentryFinance;
