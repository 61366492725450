import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";

export default function UserList() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const location = userRecord?.location;
  const [userData, setUserData] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredSearch, setFilteredSearch] = useState([]);

  // Get method to fetch user data based on the entered user name
  const getUserdata = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/userListfetch?location=${location}`)
      .then((response) => {
        const data = response.data;
        setUserData(
          data.map((row, index) => ({
            id: index + 1,
            ...row,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urllink, location]);

  useEffect(() => {
    // Fetch and set user data when the component mounts
    getUserdata();
  }, [getUserdata]);

  useEffect(() => {
    const lowerCase = searchQuery.toLowerCase();
    const filterData = userData.filter((user) => {
      const lowerCaseUserName = user.username.toLowerCase();
      return lowerCaseUserName.startsWith(lowerCase);
    });

    setFilteredSearch(filterData);
  }, [userData, searchQuery]);

  const unitcolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "username",
      name: "UserName",
      frozen: true,
    },
    {
      key: "First_Name",
      name: "Full Name",
      width: 280,
      frozen: true,
    },
    {
      key: "email",
      name: "Mail ID",
      width: 280,
    },
    {
      key: "role_name",
      name: "Role Name",
      width: 280,
    },
  ];

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>User List</h4>
      </div>
      <br />

      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="input">
              User Name <span>:</span>
            </label>
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => handleSearchChange(e, "name")}
              placeholder="Enter User Name"
            />
          </div>
        </div>
      </div>
      <div className="Main_container_app">
        <ReactGrid columns={unitcolumns} RowData={filteredSearch} />
      </div>
    </div>
  );
}
