import React, { useState, useEffect, useRef } from "react";
import AddCardIcon from "@mui/icons-material/AddCard";
import "../Billing/Billing.css";
import "../Billing/BillingInvoice.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Barcode from "react-barcode";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { MdOutlineCancel } from "react-icons/md";
import PhoneIcon from '../assets/PhoneIcon.jpg';
import { toPng } from "html-to-image";
import QRCode from "qrcode.react";
// import "../Pharmacy/PharmacyBilling.css";

import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
// import rootShouldForwardProp from "@mui/material/styles/rootShouldForwardProp";

function Billing() {
  const navigate = useNavigate();
  const [selectedCities, setSelectedCities] = useState(null);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const RequestDataforBilling = useSelector(
    (state) => state.userRecord?.RequestDataforBilling
  );
  // const [request, setrequest] = useState(false);
  console.log(RequestDataforBilling);

  const [refdoc, setrefdoc] = useState([]);
  useState("");
  const [type, setType] = useState("PatientDetailCon");
  const [testname, setTestName] = useState([]);
  const [visitid, setvisitid] = useState("");
  const [patientid, setpatientid] = useState("");
  const [testdetails, settestdetails] = useState([]);
  const [dummydetails, setdummydetails] = useState([]);
  const handleToggleChange = (event) => {
    const newValue = event.target.value;
    setType(newValue);
  };
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [invoiceno, setInvoiceno] = useState();
  const [barcode, setbarcode] = useState();
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
  const [isPrintButtonVisible1, setIsPrintButtonVisible1] = useState(true);
  // const [rural, setrural] = useState([]);
  const [commissionper, setcommissionper] = useState();
  const [TRFFile, setTRFFile] = useState(null);
  const webcamRef1 = useRef(null);
  const [showFile, setShowFile] = useState({
    file1: false,
  });
  const [isImageCaptured1, setIsImageCaptured1] = useState(false);

  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);
  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };

  const [department, setdepartment] = useState([]);
  //.log(department);
  const formatAmountWithCommas = (amount) => {
    // if (isNaN(amount)) return amount; // Return if not a valid number

    return Number(amount).toLocaleString(); // Converts the number to a locale string with commas
  };

  const [openModal, setOpenModal] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [viewtestintogroup, setviewtestintogroup] = useState([]);

  const [Remarksdata, setRemarksdata] = useState([]);
  const [Reasondata, setReasondata] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);

  const [patientdetails, setPatientDetails] = useState({
    visitid: "",
    visittype: "Walkin",
    title: "",
    patientname: "",
    fathername: "",
    dob: "",
    age: "",
    gender: "",
    maritalstatus: "",
    phlebotomisttype: "",
    phlebotomistname: "",
    Referingdoctor: "",
    ReferingdoctorCommissionID: "",
    Patientgroup: "Basic",
    Doorno: "",
    Rural: "",
    Pincode: "",
    Street: "",
    // District: "",
    City: "",
    State: "",
    PhoneNo: "",
    Alternatephn: "",
    Email: "",
    Dispatchmethod: "",
    testmethod: "Individual",
    OutsourceType: "",
    OutsourceLab: "",
    concernname: "",
    timeperiod: "",
    LabCode: "",
    communicationAddress: "",
    Remarks: "",
    // PatientPhoto: null,
    AdhaarNumber: "",
    ForSurName: "",
    SurName: "",
  });
  console.log(patientdetails);

  const Dispatchoptions = [
    {
      value: "sms",
      label: "SMS",
    },
    {
      value: "wp",
      label: "whatsapp",
    },
    {
      value: "email",
      label: "EMAIL",
    },
    {
      value: "courier",
      label: "Courier",
    },
  ];

  const remarksOptions = Remarksdata.map((remark) => ({
    value: remark.Remarks,
    label: remark.Remarks,
  }));

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    }
  };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getsetRemarksdata`)
      .then((response) => {
        console.log(response);
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setRemarksdata(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });

    axios
      .get(`${urllink}usercontrol/getReasondata`)
      .then((response) => {
        console.log(response);
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setReasondata(data);
      })
      .catch((error) => {
        console.error("Error fetching unit data:", error);
      });
  }, [urllink]);

  const [hasRun, setHasRun] = useState(false);
  useEffect(() => {
    // Prevent running the effect continuously
    if (
      !RequestDataforBilling ||
      hasRun ||
      !Object.keys(RequestDataforBilling).length
    ) {
      return;
    }
    setHasRun(true); // Set flag to true to stop further runs

    console.log("RequestDataforBilling :", RequestDataforBilling);

    const handleBillingRequest = async () => {
      // Set patient details
      setPatientDetails((prevState) => ({
        ...prevState,
        patientname: RequestDataforBilling?.Patient_Name,
        PhoneNo: RequestDataforBilling?.Phone_Number,
        dob: RequestDataforBilling?.Date_of_Birth,
        age: RequestDataforBilling?.Age,
        gender: RequestDataforBilling?.Gender,
        timeperiod: RequestDataforBilling?.timeperiod,
        Patientgroup: "Basic",
        title: RequestDataforBilling?.Title,
      }));

      console.log("patientdetails", patientdetails);

      // Filter individual and group tests separately
      const individualTestDetails =
        RequestDataforBilling?.testdetails?.filter(
          (item) => item.ServiceType === "Individual"
        ) || [];
      const groupTestDetails =
        RequestDataforBilling?.testdetails?.filter(
          (item) => item.ServiceType !== "Individual"
        ) || [];

      console.log("individualTestDetails:", individualTestDetails);
      console.log("groupTestDetails:", groupTestDetails);

      // Check for duplicate individual tests
      if (individualTestDetails.length > 0 && patientdetails?.Patientgroup) {
        const updatedCheckedTests = individualTestDetails.map((item) => ({
          Test_Name: item.Test_Name,
          Test_Code: item.Test_Code,
        }));

        console.log("updatedCheckedTests:", updatedCheckedTests);
        const testCodes = updatedCheckedTests
          .map((test) => test.Test_Code)
          .join(",");

        try {
          const url = `${urllink}usercontrol/get_payment_and_test_details_by_testname_forbulk?Test_Codes=${testCodes}&ratecard=${patientdetails.Patientgroup}`;
          const response = await axios.get(url);
          const newData = Array.isArray(response.data)
            ? response.data
            : [response.data];

          // Check for duplicates before adding
          const existingTestNames = testdetails.map((item) => item.test_name);
          const newTestData = newData.filter(
            (item) => !existingTestNames.includes(item.test_name)
          );

          if (newTestData.length > 0) {
            settestdetails((prevData) => [...prevData, ...newTestData]);

            const totalCost = newTestData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
              testname: "",
            }));
          }

          setOpenModal(false);
        } catch (error) {
          console.error("An error occurred:", error);
        }
      }

      // Process group tests and check for duplicates
      if (groupTestDetails.length > 0 && patientdetails?.Patientgroup) {
        for (const groupTest of groupTestDetails) {
          console.log("groupTest :", groupTest);
          try {
            const url = `${urllink}usercontrol/getgroupcost?Test_Code=${groupTest.GroupCode}&ratecard=${patientdetails.Patientgroup}`;
            const response = await axios.get(url);
            const newData = Array.isArray(response.data)
              ? response.data
              : [response.data];

            // Check for duplicates before adding group tests
            const existingGroupCodes = testdetails.map(
              (item) => item.test_code
            );
            const newGroupData = newData.filter(
              (item) => !existingGroupCodes.includes(item.test_code)
            );

            if (newGroupData.length > 0) {
              settestdetails((prevData) => [...prevData, ...newGroupData]);

              const totalCost = newGroupData.reduce(
                (acc, curr) => acc + Number(curr.cost_per_reportable),
                0
              );
              setorderdetails((prevState) => ({
                ...prevState,
                grossAmount: Number(prevState.grossAmount || 0) + totalCost,
                testname: "",
              }));
            } else {
              // alert("Group test data already exists in testdetails");
            }
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }
      }

      // Ensure at least one test is selected
      if (individualTestDetails.length === 0 && groupTestDetails.length === 0) {
        alert("Please select at least one test.");
      }
    };

    handleBillingRequest();
  }, [RequestDataforBilling, patientdetails.Patientgroup, urllink]);

  // //.log(patientdetails);
  const [patientphoto, setpatientphoto] = useState(null);
  // //.log(patientphoto);
  // const [trfpicture, settrfpicture] = useState(null)
  const [isEdit, setIsEdit] = useState(null);
  // const [doctorname, setdoctorname] = useState([])
  const [Billing_date, setBilling_date] = useState("");
  // const [totalPaidAmount, settotalPaidAmount] = useState(0);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState("");
  // //.log(patinetbillingbarcode);
  const [formAmount, setFormAmount] = useState([]);
  const [franchaise, setfranchaise] = useState([]);
  const [paymentdetail, setpaymentdetail] = useState({
    paidamount: "",
    Billpay_method: "",
  });
  const [patientname, setpatientname] = useState([]);
  const [labname, setlabname] = useState([]);
  const [patientdata, setpatientdata] = useState([]);
  const [initialState, setinitialState] = useState({
    totalItems: 0,
    totalAmount: 0,
    totalDiscount: 0,
    totalGstamount: 0,
    totalUnits: 0,
    totalNetAmount: 0,
    totalrecived: 0,
  });

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  // //.log(ClinicDetials);
  const [orderdetails, setorderdetails] = useState({
    testname: "",
    grossAmount: 0,
    discountType: "", // "Cash" or "Percentage"
    discountValue: "",
    paymentMethod: "",
    receivedAmount: 0,
    netAmount: 0,
    dueAmount: 0,
    CardName: "",
    CardNumber: "",
    TransactionId: "",
    UPI_Mob: "",
    CardType: "",
    Test_Code: "",
    AdditionalAmount: 0,
    AdditionalAmountReason: "",
    DiscountReason: "",
    HistopathologyNumber: "",
    MicrobiologyNumber: "",
  });

  // //.log(orderdetails);

  const today = new Date();

  // Create a new Date object for tomorrow
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  // Format the date as a string (e.g., YYYY-MM-DD)

  const handleRecaptureImage1 = () => {
    setpatientphoto(null);
    setIsImageCaptured1(false);
  };

  const handleCaptureImage1 = () => {
    const imageSrc = webcamRef1.current.getScreenshot();
    const blob = imageSrc
      ? dataURItoBlob(imageSrc, "captured_image.jpg")
      : null;

    setpatientphoto(
      blob instanceof Blob ? new File([blob], "captured_image.jpg") : null
    );

    setIsImageCaptured1(true);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleinputchangepay = (event) => {
    const { name, value } = event.target;
    const total_netamount = initialState.totalNetAmount;
    const totalPaidAmount = formAmount.reduce(
      (acc, val) => acc + Number(val.paidamount),
      0
    );

    const remainingAmount = total_netamount - totalPaidAmount;

    if (isEdit !== null) {
      // If in edit mode, allow the value to be modified freely
      setpaymentdetail((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (+totalPaidAmount !== +total_netamount) {
      if (name === "Billpay_method") {
        setpaymentdetail((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        if (formAmount.length > 0) {
          if (+remainingAmount >= +value) {
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          } else {
            alert(`Enter a value less than or equal to ${remainingAmount}`);
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          }
        } else {
          if (+total_netamount >= +value) {
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: value,
            }));
          } else {
            alert(`Enter a value less than or equal to ${total_netamount}`);
            setpaymentdetail((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          }
        }
      }
    } else {
      alert("No Balance Amount");
    }
  };

  const handleInputChange1 = (event) => {
    const { name, value } = event.target;

    if (name === "discountValue") {
      // If updating discountValue
      const isDiscountValueEntered = !!value.trim(); // Check if discountValue is not empty
      setorderdetails((prevState) => ({
        ...prevState,
        [name]: value,
        isDiscountValueEntered: isDiscountValueEntered,
      }));

      if (isDiscountValueEntered) {
        // If discountValue is entered, enforce mandatory requirement for discountType
        if (!orderdetails.discountType.trim()) {
          // If discountType is not selected
          alert("Please select a discount type.");
          setorderdetails((prevState) => ({
            ...prevState,
            discountValue: "",
          }));
        } else {
          // Subtract discount from original netAmount
          setorderdetails((prevState) => {
            const originalNetAmount =
              prevState.originalNetAmount || prevState.netAmount;
            const discount = parseFloat(value) || 0;
            const discountedAmount =
              prevState.discountType === "Percentage"
                ? originalNetAmount * (discount / 100)
                : discount;
            const newNetAmount = originalNetAmount - discountedAmount;

            return {
              ...prevState,
              netAmount: newNetAmount.toFixed(2),
              dueAmount: (
                newNetAmount - parseFloat(prevState.receivedAmount || 0)
              ).toFixed(2),
            };
          });
        }
      } else {
        // Reset netAmount to original if discount is removed
        setorderdetails((prevState) => ({
          ...prevState,
          netAmount: parseFloat(prevState.originalNetAmount).toFixed(2),
          dueAmount: (
            prevState.originalNetAmount -
            parseFloat(prevState.receivedAmount || 0)
          ).toFixed(2),
        }));
      }
    } else if (name === "AdditionalAmount") {
      // If updating AdditionalAmount
      const additionalAmount = parseFloat(value) || 0;

      // Update netAmount and dueAmount based on grossAmount + additionalAmount
      setorderdetails((prevState) => {
        const grossAmount = parseFloat(prevState.grossAmount) || 0;
        const newNetAmount = grossAmount + additionalAmount; // Calculate netAmount as grossAmount + additionalAmount
        const receivedAmount = parseFloat(prevState.receivedAmount) || 0;

        return {
          ...prevState,
          [name]: value, // Store AdditionalAmount
          netAmount: newNetAmount.toFixed(2), // Update netAmount
          dueAmount: (newNetAmount - receivedAmount).toFixed(2), // Update dueAmount based on netAmount and receivedAmount
        };
      });

      if (!value.trim()) {
        // If AdditionalAmount is cleared, reset to only grossAmount
        setorderdetails((prevState) => {
          const grossAmount = parseFloat(prevState.grossAmount) || 0;
          const receivedAmount = parseFloat(prevState.receivedAmount) || 0;

          return {
            ...prevState,
            netAmount: grossAmount.toFixed(2), // Set netAmount to grossAmount
            dueAmount: (grossAmount - receivedAmount).toFixed(2), // Update dueAmount based on grossAmount
          };
        });
      }
    } else {
      // If updating other fields
      if (patientdetails.testmethod === "Individual") {
        const selectedTest = testname?.find((item) => item.Test_Name === value);

        setorderdetails((prevState) => ({
          ...prevState,
          [name]: value,
          Test_Code: selectedTest ? selectedTest.Test_Code : "", // Set Test_Code if selectedTest is found
        }));
      } else if (patientdetails.testmethod === "Group") {
        const selectedTest = testname.find((item) => item.groupname === value);

        setorderdetails((prevState) => ({
          ...prevState,
          [name]: value,
          Test_Code: selectedTest ? selectedTest.groupcode : "", // Set Test_Code if selectedTest is found
        }));
      } else {
        const selectedTest = testname.find(
          (item) => item.package_name === value
        );
        console.log("selectedTest", selectedTest);
        setorderdetails((prevState) => ({
          ...prevState,
          [name]: value,
          Test_Code: selectedTest ? selectedTest.package_code : "", // Set Test_Code if selectedTest is found
        }));
      }
    }
  };
  const timeoutRef = useRef(null);

  const handlecontrastchange = (index, value, fieldName) => {
    console.log("939i3384444447577777777764", index, value, fieldName);

    const updatedDetails = [...testdetails];
    const comparedata = [...dummydetails];

    // Temporarily update the value while typing
    updatedDetails[index] = {
      ...updatedDetails[index],
      [fieldName]: value, // Store the raw input as string
    };

    settestdetails(updatedDetails);

    if (fieldName === "cost_per_reportable") {
      // Clear any existing timeout to avoid multiple alerts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      // Set a new timeout
      timeoutRef.current = setTimeout(() => {
        const numericValue = parseFloat(updatedDetails[index][fieldName]);

        console.log("comparedata==========", comparedata);

        // Check if the input is a valid number
        if (isNaN(numericValue)) {
          updatedDetails[index][fieldName] = 0; // Reset invalid value to 0
          settestdetails([...updatedDetails]); // Ensure state is updated
          return;
        }

        // Ensure `comparedata[index]` exists before proceeding
        if (!comparedata[index]) {
          console.warn(`No comparison data found for index ${index}`);
          return;
        }

        const comparevalue = parseFloat(comparedata[index][fieldName]);

        console.log("comparevalue----", comparevalue);

        // Validation logic
        {
          // Valid input; set the numeric value
          updatedDetails[index][fieldName] = numericValue;
          settestdetails([...updatedDetails]); // Proper state update

          // Calculate total cost after updating
          const totalCost = updatedDetails.reduce(
            (acc, curr) => acc + (parseFloat(curr.cost_per_reportable) || 0),
            0
          );

          // Update order details
          setorderdetails((prev) => ({
            ...prev,
            netAmount: totalCost,
            dueAmount: totalCost,
            grossAmount: totalCost,
          }));
        }
      }, 1000); // 1 second delay
    }
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_refering_doc_names?Location=${userRecord?.location}`
      )
      .then((response) => {
        // //.log(response);
        setrefdoc(response.data);
      })
      .catch((error) => {
        //.log(error);
      });
  }, [urllink, userRecord?.location]);

  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
      return "Zero Rupees Only";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return (
          units[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convert(num % 100) : "")
        );
      if (num < 100000)
        return (
          convert(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
        );
      if (num < 10000000)
        return (
          convert(Math.floor(num / 100000)) +
          " Lakh" +
          (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
        );
    };

    return convert(num) + " Rupees  Only";
  };

  // //.log("invoiceno", initialState);

  const handleAdd = () => {
    const requiredFields = ["Billpay_method", "paidamount"];
    const missingFields = requiredFields.filter(
      (field) => !paymentdetail[field]
    );

    if (missingFields.length === 0) {
      const existingMethod = formAmount.find(
        (payment) => payment.Billpay_method === paymentdetail.Billpay_method
      );

      if (!existingMethod) {
        setFormAmount((prev) => [...prev, paymentdetail]);
        resetPaymentDetail();
      } else {
        alert("The Payment Method already exists");
      }
    } else {
      alert(`Enter the required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleEdit = (index) => {
    setIsEdit(index);
    const item = formAmount[index];
    const editamount = item.paidamount;
    // //.log("item", editamount);

    setpaymentdetail({
      ...item,
    });

    setorderdetails((prev) => ({
      ...prev,
      dueAmount: prev.dueAmount + Number(editamount),
      receivedAmount: prev.receivedAmount - Number(editamount),
      // Corrected line
    }));
  };

  const handleUpdate = () => {
    const req = ["Billpay_method", "paidamount"];
    const missing = req.filter((row) => ![row]);
    if (missing.length === 0) {
      const data = [...formAmount];
      data[isEdit] = paymentdetail;

      setFormAmount(data);
      setpaymentdetail({
        paidamount: "",
        Billpay_method: "",
      });
      setIsEdit(null);
    } else {
      alert(`enter the required fields : ${missing.join(",")}`);
    }
  };

  const resetPaymentDetail = () => {
    setpaymentdetail({
      paidamount: "",
      Billpay_method: "",
    });
  };

  const handleSearch = async () => {
    if (
      orderdetails.Test_Code !== "" &&
      patientdetails.Patientgroup !== "" &&
      orderdetails.testname !== ""
    ) {
      if (
        patientdetails.testmethod === "Individual" ||
        patientdetails.testmethod === "Group" ||
        patientdetails.testmethod === "Offers"
      ) {
        try {
          const url =
            patientdetails.testmethod === "Individual"
              ? `${urllink}usercontrol/getcostpertest?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`
              : patientdetails.testmethod === "Group"
              ? `${urllink}usercontrol/getgroupcost?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`
              : `${urllink}usercontrol/getcostperoffer?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`;

          // const url =
          //   patientdetails.testmethod === "Individual" ||
          //   patientdetails.testmethod === "Group"
          //     ? `${urllink}usercontrol/getcostpertest?${
          //         patientdetails.testmethod === "Individual"
          //           ? "Test_Code"
          //           : "Group_Code"
          //       }=${orderdetails.Test_Code}&ratecard=${
          //         patientdetails.Patientgroup
          //       }`
          //     : `${urllink}usercontrol/getcostperoffer?Test_Code=${orderdetails.Test_Code}&ratecard=${patientdetails.Patientgroup}`;

          const response = await axios.get(url);
          // //.log(response.data);
          let newData = [];
          if (
            response.data &&
            !Array.isArray(response.data) &&
            response.data.test_code
          ) {
            newData = [response.data];
          } else if (Array.isArray(response.data)) {
            newData = response.data;
          }

          if (testdetails.length === 0) {
            settestdetails(newData);
            setdummydetails(newData);

            const totalCost = newData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
            }));

            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
            return;
          }

          let alreadyExists = false;

          for (const newItem of newData) {
            if (newItem.testmethod === "Group") {
              const groupResponse = await axios.get(
                `${urllink}Billing/get_test_name_for_group_compare?Test_Code=${newItem.test_code}`
              );
              const groupTestNames = groupResponse.data.map(
                (item) => item.test_name
              );

              if (
                groupTestNames.some((groupTestName) =>
                  testdetails.some(
                    (existingItem) => existingItem.test_name === groupTestName
                  )
                )
              ) {
                alreadyExists = true;
                break;
              }
            } else {
              for (const existingItem of testdetails) {
                if (existingItem.testmethod === "Group") {
                  const existingGroupResponse = await axios.get(
                    `${urllink}Billing/get_test_name_for_group_compare?Test_Code=${existingItem.test_code}`
                  );
                  const existingGroupTestNames = existingGroupResponse.data.map(
                    (item) => item.test_name
                  );

                  if (existingGroupTestNames.includes(newItem.test_name)) {
                    alreadyExists = true;
                    break;
                  }
                } else if (existingItem.test_name === newItem.test_name) {
                  alreadyExists = true;
                  break;
                }
              }
              if (alreadyExists) break;
            }
          }

          if (alreadyExists) {
            alert("Data already exists in testdetails");
            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
            return;
          } else {
            settestdetails((prevData) => {
              const updatedData = [...prevData, ...newData];
              return updatedData.filter((item) => {
                if (item.testmethod === "Group") return true;
                return !newData.some(
                  (newItem) =>
                    newItem.testmethod === "Group" &&
                    newItem.test_name === item.test_name
                );
              });
            });
            setdummydetails((prevData) => {
              const updatedData = [...prevData, ...newData];
              return updatedData.filter((item) => {
                if (item.testmethod === "Group") return true;
                return !newData.some(
                  (newItem) =>
                    newItem.testmethod === "Group" &&
                    newItem.test_name === item.test_name
                );
              });
            });

            const totalCost = newData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
            }));

            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
          }
        } catch (error) {
          //.error("An error occurred:", error);
        }
      }
    } else {
      alert("Please enter patient details");
    }
  };

  useEffect(() => {
    let concern = patientdetails.concernname;
    let searchconsern = concern?.split(" ").join("");
    // //.log("searchconsern", searchconsern);
    const data = franchaise.filter((p) => p === searchconsern);
    // //.log(data);
    const datas = data[0];
    if (data.length > 0) {
      setPatientDetails((prev) => ({
        ...prev,
        Patientgroup: datas,
      }));
    } else {
      setPatientDetails((prev) => ({
        ...prev,
        Patientgroup: "Basic",
      }));
    }
  }, [patientdetails.concernname, patientdetails.Patientgroup, franchaise]);

  const currTime = new Date().toLocaleTimeString();

  useEffect(() => {
    const currentDate = new Date();
    setBilling_date(format(currentDate, "dd-MM-yyyy"));

    if (patientdetails.gender !== "") {
      // //.log('123444444444')
      axios
        .get(
          `${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`
        )
        .then((response) => {
          // //.log(response.data);
          const data = response.data;
          setTestName(data);
        })
        .catch((error) => {
          //.error(error);
        });
    }

    axios
      .get(`${urllink}Billing/getinvoice`)
      .then((response) => {
        // //.log(response.data);
        const data = response.data.nextInvoiceCode;

        setInvoiceno(data);
      })
      .catch((error) => {
        //.error(error);
      });
    axios
      .get(`${urllink}Billing/getbarcode`)
      .then((response) => {
        // //.log(response);
        const data = response.data.nextInvoiceBarcode;
        setbarcode(data);
        // setInvoiceno(data);
      })
      .catch((error) => {
        //.error(error);
      });

    if (patientdetails.patientname) {
      axios
        .get(
          `${urllink}Billing/getpatientid?patientname=${patientdetails.patientname}&patientphn=${patientdetails.PhoneNo}`
        )
        .then((response) => {
          // //.log(response.data);
          const data = response.data.nextPatientId;
          setpatientid(data);
        })
        .catch((error) => {
          //.error(error);
        });

      axios
        .get(
          `${urllink}Billing/getforvisitid?patientphn=${patientdetails.PhoneNo}&patientname=${patientdetails.patientname}`
        )
        .then((response) => {
          // //.log(response.data);
          const data = response.data.nextVisitId;
          setvisitid(data);
        })
        .catch((error) => {
          //.error(error);
        });
    }

    axios
      .get(`${urllink}Billing/get_alldepartment_for_billing`)
      .then((response) => {
        // //.log(response.data);
        const data = response.data;
        setdepartment(data);
      })
      .catch((error) => {
        //.error(error);
      });
  }, [
    patientdetails.patientname,
    patientdetails.PhoneNo,
    urllink,
    patientdetails.gender,
  ]);

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/get_ratecard_names_for_billing`)
      .then((response) => {
        // //.log(response);
        setfranchaise(response.data.ratecard);
      })
      .catch((error) => {
        //.log(error);
      });
    axios
      .get(`${urllink}mainddepartment/get_for_outsource_lab_name`)
      .then((response) => {
        // //.log(response);
        setlabname(response.data);
      })
      .catch((error) => {
        //.log(error);
      });
  }, [urllink]);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord?.location}`)
      .then((response) => {
        //.log(response.data);
        const data = response.data[0];
        //.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.area + "," + data.street,
            ClinicGST: data.gstno,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        //.error(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // //.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        //.error(error);
      });

    if (patientdetails.patientname) {
      axios
        .get(
          `${urllink}Billing/get_billing_patient_barcode?Patientid=${patientid}&Patientname=${patientdetails.patientname}`
        )
        .then((response) => {
          //.log(response);
          setpatinetbillingbarcode(response.data.Patient_Barcode);
        })
        .catch((error) => {
          //.error(error);
        });
    }
  }, [patientid, patientdetails.patientname, urllink, userRecord]);

  const handleDeleted = (testname) => {
    // Find the item to be deleted
    const deletedItem = testdetails.find((p) => p.test_name === testname);

    if (!deletedItem) {
      //.error(`Item with test name ${testname} not found.`);
      return;
    } else {
      const reductionAmount = Number(deletedItem.cost_per_reportable || 0);
      const updatedTestDetails = testdetails.filter(
        (p) => p.test_name !== testname
      );
      // //.log('updated testdetails', updatedTestDetails);
      settestdetails(updatedTestDetails);

      setorderdetails((prevState) => ({
        ...prevState,
        grossAmount: Number(prevState.grossAmount || 0) - reductionAmount,
      }));
    }
  };

  // //.log('testdetails', testdetails);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const [selecteddepartment, setselecteddepartment] = useState("");
  const [isDoctor, setIsDoctor] = useState(false);

  console.log(selecteddepartment);

  // useEffect(()=>{

  // },[patientdetails,selecteddepartment])

  const handleInputChangeRemarks = (selectedOptions, actionMeta) => {
    const name = actionMeta.name;

    // Convert selected options to an array of values
    const selectedRemarks = selectedOptions
      ? selectedOptions.map((option) => option.value).join(", ")
      : "";

    setPatientDetails({
      ...patientdetails,
      Remarks: selectedRemarks, // Store selected values as an array
    });

    if (name === "Dispatchmethod") {
      // Convert selected options to an array of values
      const selectedMethod = selectedOptions
        ? selectedOptions.map((option) => option.value).join(", ")
        : "";

      setPatientDetails({
        ...patientdetails,
        Dispatchmethod: selectedMethod, // Store selected values as an array
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    //.log(name, value, files);

    let updatedValue = value;

    // Phone number handling
    if ((name === "PhoneNo" || name === "Alternatephn") && value.length > 10) {
      updatedValue = value.slice(0, 10);
    }

    let updatedDetails = {
      ...patientdetails,
      [name]: updatedValue,
    };

    // Title handling for gender and timeperiod
    if (name === "title") {
      setIsDoctor(value === "Dr" || "Baby" || "Child"); // Update isDoctor based on title
      let genderValue = "";

      // Set gender based on title selection
      if (value === "Mr") genderValue = "Male";
      else if (value === "Mrs" || value === "Ms") genderValue = "Female";
      else if (value.includes("Baby")) genderValue = "Baby";
      else if (value.includes("Child")) genderValue = "Child";
      else if (value.includes("Transgender")) genderValue = "Transgender";

      updatedDetails = { ...updatedDetails, gender: genderValue };

      if (!value.includes("Baby")) {
        updatedDetails = { ...updatedDetails, timeperiod: "year" };
      } else {
        updatedDetails = { ...updatedDetails, timeperiod: "" }; // Clear timeperiod if title includes "Baby"
      }
    }

    if (name === "dob") {
      const age = calculateAge(value);
      updatedDetails = { ...updatedDetails, age: age.toString() }; // Assuming age is stored as a string
    }

    if (name === "age") {
      const dob = calculateYear(value);
      //.log("dob", dob);
      updatedDetails = {
        ...updatedDetails,
        dob: dob.toISOString().split("T")[0],
      };
    }

    if (name === "CapturedFile4") {
      const fileObject = event.target.files[0];
      setTRFFile(fileObject);
    }

    if (name === "photo") {
      const fileObject = event.target.files[0];
      const blob = new Blob([fileObject], { type: fileObject.type });
      //.log(blob);
      // Update the patient details with the Blob
      setpatientphoto(blob);
      // setPatientDetails((prevData) => ({
      //   ...prevData,
      //   PatientPhoto: blob,
      // }));
    }

    if (name === "concernname") {
      //.log(labname);
      //.log(value);
      const selectedLab = labname.find((item) => item.Labcode === value);
      if (selectedLab) {
        updatedDetails = {
          ...updatedDetails,
          LabCode: selectedLab.Labcode,
          concernname: selectedLab.Lab_Name,
        };
      }
    }
    if (name === "Department") {
      setselecteddepartment(value);
    }

    if (name === "Referingdoctor") {
      console.log(value);
      console.log(refdoc);
      // Find the selected doctor based on the Commission_Id
      const selectedDoctor = refdoc.find(
        (item) => String(item.Commission_Id) === String(value) // Convert both to string for comparison
      );
      console.log(selectedDoctor);

      if (selectedDoctor) {
        updatedDetails = {
          ...updatedDetails,
          Referingdoctor: selectedDoctor.DoctorName, // Set the doctor name
          ReferingdoctorCommissionID: selectedDoctor.Commission_Id, // Set the commission ID
        };
      } else {
        updatedDetails = {
          ...updatedDetails,
          Referingdoctor: value, // Clear doctor name if not found
          ReferingdoctorCommissionID: "", // Set the input value as commission ID
        };
      }
    }

    setPatientDetails(updatedDetails);
  };

  const calculateYear = (age) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearOfBirth = currentYear - age;
    const dob = new Date(yearOfBirth, 1, -29);
    return dob;
  };

  useEffect(() => {
    console.log(orderdetails);
    const parsedGrossAmount = parseFloat(orderdetails.grossAmount || 0); // Ensure parsing and handle undefined
    const parsedDiscountValue = parseFloat(orderdetails.discountValue || 0); // Ensure parsing and handle undefined
    const parsedAdditionalAmount = parseFloat(
      parseInt(orderdetails.AdditionalAmount || 0)
    ); // Ensure parsing and handle undefined

    console.log(parsedAdditionalAmount);
    let discount = 0;
    if (orderdetails.discountType === "Cash") {
      discount = parsedDiscountValue;
    } else if (orderdetails.discountType === "Percentage") {
      discount = (parsedDiscountValue / 100) * parsedGrossAmount;
    }

    const netAmount = parsedGrossAmount - discount + parsedAdditionalAmount;

    // Check if discount is greater than netAmount
    if (discount > parsedGrossAmount) {
      alert("Discount cannot exceed the gross amount.");
      setorderdetails((prev) => ({
        ...prev,
        discountValue: "",
      }));
      return; // Do not update state if discount is invalid
    }

    setorderdetails((prevState) => ({
      ...prevState,
      netAmount: Number(netAmount.toFixed(2)), // Ensure parsing
    }));
    console.log(parsedAdditionalAmount);
    console.log(
      "Number(netAmount.toFixed(2) + parsedAdditionalAmount)",
      Number(netAmount.toFixed(2)) + parsedAdditionalAmount
    );
    setinitialState((prev) => ({
      ...prev,
      totalDiscount: Number(discount.toFixed(2)), // Ensure parsing
      totalNetAmount: Number(netAmount.toFixed(2)), // Ensure parsing
      // totalAdditionalAmount: Number(parsedAdditionalAmount.toFixed(2)),
    }));
  }, [
    orderdetails.discountType,
    orderdetails.discountValue,
    orderdetails.grossAmount,
    orderdetails.testname,
    orderdetails.AdditionalAmount,
  ]);

  useEffect(() => {
    const phonenum = patientdetails.PhoneNo;

    if (phonenum !== "") {
      const time = setTimeout(() => {
        axios
          .get(`${urllink}Billing/getforpatientinfo?phonenum=${phonenum}`)
          .then((response) => {
            //.log(response.data);
            const data = response.data.patient_infos;
            const patient = response.data.patients;
            setpatientname(patient);
            setpatientdata(data);
            // const error = response.data.error;
          })
          .catch((error) => {
            //.log(error);
          });
      }, 400); // Adjust delay as needed

      // Cleanup timeout on component unmount or dependency change
      return () => clearTimeout(time);
    } else {
      setpatientname([]);
    }
  }, [patientdetails.PhoneNo, urllink]);

  useEffect(() => {
    if (patientdata) {
      patientdata
        .filter((p) => p.Patient_Name === patientdetails.patientname)
        .forEach((item) => {
          setPatientDetails((prevState) => ({
            ...prevState,
            patientname: item.Patient_Name || "",
            fathername: item.Father_Name || "",
            dob: item.DOB || "",
            age: calculateAge(item.DOB).toString(),
            gender: item.Gender || "",
            maritalstatus: item.Marital_Status || "",
            Doorno: item.Door_No || "",
            Street: item.Street || "",
            State: item.State || "",
            PhoneNo: patientdetails.PhoneNo, // Assuming you want to keep the searched phone number
            Alternatephn: item.Alternate_No || "",
            Email: item.Email || "",
            title: item.Title || "",
            City: item.City || "",
            Rural: item.Rural_Urban || "",
            Pincode: item.Pincode || "", // Add other fields if necessary
          }));

          if (item.Age !== "") {
            calculateYear(item.Age);
          } else {
            calculateAge(item.DOB);
          }
        });
      // //.log('patientdaa............................', patientdaa)
    }
  }, [patientdata, patientdetails.patientname, patientdetails.PhoneNo]);

  // //.log('patientdetails................', patientdata);

  const printStyles12 = `
  @page {
    size: A5 !important; /* Ensure that the size fits the content properly */
    margin: 0 !important; /* Remove margins to prevent extra space */
    border: 1px solid black !important;
  }
  /* Add any other styles you need for printing */
`;

  // Function to create an image from a Material UI icon


  // Function to handle print
  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontent"),
    onBeforeGetContent: () => {
      const styleElement = document.createElement("style");
      styleElement.id = "printStyles12";
      styleElement.innerHTML = printStyles12;
      document.head.appendChild(styleElement);
    },
    onBeforePrint: () => {
      let styleElement = document.getElementById("printStyles12");
      if (!styleElement) {
        styleElement = document.createElement("style");
        styleElement.id = "printStyles12";
        styleElement.innerHTML = printStyles12;
        document.head.appendChild(styleElement);
      }
    },
    onAfterPrint: async () => {
      const styleElement = document.getElementById("printStyles12");
      if (styleElement) {
        document.head.removeChild(styleElement);
      }

      const printdata = document.getElementById("reactprintcontent");
      try {
        if (printdata) {
          
          

          // Set up PDF with modified content
          const contentWidth = printdata.offsetWidth;
          const padding = 20;
          const pdfWidth = contentWidth + 2 * padding;
          const pdfHeight = contentWidth * 1.5;
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });

          await pdf.html(printdata, {
            x: padding,
            y: padding,
            callback: async () => {
              
              const generatedPdfBlob = pdf.output("blob");
              const formData = new FormData();
              formData.append("pdf", generatedPdfBlob, "billing_receipt.pdf");
              formData.append("invoice", invoiceno);
              formData.append("Patientbarcode", patinetbillingbarcode);
              formData.append("Visitid", visitid);
              formData.append("location", userRecord?.location);
              formData.append("createdby", userRecord?.username);
              formData.append("patientid", patientid);
              formData.append("TRFFile", TRFFile);
              formData.append("PatientPhoto", patientphoto);
              formData.append(
                "Appointment_Request_Id",
                RequestDataforBilling?.Appointment_Request_Id
              );

              // Append additional form data
              appendFormData(formData, {
                visitid,
                patientdetails,
                orderdetails,
                formAmount,
                invoiceno,
                barcode,
                testdetails,
                patientid,
                userRecord,
                commissionper,
                TRFFile,
                patientphoto,
              });

              // Post form data to server
              await axios.post(
                `${urllink}/Billing/insert_billing_all_data`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              successMsg("Saved Successfully");
              setIsPrintButtonVisible(true);
              navigate("/Home/PatientRequestlistforlab");
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  // Helper function to create Image elements from SVG URLs
  const createImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  console.log("dummydetails--------", dummydetails);

  // Helper function to append form data
  const appendFormData = (
    formData,
    {
      visitid,
      patientdetails,
      orderdetails,
      formAmount,
      invoiceno,
      barcode,
      testdetails,
      patientid,
      userRecord,
      commissionper,
      TRFFile,
      patientphoto,
    }
  ) => {
    formData.append(
      "postpersonaldata",
      JSON.stringify({
        visitid,
        visittype: patientdetails.visittype,
        title: patientdetails.title,
        patientname: patientdetails.patientname,
        fathername: patientdetails.fathername,
        dob: patientdetails.dob,
        age: patientdetails.age,
        gender: patientdetails.gender,
        maritalstatus: patientdetails.maritalstatus,
        Doorno: patientdetails.Doorno,
        Street: patientdetails.Street,
        State: patientdetails.State,
        City: patientdetails.City,
        pincode: patientdetails.Pincode,
        PhoneNo: patientdetails.PhoneNo,
        Alternatephn: patientdetails.Alternatephn,
        Email: patientdetails.Email,
        phlebotomisttype: patientdetails.phlebotomisttype,
        phlebotomistname: patientdetails.phlebotomistname,
        Referingdoctor: patientdetails.Referingdoctor,
        Patientgroup: patientdetails.Patientgroup,
        Dispatchmethod: patientdetails.Dispatchmethod,
        Location: userRecord?.location,
        CreatedBy: userRecord?.username,
        patientbarcode: patinetbillingbarcode,
        patientid,
        Billing_Type: patientdetails.OutsourceType,
        timeperiod: patientdetails.timeperiod,
        communicationAddress: patientdetails?.communicationAddress,
        Remarks: patientdetails?.Remarks,
        AdhaarNumber: patientdetails?.AdhaarNumber,
        ForSurName: patientdetails?.ForSurName,
        SurName: patientdetails?.SurName,
      })
    );

    formData.append(
      "postbillingdetails",
      JSON.stringify({
        ...orderdetails,
        testdetails: orderdetails.testdetails,
        invoiceno,
        barcode,
        itemcount: testdetails.length,
        patientid,
        visitid,
        patientname: patientdetails.patientname,
        Dispatchmethod: patientdetails.Dispatchmethod,
        location: userRecord?.location,
        CreatedBy: userRecord?.username,
        outsource_type: patientdetails.Patientgroup,
        refering_doctor: patientdetails.Referingdoctor,
        Billing_Type: patientdetails.OutsourceType,
        Ratecardtype: patientdetails.Patientgroup,
      })
    );

    formData.append(
      "postorderdetailsforpayment",
      JSON.stringify({
        payment_details: formAmount,
        invoiceno,
        barcode,
        patientid,
        visitid,
        patientname: patientdetails.patientname,
        Dispatchmethod: patientdetails.Dispatchmethod,
        receivedAmount: orderdetails.receivedAmount,
        netAmount: orderdetails.netAmount,
        cardnum: orderdetails.CardNumber,
        cardname: orderdetails.CardName,
        cardtype: orderdetails.CardType,
        transactionid: orderdetails.TransactionId,
        onlinenum: orderdetails.UPI_Mob,
        location: userRecord?.location,
        CreatedBy: userRecord?.username,
      })
    );

    formData.append(
      "postorderdetailsinfo",
      JSON.stringify({
        testdetails,
        invoiceno,
        patientid,
        barcode,
        visitid,
        testmethod: patientdetails.testmethod,
        patientname: patientdetails.patientname,
        Dispatchmethod: patientdetails.Dispatchmethod,
        location: userRecord?.location,
        CreatedBy: userRecord?.username,
      })
    );

    formData.append(
      "postcommissionfordoctor",
      JSON.stringify({
        doctorname: patientdetails.Referingdoctor,
        billinginvoice: invoiceno,
        visitid,
        commissionper,
        netAmount: orderdetails.netAmount,
        billingdate: new Date().toLocaleDateString(),
        patientname: patientdetails.patientname,
        location: userRecord?.location,
      })
    );

    formData.append(
      "postreferinghospitaldata",
      JSON.stringify({
        payment_details: formAmount,
        invoiceno,
        patientid,
        visitid,
        patientname: patientdetails.patientname,
        receivedAmount: orderdetails.receivedAmount,
        netAmount: orderdetails.netAmount,
        location: userRecord?.location,
        CreatedBy: userRecord?.username,
        concernname: patientdetails.concernname,
        LabCode: patientdetails?.LabCode,
      })
    );
  };

  // const handlePrint1 = useReactToPrint({
  //   content: () => document.getElementById("reactprintcontentreceipt"),
  //   onBeforeGetContent: () => {
  //     // Dynamically inject the print styles before printing
  //     const styleElement = document.createElement("style");
  //     styleElement.id = "printStyles12";
  //     styleElement.innerHTML = printStyles12;
  //     document.head.appendChild(styleElement);
  //   },
  //   onBeforePrint: () => {
  //     // Ensure styles are applied right before printing
  //     let styleElement = document.getElementById("printStyles12");
  //     if (!styleElement) {
  //       styleElement = document.createElement("style");
  //       styleElement.id = "printStyles12";
  //       styleElement.innerHTML = printStyles12;
  //       document.head.appendChild(styleElement);
  //     }
  //   },

  //   onAfterPrint: async () => {
  //     const printdata = document.getElementById("reactprintcontentreceipt");

  //     try {
  //       if (printdata) {
  //         const contentWidth = printdata.offsetWidth;
  //         const padding = 20;
  //         const pdfWidth = contentWidth + 2 * padding;
  //         const pdfHeight = contentWidth * 1.5;
  //         const pdf = new jsPDF({
  //           unit: "px",
  //           format: [pdfWidth, pdfHeight],
  //         });

  //         await pdf.html(printdata, {
  //           x: padding,
  //           y: padding,
  //           callback: async () => {
  //             const generatedPdfBlob = pdf.output("blob");

  //             const formData1 = new FormData();
  //             formData1.append(
  //               "Bill_Receipt",
  //               generatedPdfBlob,
  //               "billing_receipt.pdf"
  //             );
  //             formData1.append("invoice", invoiceno);

  //             try {
  //               await axios.post(
  //                 `${urllink}Billing/update_bill_receipt`,
  //                 formData1
  //               );
  //               //.log("PDF uploaded successfully");
  //               successMsg("Receipt Saved Successfully");
  //               navigate("/Home/PatientRequestlistforlab");
  //             } catch (err) {
  //               //.error("Error uploading PDF:", err);
  //             }

  //             setIsPrintButtonVisible1(true);
  //           },
  //         });
  //       } else {
  //         throw new Error("Unable to get the target element");
  //       }
  //     } catch (error) {
  //       //.error("Error generating PDF:", error);
  //     }

  //     const styleElement = document.getElementById("printStyles12");
  //     if (styleElement) {
  //       document.head.removeChild(styleElement);
  //     }
  //   },
  // });

  // const [MaxTime, setMaxTime] = useState(null);
  // //.log(MaxTime);
  const [ReportMessage, setReportMessage] = useState(null);
  //.log(ReportMessage);

  const handleSubmit = () => {
    // Required fields validation
    const requiredFields = [
      "visittype",
      "title",
      "patientname",
      "age",
      "gender",
    ];
    const requiredFields1 = ["DiscountReason"];

    const missingFields = requiredFields.filter(
      (field) => !patientdetails[field] || patientdetails[field].trim() === ""
    );

    let missingFields1 = "";
    if (initialState?.totalDiscount > 0) {
      missingFields1 = requiredFields1.filter(
        (field) => !orderdetails[field] || orderdetails[field].trim() === ""
      );
    }

    const allMissingFields = [...missingFields, ...missingFields1];

    if (allMissingFields.length > 0) {
      userwarn(
        `Please fill all required fields: ${allMissingFields.join(", ")}.`
      );
      return;
    } else if (testdetails.length === 0) {
      userwarn("Select Test");
      return;
    }

    // Proceed with the rest of the form submission logic if no fields are missing

    // Initialize variables for max time
    let maxTimeInMinutes = 0;

    testdetails.forEach((test) => {
      const time = parseFloat(test.time); // Convert time to a number
      const timePeriod = test?.Time_Period?.toLowerCase(); // Get the time period and convert to lowercase

      // Convert time to minutes based on the time period
      let timeInMinutes = time;
      if (timePeriod === "Hours") {
        timeInMinutes = time * 60; // Convert hours to minutes
      } else if (timePeriod === "Day") {
        timeInMinutes = time * 24 * 60; // Convert days to minutes
      }

      // Check for maximum time in minutes
      if (timeInMinutes > maxTimeInMinutes) {
        maxTimeInMinutes = timeInMinutes;
      }
    });

    // Get the current time
    const currentDate = new Date();

    // Add maxTimeInMinutes to the current time
    const reportDate = new Date(
      currentDate.getTime() + maxTimeInMinutes * 60000
    ); // 60000 ms = 1 minute

    // Format the date and time for display in 12-hour format with AM/PM
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    const reportTime = reportDate.toLocaleTimeString([], options); // e.g., "7:00 PM"
    const reportDay = reportDate.toLocaleDateString(); // e.g., "8/21/2024"

    // Example output: "Please collect your report on [date] after [time]"
    const reportMessage = `Please collect your report on ${reportDay} after ${reportTime}`;

    // Set the message in your component
    setReportMessage(reportMessage);

    setIsPrintButtonVisible(false);

    setTimeout(() => {
      handlePrint();
    }, 500);
  };

  // const handleSubmit1 = () => {
  //   setIsPrintButtonVisible1(false);

  //   let maxTimeInMinutes = 0;

  //   testdetails.forEach((test) => {
  //     const time = parseFloat(test.time); // Convert time to a number
  //     const timePeriod = test?.Time_Period?.toLowerCase(); // Get the time period and convert to lowercase

  //     // Convert time to minutes based on the time period
  //     let timeInMinutes = time;
  //     if (timePeriod === "hours") {
  //       timeInMinutes = time * 60; // Convert hours to minutes
  //     }

  //     // Check for maximum time in minutes
  //     if (timeInMinutes > maxTimeInMinutes) {
  //       maxTimeInMinutes = timeInMinutes;
  //     }
  //   });

  //   // Get the current time
  //   const currentDate = new Date();

  //   // Add maxTimeInMinutes to the current time
  //   const reportDate = new Date(
  //     currentDate.getTime() + maxTimeInMinutes * 60000
  //   ); // 60000 ms = 1 minute

  //   // Format the date and time for display in 12-hour format with AM/PM
  //   const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  //   const reportTime = reportDate.toLocaleTimeString([], options); // e.g., "7:00 PM"
  //   const reportDay = reportDate.toLocaleDateString(); // e.g., "8/21/2024"

  //   // Example output: "Please collect your report on [date] after [time]"
  //   const reportMessage = `Please collect your report on ${reportDay} after ${reportTime}`;

  //   //.log(reportMessage);

  //   // Set the message in your component
  //   setReportMessage(reportMessage);

  //   setTimeout(() => {
  //     handlePrint1();
  //   }, 500);
  // };

  const handleBillingModeChange = (event) => {
    const { value } = event.target;
    setPatientDetails({ ...patientdetails, testmethod: value });
  };

  useEffect(() => {
    let value = patientdetails.testmethod;
    if (value === "Individual") {
      axios
        .get(
          `${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`
        )
        .then((response) => {
          setTestName(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    } else if (value === "Group") {
      axios
        .get(`${urllink}usercontrol/getallgroupname`)
        .then((response) => {
          //.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    } else {
      axios
        .get(`${urllink}usercontrol/get_all_package_name`)
        .then((response) => {
          //.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          //.error(error);
        });
    }
  }, [
    patientdetails.testmethod,
    patientdetails,
    patientdetails.gender,
    urllink,
  ]);

  const [testnamebydepartment, settestnamebydepartment] = useState([]);

  useEffect(() => {
    // console.log(ageData)
    if (Array.isArray(testnamebydepartment)) {
      const lowerCaseNameQuery = searchQuery.toLowerCase();
      const filteredData = testnamebydepartment.filter((row) => {
        const lowerCasePatientName = row?.Test_Name
          ? row.Test_Name.toLowerCase()
          : "";
        return lowerCasePatientName.includes(lowerCaseNameQuery);
      });
      // console.log(filteredData)
      setFilteredRows(filteredData);
    } else {
      // Handle the case where ageData is not an array
      setFilteredRows([]);
    }
  }, [searchQuery, testnamebydepartment]);

  useEffect(() => {
    const totalPaidAmount = formAmount.reduce(
      (total, ele) => +total + +ele.paidamount,
      0
    );

    const pendingamount = initialState.totalNetAmount - totalPaidAmount;
    // settotalPaidAmount(totalPaidAmount);
    // const totalnet = initialState.totalNetAmount
    setorderdetails((prev) => ({
      ...prev,
      receivedAmount: totalPaidAmount,
      dueAmount: pendingamount,
    }));
    // Remainingamount(formAmount)
  }, [formAmount, paymentdetail.paidamount, initialState]);

  useEffect(() => {
    if (patientdetails.Referingdoctor !== "") {
      const time = setTimeout(() => {
        axios
          .get(
            `${urllink}usercontrol/get_for_indivitual_doctor_commission?ReferingdoctorCommissionID=${patientdetails.ReferingdoctorCommissionID}`
          )
          .then((response) => {
            // //.log(response)
            const data = response.data.amount;
            setcommissionper(data);
          })
          .catch((error) => {
            //.error(error);
          });
      }, 500);
      return () => {
        clearTimeout(time);
      };
    }
  }, [patientdetails.Referingdoctor, urllink]);

  // useEffect(() => {
  //   if (patientdetails.gender !== "" && selecteddepartment) {
  //     axios
  //       .get(
  //         `${urllink}Billing/getalltestnamebydepartment?gender=${patientdetails.gender}&selecteddepartment=${selecteddepartment}`
  //       )
  //       .then((response) => {
  //         //.log(response);
  //         const data = response.data;
  //         settestnamebydepartment(data);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  // }, [patientdetails?.gender, selecteddepartment, urllink]);

  useEffect(() => {
    let value = patientdetails.testmethod;
    if (value === "Individual") {
      axios
        .get(
          `${urllink}usercontrol/getalltestname?gender=${patientdetails.gender}`
        )
        .then((response) => {
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else if (value === "Group") {
      axios
        .get(`${urllink}usercontrol/getallgroupname`)
        .then((response) => {
          console.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .get(`${urllink}usercontrol/get_all_package_name`)
        .then((response) => {
          console.log(response.data);
          setTestName(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [patientdetails.testmethod, patientdetails.gender, urllink]);

  const openviewtest = () => {
    if (!patientdetails.gender) {
      userwarn("Select Gender");
    } else if (!selecteddepartment) {
      userwarn("Select Department");
    } else {
      setOpenModal(true);
    }
  };

  const handleclosemodal1 = () => {
    setOpenModal(false);
    // setCheckedTests([]);
  };

  const [checkedTests, setCheckedTests] = useState([]);

  //.log(checkedTests);

  const handleCheckboxChange = (event, test) => {
    if (event.target.checked) {
      // Add test to checkedTests array
      setCheckedTests([...checkedTests, test]);
    } else {
      // Remove test from checkedTests array
      setCheckedTests(
        checkedTests.filter(
          (checkedTest) => checkedTest.Test_Code !== test.Test_Code
        )
      );
    }
  };

  // Check if a test is in the checkedTests array
  const isChecked = (test) => {
    return checkedTests.some(
      (checkedTest) => checkedTest.Test_Code === test.Test_Code
    );
  };

  const handleSearch1 = async () => {
    // Ensure that tests are selected
    if (checkedTests.length === 0) {
      alert("Please select at least one test.");
      return;
    }

    // Ensure patient details are filled
    if (
      patientdetails.Patientgroup !== "" &&
      patientdetails.testmethod === "Individual"
    ) {
      try {
        // Aggregate test codes from checkedTests
        const testCodes = checkedTests.map((test) => test.Test_Code).join(",");

        // Create URL for the batch request
        const url = `${urllink}usercontrol/get_payment_and_test_details_by_testname_forbulk?Test_Codes=${testCodes}&ratecard=${patientdetails.Patientgroup}`;

        // Make a single request for all test codes
        const response = await axios.get(url);
        //.log(response.data);

        let newData = [];
        if (
          response.data &&
          !Array.isArray(response.data) &&
          response.data.test_code
        ) {
          newData = [response.data];
        } else if (Array.isArray(response.data)) {
          newData = response.data;
        }

        // Check if testdetails is empty
        if (testdetails.length === 0) {
          settestdetails(newData);

          const totalCost = newData.reduce(
            (acc, curr) => acc + Number(curr.cost_per_reportable),
            0
          );
          setorderdetails((prevState) => ({
            ...prevState,
            grossAmount: Number(prevState.grossAmount || 0) + totalCost,
          }));

          setorderdetails((prev) => ({
            ...prev,
            testname: "",
          }));
        } else {
          // Check for duplicate entries
          let existingTestNames = testdetails.map((item) => item.test_name);
          let newTestData = newData.filter(
            (item) => !existingTestNames.includes(item.test_name)
          );

          if (newTestData.length === 0) {
            alert("Data already exists in testdetails");
          } else {
            settestdetails((prevData) => [...prevData, ...newTestData]);

            const totalCost = newTestData.reduce(
              (acc, curr) => acc + Number(curr.cost_per_reportable),
              0
            );
            setorderdetails((prevState) => ({
              ...prevState,
              grossAmount: Number(prevState.grossAmount || 0) + totalCost,
            }));

            setorderdetails((prev) => ({
              ...prev,
              testname: "",
            }));
          }
        }

        // Close the modal
        setOpenModal(false);
        // setCheckedTests([]);
      } catch (error) {
        //.error("An error occurred:", error);
      }
    } else {
      alert(
        "Please enter valid patient details and ensure the test method is 'Individual'."
      );
    }
  };

  const successMsg = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  // const errmsg = (error) => {
  //   toast.error(error, {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { marginTop: "50px" },
  //   });
  // };

  console.log(testdetails);
  const hasHistopathology = testdetails.some(
    (test) => test.Department === "HISTOPATHOLOGY"
  );
  const hasMicrobiology = testdetails.some(
    (test) => test.Department === "MICROBIOLOGY"
  );
  // Convert total amount to words

  const viewgrouptest = (row) => {
    console.log(row);
    setviewtestintogroup(row.Testdetails);
    setOpenModal1(true);
  };

  const handleclosemodal2 = () => {
    setOpenModal1(false);
  };

  const handleDeleted1 = (testCodeToDelete) => {
    const updatedArray = viewtestintogroup.filter(
      (row) => row.Test_Code !== testCodeToDelete
    );

    setviewtestintogroup(updatedArray); // Assuming `setViewtestintogroup` is the state setter for the array
    const updatedTestDetails = testdetails.map((test) => {
      if (test.Testdetails) {
        return {
          ...test,
          Testdetails: test.Testdetails.filter(
            (detail) => detail.Test_Code !== testCodeToDelete
          ),
        };
      }
      return test; // If Testdetails doesn't exist, return the test as is
    });

    // Set the updated test details back to state (assuming you have a setter for testdetails)
    settestdetails(updatedTestDetails);
  };

  const forPrintData = () => {
    return (
      <div className="billing-invoice fhfghf_u_hhhh" id="reactprintcontent">
        <div className="printheader_header">
          <div className="new_billing_address_2 ededfedf_9jui">
            <div
              style={{
                height: "2px",
                width: "100%",
                backgroundColor: "#8E201B",
                marginBottom: "5px",
              }}
            ></div>

            <div className="Register_btn_con_barcode hdyd_p05421 jdedjjcxj_ ">
              <div id="get_imagecontent_1">
                <img src={ClinicDetials.ClinicLogo} alt="logo" />
              </div>

              <div className="helpline-container0po">
              <div className="phone-icon0pfd">
                <img src={PhoneIcon} alt="Phone Icon" />
                </div>
                <div className="helpline-textsxf">
                  <span className="helpmc3yuj">Helpline</span>
                  <span className="number_pus34">
                    {ClinicDetials.ClinicLandLineNo || " - "}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="body_plo">
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              color: "var(--labelcolor)",
              fontWeight: 600,
              padding: "10px",
            }}
          >
            Billing Invoice
          </h3>

          <div className="drefgrgree_9loyuhky">
            <div className="drefgrgree2">
              {/* <div className="Register_btn_con_barcode dcec_pp" id="get_imagecontent_1">
              <Barcode
                value={patinetbillingbarcode || ""}
                lineColor="black"
                height={1}
                width={1.2}
                fontSize={1}
              />
            </div> */}

              <div>
                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Patient Name <span>:</span>
                  </label>
                  <h4>{`${patientdetails.patientname}  ${patientdetails?.ForSurName}  ${patientdetails?.SurName}`}</h4>
                </div>

                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Age/Gender <span>:</span>
                  </label>
                  <h4>{`${patientdetails.age} / ${patientdetails.gender}`}</h4>
                </div>

                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    {" "}
                    Phone No <span>:</span>
                  </label>
                  <h4>{patientdetails.PhoneNo}</h4>
                </div>
                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Address <span>:</span>
                  </label>
                  <h4>{patientdetails.communicationAddress}</h4>
                </div>
              </div>
            </div>

            <div className="drefgrgree2">
              {/* <div className="Register_btn_con_barcode dcec_pp" id="get_imagecontent_2">
              <Barcode
                value={barcode || ""}
                lineColor="black"
                height={1}
                width={1.2}
                fontSize={1}
              />
            </div> */}
              <div>
                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Invoice No <span>:</span>
                  </label>
                  <h4>{invoiceno}</h4>
                </div>
                {/* <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Sample ID <span>:</span>
                  </label>
                  <h4>{barcode}</h4>
                </div> */}
                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Email ID <span>:</span>
                  </label>
                  <h4>{patientdetails?.Email}</h4>
                </div>
                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Ref.Doc <span>:</span>
                  </label>
                  <h4>{patientdetails.Referingdoctor}</h4>
                </div>
                <div className="drefgrgree33 drefgrgree33_bar hhdhhd_0">
                  <label>
                    Date <span>:</span>
                  </label>
                  <h4>{Billing_date}</h4>
                </div>
              </div>
            </div>
          </div>

          <br />
          <hr></hr>
          <br />
          <div className="kkk_ghfg">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Service Name</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {testdetails.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{row.test_name}</td>
                    <td>{formatAmountWithCommas(row.cost_per_reportable)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <div
            className="new_billing_invoice_detials "
            style={{ paddingBottom: "10px", height: "auto" }}
          >
            <div className="fdfgdfgd">
              <div className="total_con_bill_jj">
                <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    Net Amount <span>:</span>
                  </label>
                  <span>{formatAmountWithCommas(orderdetails.netAmount)}</span>
                </div>
                <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    {" "}
                    Billed By <span>:</span>
                  </label>
                  <span>{userRecord?.username}</span>
                </div>
              </div>

              <div className="total_con_bill_jj">
                {/* <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    {" "}
                    Discount Method <span>:</span>
                  </label>
                  <span>{orderdetails?.discountType}</span>
                </div> */}
                <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    Discount <span>:</span>
                  </label>
                  <span>{initialState.totalDiscount}</span>
                </div>
              </div>

              <div className="total_con_bill_jj">
                {formAmount.map((row, index) => (
                  <div key={index} className="nhbyh_5443f7 hdcnjedd_p">
                    {/* <div className="item-index">{index + 1}</div> */}
                    <label>
                      {row.Billpay_method}
                      <span>:</span>
                    </label>
                    <span>{formatAmountWithCommas(row.paidamount)}</span>
                  </div>
                ))}
                <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    {" "}
                    Recived <span>:</span>
                  </label>
                  <span>
                    {formatAmountWithCommas(orderdetails.receivedAmount)}{" "}
                  </span>
                </div>
              </div>
            </div>
            {/* <br /> */}
            <div className="fdfgdfgd">
              <div className="total_con_bill_jj">
                <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    {" "}
                    Amount In Words<span>:</span>
                  </label>
                  <span style={{ color: "grey" }}>
                    {numberToWords(+orderdetails.netAmount)}{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="fdfgdfgd">
              <div className="total_con_bill_jj">
                <div className="nhbyh_5443f7 hdcnjedd_p">
                  <label>
                    Remarks <span>:</span>
                  </label>
                  <h4>{patientdetails.Remarks}</h4>
                </div>
              </div>
            </div>
            <br />

            <div className="Register_btn_con_QRcode_fix">
              <QRCode
                value={`${urllink}Billing/get_report?inv=${invoiceno}`}
                size={70}
                level="H"
                bgColor="#ffffff"
                fgColor="#000000"
              />
            </div>

            <div className="signature-section909">
              <p className="disclaimer23">
                This page is created automatically without a signature.
              </p>
            </div>
          </div>
        </div>

        <div className="Print_footer3456">
          <div className="dcndyeu_oikd">
            <div
              style={{
                height: "2px",
                width: "100%",
                backgroundColor: "#174864",
                marginBottom: "3px",
              }}
            ></div>

            <div className="dchfejjj0p">
              <div>
                <span>
                  {ClinicDetials.ClinicAddress}. {ClinicDetials?.ClinicCity}{" "}
                  {" - "} {ClinicDetials.ClinicCode}. Ph :{" "}
                  {ClinicDetials.ClinicMobileNo + " , "}
                  Email : {ClinicDetials.ClinicMailID} , www.drbaileylabs.com
                </span>{" "}
              </div>

              {/* <div>
                                            
                                           </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const forPrintData1 = () => {
    return (
      <div className="billing-invoice fhfghf_u" id="reactprintcontentreceipt">
        <div className="New_billlling_invoice_head gnfghhfgh">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new__jhtf55 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <br />

        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            color: "var(--labelcolor)",
            fontWeight: 600,
            padding: "10px",
          }}
        >
          Check List
        </h4>

        <div className="drefgrgree">
          <div>
            <div className="drefgrgree33">
              <label>
                Date <span>:</span>
              </label>
              <h4>{Billing_date}</h4>
            </div>
            <div className="drefgrgree33">
              <label>
                Reg Time <span>:</span>
              </label>
              <h4>{currTime}</h4>
            </div>
            <div className="drefgrgree33">
              <label>
                SID No <span>:</span>
              </label>
              <h4>{barcode}</h4>
            </div>
          </div>

          <div className="drefgrgree_head">
            <div className="drefgrgree2">
              <div className="drefgrgree33">
                <label>
                  Patient Name <span>:</span>
                </label>
                <h4>{`${patientdetails.patientname} - (${patientdetails.gender} / ${patientdetails.age})`}</h4>
              </div>
              <div className="drefgrgree33">
                <label>
                  Visit ID <span>:</span>
                </label>
                <h4>{visitid}</h4>
              </div>
              <div className="drefgrgree33">
                <label>
                  Ref Doctor <span>:</span>
                </label>
                <h4>{patientdetails.Referingdoctor}</h4>
              </div>
            </div>
          </div>
        </div>

        <br />
        <hr></hr>

        <div className="kkk_ghfg">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Test Name</th>
              </tr>
            </thead>
            <tbody>
              {testdetails.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td>{row.test_name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br />
        <div className="drefgrgree33 rgdrg">
          <label>
            Remarks <span>:</span>
          </label>
          <h4>{patientdetails.Remarks}</h4>
        </div>
        <br />

        <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
          <div className="fdfgdfgd">
            <div className="total_con_bill_jj">
              <div className="nhbyh_5443f7 hdcnjedd_p">
                <label>
                  Grand Amount <span>:</span>
                </label>
                <h4>{formatAmountWithCommas(orderdetails.netAmount)}</h4>
              </div>
              {formAmount.map((row, index) => (
                <div key={index} className="nhbyh_5443f7 hdcnjedd_p">
                  {/* <div className="item-index">{index + 1}</div> */}
                  <label>
                    Pay method
                    <span>:</span>
                  </label>
                  {/* <h4>{formatAmountWithCommas(row.paidamount)}</h4> */}
                  <h4>{row.Billpay_method}</h4>
                </div>
              ))}

              <div className="nhbyh_5443f7 hdcnjedd_p">
                <label>
                  {" "}
                  Recived Amount<span>:</span>
                </label>
                <h4>{formatAmountWithCommas(orderdetails.receivedAmount)} </h4>
              </div>
            </div>

            <div className="total_con_bill_jj">
              <div className="luutt_8">
                <label>
                  {" "}
                  Amount In Words<span>:</span>
                </label>
                <h3 style={{ color: "grey" }}>
                  {numberToWords(+orderdetails.netAmount)}{" "}
                </h3>
              </div>
            </div>
          </div>
          {/* <br /> */}

          <div className="signature-section909 hjyttyty_">
            <p className="disclaimer23 jsegfgseyu">{ReportMessage}</p>
          </div>
          <div className="signature-section909 hjyttyty_">
            <p className="disclaimer23 jsegfgseyu">
              {`For ${ClinicDetials.ClinicName} CLINICAL LABORATORY `}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPrintButtonVisible && (
        <div className="Supplier_Master_form_Container">
          <div className="Supplier_Master_form_Container_Header">
            <h3>Billing </h3>
          </div>
          {/* <BarcodeDisplay patientDetails={patientDetails} barcode={barcode} /> */}

          <div className="Supplier_Master_form_sub_con">
            <div className="Supplier_Master_form_sub_con_div">
              <div className="Supplier_Master_form_sub_con_div_1">
                <ToggleButtonGroup
                  value={type}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                >
                  <ToggleButton
                    value="PatientDetailCon" // Set the value prop here
                    style={{
                      backgroundColor:
                        type === "PatientDetailCon"
                          ? "var(--ProjectColor)"
                          : "inherit",
                    }}
                    className="Supplier_Master_form_sub_con_div_1_toggle"
                  >
                    Patient Details
                  </ToggleButton>
                </ToggleButtonGroup>
                <br />
                {type === "PatientDetailCon" ? (
                  <div className="Supplier_Master_form_sub_con_div_1">
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Patient Name{" "}
                          <span>
                            {" "}
                            <select
                              id="title"
                              name="title"
                              value={patientdetails.title}
                              onChange={handleInputChange}
                              className="krfekj_09"
                              autocomplete="off"
                            >
                              <option value="">Title</option>
                              <option value="Mr">Mr.</option>
                              <option value="Ms">Ms.</option>
                              <option value="Mrs">Mrs.</option>
                              <option value="Baby">Baby.</option>
                              <option value="Dr">Dr.</option>
                              <option value="Babyof">Baby.of.</option>
                              <option value="Child">Child.</option>
                              <option value="Transgender">Transgender</option>
                            </select>{" "}
                            :
                          </span>
                        </label>
                        <div className="Supplier_Master_form_Con_div_Row_title_name_fr5">
                          <input
                            type="text"
                            name="patientname"
                            list="patientname"
                            // placeholder={
                            //   patientname.length > 0
                            //     ? "Choose Patint"
                            //     : "Enter Patient Name"
                            // }
                            value={patientdetails.patientname}
                            onChange={handleInputChange}
                            autocomplete="off"
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                            // style={{ width: "180px" }}
                          />
                          <datalist id="patientname">
                            {patientname?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </datalist>
                        </div>
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="ForSurName"
                        >
                          Sur Name{" "}
                          <span>
                            {" "}
                            <select
                              id="ForSurName"
                              name="ForSurName"
                              value={patientdetails.ForSurName}
                              onChange={handleInputChange}
                              className="krfekj_09"
                              autocomplete="off"
                            >
                              <option value="S/O">Son of</option>
                              <option value="D/O">Daughter of</option>
                              <option value="W/O">Wife of</option>
                              <option value="H/O">Husband of</option>
                              <option value="F/O">Father of</option>
                              <option value="M/O">Mother of</option>
                            </select>{" "}
                            :
                          </span>
                        </label>
                        <div className="Supplier_Master_form_Con_div_Row_title_name_fr5">
                          <input
                            type="text"
                            name="SurName"
                            list="SurName"
                            value={patientdetails.SurName}
                            onChange={handleInputChange}
                            autocomplete="off"
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          Age
                          <span>
                            {" "}
                            <select
                              id="timeperiod"
                              name="timeperiod"
                              value={patientdetails.timeperiod}
                              onChange={handleInputChange}
                              className="krfekj_09"
                            >
                              <option value="year">Years</option>
                              <option value="day">Days</option>
                              <option value="month">Months</option>
                            </select>{" "}
                            :
                          </span>
                        </label>
                        <div className="Supplier_Master_form_Con_div_Row_title_name_ooooop">
                          <input
                            type="number"
                            name="age"
                            value={patientdetails.age}
                            onChange={handleInputChange}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="place"
                        >
                          Date Of Birth<span>:</span>
                        </label>
                        <input
                          type="Date"
                          name="dob"
                          value={patientdetails.dob}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                          max={new Date().toISOString().split("T")[0]} // Sets the max attribute to today's date
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Visit Id<span>:</span>
                        </label>
                        <input
                          type="text"
                          name="visitid"
                          // placeholder="Enter Visit Id"
                          value={visitid}
                          required
                          readOnly
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="email"
                        >
                          Gender<span>:</span>
                        </label>
                        {isDoctor ? (
                          <select
                            name="gender"
                            value={patientdetails.gender}
                            onChange={(e) => handleInputChange(e)}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        ) : (
                          <input
                            type="text"
                            name="gender"
                            value={patientdetails.gender}
                            onChange={handleInputChange}
                            readOnly
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        )}
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Refering Doctor<span>:</span>
                        </label>
                        <input
                          name="Referingdoctor"
                          list="referingdoc"
                          title="No Ref Doctor Available"
                          autoComplete="off"
                          value={patientdetails.Referingdoctor} // Use the Commission ID here
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                        <datalist id="referingdoc">
                          {refdoc?.map((item, index) => (
                            <option key={index} value={item.Commission_Id}>
                              {item.DoctorName} {/* Display DoctorName */}
                            </option>
                          ))}
                        </datalist>
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          Email <span>:</span>
                        </label>
                        <input
                          type="email"
                          name="Email"
                          // placeholder="Enter Email"
                          value={patientdetails.Email}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    </div>

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="street"
                        >
                          PhoneNo(WhatsApp) <span>:</span>
                        </label>

                        <input
                          type="number"
                          name="PhoneNo"
                          // placeholder="Enter Phone No"
                          value={patientdetails.PhoneNo}
                          onChange={handleInputChange}
                          maxLength={10}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="state"
                        >
                          Visit Type<span>:</span>
                        </label>
                        <select
                          id="visittype"
                          name="visittype"
                          className="Supplier_Master_form_Con_div_Row_input"
                          value={patientdetails.visittype}
                          onChange={handleInputChange}
                        >
                          {/* <option value="">Select</option> */}
                          <option value="Walkin">Walk-in</option>
                          <option value="InHouse">In-House</option>
                          <option value="Outsource">Out-Source</option>
                          {/* <option value="Request">Request</option> */}
                        </select>
                      </div>
                      {/* 
                      {patientdetails.visittype === "Request" && (
                        <div className="Supplier_Master_form_Con_Row">
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="phone2"
                            >
                              Outsource Type<span>:</span>
                            </label>
                            <select
                              id="OutsourceType"
                              name="OutsourceType"
                              value={patientdetails.OutsourceType}
                              onChange={handleInputChange}
                              className="Supplier_Master_form_Con_div_Row_input"
                            >
                              <option value="">Select</option>
                              <option value="Internal">Internal</option>
                              <option value="External">External</option>
                            </select>
                          </div>
                        </div>
                      )} */}

                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="Remarks"
                          >
                            Remarks<span>:</span>
                          </label>
                          <Select
                            id="Remarks"
                            name="Remarks"
                            value={remarksOptions.filter((option) =>
                              patientdetails.Remarks.includes(option.value)
                            )}
                            onChange={handleInputChangeRemarks}
                            options={remarksOptions}
                            isMulti // Enables multiple selections
                            classNamePrefix="react-select-okjuddd"
                            placeholder="Select"
                          />
                        </div>

                        {patientdetails.visittype !== "Outsource" && (
                          <>
                            <div className="Supplier_Master_form_Con_div_Row1">
                              <label
                                className="Supplier_Master_form_Con_div_Row_label"
                                htmlFor="phone2"
                              >
                                Refering-Hospital<span>:</span>
                              </label>
                              <input
                                id="concernname"
                                name="concernname"
                                list="concernnamelist"
                                value={patientdetails.concernname}
                                autocomplete="off"
                                onChange={handleInputChange}
                                placeholder={
                                  labname && labname.length > 0
                                    ? "Select"
                                    : "No Lab Available"
                                }
                                required
                                className="Supplier_Master_form_Con_div_Row_input"
                              />
                              {console.log(labname)}
                              <datalist id="concernnamelist">
                                {labname && labname.length > 0 ? (
                                  labname.map((item, index) => (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  ))
                                ) : (
                                  <option value="" disabled>
                                    No Lab Available
                                  </option>
                                )}
                              </datalist>
                            </div>
                            <div className="Supplier_Master_form_Con_div_Row1">
                              <label
                                className="Supplier_Master_form_Con_div_Row_label"
                                htmlFor="phone2"
                              >
                                Ratecard Type<span>:</span>
                              </label>
                              <input
                                id="Patientgroup"
                                name="Patientgroup"
                                list="Patientgroup1"
                                value={patientdetails.Patientgroup}
                                onChange={handleInputChange}
                                // placeholder="Enter your Address"
                                required
                                readOnly
                                className="Supplier_Master_form_Con_div_Row_input"
                              />
                              <datalist id="Patientgroup1">
                                {franchaise?.map((item, index) => (
                                  <option key={index} value={item}>
                                    {item}
                                  </option>
                                ))}
                              </datalist>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {patientdetails.visittype === "Outsource" && (
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Outsource Lab<span>:</span>
                          </label>
                          <input
                            id="Patientgroup"
                            name="Patientgroup"
                            list="OutsourceLab"
                            value={patientdetails.Patientgroup}
                            onChange={handleInputChange}
                            // placeholder="Enter your Address"
                            placeholder={
                              franchaise && franchaise.length > 0
                                ? "Select"
                                : "No Franchaise Available"
                            }
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                          <datalist id="OutsourceLab">
                            {franchaise?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </datalist>
                        </div>
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="phone2"
                          >
                            Outsource Type<span>:</span>
                          </label>
                          <select
                            id="OutsourceType"
                            name="OutsourceType"
                            value={patientdetails.OutsourceType}
                            onChange={handleInputChange}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select</option>
                            <option value="Internal">Internal</option>
                            <option value="External">External</option>
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="AdhaarNumber"
                        >
                          Adhaar Number <span>:</span>
                        </label>
                        <input
                          type="number"
                          name="AdhaarNumber"
                          // placeholder="Enter Email"
                          value={patientdetails.AdhaarNumber}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="communicationAddress"
                        >
                          Communication Address <span>:</span>
                        </label>
                        <textarea
                          id="communicationAddress"
                          name="communicationAddress"
                          value={patientdetails.communicationAddress}
                          onChange={handleInputChange}
                          required
                          className="Supplier_Master_form_Con_div_Row_input hhxhgsxy"
                        ></textarea>
                      </div>

                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="phone2"
                        >
                          Dispatch Method<span>:</span>
                        </label>

                        <Select
                          id="dispatchMode"
                          name="Dispatchmethod"
                          value={Dispatchoptions.filter((option) =>
                            patientdetails.Dispatchmethod.includes(option.value)
                          )}
                          onChange={handleInputChangeRemarks}
                          options={Dispatchoptions}
                          isMulti // Enables multiple selections
                          classNamePrefix="react-select-okjuddd"
                          placeholder="Select"
                        />
                      </div>
                    </div>
                    <div className="Supplier_Master_form_Con_Row">
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="CapturedFile4"
                        >
                          TRF File<span>:</span>
                        </label>
                        {/* <div className="RegisterForm_2"> */}
                        <input
                          type="file"
                          id="CapturedFile4"
                          name="CapturedFile4"
                          className="hiden-nochse-file"
                          accept="image/*,.pdf"
                          onChange={handleInputChange}
                          required
                          style={{ display: "none" }}
                        />
                        <label
                          htmlFor="CapturedFile4"
                          className="RegisterForm_1_btns choose_file_update"
                        >
                          Choose File
                        </label>
                      </div>
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="UniqueId"
                        >
                          Patient Photo <span>:</span>
                        </label>
                        <div className="file-input-444">
                          <div className="RegisterForm_2">
                            <button
                              onClick={() => setShowFile({ file1: true })}
                              className="RegisterForm_1_btns choose_file_update"
                            >
                              Capture Pic
                            </button>

                            {showFile.file1 && (
                              <div
                                className={
                                  isSidebarOpen
                                    ? "sideopen_showcamera_takepic"
                                    : "showcamera_takepic"
                                }
                                onClick={() =>
                                  setShowFile({
                                    file1: false,
                                  })
                                }
                              >
                                <div
                                  className={
                                    isSidebarOpen
                                      ? "sideopen_showcamera_1_takepic1"
                                      : "showcamera_1_takepic1"
                                  }
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {patientphoto ? ( // Display the captured image
                                    <img
                                      src={URL.createObjectURL(patientphoto)}
                                      alt="captured"
                                      className="captured-image11"
                                    />
                                  ) : (
                                    <div className="camera-container">
                                      <div className="web_head">
                                        <h3>Image</h3>
                                      </div>
                                      <br></br>
                                      <div className="RotateButton_canva">
                                        <Webcam
                                          audio={false}
                                          ref={webcamRef1}
                                          screenshotFormat="image/jpeg"
                                          mirrored={facingMode === "user"}
                                          className="web_cam"
                                          videoConstraints={videoConstraints}
                                        />
                                        {!devices.includes(IsmobileorNot) && (
                                          <button onClick={switchCamera}>
                                            <CameraswitchIcon />
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                  <div className="web_btn">
                                    {isImageCaptured1 ? (
                                      <button
                                        onClick={handleRecaptureImage1}
                                        className="btncon_add"
                                      >
                                        Recapture
                                      </button>
                                    ) : (
                                      <button
                                        onClick={handleCaptureImage1}
                                        className="btncon_add"
                                      >
                                        Capture
                                      </button>
                                    )}

                                    <button
                                      onClick={() =>
                                        setShowFile({
                                          file1: false,
                                        })
                                      }
                                      className="btncon_add"
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <ToggleButton
                      value="orderdetails"
                      style={{
                        color: "black",
                        background: "var(--ProjectColor)",
                        font: "14px",
                      }}
                      className="Supplier_Master_form_sub_con_div_1_toggle"
                    >
                      Billing Informations
                    </ToggleButton>
                    <div className="Supplier_Master_form_sub_con_div_1">
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor=""
                          >
                            Billing Mode <span>:</span>
                          </label>
                          <select
                            id="testmethod"
                            name="testmethod"
                            className="Supplier_Master_form_Con_div_Row_input"
                            value={patientdetails.testmethod}
                            onChange={handleBillingModeChange}
                          >
                            {/* <option value="">Select</option> */}
                            <option value="Individual">Individual</option>
                            <option value="Group">Group</option>
                            <option value="Offers">Packages</option>
                          </select>
                        </div>
                        {patientdetails?.testmethod === "Individual" && (
                          <>
                            <div className="Supplier_Master_form_Con_div_Row1">
                              <label
                                className="Supplier_Master_form_Con_div_Row_label"
                                htmlFor="shortName"
                              >
                                Test Name<span>:</span>
                              </label>
                              <div className="Supplier_Master_form_Con_div_Row_title_name_ooooop">
                                <input
                                  id="testname"
                                  name="testname"
                                  list="browsers1"
                                  value={orderdetails.testname}
                                  onChange={handleInputChange1}
                                  // placeholder="Enter your Address"
                                  autoComplete="off"
                                  disabled={!patientdetails.gender}
                                  placeholder={
                                    !patientdetails.gender
                                      ? "Select Gender"
                                      : "Select Test"
                                  }
                                  required
                                  className="Supplier_Master_form_Con_div_Row_input sxgs_o9"
                                />
                                {patientdetails.testmethod === "Individual" && (
                                  <datalist id="browsers1">
                                    {Array.isArray(testname) &&
                                      testname.map((item, index) => (
                                        <option
                                          key={index}
                                          value={item.Test_Name}
                                        >
                                          {item.Test_Name}
                                        </option>
                                      ))}
                                  </datalist>
                                )}
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "none",
                                  }}
                                  onClick={handleSearch}
                                >
                                  <AddCardIcon />
                                </button>
                              </div>
                            </div>
                          </>
                        )}

                        {patientdetails?.testmethod !== "Individual" && (
                          <div className="Supplier_Master_form_Con_div_Row1">
                            <label
                              className="Supplier_Master_form_Con_div_Row_label"
                              htmlFor="shortName"
                            >
                              Group Name<span>:</span>
                            </label>
                            <div className="Supplier_Master_form_Con_div_Row_title_name_ooooop">
                              <input
                                id="testname"
                                name="testname"
                                list="browsers1"
                                value={orderdetails.testname}
                                onChange={handleInputChange1}
                                autocomplete="off"
                                placeholder={
                                  patientdetails?.testmethod === "Group"
                                    ? "Choose Group"
                                    : "Choose Package"
                                }
                                required
                                className="Supplier_Master_form_Con_div_Row_input"
                              />

                              {patientdetails.testmethod === "Offers" ? (
                                <datalist id="browsers1">
                                  {Array.isArray(testname) &&
                                    testname?.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.package_name}
                                      >
                                        {item.package_name}
                                      </option>
                                    ))}
                                </datalist>
                              ) : (
                                <datalist id="browsers1">
                                  {Array.isArray(testname) &&
                                    testname?.map((item, index) => (
                                      <option
                                        key={index}
                                        value={item.groupname}
                                      >
                                        {item.groupname}
                                      </option>
                                    ))}
                                </datalist>
                              )}
                              <button
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                }}
                                onClick={handleSearch}
                              >
                                <AddCardIcon />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="Supplier_Master_form_sub_con_div_1"></div>
                )}
              </div>
            </div>
            <div className="Supplier_Master_form_sub_con_div">
              <div className="Supplier_Master_form_sub_con_div_1">
                <ToggleButton
                  value="orderdetails"
                  style={{
                    color: "black",
                    background: "var(--ProjectColor)",
                    font: "14px",
                  }}
                  className="Supplier_Master_form_sub_con_div_1_toggle"
                >
                  Order Details
                </ToggleButton>

                <div className="Supplier_Master_form_sub_con_div_1">
                  <div
                    className="Selected-table-container"
                    style={{ marginBottom: "50px" }}
                  >
                    <table className="selected-medicine-table2 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Code</th>
                          <th>Name</th>
                          <th>Amount</th>
                          <th>View</th>
                          {testdetails?.some(
                            (p) => p.Maindepartment === "RADIOLOGY"
                          ) && <th>Imaging Type</th>}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(testdetails)}
                        {testdetails?.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{row.test_code}</td>
                            <td>{row.test_name}</td>
                            <td>
                              <input
                                type="number"
                                name="cost_per_reportable"
                                className="Capture_Status_select1"
                                value={row.cost_per_reportable || ""} // Allow empty input initially
                                onChange={(e) =>
                                  handlecontrastchange(
                                    index,
                                    e.target.value,
                                    e.target.name
                                  )
                                }
                                // onBlur={() => handleBlur(index, "cost_per_reportable")} // Validate on blur
                              />
                            </td>
                            {row.testmethod === "Group" ? (
                              <td>
                                <Button
                                  className="cell_btn"
                                  onClick={() => viewgrouptest(row)}
                                >
                                  <VisibilityIcon className="check_box_clrr_cancell" />
                                </Button>
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                            {row.Maindepartment === "RADIOLOGY" ? (
                              <td>
                                <select
                                  className="Capture_Status_select1"
                                  name="Imagetype"
                                  value={row.Imagetype || ""}
                                  onChange={(e) =>
                                    handlecontrastchange(
                                      index,
                                      e.target.value,
                                      e.target.name
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  <option value="Contrast">Contrast</option>
                                  <option value="Plain">Plain</option>
                                </select>
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                            <td>
                              <button
                                onClick={() => handleDeleted(row.test_name)}
                              >
                                <MdOutlineCancel />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="Supplier_Master_form_Con_Row">
                    {hasHistopathology && (
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="HistopathologyNumber"
                        >
                          Histopathology Number <span>:</span>
                        </label>
                        <input
                          type="text"
                          name="HistopathologyNumber"
                          id="HistopathologyNumber"
                          value={orderdetails?.HistopathologyNumber}
                          onChange={handleInputChange1}
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    )}

                    {hasMicrobiology && (
                      <div className="Supplier_Master_form_Con_div_Row1">
                        <label
                          className="Supplier_Master_form_Con_div_Row_label"
                          htmlFor="MicrobiologyNumber"
                        >
                          Microbiology Number <span>:</span>
                        </label>
                        <input
                          type="text"
                          name="MicrobiologyNumber"
                          id="MicrobiologyNumber"
                          value={orderdetails?.MicrobiologyNumber}
                          onChange={handleInputChange1}
                          className="Supplier_Master_form_Con_div_Row_input"
                        />
                      </div>
                    )}

                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="AdditionalAmount"
                      >
                        Additional Amount <span>:</span>
                      </label>
                      <input
                        type="number"
                        name="AdditionalAmount"
                        id="AdditionalAmount"
                        value={orderdetails?.AdditionalAmount}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="AdditionalAmountReason"
                      >
                        Reason <span>:</span>
                      </label>
                      <select
                        type="text"
                        name="AdditionalAmountReason"
                        placeholder=""
                        value={orderdetails.AdditionalAmountReason}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        {Reasondata.map((remarks, index) => (
                          <option key={remarks.ReasonID} value={remarks.Reason}>
                            {remarks.Reason}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Discount Type<span>:</span>
                      </label>
                      <select
                        id="discountType"
                        name="discountType"
                        value={orderdetails.discountType}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Percentage">Percentage</option>
                      </select>
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Discount<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="discountValue"
                        // placeholder="Enter Discount"
                        value={orderdetails.discountValue}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Discount Reason<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="DiscountReason"
                        // placeholder="Enter Discount"
                        value={orderdetails.DiscountReason}
                        onChange={handleInputChange1}
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>

                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="Billpay_method"
                      >
                        Payment Mode<span>:</span>
                      </label>
                      <select
                        id="dispatchMode"
                        name="Billpay_method"
                        value={paymentdetail.Billpay_method}
                        onChange={handleinputchangepay}
                        className="Supplier_Master_form_Con_div_Row_input"
                      >
                        <option value="">Select</option>
                        <option value="Cash">Cash</option>
                        <option value="Card">Card</option>
                        <option value="OnlinePayment">UPI Payment</option>
                      </select>
                    </div>
                  </div>
                  {paymentdetail.Billpay_method === "Card" ? (
                    <>
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="CardName"
                          >
                            Card Type <span>:</span>
                          </label>
                          <select
                            name="CardType"
                            value={orderdetails.CardType}
                            onChange={handleInputChange1}
                            className="Supplier_Master_form_Con_div_Row_input"
                          >
                            <option value="">Select</option>
                            <option value="Credit">Credit</option>
                            <option value="Debit">Debit</option>
                          </select>
                        </div>
                        {/* <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="CardNumber"
                          >
                            Card Number<span>:</span>
                          </label>
                          <input
                            type="text"
                            name="CardNumber"
                            // placeholder="Enter Discount"
                            value={orderdetails.CardNumber}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div> */}
                      </div>
                      {/* <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="CardName"
                          >
                            Card Name <span>:</span>
                          </label>
                          <input
                            type="text"
                            name="CardName"
                            // placeholder="Enter Discount"
                            value={orderdetails.CardName}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                      </div> */}
                    </>
                  ) : null}
                  {paymentdetail.Billpay_method === "OnlinePayment" && (
                    <>
                      <div className="Supplier_Master_form_Con_Row">
                        <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="TransactionId"
                          >
                            UPI <span>:</span>
                          </label>
                          <input
                            type="text"
                            name="UPI_Mob"
                            // placeholder="Enter Discount"
                            value={orderdetails.UPI_Mob}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div>
                        {/* <div className="Supplier_Master_form_Con_div_Row1">
                          <label
                            className="Supplier_Master_form_Con_div_Row_label"
                            htmlFor="TransactionId"
                          >
                            Transaction Id <span>:</span>
                          </label>
                          <input
                            type="text"
                            name="TransactionId"
                            // placeholder="Enter Discount"
                            value={orderdetails.TransactionId}
                            onChange={handleInputChange1}
                            required
                            className="Supplier_Master_form_Con_div_Row_input"
                          />
                        </div> */}
                      </div>
                    </>
                  )}
                  <div className="Supplier_Master_form_Con_div_Row1">
                    <label
                      className="Supplier_Master_form_Con_div_Row_label"
                      htmlFor="webSite"
                    >
                      Received Amount<span>:</span>
                    </label>

                    <input
                      type="number"
                      name="paidamount"
                      // placeholder="Enter Received Amount"
                      value={paymentdetail.paidamount}
                      onChange={handleinputchangepay}
                      required
                      className="Supplier_Master_form_Con_div_Row_input"
                    />
                  </div>
                  <button
                    className="btncon_add prs-ad-sub-btn"
                    onClick={isEdit !== null ? handleUpdate : handleAdd}
                  >
                    {isEdit !== null ? "Update" : "Add"}
                  </button>

                  <div
                    className="Selected-table-container"
                    style={{ marginBottom: "50px" }}
                  >
                    <table className="selected-medicine-table2 ">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th> Payment Method</th>
                          <th>Paid Amount</th>
                          {/* <th>Ordered Time</th>
                      <th>TAT Time</th> */}
                          <th>Edit</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formAmount.map((row, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td> <td>{row.Billpay_method}</td>
                            <td>{formatAmountWithCommas(row.paidamount)}</td>
                            {/* <td>Ordered Time </td>{" "}
                        <td>TAT Time </td>{" "} */}
                            <td>
                              <button onClick={() => handleEdit(index)}>
                                <EditIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="Supplier_Master_form_Con_Row">
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="shortName"
                      >
                        Net Amount<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="netAmount"
                        placeholder="Enter Net Amount"
                        value={formatAmountWithCommas(orderdetails.netAmount)}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                    <div className="Supplier_Master_form_Con_div_Row1">
                      <label
                        className="Supplier_Master_form_Con_div_Row_label"
                        htmlFor="webSite"
                      >
                        Due Amount<span>:</span>
                      </label>
                      <input
                        type="text"
                        name="dueAmount"
                        placeholder="Enter Balance Amount"
                        value={formatAmountWithCommas(orderdetails.dueAmount)}
                        onChange={handleInputChange1}
                        required
                        className="Supplier_Master_form_Con_div_Row_input"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="Supplier_Master_form_Save">
            <button
              className="Supplier_Master_form_Save_button"
              onClick={handleSubmit}
            >
              Receipt Bill
            </button>
            <ToastContainer />
          </div>
          {/* <div className="Supplier_Master_form_Save">
            <button
              className="Supplier_Master_form_Save_button"
              onClick={handleSubmit1}
              // disabled={ins  ert}
            >
              Check List
            </button>
            
          </div> */}
          {openModal && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={handleclosemodal1}
            >
              <div
                className="newwProfiles"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="ghvyt67_9o0">
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <div className="con_1 ">
                      <div className="inp_1">
                        <label htmlFor="input">
                          Test Name <span>:</span>
                        </label>
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={(e) => handleSearchChange(e, "name")}
                          placeholder="Enter Test Name"
                        />
                      </div>
                    </div>
                  </div>
                  {Array.isArray(filteredRows) && filteredRows.length > 0 ? (
                    filteredRows.map((test, index) => (
                      <div key={index} className="antibioticnames_list33">
                        <label htmlFor={`test-${index}`}>
                          <input
                            type="checkbox"
                            name="testArray"
                            id={`test-${index}`}
                            value={test.Test_Code}
                            checked={isChecked(test)}
                            onChange={(e) => handleCheckboxChange(e, test)}
                          />
                          {test.Test_Name}
                        </label>
                      </div>
                    ))
                  ) : (
                    <div className="Add_items_Purchase_Master">
                      <span>No Test Found</span>
                    </div>
                  )}
                </div>
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={handleclosemodal1}
                  >
                    Close
                  </button>
                  <button
                    className="RegisterForm_1_btns"
                    onClick={handleSearch1}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          )}

          {openModal1 && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={handleclosemodal2}
            >
              <div
                className="newwProfiles"
                onClick={(e) => e.stopPropagation()}
              >
                <br />
                <div className="appointment">
                  <div className="h_head">
                    <h4>Test Details of the Group</h4>
                  </div>
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th>S.No</th>
                        <th>Code</th>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewtestintogroup?.map((row, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{row.Test_Code}</td>
                          <td>{row.test_name}</td>
                          <td>
                            <button
                              onClick={() => handleDeleted1(row.Test_Code)}
                            >
                              <MdOutlineCancel />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="Register_btn_con">
                    <button
                      className="RegisterForm_1_btns"
                      onClick={handleclosemodal2}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!isPrintButtonVisible && forPrintData()}
      {!isPrintButtonVisible1 && forPrintData1()}
    </>
  );
}

export default Billing;
