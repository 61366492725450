import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import LoupeIcon from "@mui/icons-material/Loupe";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function LabPurchaseRaiseList() {
  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const userRecord = useSelector((state) => state.userRecord?.UserData);


  const navigate = useNavigate();
  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  const [Rowdata, setRowdata] = useState([]);
  const [statustype, setstatustype] = useState("");
  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  
  const [SelectedRowss, setSelectedRowss] = useState(null);  
  const [selectedShow1, setSelectedShow1] = useState(false);

  console.log("vcvc", summa);

  useEffect(() => {
    if (Productype === "Medical" && statustype !== "") {
      axios
        .get(
          `${urllink}LabInventory/get_medical_purchase_order_data?Status=${statustype}&Productype=${Productype}`
        )
        .then((response) => {
          const data = response.data.medical_purchase_orders;
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching patients data:", error);
        });
    } else if (
      (Productype === "NonMedical" || Productype === "Stationary") && statustype !== "") {
      axios
        .get(
          `${urllink}LabInventory/get_Nonmedical_purchase_order_data?Status=${statustype}&Productype=${Productype}`
        )
        .then((response) => {
          const data = response.data.medical_purchase_orders;
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            })),
          ]);
        })
        .catch((error) => {
          console.error("Error fetching patients data:", error);
        });
    }else {
      setsumma([]);
    }
  }, [Productype,selectedShow1, statustype]);
  

  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    const index = params.row.items;
    setSelectedRowss(params.row.PurchaseOrder_InvoiceNo);
    console.log("index:", index);
    let B_data = index.map((p, index) => ({
      id: index + 1,
      ...p,
    }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const handlegotoGRN = (params) => {
    const index = params.row.PurchaseOrder_InvoiceNo;
    console.log("index:", index);
    dispatchvalue({
      type: "PurchaseInvoiceGRN",
      value: { Invoice: index, Productype: Productype },
    });
    navigate("/Home/LabPurchaseRecieveMaster");
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    console.log("ss", summa);

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.PurchaseOrder_InvoiceNo.toLowerCase();
      const lowerCaseSupplierCode = row.Supplier_Name.toString().toLowerCase(); // Convert to string for case-insensitive search

      return (
        lowerCaseSupplierName.includes(lowerCaseQuery) ||
        lowerCaseSupplierCode.includes(lowerCaseQuery)
      );
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handleRequestForm = () => {
    navigate("/Home/LabPurchaseRaiseMaster");
  };

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);



  const handleApproveEditIndent = (params) => {
    if (params.row.Status !== "Rejected") {
      const newApprovedQuantity = parseFloat(
        prompt("Enter Approved Quantity:", params.row.ApprovedQuantity)
      );

      if (
        !isNaN(newApprovedQuantity) &&
        newApprovedQuantity <= params.row.OrderQuantity
      ) {
        // Update the Approved_Quantity and Status
        params.row.ApprovedQuantity = newApprovedQuantity;
        params.row.TotalQuantity =
          +params.row.Pack_Quantity * +newApprovedQuantity || 0;
         const data = {
          Productype: Productype,
          index: SelectedRowss,
          Item_Code: params.row.Item_Code,
          Approved_Quantity:params.row.ApprovedQuantity,
          TotalQuantity:params.row.TotalQuantity,
          Status: params.row.Status,
        };
        axios
          .post(`${urllink}LabInventory/PurchaseOrder_Edit`, data)
          .then((response) => {
            console.log(response.data);
            setSelectedShow1(!selectedShow1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        alert(
          "Invalid Approved Quantity. Please enter a valid number Less than or equal Order Quantity."
        );
      }
    } else {
      alert("The selected Item is Rejected");
    }
  };


  const handleRejectEdit = (params) => {
    if (params.row.Status !== "Rejected") {
      const conform = window.confirm("Are you sure ,want to reject the Item");
      if (conform) {
        params.row.ApprovedQuantity = 0;
        params.row.Status = "Rejected";
        params.row.TotalQuantity=0;
        console.log(params.row);
        const data = {
          Productype: Productype,
          index: SelectedRowss,
          Item_Code: params.row.Item_Code,
          Approved_Quantity:params.row.ApprovedQuantity,
          TotalQuantity: params.row.TotalQuantity,
          Status: params.row.Status,
          PruductCategoryType: params.row.PruductCategoryType,
        };
        axios
          .post(`${urllink}LabInventory/PurchaseOrder_Edit`, data)
          .then((response) => {
            console.log(response.data);
            setSelectedShow1(!selectedShow1);

          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      alert("The selected Item is Already Rejected");
    }
  };

  const handleApproveEdit = (params) => {
    const allRejected = params.row.items.every(
      (node) => node.Status === "Rejected"
    );
    console.log(allRejected);

    const updatedRowdata = params.row.items.map((item) => {
      if (item.Status !== "Rejected") {
        return { ...item, Status: "Approved" };
      } else {
        return item;
      }
    });
    const data = updatedRowdata.map((row) => ({
      Productype: Productype,
      index: params.row.PurchaseOrder_InvoiceNo,
      Item_Code: row.Item_Code,
      Approved_Quantity: row.ApprovedQuantity,
      Status: row.Status,
    }));
    // console.log(data)
    if (allRejected) {
      alert("All items are Rejected");
    } else {
      if (params.row.Status === "Waiting") {
        const editrow = {
          Productype: Productype,
          index: params.row.PurchaseOrder_InvoiceNo,
          Status: "Approved",
          Approved_By: userRecord?.username,
          Itemdatas:data
        };
        console.log(editrow);
        const resultReject = window.confirm(
          "Are you sure ,you want to Approve it ?"
        );

        if (resultReject) {
          axios
            .post(
              `${urllink}LabInventory/All_PurchaseRiseApprove`,
              editrow
            )
            .then((response) => {
              console.log(response.data);
              setSelectedShow1(!selectedShow1);
            })
            .catch((error) => {
              console.log(error);
            })
        }
      }
    }
  };


  // Define the columns dynamically
  const dynamicColumns = [
    {
      field: "PurchaseOrder_InvoiceNo",
      headerName: "PurchaseOrder InvoiceNo",
      width: 200,
    },
    { field: "Purchaserise_date", headerName: "Purchaserise date", width: 200 },
    { field: "Supplier_Name", headerName: "Supplier Name", width: 170 },
    { field: "Supplier_Code", headerName: "Supplier_Code", width: 170 },
    { field: "Status", headerName: "Status", width: 120 },
    {
      field: "View",
      headerName: "View",
      width: 120,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
            <VisibilityIcon />
          </Button>
          {params.row.Status === "Approved" && (
            <Button className="cell_btn">
              <ArrowForwardIcon onClick={() => handlegotoGRN(params)} />
            </Button>
          )}
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          {params.row.Status === "Waiting" && (
            <Button
              className="cell_btn"
              onClick={() => handleApproveEdit(params)}
            >
              <CheckCircleOutlineIcon />
            </Button>
          )}
        </>
      ),
    },
    
   
  ];

  const dynamicColumns1 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Generic_Name", headerName: "Generic Name", width: 150 },
    { field: "Strength", headerName: "Strength", width: 120 },
    { field: "UOM", headerName: "UOM", width: 120 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "OrderQuantity", headerName: "Order Quantity", width: 120 },
    {field: "ApprovedQuantity",headerName: "Approved Quantity",width: 120,},
    { field: "TotalQuantity", headerName: "Total Quantity", width: 120 },
    { field: "Status", headerName: "Status", width: 120 },
    {
      field: "Action",
      headerName: "Action",
      width: 140,
      renderCell: (params) =>
        params.row.Status === "Waiting" ? (
          <>
            <Button
              className="cell_btn"
              onClick={() => handleApproveEditIndent(params)}
            >
              <EditIcon />
            </Button>
            <Button
              className="cell_btn"
              onClick={() => handleRejectEdit(params)}
            >
              <DeleteForeverIcon />
            </Button>
          </>
        ) : (
          "Action Completed"
        ),
    },
  ];

  const dynamicColumns2 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "OrderQuantity", headerName: "Order Quantity", width: 120 },
    {field: "ApprovedQuantity",headerName: "Approved Quantity",width: 120,},
    { field: "TotalQuantity", headerName: "Total Quantity", width: 120 },
    { field: "Status", headerName: "Status", width: 120 },
    {
      field: "Action",
      headerName: "Action",
      width: 140,
      renderCell: (params) =>
        params.row.Status === "Waiting" ? (
          <>
            <Button
              className="cell_btn"
              onClick={() => handleApproveEditIndent(params)}
            >
              <EditIcon />
            </Button>
            <Button
              className="cell_btn"
              onClick={() => handleRejectEdit(params)}
            >
              <DeleteForeverIcon />
            </Button>
          </>
        ) : (
          "Action Completed"
        ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>Purchase List Lab</h4>
          <div className="doctor_select_1_90">
            <label htmlFor="Calender"> Product Type <span>:</span></label>
            <select
              className="Product_Master_div_select_opt"
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }}
            >
              <option value="">Select</option>
                <option value="Medical">Medical</option>
                <option value="NonMedical">Non Medical</option>
                <option value="Stationary">Stationary</option>
            </select>
          </div>
        </div>
        <br />

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Supplier Code <span>:</span></label>
            <input
              type="number"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter Supplier Code"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Status <span>:</span></label>
            <select
              value={statustype}
              onChange={(e) => setstatustype(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Waiting">Waiting</option>
              <option value="Approved">Approved</option>
              <option value="Rejected">Rejected</option>
              <option value="Received">Received</option>
            </select>
          </div>
          <button
            className="Supplier_Master_btn_1"
            type="submit"
            onClick={() => handleRequestForm()}
            title="New Supplier"
          >
            <LoupeIcon />
          </button>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                 className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                       <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}





          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype}List</h3>
                  </div>
                  <div className="con_1 "></div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={
                            Productype === "Medical"
                              ? dynamicColumns1
                              : dynamicColumns2
                          } // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          
        </div>
      </div>
    </>
  );
}
