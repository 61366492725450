import React, { useState, useEffect } from "react";
import Barcode from "react-barcode";
import { useSelector } from "react-redux";
import axios from "axios";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
// import { colors } from '@mui/material';
// import { red } from '@mui/material/colors';
import { useDispatch } from "react-redux";
import {
  LineChart,
  Line,
  YAxis,
  CartesianGrid,
  Tooltip,
  XAxis,
  Legend,
} from "recharts";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TbDelta } from "react-icons/tb";
import "../Report/Report.css";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

function Pathologydata() {
  // const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  console.log(testDetails);
  // const [visitids, setvisitids] = useState([])
  const [reflexlist, setreflexlist] = useState([]);
  // const [value, setvalue] = useState([]);
  const [page, setPage] = useState(0);
  const [content, setcontent] = useState("");
  const [openpreview, setopenpreview] = useState(false);
  const [openpreview1, setopenpreview1] = useState(false);
  const [culturevalue, setculturevalue] = useState([]);
  const [showmodel, setshowmodel] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  // const [isNavigating, setIsNavigating] = useState(false)
  const pageSize = 10;
  const totalPages = Math.ceil(culturevalue.length / pageSize);
  const showdown = culturevalue.length;
  const [dataline, setdataline] = useState([]);
  // const [visitid, setvisitid] = useState()
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log("capturedatas", capturedatas);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  const senioredit = useSelector((state) => state.userRecord?.pathalogydoc);
  const isedited = useSelector((state) => state.userRecord?.iseditedsenior);

  const dispatchvalue = useDispatch();
  const [department, setDepartment] = useState([]);

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const [report, setreport] = useState({
    Collected: "",
    Received: "",
    Reported: "",
    Head: "",
    Detail: "",
  });

  const [summa, setsumma] = useState([]);
  // const [isedited, setisedited] = useState('')
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState("");

  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      // Add state to prevent re-renders
      navigate("/Home/AuthorizedReportEntry");
    }
  });

  const navigate = useNavigate();
  const dynamicColumns = [
    { field: "id", headerName: "S.No", width: 150 },

    { field: "antibiotic", headerName: "Antibiotic Name", width: 150 },
    { field: "Sensitivetype", headerName: "Sensitive Type", width: 150 },
    { field: "Oraganism", headerName: "Oraganism", width: 150 },
  ];

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=ALL`)
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas.Patient_Name}`
      )
      .then((response) => {
        console.log(response);
        setpatinetbillingbarcode(response.data.Patient_Barcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink, capturedatas]);

  const handleStatusChange = (index, newValue, dept) => {
    console.log("index", index);
    const updatedTestDetails = [...testDetails];
    let summa = updatedTestDetails.filter((p) => p.department === dept);
    summa[index].status = newValue;
    setTestDetails(updatedTestDetails);

    // Check if status is 'Reflex', then call handleReflexChange
    if (newValue === "Reflex") {
      handleReflexChange(index + 1, dept); // Assuming index is the parameter required by handleReflexChange
    }
  };

  const handlereflexchange = (index, newValue) => {
    const updatedDetails = [...testDetails];
    updatedDetails[index].reflextest = newValue;
    setTestDetails(updatedDetails);
  };

  const handlereflexsamplechange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].reflexsample = newValue;
    setTestDetails(updatedTestDetails);
  };

  // const handlestatuscahngeretest= (index)=>{
  //   const updatedTestDetails = [...testDetails];
  //   updatedTestDetails[index].status = '';
  //   setTestDetails(updatedTestDetails);
  //  }

  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/getverifieddata?Billinginvoice=${capturedatas.Billing_Invoice}&Visitid=${capturedatas.Visit_Id}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          // testcode: item.Test_Code,
          testname: item.Test,
          // testnames: item.Test_Name,
          Test_Code: item.Test_Code,
          Capture_Value: item.Capture_Value,
          Status: item.Status,
          Sample_Specimen: item.Sample_Specimen,
          Patient_Name: capturedatas.Patient_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.Panic_Low,
          panichigh: item.Panic_High,
          Medical_Remark: item.Medical_Remark,
          Technical_Remark: item.Technical_Remark,
          Billing_Invoice: item.Billing_invoice,
          patient_id: capturedatas.Patient_Id,
          visitid: capturedatas.Visit_Id,
          barcode: capturedatas.Barcode,
          status: item.Status === "Retest" ? "" : "Approved",
          reflextest: "",
          reflexsample: "",
          Culturetest: item.Culturetest || "",
          colony_count: item.Colony_Count || "",
          Capture_Oragnism: item.Capture_Oragnism || "",
          location: userRecord?.location,
          createdby: userRecord?.username,
          department: item.Department || "",
          Content: item.Content || "",
          reference: item.Reference,
          referencedata: item.Reference_Range,
          Notes: item.Notes || "",
          Microscopy_Data: item.Microscopy_Data || "",
          Report_Type: item.Report_Type || "",
          Culture_Report: item.Culture_Report || "",
          inputtype: item.Input_Type || "",
          Report_Status: item.Report_Status || "",
          // You don't need to explicitly include the 'actions' field here
        }));
        // setFilteredRows(data);
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas, urllink, userRecord]);

  // const ishistopathology = testDetails.some((item) => item.department === 'HISTOPATHOLOGY')

  const handleverifydata = () => {
    console.log("testDetails", testDetails);
    // Separate the testDetails into groups based on their status
    const recollectTests = testDetails.filter(
      (detail) => detail.status === "Recollect"
    );
    const retestTests = testDetails.filter(
      (detail) => detail.status === "Retest"
    );
    const verifiedTests = testDetails.filter(
      (detail) => detail.status === "Approved"
    );
    const reflexTests = testDetails.filter(
      (detail) => detail.status === "Reflex"
    );
    console.log("reflexTests", recollectTests);
    console.log("reflexTests", retestTests);
    console.log("reflexTests", verifiedTests);
    console.log("reflexTests", reflexTests);
    // Define a function for handling API calls (for demonstration)
    const performApiCall = (url, data) => {
      return axios
        .post(url, data)
        .then((response) => {
          console.log(response);
          alert(response.data.message);
          navigate("/Home/AuthorizedReportEntry");
        })
        .catch((error) => {
          console.error(error);
        });
    };

    console.log(retestTests);

    // Process Recollect Tests
    if (recollectTests.length > 0) {
      performApiCall(
        `${urllink}Phelobotomist/pathologistsampleaprove`,
        recollectTests
      );
    }

    // Process Retest Testsv
    if (retestTests.length > 0) {
      performApiCall(`${urllink}Phelobotomist/updateretest`, retestTests);
    }

    // Process Verified Tests
    if (verifiedTests.length > 0) {
      performApiCall(
        `${urllink}Phelobotomist/insertpathologistcomplete`,
        verifiedTests
      );
    }
    if (testDetails.status === "Reflex") {
      if (reflexTests.length > 0 && !reflexTests.reflex === "") {
        performApiCall(`${urllink}Phelobotomist/reflextestinsert`, reflexTests);
      } else {
        alert("Please Select one Reflex Test");
      }
    }
  };

  const handletrendview = (testname) => {
    axios
      .get(
        `${urllink}Phelobotomist/gettrendsfortest?patientname=${capturedatas.Patient_Name}&test=${testname}`
      )
      .then((response) => {
        console.log(response.data);
        let data = response.data;
        setdataline(data);
        setOpenModel(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleReflexChange = (index, depart) => {
    console.log(index);
    const reflexTestName = testDetails
      .filter((item) => item.id === index && item.department === depart)
      .map((item) => item.testname); // Filter the array based on the index
    const testname = reflexTestName[0];
    console.log("reflexTestName", testname);

    axios
      .get(`${urllink}usercontrol/getforallreflex?testname=${testname}`)
      .then((response) => {
        console.log(response);
        const data = response.data;
        // const nodata = response.error

        if (Array.isArray(data)) {
          setreflexlist(data);
        } else {
          setreflexlist([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  console.log("reflexlist", reflexlist);
  //   useEffect(() => {
  //     if (testDetails && testDetails.status === 'Reflex') {
  //         console.log('reflexTestName', reflexTestName);

  //         // axios.get(`${urllink}Billing/getforvisitid`)
  //         //     .then((response) => {
  //         //         console.log(response);
  //         //     })
  //         //     .catch((error) => {
  //         //         console.log(error);
  //         //     });
  //     }
  // }, [testDetails]);

  // const culturetests = testDetails?.some((item) => item.Culturetest === 'Yes');
  const hasRecollect = testDetails?.some(
    (detail) => detail.status === "Reflex"
  );

  const handlepdfpreview = () => {
    // Accumulate content data
    if (isedited === "PathEdited") {
      setcontent(senioredit);
      setopenpreview(!openpreview);
    } else {
      const accumulatedContent = testDetails
        .filter((item) => item.department === "HISTOPATHOLOGY")
        .map((item) => item.Content)
        .join("\n"); // Assuming item.Content contains strings

      // Update content state
      setcontent(accumulatedContent);

      // Toggle openpreview state
      setopenpreview(!openpreview);
    }
  };

  const handleshowculturetest = () => {
    axios
      .get(
        `${urllink}Phelobotomist/get_for_culture_report_completion?Billinginvoice=${capturedatas?.Billing_Invoice}&Visitid=${capturedatas?.Visit_Id}`
      )
      .then((response) => {
        console.log(response);
        const data = response.data?.map((p, index) => ({
          id: index + 1,
          ...p,
        }));
        setculturevalue(data);
        setshowmodel(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleeditdocs = () => {
    dispatchvalue({ type: "foreditcontent", value: content });
    navigate("/Home/Pathreportpreview");
    // setisedited('Edited')
  };

  useEffect(() => {
    let testname = testDetails[0]?.testname;
    axios
      .get(
        `${urllink}Phelobotomist/get_for_preview_examinations?Testname=${testname}&Visitid=${capturedatas?.Visit_Id}&patientid=1&Billinginvoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response);
        const groupsData = response.data;
        console.log("groupsData", groupsData);
        let latestCollectedDate = new Date(0);
        let latestReceivedDate = new Date(0);
        let latestReportedDate = new Date(0);

        groupsData.forEach((group) => {
          const analyseResultDate = new Date(
            group.Updated_At.Sample_Capture_in_Phelobotomist
          );
          const analyseSeniorDate = group.Updated_At.AnalyseInvestigationSenior;
          const approvePathologistDate = new Date(
            group.Updated_At.Approve_Test_Pathologist
          );

          if (analyseResultDate > latestCollectedDate) {
            latestCollectedDate = analyseResultDate;
          }

          if (analyseSeniorDate > latestReceivedDate) {
            latestReceivedDate = analyseSeniorDate;
          }

          if (approvePathologistDate > latestReportedDate) {
            latestReportedDate = approvePathologistDate;
          }
        });

        const formatDateAndTime = (date) => {
          let d = new Date(date),
            day = "" + d.getDate(),
            month = "" + (d.getMonth() + 1),
            year = d.getFullYear(),
            hours = "" + d.getHours(),
            minutes = "" + d.getMinutes();

          if (day.length < 2) day = "0" + day;
          if (month.length < 2) month = "0" + month;
          if (hours.length < 2) hours = "0" + hours;
          if (minutes.length < 2) minutes = "0" + minutes;

          return (
            [day, month, year].join("/") + " " + [hours, minutes].join(":")
          );
        };

        setreport((prev) => ({
          ...prev,
          Collected: formatDateAndTime(latestCollectedDate),
          Received: formatDateAndTime(latestReceivedDate),
          Reported: formatDateAndTime(latestReportedDate),
        }));

        const reportsData = groupsData.map((group) => {
          return {
            method: group.Method,
            Department: group.Department,
            groupName: group.Group_Name,
            Tests: group.Tests.map((test) => ({
              Test_Name: test.Test_Name || "",
              Method_Name: test.Method_Name || "",
              Department: test.Department || "",
              Sample: test.Specimen || "",
              Samplepicture: test.Samplepic
                ? `data:image/jpeg;base64,${test.Samplepic}`
                : null,
              Content: test.EditContent || "",
            })),
          };
        });

        setsumma(reportsData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [
    testDetails,
    urllink,
    capturedatas?.Visit_Id,
    capturedatas?.Billing_Invoice,
  ]);

  const handleChange = (id, value, dept) => {
    // prompt("You are changing the Capture Value");

    let updatedDetail = [...testDetails];
    let testData = updatedDetail.find(
      (p) => p.id === id + 1 && p.department === dept
    );
    console.log(testData);
    if (testData) {
      testData.Capture_Value = value;
      setTestDetails(updatedDetail); // Assuming testDetails is your state variable
    }
  };

  const parseReferenceData = (referenceData) => {
    console.log(referenceData);
    return referenceData.split(",").map((item) => {
      const [label, value] = item.split(":").map((str) => str.trim());
      let minValue = null;
      let maxValue = null;
      // console.log('value :', value)
      if (value?.includes("<")) {
        maxValue = parseFloat(value.replace("<", ""));
      } else if (value?.includes(">=")) {
        minValue = parseFloat(value.replace(">=", ""));
      } else if (value?.includes("-")) {
        [minValue, maxValue] = value
          .split("-")
          .map((str) => parseFloat(str.trim()));
      } else if (value?.includes(">")) {
        maxValue = value;
      }

      return { label, minValue, maxValue };
    });
  };

  // Function to determine the status based on capture value and reference ranges
  const getStatus = (captureValue, referenceArray) => {
    console.log(captureValue);
    console.log(referenceArray);

    for (let ref of referenceArray) {
      // if (captureValue === 0) {
      //     return <span className='indicator' style={{ color: 'green' }}></span>;

      // } else {
      if (ref.label === "Normal") {
        // Normal range
        if (captureValue >= ref.minValue && captureValue <= ref.maxValue) {
          return (
            <span className="indicator" style={{ color: "green" }}>
              Normal
            </span>
          );
        } else if (captureValue <= ref.minValue) {
          return (
            <span className="indicator" style={{ color: "blue" }}>
              <TiArrowDownThick />
            </span>
          );
        }
      } else if (ref.label === "Borderline") {
        // Borderline range
        if (captureValue >= ref.minValue && captureValue <= ref.maxValue) {
          return (
            <span className="indicator" style={{ color: "blue" }}>
              <TiArrowDownThick />
            </span>
          );
        }
      } else if (ref.label === "High Risk") {
        let highRiskThreshold = null;
        // Determine the high risk threshold
        const minVal = ref.minValue;
        const maxVal = ref.maxValue;

        let minThreshold = null;
        let maxThreshold = null;

        // Parse minValue if it's a string with '>'
        if (typeof minVal === "string" && minVal.includes(">")) {
          minThreshold = parseFloat(minVal.split(">")[1]);
          // minThreshold = minThreshold - 1
        } else if (typeof minVal === "number") {
          minThreshold = minVal;
        } else if (typeof minVal === "string" && minVal.includes(">=")) {
          minThreshold = parseFloat(minVal.split(">=")[1]);
        }

        // Parse maxValue if it's a string with '>'
        if (typeof maxVal === "string" && maxVal.includes(">")) {
          maxThreshold = parseFloat(maxVal.split(">")[1]);
        } else if (typeof maxVal === "number") {
          maxThreshold = maxVal;
        }
        if (typeof maxVal === "string" && maxVal.includes(">=")) {
          maxThreshold = parseFloat(maxVal.split(">=")[1]);
        }

        // Set the highest threshold for high risk
        if (minThreshold !== null && maxThreshold !== null) {
          highRiskThreshold = Math.max(minThreshold, maxThreshold);
        } else if (minThreshold !== null) {
          highRiskThreshold = minThreshold;
        } else if (maxThreshold !== null) {
          highRiskThreshold = maxThreshold;
        }

        // Check if captureValue is above the high risk threshold
        if (highRiskThreshold !== null && captureValue > highRiskThreshold) {
          return (
            <span className="indicator" style={{ color: "red" }}>
              <TiArrowUpThick />
            </span>
          );
        }
        // }
      }
    }
    return "Unknown";
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_for_pending_result_department?invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((response) => {
        console.log(response);
        setDepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink]);

  const reportnogrowth = testDetails.some((p) => p.Report_Type === "NoGrowth");
  const reportot = testDetails.some((p) => p.Report_Type === "Ot");

  console.log(testDetails, "..................testdetatils");

  const handlepdfpreview1 = () => {
    // Accumulate content data
    if (isedited === "PathEdited") {
      setcontent(senioredit);
      setopenpreview1(!openpreview1);
    } else {
      const accumulatedContent = testDetails
        .filter((item) => item.department === "HISTOPATHOLOGY")
        .map((item) => item.Content)
        .join("\n"); // Assuming item.Content contains strings

      // Update content state
      setcontent(accumulatedContent);

      // Toggle openpreview state
      setopenpreview1(!openpreview1);
    }
  };

  return (
    <>
      {department.map((dept, deptIndex) => (
        <div key={deptIndex}>
          <h3>{dept}</h3>
          <div className="samplecapture_component">
            <div className="Selected-table-container">
              {testDetails.some(
                (p) => p.department === dept && p.Culturetest === "Yes"
              ) && (
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Specimen</th>
                      {!reportnogrowth && !reportot && <th>Colony Count</th>}
                      {!reportnogrowth && !reportot && (
                        <th>Capture Oraganism</th>
                      )}
                      {/* {!culturetests && !ishistopathology &&<th>Capture Value</th>} */}
                      {!reportnogrowth && !reportot && <th>Antibiotic</th>}
                      {/* {!culturetests && !ishistopathology &&<><th>Reference Range</th> */}

                      {reportot && <th>Preview</th>}

                      <th>Status</th>
                      <th>Verification</th>
                      {/* <th>
                        Trends
                      </th> */}

                      {hasRecollect ? (
                        <>
                          <th>Reflex</th>2<th>Sample</th>
                        </>
                      ) : null}
                    </tr>
                  </thead>

                  <tbody>
                    {testDetails
                      ?.filter(
                        (p) => p.department === dept && p.Culturetest === "Yes"
                      )
                      .map((detail, index) => (
                        <tr key={index}>
                          <td>{detail.testname}</td>
                          <td>{detail.Sample_Specimen}</td>

                          {!reportnogrowth && !reportot && (
                            <>
                              <td>{detail.colony_count}</td>

                              <td>{detail.Capture_Oragnism}</td>
                            </>
                          )}

                          {!reportnogrowth && !reportot && (
                            <td>
                              <span onClick={handleshowculturetest}>
                                <VisibilityIcon />
                              </span>
                            </td>
                          )}
                          {reportot && (
                            <td>
                              <span>
                                <VisibilityIcon onClick={handlepdfpreview1} />{" "}
                              </span>
                            </td>
                          )}

                          <td>{detail.Status}</td>
                          <td>
                            {detail.Status === "Retest" ? null : (
                              <select
                                name="status"
                                id="status"
                                className="Capture_Status_select"
                                value={detail.status}
                                onChange={(e) =>
                                  handleStatusChange(
                                    index,
                                    e.target.value,
                                    dept
                                  )
                                }
                              >
                                {/* <option value="">Select Status</option> */}
                                <option value="Approved">Approved</option>
                                <option value="Reflex">Reflex</option>
                                <option value="Retest">Retest</option>
                                <option value="Recollect">Recollect</option>
                              </select>
                            )}
                          </td>
                          {/* <td>
                          <span className='trends' onClick={() => handletrendview(detail.Test_Code)}><TbDelta /></span>
                        </td> */}
                          {detail.status === "Reflex" ? (
                            <>
                              <td>
                                <select
                                  name="reflextest"
                                  id="reflextest"
                                  className="Capture_Status_select"
                                  value={detail.reflextest}
                                  onChange={(e) =>
                                    handlereflexchange(
                                      index,
                                      e.target.value,
                                      dept
                                    )
                                  }
                                >
                                  <option value="">Select Reflex</option>
                                  {reflexlist?.map((item, idx) => (
                                    <option key={idx} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  name="reflexsample"
                                  id="reflexsample"
                                  className="Capture_Status_select"
                                  value={detail.reflexsample}
                                  onChange={(e) =>
                                    handlereflexsamplechange(
                                      index,
                                      e.target.value,
                                      dept
                                    )
                                  }
                                >
                                  <option value="">Select Sample</option>
                                  <option value="Same Sample">
                                    Same Sample
                                  </option>
                                  <option value="New Sample">New Sample</option>
                                </select>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
              {dept !== "HISTOPATHOLOGY" &&
                !testDetails.some(
                  (p) => p.department === dept && p.Culturetest === "Yes"
                ) && (
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Specimen</th>
                        <th>Capture Value</th>
                        <th>Reference Range</th>
                        <th>Indicator</th>
                        <th>
                          Remarks
                          <table className="remarktable">
                            <tr>
                              <th>Technical</th>
                              <th>Medical</th>
                            </tr>
                          </table>
                        </th>
                        <th>Status</th>
                        <th>Verification</th>
                        <th>Trends</th>

                        {hasRecollect ? (
                          <>
                            <th>Reflex</th>
                            <th>Sample</th>
                          </>
                        ) : null}
                      </tr>
                    </thead>

                    <tbody>
                      {testDetails
                        .filter((p) => p.department === dept)
                        .map((detail, index) => {
                          const referenceArray = detail.referencedata
                            ? parseReferenceData(detail.referencedata)
                            : [];

                          return (
                            <tr key={index}>
                              <td>{detail.testname}</td>
                              <td>{detail.Sample_Specimen}</td>
                              <td>
                                <input
                                  type="text"
                                  className="Capture_Status_select1"
                                  value={detail.Capture_Value}
                                  onChange={(e) =>
                                    handleChange(index, e.target.value, dept)
                                  }
                                />
                              </td>
                              {detail.reference !== "Yes" ? (
                                <td>
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "5px",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    {detail.paniclow}-{detail.panichigh}
                                    {detail.uom}
                                  </span>
                                </td>
                              ) : (
                                <td>
                                  <div
                                    style={{
                                      width: "90px",
                                      textAlign: "start",
                                    }}
                                  >
                                    {referenceArray.map((ref, refIndex) => (
                                      <div key={refIndex}>
                                        {`${ref.label}: ${
                                          ref.minValue !== null
                                            ? ref.maxValue !== null
                                              ? `${ref.minValue}-${ref.maxValue}`
                                              : `>=${ref.minValue}`
                                            : `${ref.maxValue}`
                                        }`}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              )}

                              <td>
                                <span>
                                  {detail.inputtype === "Numeric" &&
                                    ((Number(detail.Capture_Value) >=
                                      Number(detail.panichigh) && (
                                      <span
                                        className="indicator"
                                        style={{ color: "red" }}
                                      >
                                        <TiArrowUpThick />
                                      </span>
                                    )) ||
                                      (Number(detail.Capture_Value) <=
                                        Number(detail.paniclow) && (
                                        <span
                                          className="indicatorlow"
                                          style={{ color: "blue" }}
                                        >
                                          <TiArrowDownThick />
                                        </span>
                                      )) || (
                                        <p
                                          style={{
                                            backgroundColor: "transparent",
                                            color: "green",
                                          }}
                                        >
                                          Normal
                                        </p>
                                      ))}
                                  {detail.inputtype === "" &&
                                    detail.capturevalue}
                                </span>
                              </td>

                              <td>
                                <table className="remarktable">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <p>{detail.Technical_Remark}</p>
                                      </td>
                                      <td>
                                        <p>{detail.Medical_Remark}</p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td>{detail.Status}</td>
                              <td>
                                {detail.Status === "Retest" ? null : (
                                  <select
                                    name="status"
                                    id="status"
                                    className="Capture_Status_select"
                                    value={detail.status}
                                    onChange={(e) =>
                                      handleStatusChange(
                                        index,
                                        e.target.value,
                                        dept
                                      )
                                    }
                                  >
                                    <option value="Verified">Verified</option>
                                    <option value="Retest">Retest</option>
                                    <option value="Recollect">Recollect</option>
                                  </select>
                                )}
                              </td>
                              <td>
                                <span
                                  className="trends"
                                  onClick={() =>
                                    handletrendview(detail.Test_Code)
                                  }
                                >
                                  <TbDelta />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              {dept === "HISTOPATHOLOGY" && (
                <table className="selected-medicine-table2">
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Specimen</th>

                      <th>Preview</th>
                      <th>Status</th>
                      <th>Verification</th>

                      {hasRecollect ? (
                        <>
                          <th>Reflex</th>
                          <th>Sample</th>
                        </>
                      ) : null}
                    </tr>
                  </thead>

                  <tbody>
                    {testDetails
                      ?.filter((p) => p.department === dept)
                      .map((detail, index) => (
                        <tr key={index}>
                          <td>{detail.testname}</td>
                          <td>{detail.Sample_Specimen}</td>

                          <td>
                            <span>
                              <VisibilityIcon onClick={handlepdfpreview} />
                            </span>
                          </td>
                          <td>{detail.Status}</td>
                          <td>
                            {detail.Status === "Retest" ? null : (
                              <select
                                name="status"
                                id="status"
                                className="Capture_Status_select"
                                value={detail.status}
                                onChange={(e) =>
                                  handleStatusChange(index, e.target.value)
                                }
                              >
                                {/* <option value="">Select Status</option> */}
                                <option value="Approved">Approved</option>
                                <option value="Reflex">Reflex</option>
                                <option value="Retest">Retest</option>
                                <option value="Recollect">Recollect</option>
                              </select>
                            )}
                          </td>
                          {detail.status === "Reflex" ? (
                            <>
                              <td>
                                <select
                                  name="reflextest"
                                  id="reflextest"
                                  className="Capture_Status_select"
                                  value={detail.reflextest}
                                  onChange={(e) =>
                                    handlereflexchange(index, e.target.value)
                                  }
                                >
                                  <option value="">Select Reflex</option>
                                  {reflexlist?.map((item, idx) => (
                                    <option key={idx} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td>
                                <select
                                  name="reflexsample"
                                  id="reflexsample"
                                  className="Capture_Status_select"
                                  value={detail.reflexsample}
                                  onChange={(e) =>
                                    handlereflexsamplechange(
                                      index,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select Sample</option>
                                  <option value="Same Sample">
                                    Same Sample
                                  </option>
                                  <option value="New Sample">New Sample</option>
                                </select>
                              </td>
                            </>
                          ) : null}
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleverifydata}>
          Save
        </button>
      </div>

      {openModel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModel(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              <LineChart
                width={380}
                height={200}
                data={dataline}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                className="chart_linechart"
                style={{ width: "100%", height: "100%" }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="visit" />
                <YAxis className="yaxis_linechart" />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="data"
                  name="Delta Values"
                  stroke="var(--ProjectColor)"
                  activeDot={{ r: 5 }}
                />
              </LineChart>
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {showmodel && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setshowmodel(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="grid_1">
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={culturevalue.slice(
                    page * pageSize,
                    (page + 1) * pageSize
                  )}
                  columns={dynamicColumns}
                  pageSize={pageSize}
                  pageSizeOptions={[pageSize]}
                  onPageChange={(newPage) => setPage(newPage)}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className="data_grid"
                />
                {showdown > 0 && culturevalue.length > pageSize && (
                  <div className="grid_foot">
                    <button
                      onClick={() =>
                        setPage((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page === 0}
                    >
                      Previous
                    </button>
                    Page {page + 1} of {totalPages}
                    <button
                      onClick={() =>
                        setPage((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page === totalPages - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </ThemeProvider>
              <br />
              {
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h3 style={{ color: "red" }}>Notes</h3>:{" "}
                  <p>{testDetails[0].Notes}</p>
                </div>
              }
              {culturevalue.length === 0 && (
                <div className="IP_norecords">
                  <span>No Records Found</span>
                </div>
              )}
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setshowmodel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {openpreview && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopenpreview(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Report_master_preview" id={`reactprintcontent`}>
              <div className="Report_master report_preview">
                <div className="New_billlling_invoice_head new_report_con">
                  <div className="new_billing_logo_con">
                    <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                  </div>
                  <div className="new_billing_address_1 ">
                    <span>{ClinicDetials.ClinicName}</span>
                    <div>
                      <span>{ClinicDetials.ClinicAddress},</span>
                      <span>
                        {ClinicDetials.ClinicCity +
                          "," +
                          ClinicDetials.ClinicState +
                          "," +
                          ClinicDetials.ClinicCode}
                      </span>
                    </div>
                    <div>
                      <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
                      <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
                      <span>{ClinicDetials.ClinicMailID}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="Register_btn_con"
                  style={{ color: "var(--labelcolor)", fontWeight: 600 }}
                >
                  Test Report
                </div>
                <div className="new_billing_address new_report_address">
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_1">
                          <Barcode
                            value={patinetbillingbarcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Patient Name <span>:</span>
                      </label>
                      <span>{capturedatas.Patient_Name}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Visit ID <span>:</span>
                      </label>
                      <span>{capturedatas.Visit_Id}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Age/Gender <span>:</span>
                      </label>
                      <span>
                        {capturedatas.Age}/{capturedatas.Gender}
                      </span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Phone <span>:</span>
                      </label>
                      <span>{capturedatas.Phone}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reference Doctor <span>:</span>
                      </label>
                      <span>{capturedatas.Refering_Doctor}</span>
                    </div>
                  </div>
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_2">
                          <Barcode
                            value={capturedatas.Barcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Invoice No <span>:</span>
                      </label>
                      <span>{capturedatas.Billing_Invoice}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Collected <span>:</span>
                      </label>
                      <span>{report.Collected}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Received <span>:</span>
                      </label>
                      <span>{report.Received}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reported <span>:</span>
                      </label>
                      <span>{report.Reported}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        {" "}
                        Barcode<span>:</span>
                      </label>
                      <span>{capturedatas.Barcode}</span>
                    </div>
                  </div>
                </div>

                {summa
                  .filter((p) => p.Department === "HISTOPATHOLOGY")
                  .map((group, index) => (
                    <table className="report_table report_table_for_micro ">
                      <tbody className="print_body Selected-table-container">
                        <div key={index}>
                          {console.log(group)}
                          <h3>{group.groupName}</h3>

                          <div className="completed_report_1111">
                            <div className="completed_report gghbuy_o9">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: group.Tests[0]?.Content,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                      </tbody>

                      <div className="Add_items_Purchase_Master">
                        <span> - End of Report - </span>
                      </div>
                    </table>
                  ))}
              </div>

              <div className="Register_btn_con regster_btn_contsai">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setopenpreview(false)}
                >
                  Close
                </button>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleeditdocs()}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {openpreview1 && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopenpreview1(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="Report_master_preview" id={`reactprintcontent`}>
              <div className="Report_master report_preview">
                <div className="New_billlling_invoice_head new_report_con">
                  <div className="new_billing_logo_con">
                    <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                  </div>
                  <div className="new_billing_address_1 ">
                    <span>{ClinicDetials.ClinicName}</span>
                    <div>
                      <span>{ClinicDetials.ClinicAddress},</span>
                      <span>
                        {ClinicDetials.ClinicCity +
                          "," +
                          ClinicDetials.ClinicState +
                          "," +
                          ClinicDetials.ClinicCode}
                      </span>
                    </div>
                    <div>
                      <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
                      <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
                      <span>{ClinicDetials.ClinicMailID}</span>
                    </div>
                  </div>
                </div>
                <div
                  className="Register_btn_con"
                  style={{ color: "var(--labelcolor)", fontWeight: 600 }}
                >
                  Test Report
                </div>
                <div className="new_billing_address new_report_address">
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_1">
                          <Barcode
                            value={patinetbillingbarcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Patient Name <span>:</span>
                      </label>
                      <span>{capturedatas.Patient_Name}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Visit ID <span>:</span>
                      </label>
                      <span>{capturedatas.Visit_Id}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Age/Gender <span>:</span>
                      </label>
                      <span>
                        {capturedatas.Age}/{capturedatas.Gender}
                      </span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Phone <span>:</span>
                      </label>
                      <span>{capturedatas.Phone}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reference Doctor <span>:</span>
                      </label>
                      <span>{capturedatas.Refering_Doctor}</span>
                    </div>
                  </div>
                  <div className="new_billing_address_2">
                    {
                      <div className="Register_btn_con_barcode">
                        <div id="get_imagecontent_2">
                          <Barcode
                            value={capturedatas.Barcode || ""}
                            lineColor="black"
                            height={40}
                            width={1.2}
                            displayValue={true}
                            fontSize={12}
                          />
                        </div>
                      </div>
                    }
                    <div className="new_billing_div">
                      <label>
                        Invoice No <span>:</span>
                      </label>
                      <span>{capturedatas.Billing_Invoice}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Collected <span>:</span>
                      </label>
                      <span>{report.Collected}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Received <span>:</span>
                      </label>
                      <span>{report.Received}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        Reported <span>:</span>
                      </label>
                      <span>{report.Reported}</span>
                    </div>
                    <div className="new_billing_div">
                      <label>
                        {" "}
                        Barcode<span>:</span>
                      </label>
                      <span>{capturedatas.Barcode}</span>
                    </div>
                  </div>
                </div>

                {summa
                  .filter((p) => p.Department === "MICROBIOLOGY")
                  .map((group, index) => (
                    <table
                      className="report_table report_table_for_micro"
                      key={index}
                    >
                      <tbody className="print_body Selected-table-container">
                        <tr>
                          <td>
                            <h3>{group.groupName}</h3>

                            <div className="completed_report_1111">
                              <div className="completed_report completed_report04948 gghbuy_o9">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: group.Tests[0]?.Content,
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  ))}
              </div>

              <div className="Register_btn_con regster_btn_contsai">
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => setopenpreview1(false)}
                >
                  Close
                </button>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleeditdocs()}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Pathologydata;
