import React, { useCallback, useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";



function Methods() {
  const [methodData, setMethodData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);

  const [method, setMethod] = useState("");
  const [methodcode, setMethodCode] = useState("");
  const urllink = useSelector((state) => state.userRecord?.UrlLink);


  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };




  const handleSubmitMethods = async () => {
    if (!method.trim() || !methodcode.trim()) {
      userwarn("Both unit Code Name and unit are required.");
      return; // Exit the function early if validation fails
    }
    try {
      // Make a POST request to your Django backend endpoint
      const response = await axios.post(
        `${urllink}mainddepartment/insertmethod`,
        {
          method,
          methodcode,
        }
      );

      // Handle the response as needed
      console.log(response.data);
      successMsg('Successfully Inserted')
      fetchMethodCodeData();
      // Optionally, you can reset the state after a successful submission
      setMethod("");
      // setMethodCode('');
      fetchMethodData();
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle error scenarios
    }
  };

  const fetchMethodData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getmethod`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setMethodData(data);
      })
      .catch((error) => {
        console.error("Error fetching method data:", error);
      });
  },[urllink]);

  const fetchMethodCodeData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getmethodcode`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setMethodCode(data.method_code);
      })
      .catch((error) => {
        console.error("Error fetching method data:", error);
      });
  },[urllink]);

  React.useEffect(() => {
    fetchMethodData();
    fetchMethodCodeData();
  }, [fetchMethodData,fetchMethodCodeData]);

  const handleEdit = (row) => {
    setMethod(row.method_name);
    setMethodCode(row.method_code);
    setIsEditMode(true);
    setSelectedMethodId(row.method_id); // Assuming `method_id` is the identifier
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(
        `${urllink}mainddepartment/updatemethod`,
        {
          method_id: selectedMethodId,
          method_name: method,
          method_code: methodcode,
        }
      );
      console.log(response.data);
      successMsg(response.data.message)
      fetchMethodCodeData();
      setMethod("");
      // setMethodCode('');
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchMethodData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };


  const methodscolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "method_code",
      name: "Method Code",
    },
    {
      key: "method_name",
      name: "Method Name",
      width: 280,
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleEdit(params.row)}
          startIcon={<EditIcon />}
        >
        </Button>
      ),
    },
  ];


  return (
    <>
      <div className="appointment">
        <br />
        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Methods</h2>

          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Method Code <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={methodcode}
                disabled
                onChange={(e) => setMethodCode(e.target.value)}
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Method Name <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={method}
                onChange={(e) => setMethod(e.target.value)}
                placeholder="Enter Method Name"
              />
            </div>
            {/* <div className=""> */}
            <button
              className="RegisterForm_1_btns"
              onClick={isEditMode ? handleUpdateMethod : handleSubmitMethods}
            >
              {isEditMode ? "Update" : <AddIcon />}
            </button>
            {/* </div> */}
          </div>

          <div className="Main_container_app">
          <ReactGrid columns={methodscolumns} RowData={methodData} />
        </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Methods;
