import React, { useEffect, useState } from "react";
import axios from "axios";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./SupplierMaster.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function LabSupplierMaster() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const selectedSupplierIndex = useSelector(
    (state) => state.userRecord?.selectedSupplierIndex
  );

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const navigate = useNavigate();
  const [type, setType] = useState("CommunicationAddressCon");
  const [isInactive, setIsInactive] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [formDataSupplier, setFormDataSupplier] = useState({
    SupplierCode: "", 
    SupplierName: "",
    SupplierGST: "",
    SupplierType: "",
    createdby: userRecord?.username,
    Status: "Active",
    country: "",
    state: "",
    city: "",
    place: "",
    street: "",
    email: "",
    phoneno: "",
    BankName: "",
    BranchName: "",
    AccountNo: "",
    IfscCode: "",
    PancardNo: "",
    Website: "",
    Remarks: "",
    ConcernForm: null,
    Payment_Due_Days: "",
  });
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };


  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }
  
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;
  
    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
  
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
  
      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };

  useEffect(() => {
    if (selectedSupplierIndex && selectedSupplierIndex !== null) {
      axios
        .get(
          `${urllink}LabInventory/get_supplier_Master_Data?selectedSupplierIndex=${selectedSupplierIndex}`
        )
        .then((response) => {
          console.log('response',response.data);
          const selectedSupplier = response.data[0];
          
          const capturedImage1 = base64toFile(
            selectedSupplier.DecoFile1,
            selectedSupplier.DecoFile1name,
            selectedSupplier.DecoFile1type
          );

          setFormDataSupplier({ 
            ...formDataSupplier, 
            ...selectedSupplier, 
            ConcernForm:capturedImage1,
            SupplierType: selectedSupplier.SupplierType || '', 
          });
  
          if (selectedSupplier.Status !== "Active") {
            setIsInactive(true);
          } else {
            setIsInactive(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      axios
        .get(`${urllink}LabInventory/get_supplier_master_code`)
        .then((response) => {
          console.log(response.data.nextSupplierCode);
          setFormDataSupplier((prevData) => ({
            ...prevData,
            SupplierCode: response.data.nextSupplierCode,
          }));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [selectedSupplierIndex]);
  

  console.log("gg", formDataSupplier);
  const handleToggleChange = (event) => {
    const newValue = event.target.value;
    setType(newValue);
  };
  const handleInactiveChange = () => {
    setIsInactive(!isInactive);
    setFormDataSupplier((prevData) => ({
      ...prevData,
      Status: !isInactive ? "Inactive" : "Active",
    }));
  };


  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const isValidEmail = emailPattern.test(email);
    setIsValid(isValidEmail);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "phoneno") {
      const newval = value.length;
      if (newval <= 10) {
        setFormDataSupplier((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      } else {
        userwarn("Mobile No must contain 10 digits");
      }
    }
    else if(name === "email"){
      
      setFormDataSupplier((prevData) => ({
        ...prevData,
        [name]: value,
      }));
     
      validateEmail(value);
    }    
     else {
      let capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1)

      setFormDataSupplier((prevData) => ({
        ...prevData,
        [name]: capitalizedValue,
      }));
    }
  };

  const handleSaveOrUpdate = () => {
    const newData = new FormData();
    console.log("123", formDataSupplier);
    Object.keys(formDataSupplier).forEach((key) => {
      newData.append(key, formDataSupplier[key]);
    });
    const requiredFields = [
      "SupplierCode",
      "SupplierName",
      "SupplierGST",
      "SupplierType",
      "country",
      "state",
      "city",
      "place",
      "street",
      "email",
      "phoneno",
      "BankName",
      "BranchName",
      "AccountNo",
      "IfscCode",
      "PancardNo",
      "ConcernForm",
      "Payment_Due_Days",
    ];

    const missingFields = requiredFields.filter(
      (field) => !formDataSupplier[field]
    );

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // console.log(INmasterFormData.MailId)

    const cond = regex.test(formDataSupplier.email)

    // console.log(cond)   

    if(!cond){
      alert("This is not a valid email format!")
    }
    else{

    if (missingFields.length === 0) {
      if (selectedSupplierIndex && selectedSupplierIndex !== null) {
        // const UpdateId = formDataSupplier.SupplierCode;
        axios
          .post(
            `${urllink}LabInventory/updatesupplierinfo`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            successMsg("Updated Successfully")
            clearFormInputs();
            dispatchvalue({ type: "selectedSupplierIndex", value: null });
            setTimeout(() => {
              navigate("/Home/LabSupplierLists");
            }, 1000);
            
          })
          .catch((error) => {
            console.error("Error posting Supplier data:", error);
          });
      } else {
        axios
          .post(
            `${urllink}LabInventory/insert_supplier_master_data`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            successMsg("Saved Successfully")
            clearFormInputs();
            setTimeout(() => {
              navigate("/Home/LabSupplierLists");
            }, 1000);
            
          })
          .catch((error) => {
            console.error("Error posting Supplier data:", error);
          });
      }
    } else {
      userwarn(
        `Please fill out all required fields: ${missingFields.join(", ")}`
      );
    }
    }
  };

  const clearFormInputs = () => {
    setFormDataSupplier({
      SupplierCode: "",
      SupplierName: "",
      SupplierGST: "",
      SupplierType: "",
      createdby: userRecord?.username,
      Status: "Active",
      country: "",
      state: "",
      city: "",
      place: "",
      street: "",
      email: "",
      phoneno: "",
      BankName: "",
      BranchName: "",
      AccountNo: "",
      IfscCode: "",
      PancardNo: "",
      Remarks: "",
      ConcernForm: null,
      Payment_Due_Days: "",
    });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Supplier Master Lab</h4>
      </div>
      <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="supliercode">
              Supplier Code<span>:</span>
            </label>
            <input
              type="text"
              name="SupplierCode"
              value={formDataSupplier.SupplierCode}
              required
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="Supplier">
              Supplier Name<span>:</span>
            </label>
            <input
              type="text"
              name="SupplierName"
              value={formDataSupplier.SupplierName}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="Supplier">
              Supplier GST Number<span>:</span>
            </label>
            <input
              type="text"
              name="SupplierGST"
              value={formDataSupplier.SupplierGST}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="Supplier">
              Supplier Category<span>:</span>
            </label>
            <select
              name="SupplierType"
              value={formDataSupplier.SupplierType}
              onChange={handleInputChange}
              required
            >
            <option value="">Select</option>
            <option value="Medical">Medical</option>
            <option value="NonMedical">Non Medical</option>
            <option value="Stationary">Stationary</option>
            </select>
          </div>
        </div>
     
      <br />

      <div className="Supplier_Master_form_sub_con">
        <div className="Supplier_Master_form_sub_con_div">
          <div className="Supplier_Master_form_sub_con_div_1">
            <ToggleButtonGroup
              value={type}
              exclusive
              onChange={handleToggleChange}
              aria-label="Platform"
            >
              <ToggleButton
                value="CommunicationAddressCon" // Set the value prop here
                style={{
                  backgroundColor:
                    type === "CommunicationAddressCon"
                      ? "var(--ProjectColor)"
                      : "inherit",
                }}
                className="Supplier_Master_form_sub_con_div_1_toggle"
              >
                Communication Address
              </ToggleButton>
              <ToggleButton
                value="FactoryAddressCon" // Set the value prop here
                style={{
                  backgroundColor:
                    type === "FactoryAddressCon"
                      ? "var(--ProjectColor)"
                      : "inherit",
                }}
                className="Supplier_Master_form_sub_con_div_1_toggle"
              >
                Account Details
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            {type === "CommunicationAddressCon" ? (
              <div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label htmlFor="country">
                      Country<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="country"
                      value={formDataSupplier.country}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="state">
                      State<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="state"
                      value={formDataSupplier.state}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="city">
                      City<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={formDataSupplier.city}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="place">
                      Place<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="place"
                      value={formDataSupplier.place}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="street">
                      Street<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="street"
                      value={formDataSupplier.street}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="email">
                      Email<span>:</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formDataSupplier.email}
                      onChange={handleInputChange}
                      required
                      style={{
                        color: isValid
                              ? "initial"
                              : "red"
                      }}
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="phone1">
                      Phone No<span>:</span>
                    </label>
                    <input
                      type="number"
                      name="phoneno"
                      value={formDataSupplier.phoneno}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label htmlFor="country">
                      Bank Name <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="BankName"
                      value={formDataSupplier.BankName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="state">
                      Branch Name <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="BranchName"
                      value={formDataSupplier.BranchName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="city">
                      Account No <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="AccountNo"
                      value={formDataSupplier.AccountNo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label htmlFor="place">
                      IFSC Code <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="IfscCode"
                      value={formDataSupplier.IfscCode}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="street">
                      Pan Card No <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="PancardNo"
                      value={formDataSupplier.PancardNo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="Supplier_Master_form_sub_con_div">
          <div className="Supplier_Master_form_sub_con_div_1">
            <ToggleButton
              value="Others"
              style={{
                color: "black",
                background: "var(--ProjectColor)",
                font: "14px",
              }}
              className="Supplier_Master_form_sub_con_div_1_toggle"
            >
              Other Details
            </ToggleButton>
            <br />
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="remarks">
                  Payment Due Days<span>:</span>
                </label>
                <input
                  type="number"
                  name="Payment_Due_Days"
                  value={formDataSupplier.Payment_Due_Days}
                  onChange={handleInputChange}
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="remarks">
                  Remarks<span>:</span>
                </label>
                <textarea
                  type="text"
                  name="Remarks"
                  value={formDataSupplier.Remarks}
                  onChange={handleInputChange}
                  readOnly={selectedSupplierIndex}
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label htmlFor="street">
                  Consent Form <span>:</span>
                </label>
                <input
                  type="file"
                  name="ConcernForm"
                  accept=".pdf"
                  id="Supplier_Concern_form"
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setFormDataSupplier((prev) => ({
                      ...prev,
                      ConcernForm: e.target.files[0],
                    }));
                  }}
                  required
                  style={{ display: "none" }}
                />
                <label
                  htmlFor="Supplier_Concern_form"
                  className="RegisterForm_1_btns choose_file_update "
                >
                  Choose File
                </label>
              </div>

              <div className="RegisForm_1 rghrhfh">
                <label htmlFor="inactive">
                  Inactive<span>:</span>
                </label>
                <input
                  type="checkbox"
                  id="inactive"
                  checked={isInactive}
                  onChange={handleInactiveChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSaveOrUpdate}>
          {selectedSupplierIndex ? "Update" : "Save"}
        </button>
      </div>
      <ToastContainer/>
    </div>
  );
}

export default LabSupplierMaster;
