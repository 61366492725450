import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";

const DueBillinglab = () => {
    // const [userRecord, setUserRecord] = useState([]);
    // console.log(userRecord);
  
  const urllink = useSelector(state => state.userRecord?.UrlLink)

  const userRecord = useSelector(state => state.userRecord?.UserData);

  
    const [doctorname, setdoctorname] = useState('')

    const [Billing_date, setBilling_date] = useState(new Date());
  
    const formattedDate = format(Billing_date, "yyyy-MM-dd");
  
    const [Expenses_Invoice_No, setExpenses_Invoice_No] = useState(null);

  
    const [Expense_Amount, setExpense_Amount] = useState("");
  
    const [Reasion_Expense, setReasion_Expense] = useState("");

    const [Bankname, setbankname]  = useState('')

    const [accountnum, setaccountnum] = useState('')

    const [holdername, setholdername]  = useState('')

    const [fromdate , setfromdate] = useState('')

    const [todate, settodate] = useState('')
    
    // const [Expense_Type, setExpense_Type] = useState("");
    const [paymenttype, setpaymenttype] = useState('')
  
    

      const handleChange = (e) => {
        const value = e.target.value;
    
       
      };

      useEffect(()=>{
        if (doctorname!==""){
        const time = setTimeout(() => {
        axios.get(`${urllink}Billing/get_transaction_id_for_doctor_commission?doctorname=${doctorname}`)
        .then((response)=>{
            console.log(response)
            const data = response.data.transaction_id
            setExpenses_Invoice_No(data)

        })
        .catch((error)=>{
            console.error(error)
        })
    }, 200);
    return ()=>{
        clearTimeout(time)
    }
        

    }
      },[doctorname])


      useEffect(()=>{
        if(doctorname!== ''&& fromdate!=='' && todate !==''){
            const time = setTimeout(() => {
        axios.get(`${urllink}Billing/get_amount_and_payment?doctorname=${doctorname}&fromdate=${fromdate}&todate=${todate}`)
        .then((response)=>{
            console.log(response)
            const data = parseFloat(response.data.TotalAmount)
            // TotalAmount = parseFloat(data)
            setExpense_Amount(data)

        })
        .catch((error)=>{
            console.error(error)
        })
    },200)

    return()=>{
        clearTimeout(time)
    }
    }
      },[doctorname, fromdate, todate])



      useEffect(()=>{
        if (doctorname!==""){
            const time = setTimeout(() => {
        axios.get(`${urllink}Billing/get_for_payment_type?doctorname=${doctorname}`)
        .then((response)=>{
            console.log(response)
            // const data = response.data.transaction_id
            // setExpenses_Invoice_No(data)
            const paymenttype = response.data[0].Payment_Type
            const accountnum = response.data[0].Account_Number
            const holdername = response.data[0].Holder_Name
            const Bank_Name = response.data[0].Bank_Name
            setpaymenttype(paymenttype)
            setholdername(holdername)
            setbankname(Bank_Name)
            setaccountnum(accountnum)
        })
        .catch((error)=>{
            console.error(error)
        })
    }, 200);
    return ()=>{
        clearTimeout(time)
    }
        

    }
      },[doctorname])
  

      const handlesubmit = ()=>{
        const postdata ={
            doctorname : doctorname,
            transactionid : Expenses_Invoice_No,
            fromdate : fromdate,
            todate : todate,
            Billing_date : formattedDate,
            Paidby : userRecord?.username,
            paymenttype : paymenttype,
            paidamount : Expense_Amount,
            location : userRecord?.location,
            reason : Reasion_Expense
        }

        axios.post(`${urllink}Billing/insert_into_table_paid`, postdata)
        .then((response)=>{
            console.log(response)
            setBilling_date("")
            setExpense_Amount("")
            setExpenses_Invoice_No('')
            setReasion_Expense("")
            setaccountnum("")
            setbankname("")
            setdoctorname("")
            setfromdate("")
            setholdername("")
            setpaymenttype("")
            settodate("")
            
        })
        .catch((error)=>{
            console.log(error)
        })
       }
  
  return (
    <div className="appointment">
    <div className="h_head">
      <h4>Due Payout For Lab</h4>
    </div>
    <br />
    

    <div className="RegisFormcon">
      <div className="RegisForm_1">
        <label htmlFor="Doctor_Name">
          {" "}
          Lab Name: <span>:</span>
        </label>
        <input

        
          type="text"
          name="Doctor_Name"
          value={doctorname}
          onChange={(e)=>{setdoctorname(e.target.value)}}
        //   readOnly
        />
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          Transaction No: <span>:</span>
        </label>
        <input name="Transaction_No" readOnly value={Expenses_Invoice_No} />
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          From Date: <span>:</span>
        </label>
        <input
          type="Date"
          name="Billing_date"
          value={fromdate}
          onChange={(e)=>{setfromdate(e.target.value)}}
        />
      </div>
      <div className="RegisForm_1">
        <label htmlFor="Transaction_No">
          To Date: <span>:</span>
        </label>
        <input
          type="Date"
          name="Billing_date"
          value={todate}
          onChange={(e)=>{settodate(e.target.value)}}
        />
      </div>
   
    
      <div className="RegisForm_1">
      <label htmlFor="Billing_date">
        Paid Date <span>:</span>
      </label>
      <input
        type="Date"
        name="Billing_date"
        value={Billing_date.toISOString().split("T")[0]} // Convert to ISO string and extract the date part
        onChange={(e) => setBilling_date(e.target.value)} // Set directly as the ISO date string
        readOnly
      />
      </div>

      <div className="RegisForm_1">
        <label htmlFor="Issue_From_id">
          Issue From <span>:</span>
        </label>
        <div className="Issue_input">
          <input
            type="text"
            name="Issue_From_id"
            className="dura_with1"
            value={userRecord?.user_id}
            readOnly
          />
          <input
            name="Issue_From_name"
            className="saxdsed"
            value={userRecord?.username}
            readOnly
          />
        </div>
      </div>
  

    {/* <div className="RegisForm_1">
      <label htmlFor="">
        Expenses Types <span>:</span>
      </label>
      <select
        type="text"
        name="Expenses_Types"
        value={Expense_Type}
        onChange={(e) => {
          setExpense_Type(e.target.value);
        }}
      >
        <option value={""}>Select</option>
        {Expenses_Name_list.map((ele, ind) => (
          <option key={ind} value={ele.Expenses_Name}>
            {ele.Expenses_Name}
          </option>
        ))}
      </select>
    </div> */}

    <div className="RegisForm_1">
      <label htmlFor="">
        Amount <span>:</span>
      </label>
      <input
        type="number"
        name="Given_amount"
        value={Expense_Amount}
        onChange={handleChange}
      />
    </div>

    <div className="RegisForm_1">
      <label>
        Payment Method <span>:</span>
      </label>
      <input
        type="text"
        name="paymenttype"
        value={paymenttype}
        onChange={handleChange}
      />
      
    </div>
    {(paymenttype === 'Banking' || paymenttype === 'DD')&&(
                         <>
                         <div className="RegisForm_1">
                             <label className="new_form_first" htmlFor="Bankname">Bank Name<span>:</span></label>
                             <input
                                 type="text"
                                 id="Bankname"
                                 name="Bankname"
                                 required
                                //  value=
                                value={Bankname}
                                onChange={handleChange}
                             />
                         </div>
                         <div className="RegisForm_1">
                             <label className="new_form_first" htmlFor="HolderName"> Holder Name<span>:</span></label>
                             <input
                                 type="text"
                                 id="HolderName"
                                 name="HolderName"
                                 required
                                 value={holdername}
                                 onChange={handleChange}
                             />
                         </div>
                            <div className="RegisForm_1">
                             <label className="new_form_first" htmlFor="CardNumber"> Account Number<span>:</span></label>
                             <input
                                 type="number"
                                 id="CardNumber"
                                 name="CardNumber"
                                 required
                                 value={accountnum}
                                 onChange={handleChange}
                             />
                         </div>

                            
                         </>
                        )}



    <div className="RegisForm_1">
      <label htmlFor="">
        Reason <span>:</span>
      </label>
      <textarea
        name="Reasion_Expense"
        value={Reasion_Expense}
        onChange={(e) => {
          const inputText = e.target.value;

          if (inputText.length <= 100) {
            setReasion_Expense(inputText);
          } else {
            alert("Maximum allowed wordsss is 100");
          }
        }}
      />
    </div>

    {/* <div className="RegisForm_1">
      <span className="Spanletter">
        {" "}
        * Total Balance Amount Is {view_Amount}
      </span>
      <span className="Spanletter">
        {" "}
        * Balance Amount In Cash {view_Amount1}
      </span>
      {/* <span className='Spanletter'> * Balance Amount In Recived Bank {view_Amount2}</span> 
    </div>*/}
    </div> 

    <div className="Register_btn_con">
      <button className="RegisterForm_1_btns" onClick={handlesubmit}>
        Save
      </button>
    </div>
  </div>
  )
}

export default DueBillinglab