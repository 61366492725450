import React, { useState, useEffect } from "react";
import "./PasswordChange.css";
import Axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";


function PasswordChange() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newImageProfile, setNewImageProfile] = useState(null);
  const [newImageCover, setNewImageCover] = useState(null);

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  useEffect(() => {
    Axios.get(`${urllink}profile/getuserprofile?user_id=${userRecord?.user_id}`)
      .then((response) => {
        if (response.data) {
          const userData = response.data;

          const profileBlob = dataURItoBlob(
            userData.Profile_image
          );
          const coverBlob = dataURItoBlob(
            userData.Cover_image
          );

          setNewImageProfile(profileBlob);
          setNewImageCover(coverBlob);
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [userRecord?.user_id, urllink]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  // Function to handle password change
  const handlePasswordChange = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Check if newPassword and confirmPassword match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      // Make an Axios API call to change the password in the database\

      const datatosend = new FormData();

      datatosend.append('userid', userRecord?.user_id)
      datatosend.append('newpassword', newPassword)

      Axios.post(
        `${urllink}loginpage/update_new_password`, datatosend
      )
        .then((response) => {
          successMsg(response.data.message);
          setNewPassword("");
          setConfirmPassword("");
        })
        .catch((error) => {
          // Handle error
          console.error("Error changing password:", error);
        });
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  // const warnmsg = (errorMessage) => {
  //   toast.warn(`${errorMessage}`, {
  //     position: "top-center",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "dark",
  //     style: { marginTop: "50px" },
  //   });
  // };


  return (
    <form>
      <div className="container_pass">
        <div className="head_img_head1 ">
          <div className="head_img1 ">
            {newImageCover && (
              <img src={URL.createObjectURL(newImageCover)} alt="Cover" />
            )}
          </div>
          <div className="prof_round_head">
            <div className="profile_round">
              {newImageProfile && (
                <img src={URL.createObjectURL(newImageProfile)} alt="Profile" />
              )}
            </div>
            <div className="user_name1">
              <div className="name1">
                <div className="rolenam1">
                  <h4>{userRecord?.username?.toUpperCase()}</h4>
                </div>
                <br></br>
                <div className="rolenam1">
                  <h6>{userRecord?.role_name?.toLowerCase()}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />

        <div className="user_items1">
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label className="first2">
                New Password<span>:</span>
              </label>
              <input
                type="password"
                className="inp1"
                placeholder="Enter your Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
          <br />
          <br />
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label className="first2">
                Confirm Password<span>:</span>
              </label>
              <input
                type="password"
                className="inp1"
                placeholder="Confirm your Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                autoComplete="new-password"
              />
            </div>
          </div>
          <br />

          <div className="Register_btn_con">
            <button
              className="RegisterForm_1_btns"
              onClick={handlePasswordChange}
            >
              Confirm
            </button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </form>
  );
}

export default PasswordChange;
