import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import ForwadIcon from "@mui/icons-material/Forward";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";

const Approvereport = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [summa, setsumma] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [alignment, setAlignment] = useState("UbNormalList");
  const [ubnormaldata, setubnormaldata] = useState("");
  const [statussavedata, setstatussavedata] = useState([]);
  console.log(statussavedata);

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment); // Update state when a new value is selected
    }
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/getforpathalogist?Location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data.map((row, index) => ({
          id: index, // Assuming you want to use Patient_Id as id
          ...row,
        }));
        data.sort((a, b) => {
          const invoiceA = parseInt(a.Billing_Invoice.replace(/\D/g, ""), 10);
          const invoiceB = parseInt(b.Billing_Invoice.replace(/\D/g, ""), 10);
          return invoiceB - invoiceA;
        });
        setsumma(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [urllink, userRecord]);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_patient_request_approve_UbNormal_data?Location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data.map((row, index) => ({
          id: index + 1,
          ...row,
        }));
        setubnormaldata(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [urllink, userRecord]);

  useEffect(() => {
    const lowerCaseNameQuery = searchQuery.toLowerCase();
    const lowerCasePhoneQuery = searchQuery1.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.Patient_Name
        ? row.Patient_Name.toLowerCase()
        : "";
      const lowerCasePhone = row.Phone ? row.Phone.toLowerCase() : "";
      return (
        lowerCasePatientName.includes(lowerCaseNameQuery) &&
        lowerCasePhone.includes(lowerCasePhoneQuery)
      );
    });
    setFilteredRows(filteredData);
  }, [searchQuery, searchQuery1, summa]);

  const handleSearchChange = (event, type) => {
    const value = event.target.value;
    if (type === "name") {
      setSearchQuery(value);
    } else if (type === "phone") {
      setSearchQuery1(value);
    }
  };

  const dispatchvalue = useDispatch();

  const handleSampleCapture = (params) => {
    dispatchvalue({ type: "Capturesample", value: params.row });

    console.log(dispatchvalue);
    navigate("/Home/NavigationPathalogist");
  };



  const handleStatusChange = (e, params) => {
    const newStatus = e.target.value; // Get the new status from the select
    console.log(newStatus);
    console.log(params);
    const updatedData = ubnormaldata.map((item) => {
      // Update the status for the row with the matching Test_Code
      if (item.Test_Code === params.row.Test_Code) {
        return { ...item, Status: newStatus }; // Assuming status is the field to be updated
      }
      return item; // Return the unchanged item
    });
    setstatussavedata(updatedData); // Update the state with the new data
  };

  const handlesave = () => {
    let datatosend;

    if (statussavedata.length > 0) {
      datatosend = statussavedata;
    } else {
      const confirmation = window.confirm(
        "Do you approve these Abnormal Tests?"
      );
      if (confirmation) {
        datatosend = ubnormaldata;
      } else {
        return;
      }
    }


    console.log('datatosend,', datatosend);

    axios
      .post(`${urllink}Phelobotomist/insert_ubnormal_path_data`, datatosend)
      .then((res) => {
        console.log("Response:", res);
        alert("Data saved successfully!"); // Optional: Inform the user on success
        navigate("/Home/DispatchEntryList");
      })
      .catch((err) => {
        console.error("Error:", err);
        alert("An error occurred while saving data."); // Optional: Inform the user on failure
      });
  };

  const dynamicColumns3 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "UpdatedAt",
      name: "Date",
      frozen: true,
      width: 100,
    },
    {
      key: "Barcode_Id",
      name: "SID",
      frozen: true,
    },
    {
      key: "Patent_Name",
      name: "Patent Name",
      frozen: true,
      width: 300,
    },

    {
      key: "Test",
      name: "Test name",
    },
    {
      key: "Reference_Range",
      name: "Reference Range",
      renderCell: (params) => (
        <>{`${params.row.Panic_Low} - ${params.row.Panic_High}`}</>
      ),
    },
    {
      key: "Capture_Value",
      name: "Capture Value",
    },
    {
      key: "Indicator",
      name: "Indicator",
      renderCell: (params) => (
        <>
          {params.row.Indicatorstatus === "High" && (
            <span className="indicator" style={{ color: "red" }}>
              <TiArrowUpThick />
            </span>
          )}
          {params.row.Indicatorstatus === "Low" && (
            <span className="indicatorlow" style={{ color: "blue" }}>
              <TiArrowDownThick />
            </span>
          )}
        </>
      ),
    },
    {
      key: "Remarks",
      name: "Remarks",
      frozen: true,
    },

    {
      key: "Status",
      name: "Status",
      renderCell: (params) => (
        <>
          <select
            name="status"
            id="status"
            className="Capture_Status_select"
            onChange={(e) => handleStatusChange(e, params)}
          >
            <option value="Approved">Approved</option>
            <option value="Retest">Retest</option>
            <option value="Recollect">Recollect</option>
          </select>
        </>
      ),
    },


  ];

  const dynamicColumns2 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice",
      frozen: true,
    },
    {
      key: "Patient_Name",
      name: "Patient Name",
      frozen: true,
      width: 300,
    },
    {
      key: "Barcode",
      name: "Sample Id",
    },

    {
      key: "Patient_Id",
      name: "Patient Id",
    },
    {
      key: "UpdatedAt",
      name: "Date",
    },

    {
      key: "Age",
      name: "Age",
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleSampleCapture(params)}
            sx={{
              color: "var(--ProjectColor)",
              "&:hover": {
                color: "var(--ProjectColorhover)",
              },
            }}
          >
            <ForwadIcon />
          </Button>
        </>
      ),
    },
    {
      key: "Gender",
      name: "Gender",
    },
    {
      key: "Phone",
      name: "Phone",
    },
    {
      key: "Refering_Doctor",
      name: "Reference Doctor",
    },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h4>Approval Que List</h4>
        </div>
        <div className="chart_body_1_head">
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="UbNormalList"
              style={{
                backgroundColor:
                  alignment === "UbNormalList"
                    ? "var(--ProjectColor)"
                    : "inherit",
                // width: "100%",
              }}
              className="togglebutton_container"
            >
              UbNormal List
            </ToggleButton>
            <ToggleButton
              value="OverAllList"
              style={{
                // width: "100%",
                backgroundColor:
                  alignment === "OverAllList"
                    ? "var(--ProjectColor)"
                    : "inherit",
              }}
              className="togglebutton_container"
            >
              OverAll List
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <br />
        {alignment === "OverAllList" && (
          <>
            <div
              style={{ width: "100%", display: "grid", placeItems: "center" }}
            >
              <div className="con_1 ">
                <div className="inp_1">
                  <label htmlFor="input">
                    Patient Name <span>:</span>
                  </label>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => handleSearchChange(e, "name")}
                    placeholder="Enter Patient Name"
                  />
                </div>
                <div className="inp_1">
                  <label htmlFor="input">
                    Phone No<span>:</span>
                  </label>
                  <input
                    type="text"
                    value={searchQuery1}
                    onChange={(e) => handleSearchChange(e, "phone")}
                    placeholder="Enter Phone Number"
                  />
                </div>
              </div>
            </div>

            <div className="Main_container_app">
              <ReactGrid columns={dynamicColumns2} RowData={filteredRows} />
            </div>
          </>
        )}

        {alignment !== "OverAllList" && (
          <>
            <div className="Main_container_app">
              <ReactGrid columns={dynamicColumns3} RowData={ubnormaldata} />
            </div>
            <div className="Register_btn_con regster_btn_contsai">
              <button className="RegisterForm_1_btns" onClick={handlesave}>
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Approvereport;
