import React, { useState, useRef, useEffect, useCallback } from "react";
import "./ProPass.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const Profile = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [newImageProfile, setNewImageProfile] = useState(null);
  const [newImageCover, setNewImageCover] = useState(null);
  const [changesMade, setChangesMade] = useState(false);

  const [phoneNo, setPhoneNo] = useState(userRecord?.PhoneNumber || "");
  const [doorNo, setDoorNo] = useState("");
  const [street, setStreet] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const profileData1 = useCallback(() => {
    axios
      .get(`${urllink}profile/getuserprofile?user_id=${userRecord?.user_id}`)
      .then((response) => {
        if (response.data) {
          const userData = response.data;

          setPhoneNo(userData.PhoneNumber || userRecord.PhoneNumber || "");
          setDoorNo(userData.Door_No || "");
          setStreet(userData.Street || "");
          setArea(userData.Area || "");
          setCity(userData.City || "");
          setPincode(userData.PinCode || "");
          setState(userData.State || "");
          setCountry(userData.Country || "");

          if (userData.Profile_image) {
            setNewImageProfile(userData.Profile_image);
          } else {
            console.error("Profile image not found or empty");
          }

          if (userData.Cover_image) {
            setNewImageCover(userData.Cover_image);
          } else {
            console.error("Cover image not found or empty");
          }
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [urllink, userRecord?.user_id, userRecord?.PhoneNumber]);

  useEffect(() => {
    if (phoneNo && phoneNo.length > 10) {
      alert("Phone Number must be 10 digits");
      setPhoneNo(phoneNo.slice(0, 10));
    }
  }, [phoneNo]);

  useEffect(() => {
    if (userRecord?.user_id) {
      profileData1();
    }
    if (userRecord?.PhoneNumber) {
      setPhoneNo(userRecord.PhoneNumber);
    }
  }, [userRecord.user_id, userRecord.PhoneNumber, profileData1]);

  useEffect(() => {
    return () => {
      if (newImageCover) {
        URL.revokeObjectURL(newImageCover);
      }
      if (newImageProfile) {
        URL.revokeObjectURL(newImageProfile);
      }
    };
  }, [newImageCover, newImageProfile]);

  const onImageChange = (e, setImageFunction) => {
    const file = e.target.files[0];

    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const dataURL = event.target.result;
        const blob = dataURItoBlob(dataURL);
        setImageFunction(blob);
        setChangesMade(true);
      };

      fileReader.onerror = (error) => {
        console.error("Error reading file:", error);
      };

      fileReader.readAsDataURL(file);
    }
  };

  const handleFileInputCover = (event) => {
    onImageChange(event, setNewImageCover);
  };

  const handleFileInputProfile = (event) => {
    onImageChange(event, setNewImageProfile);
  };

  const handleSaveClick = () => {
    if (changesMade) {
      const formData = new FormData();
      formData.append("cover_image", newImageCover);
      formData.append("profile_image", newImageProfile);
      formData.append("user_id", userRecord?.user_id);
      formData.append("Phone_No", phoneNo || "");
      formData.append("Door_No", doorNo.toUpperCase() || "");
      formData.append("Street", street.toUpperCase() || "");
      formData.append("Area", area.toUpperCase() || "");
      formData.append("City", city.toUpperCase() || "");
      formData.append("Pincode", pincode || "");
      formData.append("State", state.toUpperCase() || "");
      formData.append("Country", country.toUpperCase() || "");

      axios
        .post(`${urllink}profile/profileinsert`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          successMsg(response.data.message);
          profileData1();
          setChangesMade(false); // Reset changesMade after successful save
        })
        .catch((error) => {
          console.error("Error uploading images:", error);
          warnmsg("Failed to save changes.");
        });
    } else {
      warnmsg("No changes to save.");
    }
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const warnmsg = (errorMessage) => {
    toast.warn(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const fileInputCover = useRef(null);
  const fileInputProfile = useRef(null);

  return (
    <div className="container_pro">
      <div className="head_img_head">
        <div className="head_img">
          {newImageCover instanceof Blob ? (
            <img src={URL.createObjectURL(newImageCover)} alt="Cover" />
          ) : (
            <img src={newImageCover} alt="Cover" />
          )}

          <label className="custom-button-back">
            Choose File
            <input
              type="file"
              accept=".jpg, .jpeg, .png, .pdf"
              onChange={handleFileInputCover}
              className="file-input"
              ref={fileInputCover}
            />
          </label>
        </div>
        <div className="prof_round_head">
          <div className="profile_round">
            <label className="custom-button-profile">
              Choose File
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={handleFileInputProfile}
                className="file-input"
                ref={fileInputProfile}
              />
            </label>
            {newImageProfile instanceof Blob ? (
              <img src={URL.createObjectURL(newImageProfile)} alt="Profile" />
            ) : (
              <img src={newImageProfile} alt="Profile" />
            )}
          </div>

          <div className="user_name">
            <div className="name">
              <div className="rolenam">
                <h4>{userRecord?.username || ""}</h4>
              </div>
              <br />
              <div className="rolenam">
                <h6>{userRecord?.role_name || ""}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />
      <br />

      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label className="first">
            Title <span>:</span>
          </label>
          <input type="text" value={userRecord?.Title || ""} disabled />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            First Name <span>:</span>
          </label>
          <input type="text" value={userRecord?.First_Name || ""} disabled />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Last Name <span>:</span>
          </label>
          <input type="text" value={userRecord?.Last_Name || ""} disabled />
        </div>

        <div className="RegisForm_1">
          <label className="first">
            Email <span>:</span>
          </label>
          <input type="text" value={userRecord?.email || ""} disabled />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Phone no <span>:</span>
          </label>
          <input
            type="text"
            value={phoneNo}
            onChange={(e) => {
              setPhoneNo(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>

        <div className="RegisForm_1">
          <label className="first">
            Door No <span>:</span>
          </label>
          <input
            type="text"
            value={doorNo}
            onChange={(e) => {
              setDoorNo(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Street <span>:</span>
          </label>
          <input
            type="text"
            value={street}
            onChange={(e) => {
              setStreet(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            Area <span>:</span>
          </label>
          <input
            type="text"
            value={area}
            onChange={(e) => {
              setArea(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            City <span>:</span>
          </label>
          <input
            type="text"
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            PinCode <span>:</span>
          </label>
          <input
            type="number"
            value={pincode}
            onChange={(e) => {
              setPincode(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>
        <div className="RegisForm_1">
          <label className="first">
            State <span>:</span>
          </label>
          <input
            type="text"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>

        <div className="RegisForm_1">
          <label className="first">
            Country <span>:</span>
          </label>
          <input
            type="text"
            value={country}
            onChange={(e) => {
              setCountry(e.target.value);
              setChangesMade(true);
            }}
          />
        </div>
      </div>

      <br />
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSaveClick}>
          Save
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Profile;
