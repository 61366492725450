import React, { useEffect, useState } from 'react';
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function DutyRoster() {


    const userRecord = useSelector((state) => state.userRecord?.UserData);
    // const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
    const urllink = useSelector(state => state.userRecord?.UrlLink);



    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [dutyMasterData, setDutyMasterData] = useState({
        ShiftName: '',
        StartTime: '',
        EndTime: '',
        username: userRecord?.username, // Assuming these fields are available in userRecord
        location: userRecord?.location,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDutyMasterData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddShift = () => {
        if (dutyMasterData.ShiftName && dutyMasterData.StartTime && dutyMasterData.EndTime) {
            const newShift = {
                ShiftName: dutyMasterData.ShiftName,
                StartTime: dutyMasterData.StartTime,
                EndTime: dutyMasterData.EndTime,
                username: dutyMasterData?.username,
                location: dutyMasterData?.location,
            };
            console.log(newShift)
            setSelectedShifts([...selectedShifts, newShift]);
            setDutyMasterData({
                ShiftName: '',
                StartTime: '',
                EndTime: '',
                username: userRecord?.username,
                location: userRecord?.location,
            });
        }
    };

    const handleUpdateShift = () => {
        const updatedShifts = selectedShifts.map((shift, index) =>
            index === editIndex ? { ...dutyMasterData } : shift
        );
        setSelectedShifts(updatedShifts);
        setDutyMasterData({
            ShiftName: '',
            StartTime: '',
            EndTime: '',
            username: userRecord?.username,
            location: userRecord?.location,
        });
        setIsEditMode(false);
        setEditIndex(null);
    };


    const handleEditShifts = (index) => {
        const shiftToEdit = selectedShifts[index];
        setDutyMasterData({
            ShiftName: shiftToEdit.ShiftName,
            StartTime: shiftToEdit.StartTime,
            EndTime: shiftToEdit.EndTime,
        });
        setIsEditMode(true);
        setEditIndex(index);
    };

    const handleSubmit = () => {
        // Here you would typically make an API call to submit selectedShifts
        console.log("Submitting shifts:", selectedShifts);

        axios.post(`${urllink}HRmanagement/inserDutyRosterMasterData`, selectedShifts)
            .then((res) => {
                console.log(res)
                successMsg(res.data.Message)
            })
            .catch((er) => {
                console.error(er);

            })

        setDutyMasterData({
            ShiftName: '',
            StartTime: '',
            EndTime: '',
        });
    };


    useEffect(() => {
        axios.get(`${urllink}HRmanagement/getDutyRosterMasterData?location=${userRecord?.location}`)
            .then((res) => {
                console.log(res)
                setSelectedShifts(
                    res.data.map((row, index) => ({
                        id: row.EmployeeID,
                        ...row
                    }))
                )
            })
            .catch((er) => {
                console.error(er);

            })
    }, [userRecord,urllink])

    const successMsg = (message) => {
        toast.success(message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            containerId: "toast-container-over-header",
            style: { marginTop: "50px" },
        });
    };
    // const userwarn = (warningMessage) => {
    //     toast.warn(`${warningMessage}`, {
    //         position: "top-center",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "dark",
    //         style: { marginTop: "50px" },
    //     });
    // };
    // const errmsg = (error) => {
    //     toast.error(error, {
    //         position: "top-center",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "dark",
    //         style: { marginTop: "50px" },
    //     });
    // };



    return (
        <div className='appointment'>
            <div className='h_head'>
                <h4>Duty Roster Master</h4>
            </div>
            <br />


            <div className="RegisFormcon">
                <div className="RegisForm_1">
                    <label>
                        Shift Name<span>:</span>
                    </label>
                    {/* <select
                        name="ShiftName"
                        value={dutyMasterData.ShiftName}
                        onChange={handleChange}
                    >
                        <option value="">Select</option>
                        <option value="MorningShift">Morning Shift</option>
                        <option value="AfternoonShift">Afternoon Shift</option>
                        <option value="NightShift">Night Shift</option>
                        <option value="DayShift">Day Shift</option>
                    </select> */}
                    <input
                        type="text"
                        name="ShiftName"
                        value={dutyMasterData.ShiftName}
                        onChange={handleChange}
                    />
                </div>

                <div className="RegisForm_1">
                    <label>
                        Shift Start Time<span>:</span>
                    </label>
                    <input
                        type="time"
                        name="StartTime"
                        value={dutyMasterData.StartTime}
                        onChange={handleChange}
                    />
                </div>

                <div className="RegisForm_1">
                    <label>
                        Shift End Time<span>:</span>
                    </label>
                    <input
                        type="time"
                        name="EndTime"
                        value={dutyMasterData.EndTime}
                        onChange={handleChange}
                    />
                </div>
                <br />

                <div className="Register_btn_con">
                    <button
                        className="RegisterForm_1_btns"
                        onClick={isEditMode ? handleUpdateShift : handleAddShift}
                    >
                        {isEditMode ? "Update" : <AddIcon />}
                    </button>
                </div>
            </div>

            <br />

            <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                    <thead>
                        <tr>
                            <th id="slectbill_ins">Shift Name</th>
                            <th id="slectbill_ins">Shift Start Time</th>
                            <th id="slectbill_ins">Shift End Time</th>
                            <th id="slectbill_ins">Action</th>
                        </tr>
                    </thead>

                    <tbody>
                        {selectedShifts.map((shift, index) => (
                            <tr key={index}>
                                <td>{shift.ShiftName}</td>
                                <td>{shift.StartTime}</td>
                                <td>{shift.EndTime}</td>
                                <td>
                                    <button
                                        className="delnamebtn"
                                        onClick={() => handleEditShifts(index)}
                                    >
                                        <EditIcon />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <br />
            
            <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={handleSubmit}>
                    {isEditMode ? 'Update' : 'Submit'}
                </button>
            </div>
            <ToastContainer />
        </div>
    );
}

export default DutyRoster;
