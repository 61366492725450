import React, { useState, useEffect } from "react";
// import { Helmet } from "react-helmet";
// import "./Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
// import PastHistory from "./PastHistory";
import Samplecapture from "./Capturesample";
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdPhonelinkRing } from "react-icons/md";
import {useNavigate} from 'react-router-dom'
// import { TbDelta } from "react-icons/tb";
import { useSelector } from 'react-redux';
// import { useDispatch } from "react-redux";
import Barcode from "react-barcode";
import Reportcapture from "./ReportCapture";
// import axios from 'axios';


function NavigationLab() {
  // console.log(Data);
  // const userRecord = useSelector((state) => state.userRecord?.UserData);

  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log(capturedatas)

  
  const navigatelab = useSelector((state) => state.userRecord?.navigationlab);
  console.log(navigatelab)
  const navigate = useNavigate()
  // const urllink = useSelector((state) => state.userRecord?.UrlLink);
  // const [department, setDepartment] = useState([])
  const [activeTab, setActiveTab] = useState("CaptureApprove");
  const [isToggled, setIsToggled] = useState(false);
  // const [selectdepartment, setselectdepartment] = useState('')
  // const [formData, setFormData] = useState({
  //   SerialNo: "",
  //   PatientID: "",
  //   AppointmentID: "",
  //   visitNo: "",
  //   firstName: "",
  //   lastName: "",
  //   AppointmentDate: "",
  // });


  // const dispatchvalue = useDispatch()

  useEffect(()=>{
    console.log(navigatelab)
    if(navigatelab){
      setActiveTab(navigatelab)
    }
    else{
      setActiveTab('CaptureApprove')
    }
  }, [navigatelab])
  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
        navigate('/Home/AnalayseReportEntry')
    }
});

  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest(".new-kit")) {
        closeToggle();
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  });
  // const handledeptchange = (e) => {
  //   setselectdepartment(e.target.value);
  //   dispatchvalue({type :'selecteddepartment', value : selectdepartment})
  // };

  // useEffect(() => {
  //   axios.get(`${urllink}Billing/getfor_barcode_details_print?invoice=${capturedatas?.Billing_Invoice}`)
  //     .then((response) => {
  //       console.log(response);
  //       setDepartment(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [capturedatas, urllink]);

// console.log('selectdepartment', selectdepartment)
  return (
    <>
      <div className="new-patient-registration-form">
      <div className="h_head">
                    <h3>Sample Collection Form</h3> 
                   
           </div>
        <div className="patientdetails_lab">
          <div className='patientdata'>
            
            <label className='phelobotomist_head'><span className='logo_profile'><CgProfile /></span>Patient Name<span>:</span></label>
            <span>{capturedatas.Patient_Name}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><SlCalender /></span>Date  <span>:</span></label>
            <span>{capturedatas.UpdatedAt}</span>
          </div>

          <div className='patientdata'>
            <label className='phelobotomist_head'><span className='logo_profile'><MdPhonelinkRing /></span>Phone <span>:</span></label>
            <span>{capturedatas.Phone}</span>
          </div>

          <div className='patientdata'>
            <Barcode value={capturedatas.Barcode || ''} lineColor="black" height={50} width={1.5} />
          </div>
        </div>
        <div className="new-patient-registration-form1">
          <div className="new-navigation" style={{display: 'none'}}>
            <h2>
              <button onClick={() => handleTabChange("CaptureApprove")}>
               Capture Sample
              </button>
              |
              <button onClick={() => handleTabChange("Reportentry")}>
                Report Entry
              </button>
            </h2>
          </div>

          <div className="new-kit ">
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>

            <div>
              {isToggled && (
                <div className="new-navigation-toggle">
                  <h2>
                    <button onClick={() => handleTabChange("CaptureApprove")}>
                    Capture Sample
                    </button>
                    |
                    <button onClick={() => handleTabChange("Reportentry")}>
                    Report Entry
                    </button>
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {activeTab === "CaptureApprove" && (
        <Samplecapture  />
      )}
      {activeTab === "Reportentry" && (
        <Reportcapture />
      )}
      
    </>
  );
}


export default NavigationLab;
