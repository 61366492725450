import React, { useEffect,  useRef, useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './ReactGrid.css'; // Import CSS for styling

const GridTable = ({ columns = [], RowData = [], savepdfexel = false }) => {
    const [fixedColumnsWidth, setFixedColumnsWidth] = useState([]);
    const [columnWidths, setColumnWidths] = useState({});
    const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);
    const [Gridcolumns, setGridcolumns] = useState([]);
    const [visibleRows, setVisibleRows] = useState([]);
    const headerRef = useRef(null);
    const containerRef = useRef(null);


    useEffect(() => {
        try {
            if (headerRef.current) {
                const widths = Array.from(headerRef.current.children)
                    .filter((child) => child.classList.contains('sticky'))
                    .map((child) => child.offsetWidth);

                setFixedColumnsWidth(widths);
            }
        } catch (error) {
            console.error(error);
        }

    }, [Gridcolumns]);


    const calculateParentWidth = (column, depth = 0) => {
        try {
            if (!column.children || column.children?.length === 0) return 150;
            return column.children.reduce((total, child) => {
                return total + calculateParentWidth(child, depth + 1);
            }, 0);
        } catch (err) {
            console.error(err);
            return 150;
        }
    };



    const handleMouseDown = (e, key) => {
        try {
            if (e.button !== 2) return; // Only trigger on right-click

            e.preventDefault();
            const startX = e.clientX;
            const startWidth = columnWidths[key] || 150;
            const MIN_COLUMN_WIDTH = columnWidths[key]; // Minimum column width

            const onMouseMove = (e) => {
                const newWidth = startWidth + (e.clientX - startX);
                setColumnWidths((prevWidths) => ({
                    ...prevWidths,
                    [key]: Math.max(newWidth, MIN_COLUMN_WIDTH),
                }));
            };

            const onMouseUp = () => {
                document.removeEventListener('mousemove', onMouseMove);
                document.removeEventListener('mouseup', onMouseUp);
            };

            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('mouseup', onMouseUp);
        } catch (error) {
            console.log(error);

        }

    };


    const ROW_HEIGHT = 45; // Adjust this to match your row height
    const VISIBLE_ROW_COUNT = 10;
    useEffect(() => {
        const handleScroll = () => {
            if (!containerRef.current) return;

            const container = containerRef.current;
            const scrollTop = container.scrollTop;
            const startIndex = Math.max(0, Math.round(scrollTop / ROW_HEIGHT));
            const endIndex = Math.min(RowData?.length, startIndex + VISIBLE_ROW_COUNT);

            // Only update state if the visible rows actually change
            const newVisibleRows = RowData?.slice(0, endIndex);
            setVisibleRows(prevVisibleRows => {
                if (prevVisibleRows.length !== newVisibleRows.length) {
                    return newVisibleRows;
                }
                return prevVisibleRows;
            });
        };

        const debouncedHandleScroll = debounce(handleScroll, 50);

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', debouncedHandleScroll);
        }

        handleScroll(); // Initial load

        return () => {
            if (container) {
                container.removeEventListener('scroll', debouncedHandleScroll);
            }
        };
    }, [RowData]);

    useEffect(() => {
        try {
            // Sort columns based on the `frozen` property without mutating the original array
            const sortedColumns = [...columns].sort((a, b) => (b.frozen ? 1 : 0) - (a.frozen ? 1 : 0));

            const inputsRef = document.getElementById('root');

            const handleResize = debounce(() => {
                if (inputsRef) {
                    // Adjusting the grid ref without forceUpdate
                    const width = inputsRef.offsetWidth;


                    const updatedSortedColumns = sortedColumns.map((column, index) => {
                        let updatedColumn = { ...column };
                        if (updatedColumn?.frozen) {
                            if (index === 0 && width <= 700) {
                                updatedColumn.frozen = false;
                            } else if (index === 1 && width <= 950) {
                                updatedColumn.frozen = false;
                            } else if (index === 2 && width <= 1200) {
                                updatedColumn.frozen = false;
                            } else if (index >= 3 && width <= 1500) {
                                updatedColumn.frozen = false;
                            }

                        }
                        // Logic to set `frozen` based on the offsetWidth and index


                        return updatedColumn;
                    });

                    setGridcolumns(updatedSortedColumns);
                }
            }, 100);

            const resizeObserver = new ResizeObserver(handleResize);

            if (inputsRef) {
                resizeObserver.observe(inputsRef);
            }

            return () => {
                if (inputsRef) {
                    resizeObserver.unobserve(inputsRef);
                }
                resizeObserver.disconnect();
            };
        } catch (error) {
            console.log(error);

        }

    }, [columns]);



    // Simple debounce function

    function debounce(func, wait) {

        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };


    }


  

    const handleDragStart = (e, index) => {
        try {
            setDraggedColumnIndex(index);
            e.dataTransfer.effectAllowed = 'move';
        } catch (error) {
            console.log(error);
        }

    };

    const handleDragOver = (e) => {
        try {
            e.preventDefault();
            e.dataTransfer.dropEffect = 'move';
        } catch (error) {
            console.log(error);

        }

    };

    const handleDrop = (e, index) => {
        try {
            e.preventDefault();
            const newColumns = [...Gridcolumns];
            const [movedColumn] = newColumns.splice(draggedColumnIndex, 1);
            newColumns.splice(index, 0, movedColumn);
            setGridcolumns(newColumns);
            setDraggedColumnIndex(null);
        } catch (error) {
            console.log(error);

        }

    };

    const renderColumn = (column, index, diss = false) => {
        try {

            let columnWidth = column?.width ?
                column?.width :
                diss ?
                    column.children?.length * 150 - 5 :
                    calculateParentWidth(column)
            return (
                <div
                    key={column.key}
                    className={`column-header ${column.frozen ? 'sticky' : ''}`}
                    draggable={!column.frozen && !diss}
                    onDragStart={(e) => handleDragStart(e, index)}
                    onDragOver={handleDragOver}
                    onDrop={(e) => handleDrop(e, index)}
                    style={{
                        width: `${columnWidth}px`,
                        left: column.frozen ? `${fixedColumnsWidth.slice(0, index).reduce((a, b) => a + b, 0)}px` : 'auto',
                        padding: column.children && '0px'
                    }}
                    title={column.name}
                >
                    <div className='column-header-cell'>
                        {column?.renderHeaderCell ?
                            column.renderHeaderCell(column) :
                            column.name
                        }
                    </div>
                    {column.children && column.children?.length !== 0 &&
                        <div className="column-group">
                            {column.children.map((child, index) => (
                                <div key={child.key} className="column-header"
                                    style={{ width: `${child?.children && child?.children?.length !== 0 ? child?.children?.length * 150 : 150}px` }}
                                    title={child.name}
                                >
                                    <div className='column-header-cell'>
                                        {child?.children ? renderColumn(child, index, true) :
                                            child?.renderHeaderCell ?
                                                child.renderHeaderCell(child) :
                                                child.name
                                        }
                                    </div>
                                </div>
                            ))}
                        </div>
                    }


                    <div
                        className="column-resizer"
                        onMouseDown={(e) => handleMouseDown(e, column.key)}
                    />
                </div>
            );
        } catch (error) {
            console.log(error);

        }

    };

   
    const renderRows = () => {
        console.log('----Reactgrid');
        
        try {
            return (
                < >
                    {visibleRows && visibleRows?.length !== 0 &&
                        visibleRows.map((row, index) => (
                            <div key={row.id} className={`flex-row`}>
                                {Gridcolumns.map((column, cindex) => {
                                    // const columnWidth = columnWidths[column?.key] || (column?.children ? calculateParentWidth(column) : 150);
                                    let columnWidth = column?.width ?
                                        column?.width :
                                        calculateParentWidth(column)

                                    return (
                                        <div
                                            key={column?.key && column?.key}
                                            className={`column-cell ${column.frozen ? 'sticky' : ''}`}
                                            style={{
                                                width: `${columnWidth}px`,
                                                left: column?.frozen ? `${fixedColumnsWidth.slice(0, cindex).reduce((a, b) => a + b, 0)}px` : 'auto',
                                                padding: column?.children && '0px',
                                                backgroundColor: index % 2 !== 0 ? 'var(--selectbackgroundcolor)' : '#fff'
                                            }}
                                            title={column?.key ? row[column?.key] : ''}
                                        >
                                            {column?.children && column?.children?.length > 0 ? (
                                                renderchildrows(column, row, index)
                                            ) : column?.renderCell ? (
                                                column?.renderCell({ row, column })
                                            ) : (
                                                column?.key ? row[column?.key] || "-" : '-'
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ))
                    }




                </ >
            )
        } catch (error) {
            console.log(error);

        }

    };
    const renderchildrows = (column, row, index) => {
        try {
            return (
                <div className="column-group-cell">
                    {column?.children.map((child, indx) => (
                        <div
                            key={indx}
                            className="column-cell"
                            style={{
                                width: `${child?.children && child?.children?.length !== 0 ? child.children?.length * 150 : 150}px`,

                            }}
                            title={child?.key && row[child?.key]}
                        >
                            {child?.children && child?.children?.length !== 0 ?
                                renderchildrows(child, row) :
                                child?.renderCell ?
                                    child?.renderCell({ row, column }) :
                                    child?.key ? row[child?.key] || "-" : '-'
                            }
                        </div>
                    ))}
                </div>
            );
        } catch (error) {
            console.log(error);
        }
    };

    const flattenColumns = (columns) => {
        try {
            let result = [];
            columns.forEach(col => {
                if (col.children) {
                    result = result.concat(flattenColumns(col.children));
                } else {
                    result.push(col);
                }
            });
            return result;
        } catch (error) {
            console.log(error);

        }

    };

    const generatePDF = () => {
        try {
            const flattenedColumns = flattenColumns(Gridcolumns);
            const columnHeaders = flattenedColumns.map(col => col.name);
            const data = RowData.map(row => flattenedColumns.map(col => row[col.key]));

            let doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.getWidth();

            const getTextWidth = (text, fontSize = '10px', fontFamily = 'Arial') => {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                context.font = `${fontSize} ${fontFamily}`;
                const metrics = context.measureText(text);
                return metrics.width;
            };

            const calculateTableWidth = (columns) => {
                return columns.reduce((acc, column) => {
                    const padding = 10;
                    const columnWidth = getTextWidth(column.name) + padding;
                    return acc + columnWidth;
                }, 0);
            };

            const tableWidth = calculateTableWidth(flattenedColumns);
            const orientation = tableWidth > pageWidth ? 'landscape' : 'portrait';
            doc = new jsPDF(orientation, 'pt', 'a4');

            const margin = { top: 20, right: 10, bottom: 20, left: 10 };

            doc.autoTable({
                head: [columnHeaders],
                body: data,
                styles: {
                    fontSize: 10,
                    halign: 'center',
                },
                headStyles: {
                    fontSize: 8,
                    halign: 'center',
                },
                margin,
            });

            doc.save('table.pdf');
        } catch (error) {
            console.log(error);

        }

    };

    const generateExcel = () => {
        try {
            const flattenedColumns = flattenColumns(Gridcolumns);
            const data = RowData.map(row => flattenedColumns.map(col => row[col.key]));

            // Prepare worksheet with headers
            const ws = XLSX.utils.aoa_to_sheet([
                flattenedColumns.map(col => col.name), // headers
                ...data // data rows
            ]);

            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'table.xlsx');
        } catch (error) {
            console.log(error);

        }

    };


    return (
        <div className="parent-flex-container">
            {savepdfexel && visibleRows?.length !== 0 &&
                <div className="export-buttons">
                    <button onClick={generatePDF}>Export as PDF</button>
                    <button onClick={generateExcel}>Export as Excel</button>
                </div>
            }
            <div className="flex-container" ref={containerRef} >
                <div className="flex-row header" ref={headerRef}>
                    {Gridcolumns.map((column, index) => renderColumn(column, index))}
                </div>
                <div className="flex-row-cell" >
                    {renderRows()}

                </div>
            </div>
            <div className="export-buttons">
                <span>Row length </span>
                <span> : {RowData?.length}</span>

            </div>
            {
                visibleRows.length === 0 &&
                <div className='footer-flex-container'>
                    ---- No data to display  ----
                </div>
            }
        </div>
    );
};

export default React.memo(GridTable);
