import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import LoupeIcon from "@mui/icons-material/Loupe";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function LabPurchaseRecieveList() {

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(97 90 90 / 75%)",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };

  const dispatchvalue = useDispatch();

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const urllink=useSelector(state=>state.userRecord?.UrlLink);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const navigate = useNavigate();
  const [page1, setPage1] = useState(0);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [Productype, setProductype] = useState("");
  const [summa, setsumma] = useState([]);
  const [selectedShow, setSelectedShow] = useState(false);
  const [Rowdata, setRowdata] = useState([]);
  const [statustype, setstatustype] = useState("Waiting");
  const showdown1 = Rowdata.length;
  const pageSize1 = 10;

  
  const [selectedShow1, setSelectedShow1] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  console.log("vcvc", summa);

  useEffect(() => {
    if (Productype !== "" && statustype !=='') {
      if (Productype === "Medical") {
        axios
          .get(
            `${urllink}LabInventory/get_Medical_GRN_data?Status=${statustype}&Productype=${Productype}&Location=${'CentralStore'}`
          )
          .then((response) => {
            const data = response.data.data;
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error("Error fetching patients data:", error);
          });
      } else {
        axios
          .get(
            `${urllink}LabInventory/get_Non_Medical_GRN_data?Status=${statustype}&Productype=${Productype}&Location=${'CentralStore'}`
          )
          .then((response) => {
            const data = response.data.data;
            setsumma([
              ...data.map((row, ind) => ({
                id: ind + 1,
                ...row,
              })),
            ]);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [Productype,selectedShow1,statustype]);
  
  const [filteredRows, setFilteredRows] = useState([]);
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click

  const handleRequestEdit = (params) => {
    const index = params.row.items;
    console.log("index:", index);
    let B_data = index.map((p, index) => ({
      id: index + 1,
      ...p,
    }));
    setRowdata(B_data);
    setSelectedShow(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  // const handlegotoGRN = (params) => {
  //   const index = params.row.GRN_InvoiceNo;
  //   console.log("index:", index, "Productype", Productype);
  //   dispatchvalue({
  //     type: "GRNedit",
  //     value: { Invoice: index, Productype: Productype },
  //   });
  //   navigate("/Home/Purchase_Recieve_Master");
  // };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    console.log("ss", summa);

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCaseSupplierName = row.GRN_InvoiceNo.toLowerCase();

      return lowerCaseSupplierName.includes(lowerCaseQuery);
    });

    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };
  const totalPages1 = Math.ceil(Rowdata.length / 10);


  const handleViewDocument = (params) => {
    console.log("ConsentForm", params.row.Bill_Document);

    const Bill_Document = params.row.Bill_Document;

    if (
      !Bill_Document 
    ) {
      alert("No Data");
    } else {
      setModalContent(Bill_Document);
      setModalIsOpen(true);
    }
  };

  
  
  const handleApproveGRN = (params) => {
    console.log('kkk',params.row);

    const Selectdata = params.row

    const Supplier_data_insert = () => {
      const data4Supplierpay = {
        ProductType:  Selectdata.Product_Category,
        SupplierCode: Selectdata.Supplier_Code,
        SupplierName: Selectdata.Supplier_Name,
        GRNInvoice: Selectdata.GRN_InvoiceNo,
        GRNDate: Selectdata.GRN_Date,
        Supplier_Bill_Date: Selectdata.Supplier_Bill_Date,
        GRNDueDate: Selectdata.Supplier_Bill_Due_Date,
        GRNInvoiceAmount: Selectdata.Bill_Net_Amount,
        status: "Pending",
        location: "CentralStore",
        createdBy: userRecord?.username,
      };

      axios
        .post(
          `${urllink}LabInventory/insert_LabSupplier_pay_detials`,
          data4Supplierpay
        )
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error posting GRN data:", error);
        });
    };



    const editrow = {
      Productype: Productype,
      index: params.row.GRN_InvoiceNo,
      Status: "GRNApproved",
      Approved_By: userRecord?.username,
      Location: params.row.Location,
      items:params.row.items
    };
    
    const resultReject = window.confirm(
      "Are you sure ,you want to Approve it ?"
    );

    if (resultReject) {
      axios
        .post(
          `${urllink}LabInventory/Approve_All_GRN_ivoice`,
          editrow
        )
        .then((response) => {
          console.log(response);         
          Supplier_data_insert()
          setSelectedShow1(!selectedShow1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };


  
  const dynamicColumns = [
    { field: "GRN_InvoiceNo", headerName: "GRN InvoiceNo", width: 130 },
    { field: "GRN_Date", headerName: "GRN date", width: 100 },
    { field: "Supplier_Name", headerName: "Supplier Name", width: 130 },
    { field: "Supplier_Code", headerName: "Supplier_Code", width: 130 },
    { field: "Supplier_Bill_No", headerName: "Supplier BillNo", width: 150 },
    {
      field: "Supplier_Bill_Date",
      headerName: "Supplier BillDate",
      width: 140,
    },
    {
      field: "Supplier_Bill_Due_Date",
      headerName: " BillDue Date",
      width: 100,
    },
    { field: "Grand_Total", headerName: " Amount", width: 140 },
    { field: "Total_Discount_Type", headerName: " Discount Type", width: 120 },
    { field: "Total_Discount", headerName: " Discount", width: 100 },
    { field: "GRN_Finel_NetAmount", headerName: " Total", width: 140 },
    {
      field: "Document",
      headerName: " Bill Document",
      width: 170,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleViewDocument(params)}
          >
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
    {
      field: "View",
      headerName: "View",
      width: 70,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestEdit(params)}
          >
            <VisibilityIcon />
          </Button>
          {/* {params.row.Status=== 'Recieved'&&<Button className='cell_btn' >
                        <EditIcon onClick={() => handlegotoGRN(params)} />
                    </Button>} */}
        </>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <>
          {params.row.Status === "Received" && (
            <Button className="cell_btn">
              <CheckCircleOutlineIcon
                onClick={() => handleApproveGRN(params)}
              />
            </Button>
          )}
        </>
      ),
    },
  ];

  const dynamicColumns1 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Generic_Name", headerName: "Generic Name", width: 150 },
    { field: "Strength", headerName: "Strength", width: 120 },
    { field: "UOM", headerName: "UOM", width: 120 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Order_Quantity", headerName: "Order Quantity", width: 120 },
    {
      field: "RecievedOrder_Quantity",
      headerName: "Recieved Quantity",
      width: 120,
    },
    {
      field: "RecievedTotalQuantity",
      headerName: "Total Quantity",
      width: 120,
    }
  ];

  const dynamicColumns2 = [
    { field: "Item_Code", headerName: "Item Code", width: 150 },
    { field: "Item_Name", headerName: "Item Name", width: 150 },
    { field: "Pack_type", headerName: "Pack Type", width: 120 },
    { field: "Pack_Quantity", headerName: "Pack Quantity", width: 120 },
    { field: "Order_Quantity", headerName: "Order Quantity", width: 120 },
    {
      field: "RecievedOrder_Quantity",
      headerName: "Recieved Quantity",
      width: 120,
    },
    { field: "PendingQuantity", headerName: "Pending Quantity", width: 120 },
    { field: "Reason", headerName: "Reason", width: 120 },
    {
      field: "RecievedTotalQuantity",
      headerName: "Total Quantity",
      width: 120,
    },
    { field: "MRP_Per_Quantity", headerName: "MRP", width: 120 },
    { field: "Purchase_Rate", headerName: "Purchase Rate", width: 120 },
    { field: "GST_Type", headerName: "TAX Type", width: 120 },
    { field: "Tax_Percentage", headerName: "TAX ", width: 120 },
    { field: "Purchase_Rate_withtax", headerName: "Amount", width: 120 },
    { field: "DiscountType", headerName: "Discount Type", width: 120 },
    { field: "Discount", headerName: "Discount", width: 120 },
    { field: "TotalAmount", headerName: "Total", width: 120 },
  ];

  return (
    <>
      <div className="appointment">
        <div className="h_head h_head_h_2">
          <h4>GRN List</h4>
          <div className="doctor_select_1_90">
            <label htmlFor="Calender"> Product Type <span>:</span></label>
            <select
              className="Product_Master_div_select_opt"
              value={Productype}
              onChange={(e) => {
                setProductype(e.target.value);
              }} 
            > <option value="">Select</option>
              <option value="Medical">Medical</option>
              <option value="NonMedical">Non Medical</option>
              <option value="Stationary">Stationary</option>
            </select>
          </div>
        </div>
        <br />

        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Supplier Code <span>:</span></label>
            <input
              type="number"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Supplier Code"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Status <span>:</span></label>
            <select
              value={statustype}
              onChange={(e) => setstatustype(e.target.value)}
            >
              <option value="">Select</option>
              <option value="Received">Received</option>
              <option value="GRNApproved">GRN Approved</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(
                  page * pageSize,
                  (page + 1) * pageSize
                )} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                          <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
          {selectedShow && (
            <div
              className={
                isSidebarOpen
                  ? "sideopen_showcamera_profile"
                  : "showcamera_profile"
              }
              onClick={() => {
                setSelectedShow(false);
              }}
            >
              <div
                className="newwProfiles"
                style={{ padding: "10px", boxSizing: "border-box" }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="appointment">
                  <div className="calendar_head">
                    <h3>{Productype}List</h3>
                  </div>
                  <div className="IP_grid">
                    <ThemeProvider theme={theme}>
                      <div className="IP_grid_1">
                        <DataGrid
                          rows={Rowdata.slice(
                            page1 * pageSize1,
                            (page1 + 1) * pageSize1
                          )} // Display only the current page's data
                          columns={
                            Productype === "Medical"
                              ? dynamicColumns1
                              : dynamicColumns2
                          } // Use dynamic columns here
                          pageSize={10}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          pageSizeOptions={[10]}
                          onPageChange={handlePageChange1}
                          hideFooterPagination
                          hideFooterSelectedRowCount
                          className="data_grid"
                        />
                        {showdown1 > 0 && filteredRows.length > 10 && (
                          <div className="IP_grid_foot">
                            <button
                              onClick={() =>
                                setPage((prevPage) => Math.max(prevPage - 1, 0))
                              }
                              disabled={page === 0}
                            >
                              Previous
                            </button>
                            Page {page1 + 1} of {totalPages1}
                            <button
                              onClick={() =>
                                setPage((prevPage) =>
                                  Math.min(prevPage + 1, totalPages1 - 1)
                                )
                              }
                              disabled={page1 === totalPages1 - 1}
                            >
                              Next
                            </button>
                          </div>
                        )}
                      </div>
                    </ThemeProvider>
                    {showdown1 !== 0 && filteredRows.length !== 0 ? (
                      ""
                    ) : (
                      <div className="IP_norecords">
                        <span>No Records Found</span>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "grid",
                      placeItems: "center",
                    }}
                  >
                    <button
                      className="closeicon-cs"
                      onClick={() => {
                        setSelectedShow(false);
                      }}
                    >
                      close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={{ content: { ...yourStyles } }}
        >
          <div className="pdf_img_show">
            {modalContent
              .toLowerCase()
              .startsWith("data:application/pdf;base64,") ? (
              <iframe
                title="PDF Viewer"
                src={modalContent}
                style={{
                  width: "100%",
                  height: "435px",
                  border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                }}
              />
            ) : (
              <img
                src={modalContent}
                alt="Concern Form"
                style={{
                  width: "80%",
                  height: "75%",
                  marginTop: "20px",
                }}
              />
            )}
            <div className="jhuhhjh">
              <Button
                style={{ color: "white" }}
                className="clse_pdf_img"
                onClick={closeModal}
              >
                <HighlightOffIcon
                  style={{
                    fontSize: "40px",
                    backgroundColor: "#54d854bf",
                    borderRadius: "40px",
                  }}
                />
              </Button>
            </div>
          </div>
        </Modal>
    </>
  );
}
