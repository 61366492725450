import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";


function AntibioticMaster() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [antibioticData, setAntibioticData] = useState([]);
  const [antibioticGroupCode, setAntibioticGroupCode] = useState("");
  const [antibioticDes, setAntibioticDes] = useState("");
  const [antibioticCode, setAntibioticCode] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMethodId, setSelectedMethodId] = useState(null);
  const [SelectedFile, setSelectedFile] = useState(null);

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
    if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  const handleSubmitAntibiotic = async () => {
    if (
      !antibioticGroupCode.trim() ||
      !antibioticCode.trim() ||
      !antibioticDes.trim()
    ) {
      alert(
        " AntibioticGroupCode,AntibioticCode and AntibioticDes are required."
      );
      return; // Exit the function early if validation fails
    }
    try {
      const response = await axios.post(
        `${urllink}usercontrol/insertantibioticdata`,
        {
          antibioticGroupCode,
          antibioticCode,
          antibioticDes,
        }
      );

      console.log(response.data);
      successMsg('Successfully Inserted')
      fetchAntibioticcodeData();
      // setAntibioticGroupCode('');
      // setAntibioticCode('');
      setAntibioticDes("");
      fetchAntibioticData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchAntibioticData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getantibioticdata`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setAntibioticData(data);
      })
      .catch((error) => {
        console.error("Error fetching antibioticgroup data:", error);
        setAntibioticData([]); // Reset data in case of an error
      });
  }, [urllink]);

  const fetchAntibioticcodeData = useCallback(() => {
    axios
      .get(`${urllink}usercontrol/getantibioticdatacode`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);
        setAntibioticGroupCode(data.anti_biotic_group_code);
        setAntibioticCode(data.anti_biotic_code);
      })
      .catch((error) => {
        console.error("Error fetching antibioticgroup data:", error);
        setAntibioticData([]); // Reset data in case of an error
      });
  }, [urllink]);

  const handleEdit = (row) => {
    console.log(row);
    setAntibioticGroupCode(row.anti_biotic_group_code);
    setAntibioticCode(row.anti_biotic_code);
    setAntibioticDes(row.anti_biotic_des);
    setIsEditMode(true);
    setSelectedMethodId(row.anti_biotic_group_id);
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(
        `${urllink}usercontrol/updateantibioticmaster`,
        {
          method_id: selectedMethodId,
          antibioticGroupCode: antibioticGroupCode,
          antibioticCode: antibioticCode,
          method_des: antibioticDes,
        }
      );

      console.log(response.data);
      fetchAntibioticcodeData();
      // setAntibioticGroupCode('');
      // setAntibioticCode('');
      setAntibioticDes("");
      setIsEditMode(false);
      setSelectedMethodId(null);
      fetchAntibioticData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Documents") {
        axios
          .post(
            `${urllink}usercontrol/insert_csv_file_for_antibiotic`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg("File Processed Successfully");

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            // errmsg(error);
          });
      }
    }
  };

  useEffect(() => {
    fetchAntibioticData();
    fetchAntibioticcodeData();
  }, [fetchAntibioticData, fetchAntibioticcodeData]);



  const anticolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "anti_biotic_group_code",
      name: "Antibiotic Group Code",
      width: 200
    },{
      key: "anti_biotic_code",
      name: "Antibiotic Code",
    },
    {
      key: "anti_biotic_des",
      name: "Antibiotic Description",
      width: 280,
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleEdit(params.row)}
          startIcon={<EditIcon />}
        >
        </Button>
      ),
    },
  ];


  return (
    <>
      <div className="appointment">
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="input">
              Antibiotic Group Code<span>:</span>
            </label>
            <input
              type="text"
              id="antibioticGroupCode"
              name="antibioticGroupCode"
              value={antibioticGroupCode}
              disabled
              onChange={(e) => setAntibioticGroupCode(e.target.value)}
              // placeholder="Enter Antibiotic Group Code"
              // style={{ width: "150px" }}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="input">
              Antibiotic Code<span>:</span>
            </label>
            <input
              type="text"
              id="antibioticCode"
              name="antibioticCode"
              value={antibioticCode}
              disabled
              onChange={(e) => setAntibioticCode(e.target.value)}
              // placeholder="Enter Antibiotic Code"
              // style={{ width: "150px" }}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="input">
              Antibiotic Description<span>:</span>
            </label>
            <input
              type="text"
              id="antibioticDes"
              name="antibioticDes"
              value={antibioticDes}
              onChange={(e) => setAntibioticDes(e.target.value)}
              // placeholder="Enter Antibiotic Description"
              // style={{ width: "150px" }}
            />
          </div>
          <div className="RegisForm_1">
            <label>
              {" "}
              Upload CSV File <span>:</span>{" "}
            </label>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              id="Servicechoose"
              required
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <label
              htmlFor="Servicechoose"
              className="RegisterForm_1_btns choose_file_update"
            >
              Choose File
            </label>
            <button
              className="RegisterForm_1_btns choose_file_update"
              onClick={() => handleCsvupload("Documents")}
            >
              Upload
            </button>
          </div>
        </div>
        <div className="Register_btn_con">
          <button
            className="RegisterForm_1_btns"
            onClick={isEditMode ? handleUpdateMethod : handleSubmitAntibiotic}
          >
            {isEditMode ? "Update" : <AddIcon />}
          </button>
        </div>
        <div className="Main_container_app">
          <ReactGrid columns={anticolumns} RowData={antibioticData} />
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default AntibioticMaster;
