import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const TransactionTypeMaster = () => {





  
  const reduxvalueUsercord = useSelector((state) => state);
  const userRecord = reduxvalueUsercord.userRecord?.UserData;
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const User_name = userRecord.username;

  const [Add_Expenses_Name, setAdd_Expenses_Name] = useState("");

  const [Expenses_Name_table, setExpenses_Name_table] = useState([]);

  const getExpensesdata = () => {
    axios
      .get(`${urllink}FinanceMagement/Get_TransactionType_data`)
      .then((response) => {
        setExpenses_Name_table(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getExpensesdata();
  }, []);

  const Add_Expenses = () => {
    const Samedata = Expenses_Name_table.find(
      (repeat) =>
        repeat.Transactiontype.toLowerCase() === Add_Expenses_Name.toLowerCase()
    );

    if (Add_Expenses_Name === "") {
      alert("Enter Transaction Type");
    } else if (Samedata) {
      alert("Alredy Enter Data");
    } else {
      axios
        .post(`${urllink}FinanceMagement/Post_TransactionType_data`, {
          Add_Expenses_Name,
          User_name,
        })
        .then((res) => {
          getExpensesdata();
          setAdd_Expenses_Name("");
          alert("Data Added Successfully");
        })
        .catch((err) => console.error(err));
    }
  };

  const handleEditExpensesClick = (data) => {
    if (data.Status === "Inactive") {
      const status = "Active";
      const S_NO = data.S_NO;
      UpdateExpenses_status_fun(S_NO, status);
    } else {
      const status = "Inactive";
      const S_NO = data.S_NO;
      UpdateExpenses_status_fun(S_NO, status);
    }
  };

  const UpdateExpenses_status_fun = (S_NO, status) => {
    axios
      .post(`${urllink}FinanceMagement/Update_TransactionType_Status`, {
        status,
        User_name,
        S_NO,
      })
      .then((res) => {
        getExpensesdata();
        setAdd_Expenses_Name("");
        alert("Status Update Successfully");
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Transaction Type Master</h4>
      </div>
      <br />


      <div className="con_1 ">
        <h2>Add Transaction Type</h2>
      </div>

      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">
            Transaction Type <span>:</span>
          </label>
          <input
            type="text"
            id="FirstName"
            value={Add_Expenses_Name}
            onChange={(e) => {
              setAdd_Expenses_Name(e.target.value.toUpperCase());
            }}
            placeholder="Enter Transaction Type"
            style={{ width: "150px" }}
          />
        </div>
        <button className="RegisterForm_1_btns" onClick={Add_Expenses}>
          <AddIcon />
        </button>
      </div>

      <div style={{ width: "60%", display: "grid",alignSelf:'center',textAlign:'center'}}>
        <h4>Table</h4>

        <div className="Selected-table-container ">
          <table className="selected-medicine-table2 ">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Transaction Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Expenses_Name_table.map((client, index) => (
                <tr key={index}>
                  <td>{client.S_NO}</td>
                  <td>{client.Transactiontype}</td>
                  <td>
                    <button
                      onClick={() => handleEditExpensesClick(client)}
                      className="Addnamebtn_pt2"
                    >
                      {client.Status}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TransactionTypeMaster;
