import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../Billing/OverallBilling/GeneralBilling.css";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import { ToastContainer, toast } from "react-toastify";

const Billcancelation = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  // const [Dname, setDname] = useState("");
  const [FormData, setFormData] = useState({
    PatientID: "",
    PatientName: "",
    PhoneNumber: "",
    Age: "",
    Gender: "",
    City: "",
    State: "",
    Street: "",
    Pincode: "",
    Referdoc: "",
    VisitID: "",
  });

  const [Billing_date, setBilling_date] = useState("");
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [searchQuery, setSearchQuery] = useState({
    BillType: "",
    SearchID: "",
    SearchInvoiceNo: "",
    SearchBillingDate: "",
  });
  const [amount, setamount] = useState();
  // const [data, setData] = useState([])

  const [CancelList, setCancelList] = useState([]);

  // const [tableData, setTableData] = useState([]);
  // const [TableData1, setTableData1] = useState([]);
  const [cancelamount, setcancelamount] = useState();
  const [discount, setdiscount] = useState();
  // const [NameForCancel, SetNameForCancel] = useState([]);
  // const [SelectedNameCancel, setSelectedNameCancel] = useState("");
  const [Reason, setReason] = useState({
    reason: "",
    ApprovedBy: userRecord?.username,
    refundAmount: 0,
    RefundBilledBy: "",
    Billpay_method: "",
  });
  console.log();
  const [selectedBillType, setSelectedBillType] = useState("");
  // const [selectDoctorList, setselectDoctorList] = useState([]);
  const [labcancellist, setlabcancellist] = useState([]);
  useEffect(() => {
    setReason((prev) => ({
      ...prev,
      RefundBilledBy: userRecord.username,
    }));
  }, [userRecord.username]);

  const handleCheckboxChange = (test) => {
    console.log(test);
    console.log(CancelList);
    const isSelected = labcancellist.some((row) => row.Code === test);

    if (isSelected) {
      const updatedList = labcancellist?.filter((row) => row.Code !== test);
      setlabcancellist(updatedList);
      const updatecost = cancelamount?.filter((p) => p.Code !== test);
      setcancelamount(updatecost);
    } else {
      const selectRow = CancelList.find((row) => row.Code === test);
      console.log(selectRow);
      if (selectRow) {
        // Fetch billing amount data for the selected test
        axios
          .get(
            `${urllink}Billing/get_All_billing_amount_data?testcode=${selectRow.Code}&testmethod=${selectRow.Test_Method}&invoiceno=${selectRow.Billing_Invoice}`
          )
          .then((response) => {
            console.log(response);

            if (response.data.Error) {
              userwarn(response.data.Error);
              setCancelList([])
              return;
            } else {
              const data = response.data.Cost_Per_Reportable_Test;
              // Ensure cancelamount is initialized as an array
              setcancelamount((prev) => (prev || []).concat(data));
              setlabcancellist((prev) => [...prev, selectRow]);
            }
          })
          .catch((error) => {
            console.log(error);
            // Handle errors
          });
        // Append the selected row to the labcancellist
      }
    }
  };

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/get_payment_discout_value?invoice=${searchQuery.SearchInvoiceNo}&location=${userRecord?.location}`
      )
      .then((response) => {
        // console.log(response);
        const data = response.data;
        setdiscount(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchQuery.SearchInvoiceNo, userRecord?.location, urllink]);

  useEffect(() => {
    console.log("cancelamount", cancelamount);
    if (cancelamount && cancelamount.length > 0 && discount) {
      const totalamount = cancelamount.reduce(
        (acc, val) => acc + Number(val.Amount),
        0
      );
      console.log("totalamount..............", totalamount);
      console.log(discount);

      if (discount[0]?.Discounttype === "Percentage" && totalamount) {
        const perdis = parseFloat(discount[0].Discount);
        const disvalue = (perdis / 100) * totalamount;

        const lastamount = totalamount - disvalue;
        // console.log('disvalue', disvalue)
        setamount(lastamount);
      } else if (discount[0]?.Discounttype === "Cash" && totalamount) {
        const dicountvalue = parseFloat(discount[0]?.Discount);

        const lastamount = totalamount - dicountvalue;
        setamount(lastamount);
      } else {
        setamount(totalamount);
      }
    }
  }, [cancelamount, discount]);

  // console.log('labcancellist..................', discount)

  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
      return "Zero Rupees Only";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return (
          units[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convert(num % 100) : "")
        );
      if (num < 100000)
        return (
          convert(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
        );
      if (num < 10000000)
        return (
          convert(Math.floor(num / 100000)) +
          " Lakh" +
          (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
        );
    };

    return convert(num) + " Rupees  Only";
  };

  // --
  useEffect(() => {
    const currentDate = new Date();
    setBilling_date(format(currentDate, "dd-MM-yyyy"));
    // clinic detials
    axios
      .get(`${urllink}usercontrol/getClinic?location=${userRecord.location}`)
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        // console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.area + "," + data.street,
            ClinicGST: data.gstno,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        `${urllink}Billing/get_patient_detatils_for_bill_cancel?invoice=${searchQuery.SearchInvoiceNo}`
      )
      .then((response) => {
        // console.log(response)
        const data = response.data[0];
        if (data) {
          setFormData((prev) => ({
            ...prev,
            PatientID: data.patientid,
            PatientName: data.PatientName,
            Age: data.Age,
            PhoneNumber: data.Phone,
            Referdoc: data.Referingdoc,
            VisitID: data.Visit_id,
            Gender: data.Gender,
            City: data.City,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [searchQuery.SearchInvoiceNo, urllink, userRecord]);
  useEffect(() => {
    if (selectedBillType === "Procedure") {
      axios
        .get(`${urllink}Bill_Cancellation/get_Bill_Cancel_Procedure_invoice`)
        .then((response) => {
          // console.log(response.data);
          const data = response.data;
          setClinicDetials((prev) => ({
            ...prev,
            InvoiceNo: data.nextInvoiceNumber,
          }));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [selectedBillType, urllink]);

  // ---------------------
  const handlechange = (e) => {
    const { name, value } = e.target;

    setSearchQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    setamount("");
    // SetNameForCancel([]);
    // setTableData([]);
    // setTableData1([]);
    setSelectedBillType("");
    setReason((prev) => ({
      ...prev,
      reason: "",
      ApprovedBy: userRecord?.username,
    }));
    const newSearchQuery = {
      billing_invoice: searchQuery.SearchInvoiceNo
        ? searchQuery.SearchInvoiceNo
        : "Null",
      bill_date: searchQuery.SearchBillingDate
        ? searchQuery.SearchBillingDate
        : "Null",
    };
    // console.table(newSearchQuery);
    if (
      newSearchQuery.billing_invoice !== "Null" ||
      newSearchQuery.bill_date !== "Null"
    ) {
      if (
        newSearchQuery.bill_date !== "Null" &&
        newSearchQuery.billing_invoice !== "Null"
      ) {
        axios
          .get(
            `${urllink}Billing/get_billing_details_by_invoice?invoiceno=${newSearchQuery.billing_invoice}&Date=${newSearchQuery.bill_date}&location=${userRecord?.location}`
          )
          .then((response) => {
            console.log(response.data);
            // const data = response.data.message

            if (response.data.message) {
              userwarn(response.data.message);
            } else {
              const data = response.data.data.map((row, index) => ({
                id: index + 1,
                ...row,
              }));
              // const visits = {
              //   VisitID: data[0].visit_id,
              //   PatientID: data[0].patient_id,
              // };
              setCancelList(data);
              // console.log(data);

              if (data[0].message === "Data Already Exist") {
                // SetNameForCancel([]);
                // setTableData([]);
                // setTableData1([]);
                setCancelList([]);
                setSelectedBillType("");
                setSearchQuery({
                  BillType: "",
                  SearchID: "",
                  SearchInvoiceNo: "",
                  SearchBillingDate: "",
                });
                alert("Bill cancelled Already");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      alert("please Enter the fields");
    }
  };

  // useEffect(() => {
  //   // Assuming SetNameForCancel is an array of objects with a "ServiceName" property
  //   SetNameForCancel((prevState) =>
  //     prevState?.filter((p) =>
  //       TableData1.every((row) => row.ServiceName !== p.ServiceName)
  //     )
  //   );
  // }, [TableData1]);

  const handlePrint = useReactToPrint({
    content: () => document.getElementById("reactprintcontentcancel"),
    onBeforePrint: () => {
      // console.log('Before');
    },
    onAfterPrint: async () => {
      // setPdfBlob(null);
      // console.log('After');
      const printdata = document.getElementById("reactprintcontentcancel");
      // console.log('printdata', printdata);

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const padding = 20;
          const pdfWidth = contentWidth + 2 * padding;
          const pdfHeight = contentWidth * 1.5;
          const pdf = new jsPDF({
            unit: "px",
            format: [pdfWidth, pdfHeight],
          });
          pdf.html(printdata, {
            x: padding, // Set x-coordinate for content
            y: padding, // Set y-coordinate for content
            callback: () => {
              // const generatedPdfBlob = pdf.output('blob');
              // setPdfBlob(generatedPdfBlob);
              // console.log('generatedPdfBlob', generatedPdfBlob)
              setIsPrintButtonVisible(true);
            },
          });
        } else {
          throw new Error("Unable to get the target element");
        }
        // setpreview(false)
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });

  const handleSubmit = () => {
    const postData = {
      ...Reason,
      ...searchQuery,
      location: userRecord?.location,
    };

    // console.log('postData', postData);

    axios
      .post(`${urllink}Billing/insert_in_cancelation_table_update`, postData)
      .then((response) => {
        // console.log(response);
        // Call handlePrint only after successful post request
        setIsPrintButtonVisible(false);
        setTimeout(() => {
          handlePrint();
        }, 500);

        setTimeout(() => {
          removestate();
        }, 1000);
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        // Handle error if the post request fails
      });
  };

  const removestate = () => {
    setSearchQuery({
      BillType: "",
      SearchID: "",
      SearchInvoiceNo: "",
      SearchBillingDate: "",
    });
    setReason({
      reason: "",
      ApprovedBy: userRecord?.username,
      refundAmount: 0,
      RefundBilledBy: "",
      Billpay_method: "",
    });
    setlabcancellist([]);
    setCancelList([]);
  };

  const dynamicColumns1 = [
    {
      key: "id",
      name: "S.No",
      width: 70,
      frozen: true,
    },
    {
      key: "Patient_Id",
      name: "Patient Id",
      frozen: true,
      width: 100,
    },
    {
      key: "Billing_Invoice",
      name: "Invoice No",
      frozen: true,
    },
    {
      key: "Test_Name",
      name: "Service Name",
    },
    {
      key: "Billing_date",
      name: "Biling Date",
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <input
          type="checkbox"
          onChange={() => handleCheckboxChange(params.row.Code)}
        />
      ),
    },
  ];

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const forPrintData = () => {
    return (
      <div
        className="billing-invoice santhu_billing"
        id="reactprintcontentcancel"
      >
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <br />
        <div className="new_billing_address">
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Patient Name <span>:</span>
              </label>
              <span>{FormData.PatientName}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Patient ID <span>:</span>
              </label>
              <span>{FormData.PatientID}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Age <span>:</span>
              </label>
              <span>{FormData.Age}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Gender <span>:</span>
              </label>
              <span>{FormData.Gender}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Address <span>:</span>
              </label>
              <span>{FormData.City}</span>
            </div>
          </div>
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Invoice No <span>:</span>
              </label>
              <span>{searchQuery.SearchInvoiceNo}</span>
            </div>
            <div className="new_billing_div">
              <label>
                GSTIN No <span>:</span>
              </label>
              <span>{ClinicDetials.ClinicGST}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Physician Name <span>:</span>
              </label>
              <span>{FormData.Referdoc}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Date <span>:</span>
              </label>
              <span>{Billing_date}</span>
            </div>
            <div className="new_billing_div">
              <label>
                {" "}
                Patient Mobile No <span>:</span>
              </label>
              <span>{FormData.PhoneNumber}</span>
            </div>
          </div>
        </div>
        <br />
        <div className="Add_items_Purchase_Master">
          <span>Bill Cancellation</span>
        </div>
        <br />
        <div className="Main_container_app">
          <ReactGrid columns={dynamicColumns1} RowData={CancelList} />
        </div>

        <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div className="bill_body">
                <label>
                  {" "}
                  Items Count <span>:</span>
                </label>
                <span>{CancelList.length}</span>
              </div>

              <div className="bill_body">
                <label>
                  Bill Amount <span>:</span>
                </label>
                <span>{amount}</span>
              </div>

              <div className="bill_body">
                <label>
                  {" "}
                  Billed By <span>:</span>
                </label>
                <span>{userRecord?.username}</span>
              </div>
            </div>

            <div className="total_con_bill">
              <div className="bill_body">
                <label>
                  {" "}
                  Reason <span>:</span>
                </label>
                <span>{Reason.reason}</span>
              </div>
              <div className="bill_body">
                <label>
                  {" "}
                  Refund Amount <span>:</span>
                </label>
                <span>{Reason.refundAmount}</span>
              </div>
              {/* <div className="bill_body">
                  <label> Unit <span>:</span></label>
                  <span>{orderdetails.totalUnits}</span>
              </div> */}
              <div className="bill_body">
                <label>
                  {" "}
                  Paid Method <span>:</span>
                </label>
                <span>{Reason.Billpay_method}</span>
              </div>

              <div className="bill_body">
                <label>
                  Refund Billed By <span>:</span>
                </label>
                <span>{userRecord?.username}</span>
              </div>
            </div>
          </div>
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "10px",
                }}
              >
                <label>
                  {" "}
                  Amount In Words<span>:</span>
                </label>
                <span style={{ color: "grey" }}>
                  {numberToWords(+Reason.refundAmount)}{" "}
                </span>
              </div>
            </div>
          </div>

          <div className="signature-section909">
            <p className="disclaimer23">
              This page is created automatically without a signature.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isPrintButtonVisible ? (
        <>
          <div className="appointment">
            <div className="h_head">
              <h4> Billing Cancellation / Refund</h4>
            </div>
            <br />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px 10px",
              }}
            >
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Billing Invoice No <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="SearchInvoiceNo"
                    value={searchQuery.SearchInvoiceNo}
                    onChange={handlechange}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Billing Date <span>:</span>{" "}
                  </label>
                  <input
                    type="date"
                    name="SearchBillingDate"
                    value={searchQuery.SearchBillingDate}
                    onChange={handlechange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSearch}>
                Search
              </button>
            </div>

            {CancelList[0]?.Test_Method === "Group" && (
              <div className="Add_items_Purchase_Master">
                <span>Group Name : {CancelList[0]?.Group_Name}</span>
              </div>
            )}
            {console.log(CancelList)}
            <div className="Main_container_app">
              <ReactGrid columns={dynamicColumns1} RowData={CancelList} />
            </div>

            {labcancellist.length > 0 && (
              <>
                <div
                  className="RegisFormcon "
                  style={{ padding: "5px 0px", margin: "10px 0px" }}
                >
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Bill Amount <span>:</span>
                    </label>
                    <input
                      type="number"
                      value={amount}
                      readOnly
                      // placeholder="Enter the Approved by"
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Refund Amount <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={Reason.refundAmount}
                      onChange={(e) =>
                        setReason((prev) => ({
                          ...prev,
                          refundAmount: e.target.value,
                        }))
                      }
                      // placeholder="Enter the Approved by"
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Payment Method <span>:</span>
                    </label>
                    <select
                      id="dispatchMode"
                      name="Billpay_method"
                      value={Reason.Billpay_method}
                      onChange={(e) =>
                        setReason((prev) => ({
                          ...prev,
                          Billpay_method: e.target.value,
                        }))
                      }
                      className="Supplier_Master_form_Con_div_Row_input"
                    >
                      <option value="">Select</option>
                      <option value="Cash">Cash</option>
                      <option value="Card">Card</option>
                      <option value="OnlinePayment">Online Payment</option>
                    </select>
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Reason <span>:</span>
                    </label>
                    <textarea
                      value={Reason.reason}
                      onChange={(e) =>
                        setReason((prev) => ({
                          ...prev,
                          reason: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Approved By <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={Reason.ApprovedBy}
                      onChange={(e) =>
                        setReason((prev) => ({
                          ...prev,
                          ApprovedBy: userRecord?.username,
                        }))
                      }
                      // placeholder="Enter the Approved by"
                    />
                  </div>
                </div>
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
          </div>
          <ToastContainer />
        </>
      ) : (
        forPrintData()
      )}
    </>
  );
};
export default Billcancelation;
