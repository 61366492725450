import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import ReactGrid from "../OtherComponent/ReactGrid/ReactGrid";
import Button from "@mui/material/Button";

function Container() {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);

  const [containerData, setContainerData] = useState([]);
  const [container, setContainer] = useState("");
  const [containerCode, setContainerCode] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedid, setSelectedid] = useState(null);

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };

  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleSubmitContainer = () => {
    if (!container.trim() || !containerCode.trim()) {
      userwarn("Both container and containercode are required.");
      return; // Exit the function early if validation fails
    }
    axios
      .post(`${urllink}mainddepartment/insertcontainer`, {
        container,
        containerCode,
      })
      .then((response) => {
        successMsg('Successfully Inserted')
        fetchgetcontainercode();
        setContainer("");
        fetchContainerData();
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const fetchContainerData = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getcontainer`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setContainerData(data);
      })
      .catch((error) => {
        console.error("Error fetching container data:", error);
      });
  }, [urllink]);

  const fetchgetcontainercode = useCallback(() => {
    axios
      .get(`${urllink}mainddepartment/getcontainercode`)
      .then((response) => {
        const data = response.data;
        console.log("data", data);

        setContainerCode(data.container_code);
      })
      .catch((error) => {
        console.error("Error fetching container data:", error);
      });
  }, [urllink]);

  useEffect(() => {
    fetchContainerData();
    fetchgetcontainercode();
  }, [fetchContainerData, fetchgetcontainercode]);

  const handleEdit = (row) => {
    // Assuming `method_id` is the identifier
    setContainer(row.container_name);
    setContainerCode(row.container_code);
    setIsEditMode(true);
    setSelectedid(row.container_id);
  };

  const handleUpdateMethod = async () => {
    try {
      const response = await axios.post(
        `${urllink}mainddepartment/updatecontainer`,
        {
          method_id: selectedid,
          method_name: container,
          method_code: containerCode,
        }
      );
      console.log(response.data);
      successMsg(response.data.message);
      fetchgetcontainercode();
      setContainer("");
      // setContainerCode("");
      setIsEditMode(false);
      setSelectedid("");
      fetchContainerData();
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const containercolumns = [
    {
      key: "id",
      name: "S.No",
      width: 70,
    },
    {
      key: "container_code",
      name: "Container Code",
    },
    {
      key: "container_name",
      name: "Container Name",
      width: 280,
    },
    {
      key: "EditAction",
      name: "Action",
      renderCell: (params) => (
        <Button
          size="small"
          onClick={() => handleEdit(params.row)}
          startIcon={<EditIcon />}
        ></Button>
      ),
    },
  ];

  return (
    <>
      <div className="appointment">
        <br />

        <div className="ShiftClosing_Container">
          <h2 style={{ textAlign: "center" }}>Container</h2>

          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">
                Container Code <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={containerCode}
                disabled
                onChange={(e) => setContainerCode(e.target.value)}
                placeholder="Enter Designation Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">
                Container Name <span>:</span>
              </label>
              <input
                type="text"
                id="FirstName"
                name="roleName"
                value={container}
                onChange={(e) => setContainer(e.target.value)}
                placeholder="Enter Designation Name"
              />
            </div>

            <button
              className="RegisterForm_1_btns"
              onClick={isEditMode ? handleUpdateMethod : handleSubmitContainer}
            >
              {isEditMode ? "Update" : <AddIcon />}
            </button>
          </div>
          <div className="Main_container_app">
            <ReactGrid columns={containercolumns} RowData={containerData} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Container;
