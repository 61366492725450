import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";

const DoctorsPayout = () => {
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [doctorname, setDoctorname] = useState("");
  const [billingDate, setBillingDate] = useState(new Date());
  const formattedDate = format(billingDate, "yyyy-MM-dd");
  const [expensesInvoiceNo, setExpensesInvoiceNo] = useState(null);
  const [expenseAmount, setExpenseAmount] = useState("");
  const [reasonExpense, setReasonExpense] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const [holderName, setHolderName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const handleChangePaymentType = (e) => {
    setPaymentType(e.target.value);
  };

  useEffect(() => {
    if (doctorname !== "") {
      const time = setTimeout(() => {
        axios
          .get(`${urllink}Billing/get_transaction_id_for_doctor_commission?doctorname=${doctorname}`)
          .then((response) => {
            const data = response.data.transaction_id;
            setExpensesInvoiceNo(data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 200);
      return () => {
        clearTimeout(time);
      };
    }
  }, [doctorname, urllink]);

  useEffect(() => {
    if (doctorname !== "" && fromDate !== "" && toDate !== "") {
      const time = setTimeout(() => {
        axios
          .get(`${urllink}Billing/get_amount_and_payment?doctorname=${doctorname}&fromdate=${fromDate}&todate=${toDate}`)
          .then((response) => {
            const data = parseFloat(response.data.TotalAmount);
            setExpenseAmount(data);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 200);
      return () => {
        clearTimeout(time);
      };
    }
  }, [doctorname, fromDate, toDate, urllink]);

  useEffect(() => {
    if (doctorname !== "") {
      const time = setTimeout(() => {
        axios
          .get(`${urllink}Billing/get_for_payment_type?doctorname=${doctorname}`)
          .then((response) => {
            const paymentType = response.data[0].Payment_Type;
            const accountNum = response.data[0].Account_Number;
            const holderName = response.data[0].Holder_Name;
            const bankName = response.data[0].Bank_Name;
            setPaymentType(paymentType);
            setHolderName(holderName);
            setBankName(bankName);
            setAccountNum(accountNum);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 200);
      return () => {
        clearTimeout(time);
      };
    }
  }, [doctorname, urllink]);

  const handleSubmit = () => {
    const postData = {
      doctorname: doctorname,
      transactionid: expensesInvoiceNo,
      fromdate: fromDate,
      todate: toDate,
      Billing_date: formattedDate,
      Paidby: userRecord?.username,
      paymenttype: paymentType,
      paidamount: expenseAmount,
      location: userRecord?.location,
      reason: reasonExpense,
    };

    axios
      .post(`${urllink}Billing/insert_into_table_paid`, postData)
      .then((response) => {
        console.log(response);
        setBillingDate(new Date());
        setExpenseAmount("");
        setExpensesInvoiceNo(null);
        setReasonExpense("");
        setAccountNum("");
        setBankName("");
        setDoctorname("");
        setFromDate("");
        setHolderName("");
        setPaymentType("");
        setToDate("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Doctors Payout</h4>
      </div>
      <br />
      

      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="Doctor_Name"> Doctor Name: <span>:</span></label>
          <input
            type="text"
            name="Doctor_Name"
            value={doctorname}
            onChange={(e) => setDoctorname(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Transaction_No">Transaction No: <span>:</span></label>
          <input name="Transaction_No" readOnly value={expensesInvoiceNo || ''} />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="From_Date">From Date: <span>:</span></label>
          <input
            type="date"
            name="From_Date"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="To_Date">To Date: <span>:</span></label>
          <input
            type="date"
            name="To_Date"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Billing_date">Paid Date <span>:</span></label>
          <input
            type="date"
            name="Billing_date"
            value={billingDate.toISOString().split("T")[0]}
            onChange={(e) => setBillingDate(new Date(e.target.value))}
            readOnly
          />
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Issue_From_id">Issue From <span>:</span></label>
          <div className="Issue_input">
            <input
              type="text"
              name="Issue_From_id"
              className="dura_with1"
              value={userRecord?.user_id || ''}
              readOnly
            />
            <input
              name="Issue_From_name"
              className="saxdsed"
              value={userRecord?.username || ''}
              readOnly
            />
          </div>
        </div>
        <div className="RegisForm_1">
          <label htmlFor="Given_amount">Amount <span>:</span></label>
          <input
            type="number"
            name="Given_amount"
            value={expenseAmount}
            readOnly
          />
        </div>
        <div className="RegisForm_1">
          <label>Payment Method <span>:</span></label>
          <input
            type="text"
            name="paymenttype"
            value={paymentType}
            onChange={handleChangePaymentType}
          />
        </div>
        {(paymentType === 'Banking' || paymentType === 'DD') && (
          <>
            <div className="RegisForm_1">
              <label htmlFor="Bankname">Bank Name<span>:</span></label>
              <input
                type="text"
                id="Bankname"
                name="Bankname"
                value={bankName}
                readOnly
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="HolderName">Holder Name<span>:</span></label>
              <input
                type="text"
                id="HolderName"
                name="HolderName"
                value={holderName}
                readOnly
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="CardNumber">Account Number<span>:</span></label>
              <input
                type="text"
                id="CardNumber"
                name="CardNumber"
                value={accountNum}
                readOnly
              />
            </div>
          </>
        )}
        <div className="RegisForm_1">
          <label htmlFor="Reasion_Expense">Reason <span>:</span></label>
          <textarea
            name="Reasion_Expense"
            value={reasonExpense}
            onChange={(e) => {
              const inputText = e.target.value;
              if (inputText?.length <= 100) {
                setReasonExpense(inputText);
              } else {
                alert("Maximum allowed words is 100");
              }
            }}
          />
        </div>
      </div>
      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </div>
  );
};

export default DoctorsPayout;
