import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { saveAs } from "file-saver";
import "jspdf-autotable";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: " var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid   var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const CashExpenseReport = () => {
  const [userRecord, setUserRecord] = useState([]);
  console.log(userRecord);

  useEffect(() => {
    const userRecord = localStorage.getItem("token");

    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      console.log("decodedTokenData", decodedTokenData);
      setUserRecord(decodedTokenData);
    }
  }, []); // console.log('sss',userRecord)

  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyy-MM-dd");
  const urllink = useSelector(state => state.userRecord?.UrlLink);

  console.log(formattedDate);

  const [formData, setFormData] = useState({
    // BranchName:'',
    ExpensesType: "",
    DateType: "",
    CurrentDate: "",
    FromDate: "",
    ToDate: "",
  });
  // const [formDataPrint, setFormDataPrint] = useState(null);
  const [Expenses_Name_list, setExpenses_Name_list] = useState([]);
  const [selectBranch, setSelectBranch] = useState("");
  const [serachBranch, setserachBranch] = useState([]);
  // const [rows, setRows] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [page, setPage] = useState(0);
  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "DateType") {
      if (value === "Current") {
        setFormData({
          ...formData,
          CurrentDate: formattedDate,
          [name]: value,
        });
      } else {
        setFormData({
          ...formData,
          CurrentDate: "",
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "dd-MM-yyyy");
    setFormData((prev) => ({
      ...prev,
      date: formattedDate,
    }));
  }, []);

  useEffect(() => {
    // const username = userRecord.username;
    axios
      .get(`${urllink}usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setserachBranch(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  }, [userRecord.username, urllink]);
  const createColumnsForAllBranches = (branchNames) => {
    let columns = [
      { field: "ExpensesType", headerName: "Expenses Type", width: 150 },
      { field: "BillingDates", headerName: "Bill Date", width: 100 },
      ...branchNames
        .filter((name) => name !== "ALL")
        .map((branchName) => ({
          field: branchName.replace(/\s+/g, ""), // Remove spaces for valid field names
          headerName: branchName,
          width: 130,
        })),
    ];
    return columns;
  };

  useEffect(() => {
    // Assuming serachBranch contains the branch names
    let branchNames = serachBranch.map((branch) => branch.location_name);

    // Dynamically set columns based on the selection
    const DynamicColumns =
      selectBranch === "ALL"
        ? createColumnsForAllBranches(branchNames)
        : [
            { field: "id", headerName: "S.NO", width: 50 },
            { field: "BranchName", headerName: "Branch Name", width: 130 },
            { field: "InvoiceNo", headerName: "Invoice No", width: 100 },
            { field: "BillDate", headerName: "Bill Date", width: 100 },
            {
              field: "IssueFromName",
              headerName: "Issue From Name",
              width: 150,
            },
            { field: "ExpensesType", headerName: "Expenses Type", width: 150 },
            {
              field: "ExpensesAmount",
              headerName: "Expenses Amount",
              width: 130,
            },
            { field: "Reason", headerName: "Reason", width: 130 },
          ];

    setDynamicColumns(DynamicColumns);
  }, [selectBranch, serachBranch, urllink]);
  const transformDataForAllBranches = (data) => {
    // Extract unique branch names
    const branchNames = [...new Set(data.map((item) => item.BranchName))];

    // Initialize an object to hold the transformed data
    let transformedData = {};

    // Loop through each item and organize it under its respective branch, expense type, billing dates, and expense methods
    data.forEach((item) => {
      const expenseKey = item.ExpensesType;

      // If the expense type doesn't exist, initialize it
      if (!transformedData[expenseKey]) {
        transformedData[expenseKey] = {
          ExpensesType: item.ExpensesType,
          BillingDates: new Set(), // Using a Set to store unique billing dates
          ExpenseMethods: new Set(), // Using a Set to store unique expense methods
        };
        branchNames.forEach((branch) => {
          transformedData[expenseKey][branch] = 0; // Initialize each branch amount to 0
        });
      }

      // Convert item.ExpensesAmount to a number if it's not already
      const expensesAmount = parseFloat(item.ExpensesAmount);

      // Add billing date and expense method to the Sets
      transformedData[expenseKey].BillingDates.add(item.BillingDate);
      transformedData[expenseKey].ExpenseMethods.add(item.ExpenseMethod);

      // Check if the branch name exists to avoid undefined errors
      if (transformedData[expenseKey][item.BranchName] !== undefined) {
        // Add the amount under the correct branch and expense type, ensuring to add it to the existing amount
        transformedData[expenseKey][item.BranchName] += expensesAmount;
      }
    });

    // Convert the Sets to arrays and the overall object back to an array for rendering
    return Object.values(transformedData).map((item) => {
      // Convert Sets to Arrays
      item.BillingDates = Array.from(item.BillingDates);
      item.ExpenseMethods = Array.from(item.ExpenseMethods);

      // Round each branch amount to avoid floating point precision issues
      branchNames.forEach((branch) => {
        if (item[branch] !== undefined) {
          item[branch] = parseFloat(item[branch]).toFixed(2); // Convert to string with 2 decimal places
        }
      });
      return item;
    });
  };

  useEffect(() => {
    axios
      .get(`${urllink}Pettycash_link/Get_Expenses_Name_data_method`)
      .then((response) => {
        setExpenses_Name_list(response.data.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urllink]);

  // const handletoSearch=()=>{

  //     axios.get(``)
  //     .then((response) => {
  //         console.log('111',response.data)
  //         const A_data=response.data
  //         const B_data =A_data.map((p,index)=>({
  //             id:index+1,
  //             ...p
  //           }))
  //         setFilteredRows(B_data)
  //         setFormData({
  //             BranchName: '',
  //             FromStaff: '',
  //             ToStaff: '',
  //             DateType: '',
  //             CurrentDate: '',
  //             FromDate: '',
  //             ToDate: '',
  //         })
  //     })
  //     .catch((error) => {
  //         console.error(error);
  //     });

  // }

  const handletoSearch = async () => {
    if (
      formData.ExpensesType === "" &&
      formData.DateType === "" &&
      selectBranch === ""
    ) {
      alert("Select Data");
    } else {
      try {
        const queryParams = new URLSearchParams({
          BranchName: selectBranch,
          ExpensesType: formData.ExpensesType,
          DateType: formData.DateType,
          CurrentDate: formData.CurrentDate,
          FromDate: formData.FromDate,
          ToDate: formData.ToDate,
        });

        const response = await axios.get(
          `${urllink}Pettycash_link/get_cash_expenses_report?${queryParams}`
        );
        const data = response.data;
        console.log("data", data);
        if (selectBranch === "ALL") {
          const transformedData = transformDataForAllBranches(data);
          setFilteredRows(transformedData);

          const branchNames = [...new Set(data.map((item) => item.BranchName))];
          const dynamicColumns = createColumnsForAllBranches(branchNames);
          setDynamicColumns(dynamicColumns);
        } else {
          const processedData = data.map((item, index) => ({
            id: index + 1,
            ...item,
          }));

          setFilteredRows(processedData);
          setSelectBranch("");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  // const handleExportToExcel = () => {
  //   if (filteredRows.length !== 0) {
  //     // Dynamically generate column headers from dynamicColumns
  //     const headers = dynamicColumns.map((col) => col.headerName);

  //     // Map each row to a CSV string, properly handling commas and quotes
  //     const rows = filteredRows.map((row) =>
  //       dynamicColumns
  //         .map((col) => {
  //           // Use dynamicColumns here instead of columns
  //           let cell = row[col.field]; // Ensure this matches the attribute used in your objects
  //           // If the cell contains a comma, newline or double-quote, enclose it in double quotes
  //           if (
  //             (cell && cell.toString().includes(",")) ||
  //             (cell && cell.toString().includes("\n")) ||
  //             (cell && cell.toString().includes('"'))
  //           ) {
  //             cell = `"${cell.replace(/"/g, '""')}"`; // Escape double quotes
  //           }
  //           return cell;
  //         })
  //         .join(",")
  //     );

  //     // Additional Information rows
  //     // const additionalInfo = [
  //     //   `Location: ${formDataPrint.BranchName}`,
  //     //   formDataPrint.DateType === "Day"
  //     //     ? `Date: ${formDataPrint.CurrentDate}`
  //     //     : `From: ${formDataPrint.FromDate} To: ${formDataPrint.ToDate}`,
  //     //   `Invoice No: ${formDataPrint.invoiceNo}`,
  //     //   `Expenses Type: ${formDataPrint.ExpensesType}`,
  //     // ];

  //     // Combine header, rows, and additionalInfo into a single CSV string
  //     // const csvContent = [
  //     //   "\ufeff" + headers.join(","), // BOM + header row
  //     //   ...rows,
  //     //   "", // Empty row for spacing (optional)
  //     //   ...additionalInfo,
  //     // ].join("\r\n");

  //     // Create a Blob with the CSV content and trigger download
  //     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  //     saveAs(blob, "Report.csv");
  //   } else {
  //     alert("No Record Found");
  //   }
  // };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Cash Expenses Report</h4>
      </div>
<br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="branch">
            Branch Name <span>:</span>
          </label>
          <select
            name="branch"
            id="branch"
            value={selectBranch}
            onChange={(e) => setSelectBranch(e.target.value)}
          >
            <option value="">Select</option>{" "}
            {/* Optional: add a default "select" option */}
            {serachBranch.map((loc) => (
              <option key={loc.location_id} value={loc.location_name}>
                {loc.location_name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Expenses Type <span>:</span>
          </label>
          <select
            name="ExpensesType"
            value={formData.ExpensesType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            {Expenses_Name_list.map((ele, ind) => (
              <option key={ind} value={ele.Expenses_Name}>
                {ele.Expenses_Name}
              </option>
            ))}
          </select>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="">
            Date Type <span>:</span>
          </label>
          <select
            name="DateType"
            value={formData.DateType}
            onChange={handleChange}
          >
            <option value="">Select</option>
            <option value="Current">Current Date</option>
            <option value="Customize">Customize</option>
          </select>
        </div>

        {formData.DateType === "Customize" && (
          <div className="RegisForm_1">
            <label htmlFor="">
              From Date <span>:</span>
            </label>
            <input
              type="date"
              name="FromDate"
              value={formData.FromDate}
              onChange={handleChange}
            />
          </div>
        )}

        {formData.DateType === "Customize" && (
          <div className="RegisForm_1">
            <label htmlFor="ToDate">
              To Date <span>:</span>
            </label>
            <input
              type="date"
              name="ToDate"
              value={formData.ToDate}
              onChange={handleChange}
            />
          </div>
        )}
      </div>
      <br />

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handletoSearch}>
          Search
        </button>
      </div>

      <div className="grid_1">
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={dynamicColumns} // Assuming this dynamically changes based on some state
              pageSize={10}
              getRowId={(row) => `${row.ExpensesType}-${row.BranchName}`} // Ensuring uniqueness more clearly
              className="data_grid"
              initialState={{
                pagination: {
                  pageSize: 10, // Setting initial page size
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange} // Make sure this function is defined and updates your component state accordingly
              hideFooterPagination={true} // Hides the footer pagination, consistent with your settings
              hideFooterSelectedRowCount={true} // Hides the footer selected row count, as per your settings
            />

            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {showdown !== 0 && filteredRows.length !== 0 ? (
          ""
        ) : (
          <div className="IP_norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
      {filteredRows.length !== 0 && (
        <div className="PrintExelPdf">
          {/* <button onClick={handleExportToExcel}>Save Exel</button> */}
        </div>
      )}
    </div>
  );
};

export default CashExpenseReport;
