import React, { useEffect, useState } from "react";
import axios from "axios";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

function PaymentVoucherFinance() {

  
  const cu_date = format(new Date(), "yyyy-MM-dd");

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [isedit, setisedit] = useState(false);

  const [datalistdata, setdatalistdata] = useState([]);

  const [Paymentlist, setPaymentlist] = useState([]);

  const [ExpensLiblitydata, SetExpensLiblitydata] = useState([]);

  const [ArrayForMultiplePayment, setArrayForMultiplePayment] = useState([]);

  const [selectedShow, setSelectedShow] = useState(false);

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  const [PaymentToState, setPaymentToState] = useState({
    VoucherNo: "",
    VoucherDate: cu_date,
    VoucherNarration: "",
    ToAccountID: "",
    ToAccount: "",
    ToAccountCurBalance: "",
    ToAccountOpnBalance: "",
    CreditAmount: "",
  });

  const [FromAccountstate, setFromAccountstate] = useState({
    FromAccountID: "",
    FromAccount: "",
    FromAccountOpnBalance: "",
    FromAccountCurBalance: "",
    BankDetailes: "",
    DebitAmount:"",
  });

  const [MultiplePaymentdata, setMultiplePaymentdata] = useState({
    PaymentType: "",
    PaymentAmount: "",
    Remarks: "",
  });

  useEffect(() => {
    getContraNum();
  }, []);

  const getContraNum = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Payment_Voucher_invoice_no`)
      .then((response) => {
        const data = response.data.nextContraInvoiceNo;

        setPaymentToState((prev) => ({
          ...prev,
          VoucherNo: data,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getdatalistdata();
    Allleddetailes();
  }, []);

  const getdatalistdata = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_for_Cur_balance`)
      .then((response) => {
        const data = response.data.LedgerEntries;
        console.log('erer',data)
        setdatalistdata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const Allleddetailes = () => {
    axios
      .get(`${urllink}FinanceMagement/get_Ledger_for_PaymentVocher_Cur_balance`)
      .then((response) => {
        const data = response.data.LedgerEntries;

        SetExpensLiblitydata(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const PaymentstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "ToAccount" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => ele.Account_Name === value);

      if (findData) {
        setPaymentToState((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: findData.Current_Balance,
          ToAccountCurBalance: findData.Current_Balance,
          ToAccountID: findData.AccountID,
        }));
      } else {
        setPaymentToState((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: "",
          ToAccountCurBalance: "",
          ToAccountID:"",
        }));
      }
    }
    else if (name === "ToAccountID" && ExpensLiblitydata.length !== 0) {
      let findData = ExpensLiblitydata.find((ele) => +ele.AccountID === +value);

      if (findData) {
        setPaymentToState((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: findData.Current_Balance,
          ToAccountCurBalance: findData.Current_Balance,
          ToAccount: findData.Account_Name,
        }));
      } else {
        setPaymentToState((prev) => ({
          ...prev,
          [name]: value,
          ToAccountOpnBalance: "",
          ToAccountCurBalance: "",
          ToAccount:"",
        }));
      }
    } else {
      setPaymentToState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const FromAccountstateFun = (event) => {
    const { name, value } = event.target;

    if (name === "FromAccount" && datalistdata.length !== 0) {
      let findData = datalistdata.find((ele) => ele.Account_Name === value);

      if (findData) {
        setFromAccountstate((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: findData.Current_Balance,
          FromAccountCurBalance: findData.Current_Balance,
          BankDetailes:findData.BankDetailes,
          FromAccountID: findData.AccountID,
        }));
      } else {
        setFromAccountstate((prev) => ({
          ...prev,
          [name]: value,
          FromAccountCurBalance: "",
          FromAccountOpnBalance: "",
          DebitAmount: "",
          BankDetailes:"",
          FromAccountID: "",
        }));
      }
    } else  if (name === "FromAccountID" && datalistdata.length !== 0) {
      let findData = datalistdata.find((ele) => +ele.AccountID === +value);

      if (findData) {
        setFromAccountstate((prev) => ({
          ...prev,
          [name]: value,
          FromAccountOpnBalance: findData.Current_Balance,
          FromAccountCurBalance: findData.Current_Balance,
          BankDetailes:findData.BankDetailes,
          FromAccount: findData.Account_Name,
        }));
      } else {
        setFromAccountstate((prev) => ({
          ...prev,
          [name]: value,
          FromAccountCurBalance: "",
          FromAccountOpnBalance: "",
          DebitAmount: "",
          BankDetailes:"",
          FromAccount: "",
        }));
      }
    } else {
      setFromAccountstate((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const MultiplePaymentFun = (event) => {
    const { name, value } = event.target;
    setMultiplePaymentdata((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (FromAccountstate.DebitAmount !== "") {
      const CrdAmo =
        +FromAccountstate.FromAccountOpnBalance - +FromAccountstate.DebitAmount;

      setFromAccountstate((prev) => ({
        ...prev,
        FromAccountCurBalance: CrdAmo,
      }));
    } else {
      setFromAccountstate((prev) => ({
        ...prev,
        FromAccountCurBalance: +FromAccountstate.FromAccountOpnBalance,
      }));
    }
  }, [FromAccountstate.DebitAmount]);

  useEffect(() => {
    if (PaymentToState.CreditAmount !== "") {
      const balAmo =
        +PaymentToState.ToAccountOpnBalance + +PaymentToState.CreditAmount;

      if (balAmo) {
        setPaymentToState((prev) => ({
          ...prev,
          ToAccountCurBalance: balAmo,
        }));
      } else {
        setPaymentToState((prev) => ({
          ...prev,
          ToAccountCurBalance: PaymentToState.ToAccountOpnBalance,
        }));
      }
    }
  }, [PaymentToState.CreditAmount]);

  useEffect(() => {
    if (Paymentlist.length !== 0) {
      const Amounttotal = Paymentlist.reduce(
        (total, ele) => total + +ele.DebitAmount,
        0
      );

      setPaymentToState((prev) => ({
        ...prev,
        CreditAmount: Amounttotal,
      }));
    } else {
      setPaymentToState((prev) => ({
        ...prev,
        CreditAmount: 0,
      }));
    }
  }, [Paymentlist]);


  useEffect(()=>{
    if(FromAccountstate.BankDetailes === "Yes" && FromAccountstate.FromAccount !== '' && FromAccountstate.FromAccountCurBalance !== ''){
    setSelectedShow(true)
    }else{
      setSelectedShow(false)
    }
  },[FromAccountstate.BankDetailes,FromAccountstate.FromAccount,FromAccountstate.FromAccountCurBalance === ''])

  useEffect(() => {
    if (
      ArrayForMultiplePayment.length !== 0 &&
      selectedShow
    ) {
      const Amounttotal = ArrayForMultiplePayment.reduce(
        (total, ele) => total + +ele.PaymentAmount,
        0
      );

      setFromAccountstate((prev) => ({
        ...prev,
        DebitAmount: Amounttotal,
        FromAccountCurBalance:
          +FromAccountstate.FromAccountOpnBalance - +Amounttotal,
      }));
    } else if (
      ArrayForMultiplePayment.length === 0 &&
      selectedShow
    ) {
      setFromAccountstate((prev) => ({
        ...prev,
        DebitAmount: 0,
        FromAccountCurBalance: FromAccountstate.FromAccountOpnBalance,
      }));
    }
  }, [ArrayForMultiplePayment,selectedShow]);

  const ClearContradata = () => {
    setPaymentToState({
      VoucherNo: "",
      VoucherDate: cu_date,
      VoucherNarration: "",
      ToAccountID:"",
      ToAccount: "",
      ToAccountOpnBalance: "",
      ToAccountCurBalance: "",
      CreditAmount: "",
    });
  };

  const handlecleardata = () => {
    setFromAccountstate({
      FromAccount: "",
      FromAccountCurBalance: "",
      FromAccountOpnBalance: "",
      DebitAmount: "",
      BankDetailes:"",
      FromAccountID: "",
    });
  };

  const clearmultiplpaydata = () => {
    setMultiplePaymentdata({
      PaymentType: "",
      PaymentAmount: "",
      Remarks: "",
    });
  };

  const Addmuldiplepayment = () => {
    const requiredfields = ["PaymentType", "PaymentAmount", "Remarks"];

    const existing = requiredfields.filter(
      (field) => !MultiplePaymentdata[field]
    );

    if (existing.length === 0) {
      setArrayForMultiplePayment((prev) => [
        ...prev,
        {
          id: prev.length + 1,
          ...MultiplePaymentdata,
        },
      ]);

      clearmultiplpaydata();
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const add_data = () => {
    const requiredfields = [
      "FromAccount",
      "FromAccountCurBalance",
      "DebitAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !FromAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.find(
        (ele) =>
          ele.FromAccount.toLowerCase() ===
          FromAccountstate.FromAccount.toLowerCase()
      );

      if (CheckName) {
        alert("ToAccount Already Exists");
      } else {
        setPaymentlist((prev) => [
          ...prev,
          {
            id: prev.length + 1,
            MulitPayment: ArrayForMultiplePayment,
            ...FromAccountstate,
          },
        ]);

        handlecleardata();
      }

      setArrayForMultiplePayment([]);
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Editdataform = (row) => {
    if (row) {
      let paydatas = row.MulitPayment;
      setFromAccountstate((prev) => ({
        ...prev,
        id: row.id,
        FromAccount: row.FromAccount,
        FromAccountCurBalance: row.FromAccountCurBalance,
        FromAccountOpnBalance: row.FromAccountOpnBalance,
        DebitAmount: row.DebitAmount,
        BankDetailes: row.BankDetailes,
        FromAccountID: row.FromAccountID,
      }));
      setArrayForMultiplePayment(paydatas);
    }
  };

  const Deletedataform = (row) => {
    setPaymentlist((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const Deletemultipay = (row) => {
    setArrayForMultiplePayment((prevList) => {
      const updatedList = prevList.filter((ele) => ele.id !== row.id);
      return updatedList.map((newRow, ind) => ({ ...newRow, id: ind + 1 }));
    });
  };

  const update_data = () => {
    const requiredfields = [
      "FromAccount",
      "FromAccountCurBalance",
      "DebitAmount",
    ];

    const existing = requiredfields.filter(
      (field) => !FromAccountstate[field]
    );

    if (existing.length === 0) {
      const CheckName = Paymentlist.filter(
        (ele) => ele.id !== FromAccountstate.id
      ).find(
        (ele) =>
          ele.FromAccount.toLowerCase() ===
          FromAccountstate.FromAccount.toLowerCase()
      );

      if (CheckName) {
        alert("ToAccount Already Exists");
      } else {
        setPaymentlist((prevList) => {
          const updatedList = prevList.filter(
            (ele) => ele.FromAccount !== FromAccountstate.FromAccount
          );
          return [
            ...updatedList,
            { ...FromAccountstate, MulitPayment: ArrayForMultiplePayment },
          ];
        });

        handlecleardata();
        setArrayForMultiplePayment([]);
        setisedit(false);
      }
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  const Postalldata = () => {
    const requiredfields = [
      "VoucherNo",
      "VoucherDate",
      "VoucherNarration",
      "ToAccount",
      "ToAccountCurBalance",
      "CreditAmount",
    ];

    const existing = requiredfields.filter((field) => !PaymentToState[field]);

    if (existing.length === 0) {
      let Insertdata = {
        PaymentToState: PaymentToState,
        Paymentlist: Paymentlist,
        Location: userRecord.location,
        CreatedBy: userRecord.username,
      };

      axios
        .post(
          `${urllink}FinanceMagement/insert_Payment_Voucher_Entry_details`,
          Insertdata
        )
        .then((response) => {
          alert(response.data.Message);
          ClearContradata();
          setPaymentlist([]);
          getContraNum();
          getdatalistdata();
          Allleddetailes();
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Payment Voucher F</h4>
      </div>
      <br />
      <div className="RegisFormcon">
        <div className="RegisForm_1">
          <label htmlFor="VoucherNo">
            Voucher No <span>:</span>
          </label>
          <input
            type="text"
            name="VoucherNo"
            required
            readOnly
            value={PaymentToState.VoucherNo}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherDate">
            Voucher Date <span>:</span>
          </label>
          <input
            type="date"
            name="VoucherDate"
            required
            value={PaymentToState.VoucherDate}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="VoucherNarration">
            Short Narration <span>:</span>
          </label>
          <textarea
            type="text"
            name="VoucherNarration"
            required
            value={PaymentToState.VoucherNarration}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label htmlFor="ToAccount">
            To Account ID<span>:</span>
          </label>
          <input
            type="text"
            name="ToAccountID"
            list="CrAC_listID"
            required
            value={PaymentToState.ToAccountID}
            onChange={PaymentstateFun}
            autoComplete="off"
          />
          <datalist id="CrAC_listID">
            {ExpensLiblitydata.map((item, index) => (
              <option key={index+'key'} value={item.AccountID}></option>
            ))}
          </datalist>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="ToAccount">
            To Account <span>:</span>
          </label>
          <input
            type="text"
            name="ToAccount"
            list="CrAC_list"
            required
            value={PaymentToState.ToAccount}
            onChange={PaymentstateFun}
            autoComplete="off"
          />
          <datalist id="CrAC_list">
            {ExpensLiblitydata.map((item, index) => (
              <option key={index+'key'} value={item.Account_Name}></option>
            ))}
          </datalist>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="ToAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="ToAccountCurBalance"
            required
            readOnly
            value={PaymentToState.ToAccountCurBalance}
            onChange={PaymentstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label >
            Credit <span>:</span>
          </label>
          <input
            type="number"
            name="CreditAmount"
            required
            readOnly
            value={PaymentToState.CreditAmount}
            onChange={PaymentstateFun}
          />
        </div>
        
        <div className="RegisForm_1">
          <label htmlFor="FromAccount">
            From Account ID<span>:</span>
          </label>
          <input
            type="text"
            name="FromAccountID"
            list="DrAC_listID"
            required
            value={FromAccountstate.FromAccountID}
            onChange={FromAccountstateFun}
            autoComplete="off"
          />
          <datalist id="DrAC_listID">
            {datalistdata.map((item, index) => (
              <option key={index+'key'} value={item.AccountID}></option>
            ))}
          </datalist>
          
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccount">
            From Account <span>:</span>
          </label>
          <input
            type="text"
            name="FromAccount"
            list="DrAC_list"
            required
            value={FromAccountstate.FromAccount}
            onChange={FromAccountstateFun}
            autoComplete="off"
          />
          <datalist id="DrAC_list">
            {datalistdata.map((item, index) => (
              <option key={index+'key'} value={item.Account_Name}></option>
            ))}
          </datalist>
          
        </div>

        <div className="RegisForm_1">
          <label htmlFor="FromAccountCurBalance">
            Cur Balance<span>:</span>
          </label>
          <input
            type="text"
            name="FromAccountCurBalance"
            required
            readOnly
            value={FromAccountstate.FromAccountCurBalance}
            onChange={FromAccountstateFun}
          />
        </div>

        <div className="RegisForm_1">
          <label >
            Debit <span>:</span>
          </label>
          <input
            type="text"
            name="DebitAmount"
            required
            disabled={selectedShow === true}
            value={FromAccountstate.DebitAmount}
            onChange={FromAccountstateFun}
          />
        </div>
      </div>
      <br />

      {selectedShow === true ? <></>: (
        <>
        <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={isedit ? update_data : add_data}
        >
          {isedit ? "Update" : "Add"}
        </button>
        </div>          
        </>
      ) }

      

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Account ID</th>
              <th>Account Name</th>
              <th>Current Balance</th>
              <th>CreditAmount</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Paymentlist.map((row, index) => (
              <tr key={index}>
                <td>{row.id}</td>
                <td>{row.FromAccountID}</td>
                <td>{row.FromAccount}</td>
                <td>{row.FromAccountCurBalance}</td>
                <td>{row.DebitAmount}</td>
                <td>
                  <EditIcon
                    onClick={() => {
                      Editdataform(row);
                      setisedit(true);
                    }}
                  />

                  <DeleteIcon
                    onClick={() => {
                      Deletedataform(row);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      <div className="for_total9">
        <label className="for_total9_xyz">Total<span>:</span></label>
        <div className="for_total9_xyz">
          {PaymentToState.CreditAmount || 0}
        </div>
      </div>
      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          type="button"
          onClick={Postalldata}
        >
          Submit
        </button>
      </div>
      {selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>Payment List</h3>
                </div>
                <>
                <div className="RegisFormcon">
                        <div className="RegisForm_1">
                        <label htmlFor="">
                            Payment Type <span>:</span>
                        </label>
                        <select
                            name="PaymentType"
                            className="select_with_dwn"
                            required
                            value={MultiplePaymentdata.PaymentType}
                            onChange={MultiplePaymentFun}
                        >
                            <option value="">Select</option>
                            <option value="Cheque">Cheque</option>
                            <option value="OnlinePayment">OnlinePayment</option>
                            <option value="Others">Others</option>
                        </select>
                        </div>

                        <div className="RegisForm_1">
                        <label htmlFor="">
                            Amount <span>:</span>
                        </label>
                        <input
                            type="number"
                            name="PaymentAmount"
                            placeholder="Enter Amount"
                            required
                            value={MultiplePaymentdata.PaymentAmount}
                            onChange={MultiplePaymentFun}
                        />
                        </div>

                        <div className="RegisForm_1">
                        <label htmlFor="">
                            Remark <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="Remarks"
                            placeholder="Enter Remark"
                            required
                            value={MultiplePaymentdata.Remarks}
                            onChange={MultiplePaymentFun}
                        />
                        </div>
                    </div>
                    <div className="Register_btn_con">
                        <div className="RegisterForm_1_btns">
                        <AddIcon
                            style={{ cursor: "pointer" }}
                            onClick={Addmuldiplepayment}
                        />
                        </div>
                    </div>

                    <div className="Selected-table-container">
                        <table className="selected-medicine-table2">
                        <thead>
                            <tr>
                            <th>S.No</th>
                            <th>Payment Type</th>
                            <th>Amount</th>
                            <th>Remarks</th>
                            <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ArrayForMultiplePayment.map((row, index) => (
                            <tr key={index}>
                                <td>{row.id}</td>
                                <td>{row.PaymentType}</td>
                                <td>{row.PaymentAmount}</td>
                                <td>{row.Remarks}</td>
                                <td>
                                <DeleteIcon
                                    onClick={() => {
                                    Deletemultipay(row);
                                    }}
                                />
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                    </div>
                    <br />
                    
                    <div className="for_total9">
                        <label className="for_total9_xyz">Total<span>:</span></label>
                        <div className="for_total9_xyz">
                        {FromAccountstate.DebitAmount || 0}
                        </div>
                    </div>
                </>
                
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={isedit ? update_data : add_data}
                  >
                    {isedit ? "Update" : "Add"}
                  </button>

                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => {
                      setSelectedShow(false);
                      handlecleardata()
                      setArrayForMultiplePayment([])
                    }}

                  >
                    close
                  </button>
                </div>
               
              </div>


            </div>
          </div>
        )}
    </div>
  );
}

export default PaymentVoucherFinance;
