import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import mammoth from "mammoth";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "./ckeditor-custom.css"; // Import custom CSS
// import Webcam from "react-webcam";
// import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
// const UPLOAD_ENDPOINT =  'api/auth/upload';
// const API_URL = "https://playerofcode.in";

// const uploadAdapter = (loader) => {
//     return {
//         upload: () => {
//             return loader.file
//                 .then(file => new Promise((resolve, reject) => {
//                     const formData = new FormData();
//                     formData.append('uploadImg', file);

//                     fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
//                         method: 'POST',
//                         body: formData,
//                     })
//                         .then(response => response.json())
//                         .then(result => {
//                             console.log(result);
//                             resolve({ default: result.url });
//                         })
//                         .catch(error => reject(error));
//                 }));
//         }
//     };
// };

// function uploadPlugin(editor) {
//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//         return uploadAdapter(loader);
//     };
// }

function Reportcapture() {
  // const [value, setValue] = useState()

  // const api_url = "https://playerofcode.in";

  // const upload_endpoint = 'api/auth/upload';

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then((file) => {
            const reader = new FileReader();

            reader.onloadend = () => {
              // Convert image file to base64
              const base64String = reader.result.split(",")[1];
              resolve({ default: `data:${file.type};base64,${base64String}` });
            };

            reader.onerror = (error) => {
              reject(error);
            };

            reader.readAsDataURL(file);
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  console.log("userRecord................", userRecord);

  // const [filteredRows, setFilteredRows] = useState([]);
  const [testDetails, setTestDetails] = useState([]);
  const [captureOrganism, setcaptureOrganism] = useState("");
  const [formatword, setformatword] = useState("");
  // const [captureantibiotic, setcaptureantibiotic] = useState('');
  // const [sensitivetype, setsensitivetype] = useState('');
  // const [completeantibiotic, setcompleteantibiotic] = useState([]);
  const [reportformat, setreportformat] = useState("NoGrowth");
  const [reportstatus, setreportstatus] = useState("");
  const [wordtest, setwordtest] = useState([]);
  const [comments, setComments] = useState("");
  const [nogrowthreport, setnogrowthreport] = useState("");
  const [microscopy, setmicroscopy] = useState("");
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  console.log("capturedatas", capturedatas);
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  // const [showFile, setShowFile] = useState({
  //     file1: false,
  // });
  // const [isImageCaptured1, setIsImageCaptured1] = useState(false);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
  // const [facingMode, setFacingMode] = useState("user");
  // const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  // const [IsmobileorNot, setIsmobileorNot] = useState(null);
  // const [samplepicture, setsamplepicture] = useState(null)
  const dispatchvalue = useDispatch();
  const [sensitivityLevels, setSensitivityLevels] = useState({});
  // const [department, setDepartment] = useState([])
  // const, setselectdepartment] = useState()
  const [openpreview, setopenpreview] = useState(false);
  const [selectedAntibiotics, setSelectedAntibiotics] = useState({});
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const contentRef = useRef(null);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChangeComment = (e) => {
    setIsChecked(e.target.checked);
  };

  // const [formData3, setFormData3] = useState({
  //     CapturedImage1: null,
  // });

  // const videoConstraints = {
  //     facingMode: facingMode,
  // };
  // const webcamRef1 = useRef(null);
  // const dataURItoBlob = (dataURI) => {
  //     const byteString = atob(dataURI.split(",")[1]);
  //     const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  //     const ab = new ArrayBuffer(byteString.length);
  //     const ia = new Uint8Array(ab);
  //     for (let i = 0; i < byteString.length; i++) {
  //         ia[i] = byteString.charCodeAt(i);
  //     }
  //     return new Blob([ab], { type: mimeString });
  // };

  // const switchCamera = () => {
  //     setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  // };
  // const handleFileChange = (e) => {
  //     const selectedFile = e.target.files[0];
  //     if (selectedFile) {
  //         setShowFile(selectedFile);
  //     }
  // };

  const handlereportedit = (data, word) => {
    let department = data?.subdepartment;
    console.log(data);
    let testname = word;
    let testcode = data?.testcode;

    axios
      .get(
        `${urllink}/Billing/get_word_data?department=${department}&testname=${testname}&testcode=${testcode}`,
        { responseType: "blob" }
      )
      .then((response) => {
        console.log(response);
        const fileReader = new FileReader();
        fileReader.onload = () => {
          const arrayBuffer = fileReader.result;
          loadWordFile(arrayBuffer);
        };
        fileReader.readAsArrayBuffer(response.data);
      })
      .catch((error) => {
        console.error("Error fetching DOCX file:", error);
      });
    setopenpreview(true);
  };

  const loadWordFile = async (arrayBuffer) => {
    try {
      const { value, messages } = await mammoth.convertToHtml({ arrayBuffer });

      if (messages && messages.length > 0) {
        console.warn("Conversion warnings:", messages);
      }

      setContent(value); // Assuming setContent is a state setter to update your HTML content
    } catch (error) {
      console.error("Error converting DOCX to HTML:", error);
    }
  };

  // const loadWordFile = async (content) => {
  //   try {
  //     const arrayBuffer = await content.arrayBuffer();
  //     const uint8Array = new Uint8Array(arrayBuffer);
  //     console.log('File Content (Uint8Array):', uint8Array);

  //     const { value, messages } = await mammoth.convertToHtml({ arrayBuffer });

  //     if (messages && messages.length > 0) {
  //       console.warn('Conversion warnings:', messages);
  //     }

  //     setContent(value);
  //   } catch (error) {
  //     console.error('Error converting DOCX to HTML:', error);
  //   }
  // };

  // const handlereportedit = (data) => {
  //   console.log(data);
  //   let department = data?.subdepartment;
  //   let testname = data?.testname;
  //   axios.get(`${urllink}/Billing/get_word_data?department=${department}&testname=${testname}`, { responseType: 'blob' })
  //     .then((response) => {
  //       console.log('response.data', response.data);
  //       const content = response.data;
  //       loadWordFile(content);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching DOCX file:', error);
  //     });
  //   setopenpreview(true);
  // };

  // const handleRecaptureImage1 = () => {

  //     setFormData3((prev) => ({
  //         ...prev,
  //         CapturedImage1: null,
  //     }));
  //     setIsImageCaptured1(false);
  // };

  // const handleCaptureImage1 = () => {
  //     const imageSrc = webcamRef1.current.getScreenshot();
  //     const blob = imageSrc
  //         ? dataURItoBlob(imageSrc, "captured_image.jpg")
  //         : null;

  //     setsamplepicture(
  //         blob instanceof Blob ? new File([blob], "captured_image.jpg") : null,
  //     );

  //     setIsImageCaptured1(true);
  // };

  // useEffect(()=>{
  //     if(reportformat === 'Ot'){
  //         handlereportedit({subdepartment: 'MICROBIOLOGY', testcode: 'RD0257'},'BLOOD')

  //     }

  // },[reportformat])

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/getcompletedsample?Billinginvoice=${capturedatas?.Billing_Invoice}&Location=${userRecord?.location}&gender=${capturedatas.Gender}&age=${capturedatas?.Age}&timeperiod=${capturedatas?.Time_Period}&Department=${userRecord?.Department}`
      )
      .then((response) => {
        console.log("------------", response);
        const data = response.data.map((item, index) => ({
          id: index + 1, // Assigning a unique id based on the item index
          testcode: item.Test_Code,
          testname: item.Test_Name,
          testnames: item.Test_Name,
          Captured_Unit: item.Captured_Unit,
          Container_Name: item.Container_Name,
          Specimen_Name: item.Specimen_Name,
          UpdateAt: capturedatas.UpdatedAt,
          paniclow: item.PanicLow,
          panichigh: item.PanicHigh,
          Calculation: item.Calculation,
          Formula: item.Formula,
          patient_name: capturedatas.Patient_Name,
          Billing_Invoice: capturedatas.Billing_Invoice,
          barcodedata: capturedatas.Barcode_id,
          parameterone: item.Parameterone,
          parametertwo: item.parametertwo,
          Colonycount: item.Colonycount,
          uom: item.Captured_Unit,
          culturetest: item.culturetest || "",
          inputtype: item.input_type,
          organisn: item.Organism || [],
          antibiotic: item.Antibiotic || [],
          subdepartment: item.Department,
          reference: item.Reference,
          referencedata: item.Referencedata,
          Test_Method: item.Test_Method || "",
          capturevalue: item.capturevalue,
          // captureOrganism : captureOrganism
          technicalRemark: item.Technical_Remark,

          // You don't need to explicitly include the 'actions' field here
        }));
        // setFilteredRows(data);
        setTestDetails(data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas, urllink, userRecord]);

  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/getmachineinterfaceresult?sampleid=${capturedatas?.Barcode}`
      )
      .then((response) => {
        console.log("------------", response);
        const data = response.data;

        // Create a copy of testDetails and update capturevalue for matching testcode

        if (data.length > 0 && testDetails.length > 0) {
          let foundmatch = false;
          const updatedTestDetails = testDetails.map((testDetail) => {
            console.log(testDetail.testcode, "7uy84y8789");
            const matchingResult = data.find(
              (item) => item.LIS_MACHTESTID === testDetail.testcode
            );
            console.log("matchingResult", matchingResult);
            if (matchingResult) {
              foundmatch = true;
              return {
                ...testDetail,
                capturevalue: matchingResult.LIS_MACHRESULTS,
              };
            }
            return testDetail;
          });
          if (!foundmatch) {
            alert("Sample is still not processed");
          }

          setTestDetails(updatedTestDetails);
        }
        // Optional: If you need to use machine results separately
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [capturedatas?.Barcode, testDetails, urllink]);

  const handleRemarksChange = (index, newValue, depart) => {
    const updatedvall = [...testDetails];
    const updatedvalue = updatedvall.filter((p) => p.subdepartment === depart);

    // Check if culture test is "Yes" for the corresponding test
    if (updatedvalue[index].culturetest === "Yes") {
      updatedvalue[index].Colonycount = newValue; // Update Colonycount
    } else {
      updatedvalue[index].capturevalue = newValue;
    }

    setTestDetails(updatedvall); // Use the original array with the modified sub-array
  };

  const handleantibiotic = () => {
    navigate("/Home/Antibioticmaster");
  };

  // const capturevaluealert = (index) => {
  //   const alertvalue = testDetails.filter((p) => p.id === index + 1);
  //   console.log('alertvalue', alertvalue);
  //   setalert(alertvalue);
  // };

  // useEffect(() => {
  //   // Set a new timeout to show the alert after typing is complete
  //   let alertTimeout = setTimeout(() => {
  //     if (alerts.length > 0) {
  //       const alertItem = alerts[0];
  //       if (Number(alertItem?.capturevalue) >= Number(alertItem?.panichigh) || Number(alertItem?.capturevalue) <= Number(alertItem?.paniclow)) {
  //         const resultEntry = window.confirm(`Result Out of Range ${alertItem?.capturevalue}`);
  //         if (resultEntry) {
  //           // User confirmed, proceed with the entry (no action needed)
  //         } else {
  //           // User canceled, revert the change
  //           setTestDetails((prevDetails) => {
  //             const revertedDetails = [...prevDetails];
  //             revertedDetails[alertItem.id - 1].capturevalue = ''; // or set to previous value if available
  //             return revertedDetails;
  //           });
  //         }
  //       }
  //     }
  //   }, 500);

  //   return () => {
  //     clearTimeout(alertTimeout);
  //   };
  // }, [alerts]);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
 
  useEffect(() => {
    const updatedTestDetails = testDetails.map((test) => {
      if (test.Calculation === "Yes") {
        const formula = test.Formula.trim();
        console.log("formula", formula);
        const parts = tokenizeFormula(formula); // Tokenize formula excluding parentheses and operators
 
        const parameters = {};
        console.log("parts", parts);
 
        // Populate parameters with values from  testDetails
        parts.forEach((element) => {
          if (isNaN(element)) {
            const parameterTest = testDetails.find(
              (item) => item.testcode === element
            );
            console.log("parameterTest", parameterTest);
            parameters[element] = parameterTest
              ? parseFloat(parameterTest.capturevalue) || 0
              : 0;
          }
        });
 
        // Evaluate expression using parameters
        return evaluateExpression(formula, parameters)
          .then((result) => {
            console.log("res........", result);
            return { ...test, capturevalue: parseFloat(result) || 0 }; // Return a new object with updated capturevalue
          })
          .catch((error) => {
            console.error("Error evaluating expression:", error);
            return { ...test, capturevalue: 0 }; // Return a new object with capturevalue set to 0 in case of error
          });
      }
      return test;
    });
 
    // Wait for all promises to resolve
    Promise.all(updatedTestDetails).then((updatedTestDetailsResolved) => {
      // Check if the updatedTestDetailsResolved is different from the current state
      const isTestDetailsChanged =
        JSON.stringify(updatedTestDetailsResolved) !==
        JSON.stringify(testDetails);
 
      // Update the state only if the testDetails has changed
      if (isTestDetailsChanged) {
        setTestDetails(updatedTestDetailsResolved);
      }
    });
  }); // Depend on testDetails to trigger the effect
 
  async function evaluateExpression(tokens, parameters) {
    console.log(parameters);
    try {
      const response = await axios.get(
        `${urllink}Billing/getformula_calculated_value`,
        {
          params: {
            formula: tokens,
            parameters: JSON.stringify(parameters),
          },
        }
      );
      console.log(response.data);
      const currentOperand = response.data?.valll;
      console.log(currentOperand, "0000000000000000");
      return parseFloat(currentOperand) || 0;
    } catch (error) {
      console.error("Error fetching expression:", error);
      return 0;
    }
  }
 
  function tokenizeFormula(formula) {
    // Add spaces around operators and parentheses to tokenize them properly
    formula = formula.replace(/([+\-*/%^()])/g, " $1 ");
    // Split by spaces to get tokens
 
    const tokens = formula.split(/\s+/).filter((token) => token.trim() !== "");
 
    // Remove parentheses and operators from tokens
    return tokens.filter(
      (token) => !["(", ")", "+", "-", "*", "/", "%", "^"].includes(token)
    );
  }

  
  // const handlecompleteantibiotic = () => {
  //     if (captureOrganism !== '' && captureantibiotic !== '') {
  //         // Check if the captureantibiotic already exists in completeantibiotic
  //         const exists = completeantibiotic.some(item => item.antibiotic === captureantibiotic);
  //         if (exists) {
  //             alert("Antibiotic already exists.");
  //         } else {
  //             // If not, add it to completeantibiotic array
  //             setcompleteantibiotic(prev => [
  //                 ...prev,
  //                 { antibiotic: captureantibiotic, sensitivetype: sensitivetype }
  //             ]);

  //             // Clear captureantibiotic and sensitivetype inputs after adding

  //         }
  //     }
  // };

  const handleaddneworaganism = () => {
    navigate("/Home/OrganismMaster");
  };
  // useEffect(() => {
  //     console.log('completeantibiotic', completeantibiotic)
  //     // setcaptureantibiotic("");
  //     setsensitivetype("");
  // }, [completeantibiotic])

  const handleCheckboxChange = (antibiotic) => {
    setSelectedAntibiotics((prevSelected) => {
      // If "All" is selected, toggle all antibiotics
      if (antibiotic === "All") {
        const allAntibiotics = getFilteredAntibiotics().filter(
          (item) => item !== "All"
        );
        const areAllSelected = allAntibiotics.every(
          (item) => prevSelected[item]
        );

        // If all are selected, deselect all, otherwise select all
        const newSelectionState = areAllSelected
          ? {}
          : allAntibiotics.reduce((acc, item) => {
              acc[item] = true;
              return acc;
            }, {});

        return newSelectionState;
      } else {
        // Toggle the selection state of the specific antibiotic
        return {
          ...prevSelected,
          [antibiotic]: !prevSelected[antibiotic],
        };
      }
    });
  };

  const handlemedicalRemarksChange = (index, newValue) => {
    const updatedTestDetails = [...testDetails];
    updatedTestDetails[index].medicalremark = newValue;
    setTestDetails(updatedTestDetails);
  };

  const handletecnicalRemarksChange = (detail, newValue) => {
    console.log("Index:", detail, "New Value:", newValue); // Debugging
    const updatedTestDetails = [...testDetails];
  
    // Find the index of the item with the matching testcode
    const index = updatedTestDetails.findIndex(item => item.testcode === detail.testcode);
  
    if (index !== -1) {
      updatedTestDetails[index].technicalRemark = newValue;
      setTestDetails(updatedTestDetails);
    } else {
      console.error("Test code not found in test details array.");
    }
  };
  

  // const handleSave = (detail) => {
  //     console.log('detail', detail);

  //     // Check if 'capturevalue' key is present and not empty
  //     if (detail.culturetest !== 'Yes' && !('capturevalue' in detail) || detail.capturevalue === '') {
  //         alert(`${detail.testname} please Enter Result Value`);
  //         return; // Exit the function if the condition is met
  //     }
  //     else {
  //         const basePostData = {
  //             Billing_Invoice: capturedatas.Billing_Invoice,
  //             visitid: capturedatas.Visit_Id,
  //             patient_Id: capturedatas.Patient_Id,
  //             CreatedBy: userRecord?.username,
  //             Location: userRecord?.location,
  //             captureOrganism: captureOrganism,
  //             content: content || '',
  //             Photo: samplepicture
  //         };

  //         // Create FormData object and append the individual test detail
  //         const formData = new FormData();
  //         formData.append('testDetail', JSON.stringify(detail));

  //         // Append other postdata
  //         for (const key in basePostData) {
  //             if (key === 'Photo' && basePostData[key]) {
  //                 formData.append(key, basePostData[key]); // Append file directly
  //             } else if (typeof basePostData[key] === 'object' && basePostData[key] !== null) {
  //                 formData.append(key, JSON.stringify(basePostData[key])); // Stringify JSON objects
  //             } else {
  //                 formData.append(key, basePostData[key]); // Append simple values directly
  //             }
  //         }

  //         axios.post(`${urllink}Phelobotomist/insert_analyse_investigation_result`, formData, {
  //             headers: {
  //                 'Content-Type': 'multipart/form-data'
  //             }
  //         })
  //             .then((response) => {
  //                 console.log(`Inserted test detail: ${detail.testname}`, response);
  //             })
  //             .catch((error) => {
  //                 console.error(`Error inserting test detail: ${detail.testname}`, error);
  //             });
  //     }
  // };

  const handlenavigateagesetupmaster = () => {
    navigate("/Home/TestMastersNavigation");
  };

  const handleSensitivityChange = (level) => {
    const updatedLevels = { ...sensitivityLevels };
    Object.keys(selectedAntibiotics).forEach((antibiotic) => {
      if (selectedAntibiotics[antibiotic]) {
        updatedLevels[antibiotic] = level;
      }
    });
    setSensitivityLevels(updatedLevels);
    // Reset selected antibiotics
    setSelectedAntibiotics({});
  };

  const getFilteredAntibiotics = () => {
    const allAntibiotics = testDetails
      .filter((p) => p.culturetest === "Yes")
      .flatMap((detail) => detail.antibiotic);

    const filteredAntibiotics = allAntibiotics.filter(
      (antibiotic) => !sensitivityLevels[antibiotic]
    );

    return ["All", ...filteredAntibiotics];
  };

  // const isEmptyObject = (obj) => {
  //     return Object.keys(obj).length === 0;
  // };

  const handleSubmitAnalyseReport = () => {
    // if (reportformat === 'Sensitivity' && isEmptyObject(sensitivityLevels) && isEmptyObject(selectedAntibiotics)){
    const postdata = {
      testDetails,
      Billing_Invoice: capturedatas.Billing_Invoice,
      visitid: capturedatas.Visit_Id,
      patient_Id: capturedatas.Patient_Id,
      CreatedBy: userRecord?.username,
      Location: userRecord?.location,
      captureOrganism: captureOrganism,
      content: content || "",
      // Photo: samplepicture,
      Notes: comments || "",
      microscopy: microscopy || "",
      reportformat: reportformat || "",
      nogrowthreport: nogrowthreport || "",
      reportstatus: reportstatus || "",
    };

    const formData = new FormData();
    for (const key in postdata) {
      if (key === "Photo" && postdata[key]) {
        formData.append(key, postdata[key]); // Append file directly
      } else if (typeof postdata[key] === "object" && postdata[key] !== null) {
        formData.append(key, JSON.stringify(postdata[key])); // Stringify JSON objects
      } else {
        formData.append(key, postdata[key]); // Append simple values directly
      }
    }

    console.log(postdata);
    // Perform API calls

    axios
      .post(
        `${urllink}Phelobotomist/insert_analyse_investigation_result`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      .then((response) => {
        console.log(response);
        dispatchvalue({ type: "Navigationlab", value: "" });
        navigate("/Home/AnalayseReportEntry");
      })
      .catch((error) => {
        console.log(error);
      });

    // }else{
    //     alert("Please Submit Sensitivity & Antibiotic")
    // }
  };

  const handlecontinueprocess = () => {
    const culturepost = {
      Billing_Invoice: capturedatas?.Billing_Invoice,
      visitid: capturedatas?.Visit_Id,
      patient_Id: capturedatas?.Patient_Id,
      CreatedBy: userRecord?.username,
      Location: userRecord?.location,
      Patient_name: capturedatas?.Patient_Name,
      refering_doc: capturedatas?.Refering_Doctor,
      antibiotic: sensitivityLevels,
      captureOrganism: captureOrganism,
    };

    axios
      .post(`${urllink}Phelobotomist/insert_into_culture_report`, culturepost)
      .then((response) => {
        console.log(response);
        setSelectedAntibiotics({});
        setSensitivityLevels({});
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // useEffect(()=>{
  //   let data = testDetails?.filter((p)=>p.subdepartment === selectdepartment)
  //   setFilteredRows(data)

  // }, [selectdepartment, testDetails, department])

  console.log("selectedAntibiotics........", selectedAntibiotics);
  console.log("sensitivityLevels", sensitivityLevels);

  console.log("testDetails", testDetails);

  // const parseReferenceData = (referenceData) => {
  //     console.log(referenceData)
  //     return referenceData.split(',').map(item => {
  //         const [label, value] = item.split(':').map(str => str.trim());
  //         let minValue = null;
  //         let maxValue = null;
  //         // console.log('value :', value)
  //         if (value?.includes('<')) {
  //             maxValue = parseFloat(value.replace('<', ''));
  //         } else if (value?.includes('>=')) {
  //             minValue = parseFloat(value.replace('>=', ''));
  //         } else if (value?.includes('-')) {
  //             [minValue, maxValue] = value.split('-').map(str => parseFloat(str.trim()));
  //         } else if (value?.includes('>')) {
  //             maxValue = value;
  //         }

  //         return { label, minValue, maxValue };
  //     });
  // };

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/get_test_name_by_word`)
      .then((response) => {
        console.log(typeof response.data, "8888888888888888888888888888");
        setwordtest(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [urllink]);

  // Function to determine the status based on capture value and reference ranges
  // const getStatus = (captureValue, referenceArray) => {
  //     console.log(captureValue);
  //     console.log(referenceArray);

  //     for (let ref of referenceArray) {
  //         // if (captureValue === 0) {
  //         //     return <span className='indicator' style={{ color: 'green' }}></span>;

  //         // } else {
  //         if (ref.label === 'Normal') {
  //             // Normal range
  //             if (captureValue >= ref.minValue && captureValue <= ref.maxValue) {
  //                 return <span className='indicator' style={{ color: 'green' }}>Normal</span>;
  //             } else if (captureValue <= ref.minValue) {
  //                 return <span className='indicator' style={{ color: 'blue' }}><TiArrowDownThick /></span>;
  //             }
  //         } else if (ref.label === 'Borderline') {
  //             // Borderline range
  //             if (captureValue >= ref.minValue && captureValue <= ref.maxValue) {
  //                 return <span className='indicator' style={{ color: 'blue' }}><TiArrowDownThick /></span>;
  //             }
  //         } else if (ref.label === 'High Risk') {

  //             let highRiskThreshold = null;
  //             // Determine the high risk threshold
  //             const minVal = ref.minValue;
  //             const maxVal = ref.maxValue;

  //             let minThreshold = null;
  //             let maxThreshold = null;

  //             // Parse minValue if it's a string with '>'
  //             if (typeof minVal === 'string' && minVal.includes('>')) {
  //                 minThreshold = parseFloat(minVal.split('>')[1]);
  //                 // minThreshold = minThreshold - 1
  //             } else if (typeof minVal === 'number') {
  //                 minThreshold = minVal;
  //             } else if (typeof minVal === 'string' && minVal.includes('>=')) {
  //                 minThreshold = parseFloat(minVal.split('>=')[1]);
  //             }

  //             // Parse maxValue if it's a string with '>'
  //             if (typeof maxVal === 'string' && maxVal.includes('>')) {
  //                 maxThreshold = parseFloat(maxVal.split('>')[1]);
  //             } else if (typeof maxVal === 'number') {
  //                 maxThreshold = maxVal;
  //             } if (typeof maxVal === 'string' && maxVal.includes('>=')) {
  //                 maxThreshold = parseFloat(maxVal.split('>=')[1]);
  //             }

  //             // Set the highest threshold for high risk
  //             if (minThreshold !== null && maxThreshold !== null) {
  //                 highRiskThreshold = Math.max(minThreshold, maxThreshold);
  //             } else if (minThreshold !== null) {
  //                 highRiskThreshold = minThreshold;
  //             } else if (maxThreshold !== null) {
  //                 highRiskThreshold = maxThreshold;
  //             }

  //             // Check if captureValue is above the high risk threshold
  //             if (highRiskThreshold !== null && captureValue > highRiskThreshold) {
  //                 return <span className='indicator' style={{ color: 'red' }}><TiArrowUpThick /></span>;
  //             }
  //             // }
  //         }
  //     }
  //     return 'Unknown';
  // };

  useEffect(() => {
    if (!userRecord || !userRecord.Department) return;

    axios
      .get(`${urllink}Billing/get_for_pending_result_department`, {
        params: {
          invoice: capturedatas?.Billing_Invoice,
        },
      })
      .then((response) => {
        console.log(response);

        // Assuming response.data is an array of departments
        const fetchedDepartments = response.data.map((dept) => dept.trim());
        // setDepartment(fetchedDepartments);

        // Filter the fetched departments based on userRecord.Department
        const userDepartments = userRecord.Department.split(",").map((dept) =>
          dept.trim()
        );
        const filtered = fetchedDepartments.filter((dept) =>
          userDepartments.includes(dept)
        );
        setFilteredDepartments(filtered);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink, userRecord]);

  return (
    <>
    {console.log(filteredDepartments)}
      {testDetails.length > 0 &&
      
        filteredDepartments.map((dept, deptIndex) => (
          <div key={deptIndex}>
            <h3>{dept}</h3>
            <div className="samplecapture_component">
              <div className="Selected-table-container">
                {testDetails?.some(
                  (p) => p.subdepartment === dept && p.culturetest === "Yes"
                ) && (
                  <>
                    <table className="selected-medicine-table2">
                      <thead>
                        <tr>
                          <th>Test Name</th>
                          <th>Specimen</th>
                          {reportformat !== "Ot" &&
                            reportformat !== "NoGrowth" && (
                              <th>Capture Organism</th>
                            )}
                          {reportformat !== "Ot" && (
                            <th>Colony Count / Growth</th>
                          )}
                          <th>Report Type</th>
                          {reportformat === "Ot" && <th>Format</th>}
                          {reportformat === "Ot" && <th>Action</th>}
                          {reportformat === "NoGrowth" && <th>Status</th>}
                        </tr>
                      </thead>
                      {console.testDetails}
                      <tbody>
                        {testDetails
                          ?.filter((p) => p.culturetest === "Yes")
                          .map((detail, index) => (
                            <tr key={index}>
                              <td>{detail.testname}</td>
                              <td>{detail.Specimen_Name}</td>

                              {reportformat !== "Ot" && (
                                <>
                                  {reportformat !== "NoGrowth" && (
                                    <td>
                                      <div className="organism_add">
                                        <input
                                          type="type"
                                          list="organismlist"
                                          value={captureOrganism}
                                          className="Capture_Status_select1"
                                          onChange={(e) =>
                                            setcaptureOrganism(e.target.value)
                                          }
                                        />
                                        <datalist id="organismlist">
                                          {detail.organisn.map((p, i) => (
                                            <option key={i} value={p}>
                                              {p}
                                            </option>
                                          ))}
                                        </datalist>
                                        <span onClick={handleaddneworaganism}>
                                          <AddIcon />
                                        </span>
                                      </div>
                                    </td>
                                  )}
                                  <td>
                                    <input
                                      type={
                                        detail.Specimen_Name.includes("URINE")
                                          ? "number"
                                          : "text"
                                      }
                                      className="Capture_Status_select1"
                                      onKeyDown={
                                        detail.Specimen_Name.includes("URINE")
                                          ? blockInvalidChar
                                          : null
                                      }
                                      value={detail.Colonycount}
                                      onChange={(e) =>
                                        handleRemarksChange(
                                          index,
                                          e.target.value,
                                          dept
                                        )
                                      }
                                    />
                                  </td>
                                </>
                              )}

                              <td>
                                <select
                                  className=""
                                  value={reportformat}
                                  onChange={(e) =>
                                    setreportformat(e.target.value)
                                  }
                                >
                                  <option value="NoGrowth">No Growth </option>
                                  <option value="Sensitivity">
                                    Sensitivity
                                  </option>
                                  <option value="Ot">Ot</option>
                                </select>
                              </td>

                              {reportformat === "Ot" && (
                                <>
                                  <td>
                                    <input
                                      type="type"
                                      list="organismlist1"
                                      value={formatword}
                                      className="Capture_Status_select1"
                                      onChange={(e) =>
                                        setformatword(e.target.value)
                                      }
                                    />
                                    <datalist id="organismlist1">
                                      {wordtest
                                        ?.filter(
                                          (p) =>
                                            p.Department_Name ===
                                            detail.subdepartment
                                        )
                                        .map((p, i) => (
                                          <option key={i} value={p.Test_Name}>
                                            {p.Test_Name}
                                          </option>
                                        ))}
                                    </datalist>
                                  </td>

                                  <td>
                                    <button
                                      onClick={() =>
                                        handlereportedit(detail, formatword)
                                      }
                                    >
                                      <EditIcon />
                                    </button>
                                  </td>
                                </>
                              )}

                              {reportformat === "NoGrowth" && (
                                <td>
                                  <select
                                    className=""
                                    value={reportstatus}
                                    onChange={(e) =>
                                      setreportstatus(e.target.value)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Final Report">
                                      Final Report{" "}
                                    </option>
                                    <option value="Preliminary Report">
                                      Preliminary Report
                                    </option>
                                  </select>
                                </td>
                              )}
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    {reportformat === "Sensitivity" && (
                      <>
                        <div className="antibioticnames">
                          {getFilteredAntibiotics().map((antibiotic, index) => (
                            <div key={index} className="antibioticnames_list">
                              <input
                                type="checkbox"
                                name={antibiotic}
                                id={antibiotic}
                                checked={
                                  selectedAntibiotics[antibiotic] || false
                                }
                                onChange={() =>
                                  handleCheckboxChange(antibiotic)
                                }
                              />
                              <label htmlFor={antibiotic}>{antibiotic}</label>
                            </div>
                          ))}
                          <button
                            className="RegisterForm_1_btns"
                            onClick={handleantibiotic}
                          >
                            <AddIcon />
                          </button>
                        </div>

                        <div className="Register_btn_con">
                          <button
                            className="RegisterForm_1_btns"
                            onClick={() => handleSensitivityChange("Sensitive")}
                          >
                            Sensitive
                          </button>
                          <button
                            className="RegisterForm_1_btns"
                            onClick={() =>
                              handleSensitivityChange("Intermediate")
                            }
                          >
                            Intermediate
                          </button>
                          <button
                            className="RegisterForm_1_btns"
                            onClick={() => handleSensitivityChange("Resistant")}
                          >
                            Resistant
                          </button>
                          <button
                            className="RegisterForm_1_btns"
                            onClick={() => {
                              handlecontinueprocess();
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </>
                    )}
                    {(reportformat === "NoGrowth" ||
                      reportformat === "Sensitivity") && (
                      <div className="comments_for_microscopy">
                        <label htmlFor="Microscopy">
                          Microscopy<span>:</span>
                        </label>
                        <textarea
                          id="Microscopy"
                          name="Microscopy"
                          value={microscopy}
                          onChange={(e) => {
                            setmicroscopy(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    )}

                    {reportformat === "Sensitivity" && (
                      <div className="comments_for_microscopy">
                        <label htmlFor="remarks">
                          Notes<span>:</span>
                        </label>
                        <textarea
                          id="remarks"
                          name="remarks"
                          value={comments}
                          onChange={(e) => {
                            setComments(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    )}
                    {reportformat === "NoGrowth" && (
                      <div className="comments_for_microscopy">
                        <label htmlFor="remarks">
                          Culture report<span>:</span>
                        </label>
                        {
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChangeComment}
                          />
                        }
                        {isChecked && (
                          <textarea
                            id="remarks"
                            name="remarks"
                            value={nogrowthreport}
                            onChange={(e) => {
                              setnogrowthreport(e.target.value);
                            }}
                          ></textarea>
                        )}
                      </div>
                    )}

                    {/* {completeantibiotic.length > 0 && (
                                        <div className="Selected-table-container">
                                            <table className="selected-medicine-table2">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Antibiotic</th>
                                                        <th>Sensitive Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {completeantibiotic.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.antibiotic}</td>
                                                            <td>{item.sensitivetype}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )} */}
                  </>
                )}

                {dept !== "HISTOPATHOLOGY" &&
                  !testDetails?.some(
                    (p) => p.subdepartment === dept && p.culturetest === "Yes"
                  ) && (
                    <table className="selected-medicine-table2">
                      <thead>
                        <tr>
                          <th>Test Name</th>
                          <th>Specimen</th>
                          <th>Test Method</th>
                          <th>Capture Value</th>
                          <th>Unit</th>
                          <th>Reference Range</th>
                          <th>Indicator</th>
                          <th>
                            Remarks
                           
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {console.log(testDetails)}
                        {testDetails
                          .filter(
                            (detail) =>
                              detail.subdepartment === dept &&
                              detail.culturetest !== "Yes"
                          )
                          .map((detail, index) => {
                            // const referenceArray = detail.referencedata ? parseReferenceData(detail.referencedata) : [];
                            // { console.log('referenceArray', referenceArray) }
                            return (
                              <tr key={index}>
                                <td>{detail.testname}</td>
                                <td>{detail.Specimen_Name}</td>
                                <td>{detail.Test_Method}</td>
                                <td>
                                {detail.Calculation === "Yes" ? (
  detail.capturevalue
) : detail.inputtype === "Textfield" ? (
  <textarea
    className="Capture_Status_select1"
    value={detail.capturevalue}
    onChange={(e) =>
      handleRemarksChange(index, e.target.value, dept)
    }
  />
) : (
  <input
    type={detail.inputtype === "Numeric" ? "number" : "text"}
    className="Capture_Status_select1"
    value={detail.capturevalue}
    onChange={(e) =>
      handleRemarksChange(index, e.target.value, dept)
    }
  />
)}

                                </td>
                                <td>{detail.uom}</td>
                                {detail.reference !== "Yes" ? (
                                  <td>
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      {detail.paniclow}-{detail.panichigh}
                                      
                                      <span
                                        onClick={handlenavigateagesetupmaster}
                                      >
                                        <AddIcon />
                                      </span>
                                    </span>
                                  </td>
                                ) : (
                                  <td>
                                    <div
                                      style={{
                                        width: "90px",
                                        textAlign: "start",
                                      }}
                                    >
                                      {detail?.paniclow} - {detail?.panichigh} {detail?.uom}
                                    </div>
                                  </td>
                                )}

                                <td>
                                  <span>
                                    {detail.inputtype === "Numeric" &&
                                      ((Number(detail.capturevalue) >=
                                        Number(detail.panichigh) && (
                                        <span
                                          className="indicator"
                                          style={{ color: "red" }}
                                        >
                                          <TiArrowUpThick />
                                        </span>
                                      )) ||
                                        (Number(detail.capturevalue) <=
                                          Number(detail.paniclow) && (
                                          <span
                                            className="indicatorlow"
                                            style={{ color: "blue" }}
                                          >
                                            <TiArrowDownThick />
                                          </span>
                                        )) || (
                                          <p
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "green",
                                            }}
                                          >
                                            Normal
                                          </p>
                                        ))}
                                    {detail.inputtype === "" &&
                                      detail.capturevalue}
                                  </span>
                                </td>

                                <td>
                                  <textarea
                                    cols="20"
                                    rows="1"
                                    value={detail.technicalRemark}
                                    className="table_textarea"
                                    onChange={(e) =>
                                      handletecnicalRemarksChange(
                                        detail,
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter Remarks"
                                  ></textarea>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  )}

                {dept === "HISTOPATHOLOGY" && (
                  <table className="selected-medicine-table2">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Test Code</th>
                        <th>Specimen</th>
                        <th>Format</th>
                        {/* <th>Sensitive Types</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {testDetails
                        .filter((p) => p.subdepartment === dept)
                        .map((detail, index) => (
                          <tr key={index}>
                            <td>{detail.testname}</td>
                            <td>{detail.testcode}</td>
                            <td>{detail.Specimen_Name}</td>
                            <td>
                              <input
                                type="type"
                                list="organismlist1"
                                value={formatword}
                                className="Capture_Status_select1"
                                onChange={(e) => setformatword(e.target.value)}
                              />
                              <datalist id="organismlist1">
                                {wordtest
                                  .filter(
                                    (p) =>
                                      p.Department_Name === detail.subdepartment
                                  )
                                  .map((p, i) => (
                                    <option key={i} value={p.Test_Name}>
                                      {p.Test_Name}
                                    </option>
                                  ))}
                              </datalist>
                            </td>
                            <td>
                              <button
                                onClick={() =>
                                  handlereportedit(detail, formatword)
                                }
                              >
                                <EditIcon />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        ))}

      <div className="Register_btn_con">
        <button
          className="RegisterForm_1_btns"
          onClick={handleSubmitAnalyseReport}
        >
          Save
        </button>
      </div>

      {openpreview && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setopenpreview(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div ref={contentRef} className="editor_life">
              <CKEditor
                editor={ClassicEditor}
                config={{
                  extraPlugins: [uploadPlugin],
                }}
                data={content}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setContent(data);
                }}
              />
            </div>

            <div className="Register_btn_con regster_btn_contsai">
              <button
                className="RegisterForm_1_btns"
                onClick={() => setopenpreview(false)}
              >
                Close
              </button>
              {/* <button className="RegisterForm_1_btns" onClick={handleSavedoc}>
                                Save
                            </button> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Reportcapture;
