import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStethoscope,
  faMoneyBillAlt,
  faRightFromBracket,
  faPenNib,
  faPrint,
  faChartSimple,
  faCartShopping,
  faArrowsUpToLine,
  faSackDollar,
  faUserTag,
  faChartBar,
  faAngleDown,
  faBuildingColumns,
  faSuitcaseMedical,
  faRegistered,
  faUser,
  
} from "@fortawesome/free-solid-svg-icons";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { useNavigate } from "react-router-dom";
import "./SideBar.css";

const Sidebar = () => {
  const SidebarToggle = useSelector((state) => state.userRecord?.SidebarToggle);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();

  useEffect(() => {
    if (!SidebarToggle) {
      setOpenSubMenu(null); // Close all submenus when sidebar collapses
    }
  }, [SidebarToggle]);

  const handleSubMenuClick = (menu) => {
    if (SidebarToggle) {
      setOpenSubMenu(openSubMenu === menu ? null : menu);
    }
  };

  const handleLogoutClick = () => {
    navigate("/");
    localStorage.clear();
  };

  const handlenavigateclick = (navval) => {
    navigate(`/Home/${navval}`);
    dispatchvalue({ type: "SidebarToggle", value: false });
    dispatchvalue({ type: "Registeredit", value: {} });
    dispatchvalue({ type: "PurchaseOrderList", value: {} });
  };

  const handleIconClick = () => {
    if (!SidebarToggle) {
      dispatchvalue({ type: "SidebarToggle", value: true });
    }
  };

  return (
    <div
      className={`Sidebar_container_con ${
        SidebarToggle ? "expanded" : "collapsed"
      }`}
      onClick={(e) => e.stopPropagation()}
    >
      {/* Clinic Metrics */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu1")}
      >
        <li onClick={() => handlenavigateclick("MainDashboard")} className="icon_tooltip">
          <FontAwesomeIcon
            icon={faStethoscope}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Dashboard</span>
        </li>
      </ul>

      {/* Receptionist */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu2")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu2" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faUserTag}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Receptionist</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu2" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu2" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu2" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("Appoinment-RequestList")}>
              <span className="icon-name">Appoinment Request</span>
            </li>
            <li onClick={() => handlenavigateclick("Lab-Calendar")}>
              <span className="icon-name">Appoinment Calendar</span>
            </li>
            <li onClick={() => handlenavigateclick("ReceptionistBilling")}>
              <span className="icon-name">Registration Billing</span>
            </li>
            <li onClick={() => handlenavigateclick("Deu-History-List")}>
              <span className="icon-name">Due History</span>
            </li>
            <li onClick={() => handlenavigateclick("PatientRequestlistforlab")}>
              <span className="icon-name">Bill Reprint</span>
            </li>
            <li onClick={() => handlenavigateclick("Bill-Cancellation")}>
              <span className="icon-name">Bill Cancellation</span>
            </li>
            <li onClick={() => handlenavigateclick("Bill-Edit")}>
              <span className="icon-name">Bill Edit</span>
            </li>
            <li onClick={() => handlenavigateclick("Patientlistforlab")}>
              <span className="icon-name">Patient List</span>
            </li>

            <li onClick={() => handlenavigateclick("PaymentHistroy")}>
              <span className="icon-name">Payment History</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Phelobotomist */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu16")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu16" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faChartSimple}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Phelobotomist</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu16" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu16" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu16" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("CaptureSamplelist")}>
              <span className="icon-name">Sample Waiting List</span>
            </li>
            {/* <li onClick={() => handlenavigateclick("PatientRequestlistforlab")}>
              <span className="icon-name">Request List</span>
            </li> */}
            <li onClick={() => handlenavigateclick("SamplelistData")}>
              <span className="icon-name">Completed List</span>
            </li>
          </ul>
        )}
      </ul>

      {/*Lab Technicians */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu17")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu17" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faMoneyBillAlt}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Lab Technicians</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu17" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu17" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu17" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("AnalayseReportEntry")}>
              <span className="icon-name">Report Entry Que</span>
            </li>

            <li onClick={() => handlenavigateclick("ValidatedQue")}>
              <span className="icon-name"> Validated Que</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Senior Technicians */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu18")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu18" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faSuitcaseMedical}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Senior Technicians</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu18" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu18" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu18" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("VerifyReportEntry");
              }}
            >
              <span className="icon-name">Report Verify Que</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("SrLabValidatedQue");
              }}
            >
              <span className="icon-name">Validated Que</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Pathologist */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu4")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu4" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faMoneyBillAlt}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Pathologist</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu4" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu4" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu4" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("AuthorizedReportEntry");
              }}
            >
              <span className="icon-name">Report Approval Que</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("PathValidatedQue");
              }}
            >
              <span className="icon-name">Validated Que </span>
            </li>
          </ul>
        )}
      </ul>

       {/* MRI Technicians */}
       <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu20")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu20" ? "active_act" : ""
          }`}
        >
          <MonitorHeartIcon
            // icon={faMoneyBillAlt}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">MRI Technician</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu20" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu20" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu20" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("MRI-Techy-Que");
              }}
            >
              <span className="icon-name">MRI Que</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("PathValidatedQue");
              }}
            >
              <span className="icon-name">Validated Que </span>
            </li>
          </ul>
        )}
      </ul>

      {/* Radiologist */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu19")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu19" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faMoneyBillAlt}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Radiologist</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu19" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu19" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu19" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("Radiologistque");
              }}
            >
              <span className="icon-name">Radiologist Que</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("PathValidatedQue");
              }}
            >
              <span className="icon-name">Validated Que </span>
            </li>
          </ul>
        )}
      </ul>


      {/* Dispatch */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu5")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu5" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon icon={faPrint} className="inventory_sidebar_icon" />
          <span className="icon-name">Dispatch</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu5" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu5" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu5" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("DispatchEntryList");
                dispatchvalue({ type: "SignPerson", value: "" });
              }}
            >
              <span className="icon-name">Waiting For Dispatch</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Petty Cash */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu6")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu6" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faSackDollar}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Petty Cash</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu6" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu6" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu6" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("Expenses-Master");
              }}
            >
              <span className="icon-name">Expense Master</span>
            </li>

            <li
              onClick={() => {
                handlenavigateclick("Cash-Expenses");
              }}
            >
              <span className="icon-name">Cash Expenses</span>
            </li>

            <li
              onClick={() => {
                handlenavigateclick("Cash-Expenses-Report");
              }}
            >
              <span className="icon-name">Expenses Report</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("Day-Closing");
              }}
            >
              <span className="icon-name">Day Closing</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("Day-Book-Report");
              }}
            >
              <span className="icon-name">Day Closing Report</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("DoctorsPayout");
              }}
            >
              <span className="icon-name">Doctors Payout</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("DoctorsPayoutReport");
              }}
            >
              <span className="icon-name">Doctors Payout Report</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("DueBillPayout");
              }}
            >
              <span className="icon-name">Due Payout</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Lab Inventory Master */}
      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu7")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu7" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faSuitcaseMedical}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Lab Inventory Master</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu7" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu7" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu7" ? "show" : ""
            }`}
          >
            <li
              onClick={() => {
                handlenavigateclick("LabSupplierLists");
              }}
            >
              <span className="icon-name">Supplier Master</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("Productitemmaster");
              }}
            >
              <span className="icon-name">Product Type Master</span>
            </li>
            <li
              onClick={() => {
                handlenavigateclick("LabProductMasterList");
              }}
            >
              <span className="icon-name">Product Master</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Central Store */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu8")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu8" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faCartShopping}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Central Store</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu8" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu8" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu8" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("LabPurchaseRaiseList")}>
              <span className="icon-name">Purchase Raise</span>
            </li>

            <li onClick={() => handlenavigateclick("LabPurchaseRecieveList")}>
              <span className="icon-name">GRN List</span>
            </li>

            <li
              onClick={() => handlenavigateclick("LabQuickStockMasterCentral")}
            >
              <span className="icon-name">Lab QuickStock </span>
            </li>

            <li
              onClick={() => handlenavigateclick("LabPurchaseReturnMasterlist")}
            >
              <span className="icon-name">Purchase Return</span>
            </li>

            <li
              onClick={() => handlenavigateclick("LabSupplierPayCentralStore")}
            >
              <span className="icon-name">Supplier Pay</span>
            </li>

            <li onClick={() => handlenavigateclick("LabIndentIssueList")}>
              <span className="icon-name">Indent Issue </span>
            </li>

            <li onClick={() => handlenavigateclick("LabCentralStockList")}>
              <span className="icon-name"> StockList </span>
            </li>
          </ul>
        )}
      </ul>

      {/* Indent Approve  */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu9")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu9" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faArrowsUpToLine}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Indent Approve</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu9" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu9" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu9" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("LabIndentRaiseList")}>
              <span className="icon-name">Indent Raise</span>
            </li>
            <li onClick={() => handlenavigateclick("LabIndentRecieveList")}>
              <span className="icon-name">Indent Recieve</span>
            </li>
            <li onClick={() => handlenavigateclick("LabIndentReturnList")}>
              <span className="icon-name">Indent Return</span>
            </li>
            <li onClick={() => handlenavigateclick("LabLocationStock")}>
              <span className="icon-name">Location StockList</span>
            </li>
          </ul>
        )}
      </ul>

      {/* Reports */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu10")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu10" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faRegistered}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Reports</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu10" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu10" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu10" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("Supplier_Pay_List")}>
              <span className="icon-name">Supplier Pay List</span>
            </li>
            <li onClick={() => handlenavigateclick("PurchaseRegister")}>
              <span className="icon-name">Purchase Register </span>
            </li>
            <li onClick={() => handlenavigateclick("SalesRegister")}>
              <span className="icon-name">Sales Register </span>
            </li>
            <li onClick={() => handlenavigateclick("RevenueByDepartment")}>
              <span className="icon-name">Revenue By Department </span>
            </li>
            <li onClick={() => handlenavigateclick("tatreport")}>
              <span className="icon-name">TAT Report </span>
            </li>
            <li onClick={() => handlenavigateclick("ReferingDoctorReport")}>
              <span className="icon-name">Refering Doctor List </span>
            </li>
            <li onClick={() => handlenavigateclick("Userwisebillreport")}>
              <span className="icon-name">User Wise Collection </span>
            </li>
            <li onClick={() => handlenavigateclick("Audittrailreport")}>
              <span className="icon-name">Audit Trail </span>
            </li>
            <li onClick={() => handlenavigateclick("ReferDocreviewreport")}>
              <span className="icon-name">Review Report </span>
            </li>
            <li onClick={() => handlenavigateclick("Refering-Hospital-Report")}>
              <span className="icon-name"> Refering Hospital Report </span>
            </li>
            <li onClick={() => handlenavigateclick("Regis-Report")}>
              <span className="icon-name">Register Report </span>
            </li>
            <li onClick={() => handlenavigateclick("TestWise-Report")}>
              <span className="icon-name">TestWise Report </span>
            </li>
            <li onClick={() => handlenavigateclick("FinanceReport")}>
              <span className="icon-name">Finance Report </span>
            </li>

          </ul>
        )}
      </ul>

      {/* HR Management */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu11")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu11" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon icon={faPenNib} className="inventory_sidebar_icon" />
          <span className="icon-name">HR Management</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu11" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu11" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu11" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("Employee-Register")}>
              <span className="icon-name">Employee Register</span>
            </li>
            <li onClick={() => handlenavigateclick("Employee-List")}>
              <span className="icon-name">Employee List </span>
            </li>
            <li onClick={() => handlenavigateclick("Employee-Attendance")}>
              <span className="icon-name">Attendance </span>
            </li>
            <li onClick={() => handlenavigateclick("Employee-PayRoll")}>
              <span className="icon-name">Pay Roll </span>
            </li>
            <li onClick={() => handlenavigateclick("Duty-Management")}>
              <span className="icon-name">Duty Management </span>
            </li>
            <li onClick={() => handlenavigateclick("Duty-Roster-Master")}>
              <span className="icon-name">Duty Roster Master </span>
            </li>
            <li
              onClick={() => handlenavigateclick("Leave-Management-Navigation")}
            >
              <span className="icon-name">Leave Management </span>
            </li>
            <li
              onClick={() =>
                handlenavigateclick("Advance-Management-Navigation")
              }
            >
              <span className="icon-name">Advance Management </span>
            </li>
            <li
              onClick={() =>
                handlenavigateclick("Performance-Appraisal-Navigation")
              }
            >
              <span className="icon-name">Performance Management </span>
            </li>
            <li onClick={() => handlenavigateclick("Employee-Reports")}>
              <span className="icon-name">Employee Report </span>
            </li>
          </ul>
        )}
      </ul>

      {/* Employee Request */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu12")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu12" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faUserTag}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Employee Request</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu12" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu12" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu12" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("Navigation-leave")}>
              <span className="icon-name">Leave Management</span>
            </li>
            <li onClick={() => handlenavigateclick("Navigation-Advance")}>
              <span className="icon-name">Advance Management </span>
            </li>
            <li onClick={() => handlenavigateclick("Shift-Details")}>
              <span className="icon-name">Shift Management </span>
            </li>
            <li onClick={() => handlenavigateclick("Employee-PaySlip-View")}>
              <span className="icon-name">Pay Slip Download </span>
            </li>
          </ul>
        )}
      </ul>

      {/* Masters */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu13")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu13" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faChartBar}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Masters</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu13" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu13" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu13" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("MastersNavigation")}>
              <span className="icon-name">Masters </span>
            </li>
            <li onClick={() => handlenavigateclick("NewTestMasterList")}>
              <span className="icon-name">TestMaster List</span>
            </li>
           

            <li onClick={() => handlenavigateclick("TestMastersNavigation")}>
              <span className="icon-name">Test Masters Navigation</span>
            </li>

            <li onClick={() => handlenavigateclick("ExternalLabmasterList")}>
              <span className="icon-name">External Lab Master</span>
            </li>
            
            <li onClick={() => handlenavigateclick("DepartmentOrderMaster")}>
              <span className="icon-name">Department order Master</span>
            </li>
            <li onClick={() => handlenavigateclick("GroupMasterList")}>
              <span className="icon-name">Group Master</span>
            </li>
            <li onClick={() => handlenavigateclick("OfferPackageList")}>
              <span className="icon-name">Package Master</span>
            </li>
            <li onClick={() => handlenavigateclick("Referdoctormaster")}>
              <span className="icon-name">Refer Doctor</span>
            </li>
            <li onClick={() => handlenavigateclick("Ratecardlims")}>
              <span className="icon-name">Ratecard Master</span>
            </li>
           
            {/* <li onClick={() => handlenavigateclick("FileUploadForm")}>
              <span className="icon-name">Document Upload</span>
            </li> */}
          </ul>
        )}
      </ul>

      {/* User Control */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu14")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu14" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faUser}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">User Control</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu14" ? "arrow-rotate" : ""
            }`}
          />
          
        </li>
        {openSubMenu === "subsidebarmenu14" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu14" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("Role-Management")}>
              <span className="icon-name">Role Management</span>
            </li>
            <li onClick={() => handlenavigateclick("EmployeeQue-List")}>
              <span className="icon-name">Employee Queue List</span>
            </li>
            <li onClick={() => handlenavigateclick("Register-User")}>
              <span className="icon-name"> User Register </span>
            </li>
            <li onClick={() => handlenavigateclick("Account Settings")}>
              <span className="icon-name">Account Settings </span>
            </li>
            <li onClick={() => handlenavigateclick("Clinic Details")}>
              <span className="icon-name">Lab Details </span>
            </li>
            <li onClick={() => handlenavigateclick("User-List")}>
              <span className="icon-name">User List </span>
            </li>
          </ul>
        )}
      </ul>

      {/* Accounts */}

      <ul
        className="Sidebarmenuhover"
        onClick={() => handleSubMenuClick("subsidebarmenu15")}
      >
        <li
          onClick={handleIconClick}
          className={`icon_tooltip ${
            openSubMenu === "subsidebarmenu15" ? "active_act" : ""
          }`}
        >
          <FontAwesomeIcon
            icon={faBuildingColumns}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">Accounts</span>
          <FontAwesomeIcon
            icon={faAngleDown}
            className={`arrow-icon ${
              openSubMenu === "subsidebarmenu15" ? "arrow-rotate" : ""
            }`}
          />
        </li>
        {openSubMenu === "subsidebarmenu15" && (
          <ul
            className={`subSidebarmenuhover ${
              openSubMenu === "subsidebarmenu15" ? "show" : ""
            }`}
          >
            <li onClick={() => handlenavigateclick("AccountsMasterList")}>
              <span className="icon-name">Accounts Master</span>
            </li>
            <li onClick={() => handlenavigateclick("TransactionTypeMaster")}>
              <span className="icon-name"> Transaction Type Master</span>
            </li>
            <li onClick={() => handlenavigateclick("ContraVoucherListFinance")}>
              <span className="icon-name"> Contra Voucher </span>
            </li>
            <li
              onClick={() => handlenavigateclick("PaymentVoucherFinanceList")}
            >
              <span className="icon-name">Payment Voucher</span>
            </li>
            <li
              onClick={() => handlenavigateclick("ReceiptVoucherFinanceList")}
            >
              <span className="icon-name">Receipt Voucher </span>
            </li>
            <li onClick={() => handlenavigateclick("JournalentryFinanceList")}>
              <span className="icon-name">Journal Voucher</span>
            </li>
            <li onClick={() => handlenavigateclick("CashbookFinance")}>
              <span className="icon-name">Cash book</span>
            </li>
            <li onClick={() => handlenavigateclick("BankbookFinance")}>
              <span className="icon-name">Bank book</span>
            </li>
            <li onClick={() => handlenavigateclick("DayBookFinance")}>
              <span className="icon-name">Day book</span>
            </li>
            <li onClick={() => handlenavigateclick("TrialBalanceFinance")}>
              <span className="icon-name">Trial Balance</span>
            </li>
            <li onClick={() => handlenavigateclick("BalanceSheetFinance")}>
              <span className="icon-name">Balance Sheet</span>
            </li>
            <li onClick={() => handlenavigateclick("ProfitandLossFinance")}>
              <span className="icon-name">Profit and Loss</span>
            </li>
          </ul>
        )}
      </ul>

      {/* LogOut */}
      <ul className="Sidebarmenuhover" onClick={handleLogoutClick}>
        <li onClick={handleIconClick} className="icon_tooltip">
          <FontAwesomeIcon
            icon={faRightFromBracket}
            className="inventory_sidebar_icon"
          />
          <span className="icon-name">LogOut</span>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
