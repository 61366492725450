import React, { useRef, useState, useEffect } from "react";
// import Barcode from "react-barcode";
import "./Report.css";
import axios from "axios";
import { useSelector } from "react-redux";
// import QRCode from "qrcode.react";
import jsPDF from "jspdf";
import { useReactToPrint } from "react-to-print";
import { toPng } from "html-to-image";
import { useNavigate } from "react-router-dom";
// import { fontSize } from "@mui/system";
import Docsign from "../assets/Dr.Hemapriya sign.jpg";
import Microsign from "../assets/Dr.Hemapriya sign.jpg";
import Button from "@mui/material/Button";
// import JsBarcode from "jsbarcode";
import { TiArrowDownThick, TiArrowUpThick } from "react-icons/ti";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import PhoneIcon from "../assets/PhoneIcon.jpg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import ReactToPrint from "react-to-print";
import { saveAs } from "file-saver";
import ReactDOM from "react-dom";
import html2canvas from "html2canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const chunkArray = (array, chunkSize) => {
//   const result = [];
//   for (let i = 0; i < array.length; i += chunkSize) {
//     result.push(array.slice(i, i + chunkSize));
//   }
//   return result;
// };

function CancelAppointmentDialog({
  open,
  onClose,
  onConfirm,
  cancelReason,
  setCancelReason,
}) {
  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
  };

  const handleClose = () => {
    setCancelReason("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Reprint Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Reprint Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const TableComponent = React.forwardRef(
  (
    {
      capturedatas,
      summa,
      ClinicDetials,
      emailsent,
      hasOTReport,
      navigate,
      totalPages,
      patinetbillingQr,
      showHeaderFooter,
      currentPage,
      formatMethodName,
    },
    ref
  ) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
        {summa.length !== 0 && (
          <table ref={ref} className="print-table33" style={{flexGrow: 1 }}>
            <thead className="print_header" style={{ width: '100%', borderCollapse: 'collapse' ,height: "150px"  }} >
              {!showHeaderFooter && (
                <div
                  style={{
                    height: "155px",
                    width: "100%",
                    // backgroundColor: 'lightgray'
                  }}
                ></div>
              )}

              {showHeaderFooter && (
                <div className="wiioo900 dsdwd_p9o">
                  <div
                    style={{
                      height: "2px",
                      width: "100%",
                      backgroundColor: "#8E201B",
                    }}
                  ></div>

                  <tr className="new_billing_address_2 ededfedf_9jui">
                    <td className="Register_btn_con_barcode hdyd_p05421">
                      <div id="get_imagecontent_1">
                        <img src={ClinicDetials.ClinicLogo} alt="logo" />
                      </div>

                      <div className="helpline-container0po">
                        <div className="phone-icon0pfd">
                          <img src={PhoneIcon} alt="Phone Icon" />
                        </div>

                        <div className="helpline-textsxf">
                          <span className="helpmc3yuj">Helpline</span>
                          <span className="number_pus34">
                            {ClinicDetials.ClinicLandLineNo || " - "}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </div>
              )}

              <hr style={{ width: "90%", marginLeft: "45px" }} />

              {hasOTReport ? (
                <>
                  <div className="wiioo900">
                    <td>
                      <h4>
                        {capturedatas.Patient_Name} {" , "}{" "}
                        {capturedatas.Refering_Hospital}
                      </h4>
                    </td>
                  </div>
                  <div className="wiioo900 nbjui_94d">
                    <tr className="new_billing_address_2">
                      <td className="new_billing_div_report hhh_p2q">
                        <label>
                          Collected <p>:</p>
                        </label>
                        <span>
                          {/* {item.testdetails?.[0]?.Collected || "-"} */}
                        </span>
                      </td>
                      <td className="new_billing_div_report hhh_p2q">
                        <label>
                          Received <p>:</p>
                        </label>
                        <span>
                          {/* {item.testdetails?.[0]?.Received || "-"} */}
                        </span>
                      </td>
                      <td className="new_billing_div_report hhh_p2q">
                        <label>
                          Barcode <p>:</p>
                        </label>
                        {/* <span>{captureddatas.Barcode}</span> */}
                      </td>
                    </tr>
                  </div>
                </>
              ) : (
                <>
                  <div className="wiioo900">
                    <tr className="new_billing_address_2">
                      <td className="new_billing_div_report">
                        <label>
                          Name <p>:</p>
                        </label>
                        <span>
                          {capturedatas.Title}.{" "}
                          {capturedatas.ReportPatient_Name}
                        </span>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          Age/Gender <p>:</p>
                        </label>
                        <span>
                          {capturedatas.Age} {capturedatas?.Time_Period} /{" "}
                          {capturedatas.Gender}
                        </span>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          Reference Doctor <p>:</p>
                        </label>
                        <span>{capturedatas.Refering_Doctor || "-"}</span>
                      </td>
                      <td className="new_billing_div_report">
                        <label>
                          Sample ID <p>:</p>
                        </label>
                        <span>{capturedatas?.SampleID}</span>
                      </td>
                    </tr>
                    <tr className="new_billing_address_2">
                      <td className="new_billing_div_report">
                        <label>
                          Patient ID <p>:</p>
                        </label>
                        <span>{capturedatas.Patient_Id || "-"}</span>
                      </td>

                      <td className="new_billing_div_report">
                        <label>
                          Collected Date<p>:</p>
                        </label>
                        <span>{capturedatas?.Latest_CreatedAt}</span>
                      </td>

                      <td className="new_billing_div_report">
                        <label>
                          Received Date<p>:</p>
                        </label>
                        <span>{capturedatas?.Latest_CreatedAt}</span>
                      </td>

                      <td className="new_billing_div_report">
                        <label>
                          Report Date<p>:</p>
                        </label>

                        <span>{capturedatas?.Latest_CreatedAt}</span>
                      </td>
                    </tr>
                  </div>
                </>
              )}
            </thead>
            {/* <br /> */}

            <tbody
              className="print_body Selected-table-container deededsw"
              style={{
             display:'flex',
             flexDirection:'column',
                overflow: "auto",
              }}
            >
              {Array.isArray(summa) &&
                summa?.map((dpt, undex) => {
                  // const currentPage = undex + 1;

                  if (dpt.department === "MICROBIOLOGY") {
                    return (
                      <div>
                        <div className="page-numbers-top-ad">
                          <td
                            colSpan="3"
                            className="page-numbers"
                            style={{ textAlign: "right" }}
                          ></td>
                        </div>

                        <table
                          key={undex}
                          style={{
                            width: "85%",
                            position: "relative",
                            left: "55px",
                            pageBreakInside: "avoid",
                          }}
                          className="flex_tbdy_tfoot"
                        >
                          <tbody>
                            <div className="print-page3jjj">
                              {dpt?.testdetails.map((group, groupIndex) => (
                                <div
                                  key={groupIndex}
                                  className="print-page3jjj"
                                >
                                  <div className="table-container554">
                                    <div className="culture_report_data">
                                      <div className="culture_test_header">
                                        <p> Service - {dpt.department}</p>
                                      </div>
                                      <br />
                                      {group?.Report_Type === "Ot" && (
                                        <table
                                          className="report_table report_table_for_micro"
                                          key={groupIndex}
                                        >
                                          <tbody className="print_body Selected-table-container">
                                            <tr>
                                              <td>
                                                <h3>{group.groupName}</h3>
                                                <div className="completed_report_1111">
                                                  <div className="completed_report completed_report04948 gghbuy_o9">
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent:
                                                          "center",
                                                        width: "100%",
                                                      }}
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          group?.EditContent,
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      )}

                                      {(group?.Report_Type === "NoGrowth" ||
                                        group?.Report_Type ===
                                          "Sensitivity") && (
                                        <div className="culturetest_div_head">
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Test Requested<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {group.Test}
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Nature of specimen<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {group?.Sample_Specimen}
                                            </p>
                                          </div>

                                          {group?.Report_Type ===
                                            "NoGrowth" && (
                                            <>
                                              <div className="culturetest_div">
                                                <label className="headerculture">
                                                  Report Status<span>:</span>
                                                </label>
                                                <p className="dataforprint">
                                                  Final Report{" "}
                                                </p>
                                              </div>
                                              <br />
                                            </>
                                          )}

                                          {group?.Microscopy_Data !== "" && (
                                            <div className="culturetest_div">
                                              <label className="headerculture">
                                                Microscopy<span>:</span>
                                              </label>
                                              <p className="dataforprint">
                                                {group?.Microscopy_Data}
                                              </p>
                                            </div>
                                          )}
                                          {group?.Report_Type === "NoGrowth" &&
                                            group?.Culture_Report !== "" && (
                                              <>
                                                {/* <br /> */}
                                                <div className="culturetest_div">
                                                  <label className="headerculture">
                                                    Culture Report<span>:</span>
                                                  </label>
                                                  <p className="dataforprint">
                                                    {group?.Culture_Report}
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                        </div>
                                      )}
                                      {group?.Report_Type === "Sensitivity" && (
                                        <>
                                          {/* <br /> */}
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Organism Isolated<span>:</span>
                                            </label>
                                            <p className="dataforprint summaprintsummauuu">
                                              <ol
                                                style={{
                                                  listStyleType: "upper-alpha",
                                                  textAlign: "start",
                                                  paddingLeft: "10px",
                                                }}
                                              >
                                                {group?.UniqueOrganism?.map(
                                                  (org, index) => (
                                                    <li key={index}>
                                                      <i
                                                        style={{
                                                          fontWeight: "600",
                                                        }}
                                                      >
                                                        {
                                                          org?.Captured_Oraganism
                                                        }
                                                      </i>{" "}
                                                      -{" "}
                                                      <span>Colony Count</span>{" "}
                                                      - {group?.Colony_Count}{" "}
                                                      {group?.UOM}
                                                    </li>
                                                  )
                                                )}
                                              </ol>
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Sensitive<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {
                                                group?.SensitiveAntibiotics
                                                  ?.Sensitive
                                              }
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Resistant<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {
                                                group?.SensitiveAntibiotics
                                                  ?.Resistant
                                              }
                                            </p>
                                          </div>
                                          <div className="culturetest_div">
                                            <label className="headerculture">
                                              Intermediate<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {
                                                group?.SensitiveAntibiotics
                                                  ?.Intermediate
                                              }
                                            </p>
                                          </div>
                                          <br />
                                          <br />
                                          <div className="culturetest_div tffyfcgyt_o">
                                            {" "}
                                            <label className="headerculture">
                                              Notes<span>:</span>
                                            </label>
                                            <p className="dataforprint">
                                              {group?.Notes}
                                            </p>
                                          </div>
                                        </>
                                      )}
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </tbody>
                        </table>
                      </div>
                    );
                  } else if (
                    dpt.department !== "MICROBIOLOGY" &&
                    dpt.department !== "HISTOPATHOLOGY"
                  ) {
                    return (
                      <div>
                        <table
                          key={undex}
                          style={{
                            width: "90%",
                            position: "relative",
                            left: "45px",
                          }}
                        >
                          <div className="print-page3">
                            <div className="ewdwedcsdwe">
                              <div className="culture_test_header">
                                <p>{dpt.department} - Test Report</p>
                              </div>

                              <div className="table-container554">
                                <table className="report_table report_table_for_micro">
                                  <thead style={{ backgroundColor: "white" }}>
                                    <tr className="report_table_0op9">
                                      <th>Test Name</th>
                                      <th>Result</th>
                                      {/* <th>Indicator</th> */}
                                      <th>Unit</th>
                                      <th>Biological Reference</th>
                                      {/* <th>Method</th> */}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {dpt.testdetails
                                      .sort((a, b) => a.Item_Id - b.Item_Id)
                                      .map((group, index) => (
                                        <React.Fragment key={index}>
                                          {group?.Test_Method === "Group" &&
                                            index === 0 && (
                                              <tr>
                                                <td colSpan={6}>
                                                  <p
                                                    style={{ fontWeight: 'bold' }}
                                                  >
                                                    {/* {dpt.Department} -{" "} */}
                                                    {dpt.groupname}
                                                  </p>
                                                </td>
                                              </tr>
                                            )}
                                          <tr>
                                            <td>
                                              <div className="uyhfgr_gf">
                                                <p>{group.Test_Name}</p>
                                                <i>
                                                  {formatMethodName(
                                                    group?.Method_Name
                                                  )}
                                                </i>
                                              </div>
                                            </td>
                                            <td>
                                              {group?.Capture_Value >
                                              group?.panichigh ? (
                                                <span
                                                  style={{ fontWeight: "bold" }}
                                                >
                                                  {group?.Capture_Value}
                                                </span>
                                              ) : (
                                                group?.Capture_Value
                                              )}
                                            </td>

                                            {/* <td>
                                          {group?.Capture_Value <
                                            group?.paniclow && (
                                            <span style={{ color: "blue" }}>
                                              <TiArrowDownThick />
                                            </span>
                                          )}
                                          {group?.Capture_Value >
                                            group?.panichigh && (
                                            <span style={{ color: "red" }}>
                                              <TiArrowUpThick />
                                            </span>
                                          )}
                                          {group?.Capture_Value >=
                                            group?.paniclow &&
                                            group?.Capture_Value <=
                                              group?.panichigh && (
                                              <span style={{ color: "green" }}>
                                                Normal
                                              </span>
                                            )}
                                        </td> */}
                                            <td>{group?.Captured_Unit}</td>
                                            <td>{group?.Reference_Range}</td>
                                            {/* <td>{formatMethodName(group?.Method_Name)}</td> */}
                                          </tr>
                                        </React.Fragment>
                                      ))}
                                    <br />
                                  </tbody>

                                 

                                </table>
                              </div>
                            </div>
                          </div>
                        </table>
                      </div>
                    );
                  } else if (dpt.department === "HISTOPATHOLOGY") {
                    return (
                      <div>
                        <div className="print-page3">
                          <div className="ewdwedcsdwe">
                            <div className="culture_test_header">
                              <p>{dpt.department}</p>
                            </div>

                            <div className="table-container554"></div>

                            <table className="report_table report_table_for_micro">
                              <tbody className="print_body Selected-table-container">
                                <div key={undex}>
                                  {/* <h3>{dpt?.department}</h3> */}

                                  <div className="completed_report_1111">
                                    <div className="completed_report gghbuy_o9">
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            dpt.testdetails[0]?.EditContent,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}


<div className="Add_items_Purchase_Master yydd_end_reprt">
                                <span> --------------------------------- End of Report --------------------------------- </span>
                              </div>

              </tbody>
<br />
            <tfoot
              className="print_footerr"
            >
              

              <tr className="jjxcdsjjej_" >


                <td className="footer_fix_conten_fyft microejeo">
                  <div className="dcnnmjy0s66">
                    <div className="Register_btn_con_QRcode_fix">
                      <img
                        src={patinetbillingQr}
                        alt="qrcode"
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "fill",
                        }}
                      />
                    </div>

                    <div className="doctr_sign_fix7cc">
                      <div>
                        <img src={Docsign} alt="" className="docsign_fix" />
                      </div>
                    </div>
                  </div>
                </td>

             
              </tr>
              <tr className="ehdhe_9ikw">
              <td className="shshxhxs_secfooter">
                  {!showHeaderFooter && (
                   
                    <div
                      style={{
                        height: "30px",
                        width: "100%",
                      }}
                    ></div>
                  )}

                  {showHeaderFooter && (
                    <div className="dcndyeu_oikd">
                      <div
                        style={{
                          height: "2px",
                          width: "100%",
                          backgroundColor: "#174864",
                          marginBottom: "3px",
                        }}
                      ></div>

                      <div className="dchfejjj0p">
                        <div>
                          <span>
                            {ClinicDetials.ClinicAddress}.{" "}
                            {ClinicDetials?.ClinicCity} {" - "}{" "}
                            {ClinicDetials.ClinicCode}. Ph :{" "}
                            {ClinicDetials.ClinicMobileNo + " , "}
                          </span>
                        </div>

                        <div>
                          <span>
                            Email : {ClinicDetials.ClinicMailID} .
                            www.drbaileylabs.com{" "}
                          </span>{" "}
                        </div>
                      </div>

                  

                    </div>
                  )}
                </td>
              
              </tr>
            </tfoot>
          </table>
        )}

        {summa.length === 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <p style={{ color: "red" }}> Your Report is not Ready Now</p>

            <button
              className="RegisterForm_1_btns  Rhugt_report"
              onClick={() => navigate("/Home/DispatchEntryList")}
            >
              Back
            </button>
          </div>
        )}
      </div>
    );
  }
);

function Reports() {
  const componentRef = useRef();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const SignPerson = useSelector((state) => state.userRecord?.SignPerson);
  const printpapaer = useSelector((state) => state.userRecord?.printpapaer);
  const [microandhistonumber, setmicroandhistonumber] = useState({});
  let isNoRendered = false;
  const [summa, setsumma] = useState([]);
  const [cancelReason, setCancelReason] = useState("");
  const [trues, settrues] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [patinetbillingbarcode, setpatinetbillingbarcode] = useState();
  const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
  const navigate = useNavigate();
  const hasOTReport = Array.isArray(summa)
    ? summa.some((item) => item.Report_Type === "OT")
    : [];
  const [emailsent, setemailsent] = useState(false);
  const [patinetbillingQr, setpatinetbillingQr] = useState("");

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });



  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [showHeaderFooter, setShowHeaderFooter] = useState(true);

  const handleCheckboxChange = (e) => {
    setShowHeaderFooter(e.target.value === "yes");
  };
  // const [report, setreport] = useState({
  //   Collected: "",
  //   Received: "",
  //   Reported: "",
  //   Head: "",
  //   Detail: "",
  // });






  useEffect(() => {
    axios
      .get(
        `${urllink}Phelobotomist/get_data_forReport_New?Visitid=${capturedatas?.Visit_Id}&patientid=${capturedatas.Patient_Id}&Billinginvoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        console.log(res);
        setsumma(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
    if (capturedatas) {
      const barval = `${urllink}Billing/get_report?inv=${capturedatas?.Barcode}`;
      axios
        .get(`${urllink}Phelobotomist/get_qrcode_image?qrValue=${barval}`)
        .then((res) => {
          const datass = res.data.qrImage;
          setpatinetbillingQr(datass);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [capturedatas, urllink]);

  useEffect(() => {
    axios
      .get(
        `${urllink}Billing/getMicro_and_HistoNumber?Invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        setmicroandhistonumber(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [urllink, capturedatas]);

  useEffect(() => {
    if (Object.keys(capturedatas).length === 0) {
      navigate("/Home/DispatchEntryList");
    }
  }, [capturedatas, navigate]);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/getClinic?location=ALL`)
      .then((response) => {
        console.log(response);
        const data = response.data[0];
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.doorNo + "," + data.street + "," + data.area,
            ClinicGST: data.Gst_no,
            ClinicCity: data.location,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phoneNo,
            ClinicLandLineNo: data.landline,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${urllink}usercontrol/getAccountsetting`)
      .then((response) => {
        const data = response.data;
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicName: data.clinicName,
            ClinicLogo: `data:image/png;base64,${data.clinicLogo}`,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(
        `${urllink}Billing/get_billing_patient_barcode?Patientid=${capturedatas?.Patient_Id}&Patientname=${capturedatas.Patient_Name}`
      )
      .then((response) => {
        setpatinetbillingbarcode(response.data.Patient_Barcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [capturedatas, urllink]);

  useEffect(() => {
    const updatePageCount = () => {
      if (componentRef.current) {
        const contentHeight = componentRef.current.scrollHeight;
        const pageHeight = 1120;
        const pages = Math.ceil(contentHeight / pageHeight);
        setTotalPages(pages);
        setCurrentPage(1); // Reset to first page when calculating total pages
      }
    };

    updatePageCount();
  }, [summa]);

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   onBeforePrint: () => {
  //     // You can set any pre-print logic here, if necessary
  //   },
  //   onAfterPrint: async () => {
  //     const printdata = componentRef.current;

  //     try {
  //       if (printdata) {
  //         const contentWidth = printdata.offsetWidth;
  //         const padding = 20;
  //         const pdfWidth = contentWidth + 2 * padding;
  //         const pdfHeight = contentWidth * 1.5;
  //         // Initialize the pdf before use
  //         const pdf = new jsPDF({
  //           // orientation: "portrait",
  //           unit: "px",
  //           format: [pdfWidth, pdfHeight],
  //         });

  //         // Calculate total pages based on the number of departments (summa)
  //         const totalPages = summa.length;

  //         // Generating the barcodes and adding them to the PDF

  //         // Loop through each page and add to PDF
  //         for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
  //           await pdf.html(printdata, {
  //             x: padding, // Set x-coordinate for content
  //             y: padding, // Set y-coordinate for content
  //             callback: () => {
  //               const generatedPdfBlob = pdf.output("blob");

  //               const formdata = new FormData();
  //               const postdata = {
  //                 patientname: capturedatas.Patient_Name,
  //                 patient_Id: capturedatas.Patient_Id,
  //                 Visit_Id: capturedatas.Visit_Id,
  //                 refering_doc: capturedatas.Refering_Doctor,
  //                 invoice: capturedatas.Billing_Invoice,
  //                 patinetbillingbarcode: patinetbillingbarcode,
  //                 samplecode: capturedatas.Barcode,
  //                 location: userRecord?.location,
  //                 createdby: userRecord?.username,
  //                 currentPage, // Add current page for tracking
  //                 totalPages, // Add total pages for tracking
  //               };

  //               for (const key in postdata) {
  //                 if (postdata.hasOwnProperty(key)) {
  //                   formdata.append(key, postdata[key]);
  //                 }
  //               }

  //               formdata.append("report", generatedPdfBlob);

  //               axios
  //                 .post(
  //                   `${urllink}Phelobotomist/insert_reort_table`,
  //                   formdata,
  //                   {
  //                     headers: {
  //                       "Content-Type": "multipart/form-data",
  //                     },
  //                   }
  //                 )
  //                 .then((response) => {
  //                   settrues(!trues);
  //                 })
  //                 .catch((error) => {
  //                   console.log(error);
  //                 });

  //               if (capturedatas.Refering_Hospital === "RMC") {
  //                 axios
  //                   .post(
  //                     `${urllink}Phelobotomist/update_report_for_external_request`,
  //                     formdata,
  //                     {
  //                       headers: {
  //                         "Content-Type": "multipart/form-data",
  //                       },
  //                     }
  //                   )
  //                   .then((response) => {
  //                     settrues(!trues);
  //                   })
  //                   .catch((error) => {
  //                     console.log(error);
  //                   });
  //               }
  //             },
  //           });
  //         }
  //       } else {
  //         throw new Error("Unable to get the target element");
  //       }
  //     } catch (error) {
  //       console.error("Error generating PDF:", error);
  //     }
  //   },
  // });
  // Function to create an image from a Material UI icon
  const createImageFromIcon = async (IconComponent) => {
    return new Promise((resolve, reject) => {
      // Create a canvas element
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Set the size of the canvas
      const size = 24; // Size of the icon (adjust as necessary)
      canvas.width = size;
      canvas.height = size;

      // Create a temporary DOM element to render the icon
      const tempIcon = document.createElement("div");
      const icon = <IconComponent style={{ fontSize: size, color: "black" }} />;

      // Render the icon using ReactDOM to the temporary element
      ReactDOM.render(icon, tempIcon);

      // Append to the body to avoid layout issues
      document.body.appendChild(tempIcon);

      // Use html2canvas to capture the icon as an image
      html2canvas(tempIcon)
        .then((canvas) => {
          // Get the data URL from the canvas
          const imgDataUrl = canvas.toDataURL("image/png");
          document.body.removeChild(tempIcon); // Clean up
          resolve(imgDataUrl);
        })
        .catch((error) => {
          document.body.removeChild(tempIcon); // Clean up
          reject(error);
        });
    });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      // You can set anyS pre-print logic here, if necessary
    },
    onAfterPrint: async () => {
      const printdata = componentRef.current;

      try {
        if (printdata) {
          const contentWidth = printdata.offsetWidth;
          const pdfWidth = contentWidth + 2 * 5;

          const pdf = new jsPDF({
            orientation: "portrait",
            unit: "px",
            format: [pdfWidth, 1120],
          });

          // Calculate total pages based on the number of departments (summa)
          const totalPages = summa.length; // Or any other logic to determine the total pages

          // const barcodeImagecon1 = await toPng(
          //   printdata.querySelector("#get_imagecontent_1")
          // );
          // const barcodeImagecon2 = await toPng(
          //   printdata.querySelector("#get_imagecontent_2")
          // );

          // const barcodeImage1 = await new Promise((resolve, reject) => {
          //   const barcodeImg1 = new Image();
          //   barcodeImg1.onload = () => resolve(barcodeImg1);
          //   barcodeImg1.onerror = reject;
          //   barcodeImg1.src = barcodeImagecon1;
          // });

          // const barcodeImage2 = await new Promise((resolve, reject) => {
          //   const barcodeImg2 = new Image();
          //   barcodeImg2.onload = () => resolve(barcodeImg2);
          //   barcodeImg2.onerror = reject;
          //   barcodeImg2.src = barcodeImagecon2;
          // });

          // // Append barcode images to their respective containers
          // const barcodeContainer1 = printdata.querySelector(
          //   "#get_imagecontent_1"
          // );
          // barcodeContainer1.innerHTML = ""; // Clear previous content
          // barcodeContainer1.appendChild(barcodeImage1);

          // const barcodeContainer2 = printdata.querySelector(
          //   "#get_imagecontent_2"
          // );
          // barcodeContainer2.innerHTML = ""; // Clear previous content
          // barcodeContainer2.appendChild(barcodeImage2);

          // Print each page in the PDF
          for (let currentPage = 1; currentPage <= totalPages; currentPage++) {
            await pdf.html(printdata, {
              x: 5, // Set x-coordinate for content
              y: 5, // Set y-coordinate for content
              callback: () => {
                const generatedPdfBlob = pdf.output("blob");

                const formdata = new FormData();
                const postdata = {
                  patientname: capturedatas.Patient_Name,
                  patient_Id: capturedatas.Patient_Id,
                  Visit_Id: capturedatas.Visit_Id,
                  refering_doc: capturedatas.Refering_Doctor,
                  invoice: capturedatas.Billing_Invoice,
                  patinetbillingbarcode: patinetbillingbarcode,
                  samplecode: capturedatas.Barcode,
                  location: userRecord?.location,
                  createdby: userRecord?.username,
                  currentPage, // Add current page for tracking
                  totalPages, // Add total pages for tracking
                };

                // Append each field in postdata to the formdata
                for (const key in postdata) {
                  if (postdata.hasOwnProperty(key)) {
                    formdata.append(key, postdata[key]);
                  }
                }

                // Append the PDF blob to the formdata
                formdata.append("report", generatedPdfBlob);

                axios
                  .post(
                    `${urllink}Phelobotomist/insert_reort_table`,
                    formdata,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then((response) => {
                    settrues(!trues);
                  })
                  .catch((error) => {
                    console.log(error);
                  });

                if (capturedatas.Refering_Hospital === "RMC") {
                  axios
                    .post(
                      `${urllink}Phelobotomist/update_report_for_external_request`,
                      formdata,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((response) => {
                      settrues(!trues);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              },
            });
          }
        } else {
          throw new Error("Unable to get the target element");
        }
      } catch (error) {
        console.error("Error generating PDF:", error);
      }
    },
  });



  const [loader, setloader] = useState(false);

  const formatMethodName = (methodName) => {
    if (!methodName) return ""; // Handle empty or undefined method names
    return (
      methodName.charAt(0).toUpperCase() + methodName.slice(1).toLowerCase()
    );
  };

  const handleDownloadPDF = async () => {
    setemailsent(true);

    const printData = componentRef.current;

    if (!printData) {
      console.error("No data to print.");
      return;
    }

    try {
      const contentWidth = printData.offsetWidth;
      const contentHeight = printData.offsetHeight;

      // A4 size in pixels
      const a4WidthPx = 794; // A4 width in pixels (portrait)
      const a4HeightPx = 1123; // A4 height in pixels

      // Initialize jsPDF with A4 format
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [a4WidthPx, a4HeightPx], // A4 size in pixels
      });

      // Center content on the page
      const marginX = (a4WidthPx - contentWidth) / 4;
      const marginY = (a4HeightPx - contentHeight) / 4;

      // Render HTML to PDF
      await pdf.html(printData, {
        x: marginX > 0 ? marginX : 2, // Center content horizontally
        y: marginY > 0 ? marginY : 2, // Center content vertically
        html2canvas: {
          scale: 2, // Higher scale for better resolution in PDF
        },
        callback: async () => {
          // Output PDF as blob
          const generatedPdfBlob = pdf.output("blob");

          // Prepare FormData for submission
          const formData = new FormData();
          const postData = {
            patientname: capturedatas.Patient_Name,
            Patient_Id: capturedatas.Patient_Id,
            Billing_Invoice: capturedatas.Billing_Invoice,
          };

          // Append postData to formData
          for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
              formData.append(key, postData[key]);
            }
          }

          // Append the generated PDF blob to formData
          formData.append("report", generatedPdfBlob);

          // Set loader while sending email
          setloader(true);

          // Send PDF via email
          try {
            const response = await axios.post(
              `${urllink}Phelobotomist/sendEmail`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            console.log("Email sent successfully:", response);
            setemailsent(false); // Reset email sent flag
            setloader(false); // Hide loader
            setfiledata(response.data.file);
          } catch (error) {
            console.error("Error sending email:", error);
            setloader(false); // Hide loader on error
          }
        },
      });
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const [filedata, setfiledata] = useState(null);

  const handlesendWhatsapp = () => {
    axios
      .post(
        `${urllink}Phelobotomist/sendWhamsg?Patient_Id=${capturedatas?.Patient_Id}&Billing_Invoice=${capturedatas?.Billing_Invoice}`
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handlesendSMS = () => {};

  return (
    <>
      {loader ? (
        <>
          <div class="loader"></div>
        </>
      ) : (
        <div>
          <TableComponent
            ref={componentRef}
            patinetbillingbarcode={patinetbillingbarcode}
            capturedatas={capturedatas}
            summa={summa}
            userRecord={userRecord}
            navigate={navigate}
            ClinicDetials={ClinicDetials}
            urllink={urllink}
            emailsent={emailsent}
            SignPerson={SignPerson}
            printpapaer={printpapaer}
            isNoRendered={isNoRendered}
            microandhistonumber={microandhistonumber}
            hasOTReport={hasOTReport}
            totalPages={totalPages}
            currentPage={currentPage}
            patinetbillingQr={patinetbillingQr}
            showHeaderFooter={showHeaderFooter}
            formatMethodName={formatMethodName}
          />

          <br />

          <div className="sdcyysc_uxcds67">
            <label>
              <h3>Do you want Header and Footer?</h3>
            </label>
            <div className="dhchche_oici8">
              <h3>
                {" "}
                <label>
                  <input
                    type="checkbox"
                    name="headerFooterOption"
                    value="yes"
                    checked={showHeaderFooter === true}
                    onChange={handleCheckboxChange}
                  />
                  Yes
                </label>
              </h3>
              <h3>
                <label>
                  <input
                    type="checkbox"
                    name="headerFooterOption"
                    value="no"
                    checked={showHeaderFooter === false}
                    onChange={handleCheckboxChange}
                  />
                  No
                </label>
              </h3>
            </div>
          </div>
          <br />

          {summa?.length > 0 && (
            <>
              {" "}
              <ReactToPrint
                trigger={() => (
                  <div className="Register_btn_con">
                    <button
                      onClick={handlePrint}
                      className="RegisterForm_1_btns  Rhugt_report"
                    >
                      Print
                    </button>

                    <button
                      onClick={handleDownloadPDF}
                      className="RegisterForm_1_btns  Rhugt_report"
                    >
                      Send Email
                    </button>

                    <button
                      onClick={handlesendWhatsapp}
                      className="RegisterForm_1_btns  Rhugt_report"
                    >
                      Whatsapp
                    </button>

                    <button
                      onClick={handlesendSMS}
                      className="RegisterForm_1_btns  Rhugt_report"
                    >
                      SMS
                    </button>
                  </div>
                )}
              />
           
            </>
          )}
          {console.log(filedata)}

          {/* <iframe
            title="PDF Viewer"
            src={filedata}
     
          /> */}

          <CancelAppointmentDialog
            open={openDialog}
            onClose={() => setOpenDialog(false)}
            setCancelReason={setCancelReason}
            cancelReason={cancelReason}
          />
        </div>
      )}
    </>
  );
}
export default Reports;
