import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import mammoth from 'mammoth';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CgProfile } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdPhonelinkRing } from "react-icons/md";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import CancelIcon from '@mui/icons-material/Cancel';
import '../LabTechnician/ckeditor-custom.css'; 
import './MRIImage.css' // Import custom CSS
// import Webcam from "react-webcam";
// import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';


const styles = {
    cancelIcon: {
      color: 'red',
      cursor: 'pointer', // Ensure the pointer cursor on hover
      transition: 'transform 0.2s ease-in-out', // Smooth animation effect
    },
  };
  

function MRITechnician() {

    const uploadAdapter = (loader) => {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    loader.file.then((file) => {
                        const reader = new FileReader();

                        reader.onloadend = () => {
                            // Convert image file to base64
                            const base64String = reader.result.split(',')[1];
                            resolve({ default: `data:${file.type};base64,${base64String}` });
                        };

                        reader.onerror = (error) => {
                            reject(error);
                        };

                        reader.readAsDataURL(file);
                    });
                });
            }
        };
    };

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }





    const urllink = useSelector(state => state.userRecord?.UrlLink)
    const userRecord = useSelector(state => state.userRecord?.UserData)
    console.log('userRecord................', userRecord);
    const [testDetails, setTestDetails] = useState([]);
    const capturedatas = useSelector((state) => state.userRecord?.Samplecapture);
    console.log('capturedatas', capturedatas)
    const [filteredDepartments, setFilteredDepartments] = useState([]);
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);
    const dispatchvalue = useDispatch()
    const [sensitivityLevels, setSensitivityLevels] = useState({});
    const [openpreview, setopenpreview] = useState(false)
    const [selectedAntibiotics, setSelectedAntibiotics] = useState({});
    const navigate = useNavigate()
    const [content, setContent] = useState('');
    const contentRef = useRef(null);
    const [multipleImages, setMultipleImages] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isChecked, setIsChecked] = useState(false);
    const [edittestcode, setedittestcode] = useState('')



 
    const handlereportedit = (data) => {
        let testcode = data?.testcode
        const isChecked = testDetails.filter((p)=> p.testcode === testcode).some((p)=> p.isChecked === true)
        if(!isChecked){
            axios.get(`${urllink}/Billing/get_word_data_radiology?testcode=${testcode}`, { responseType: 'blob' })
            .then((response) => {
                console.log(response)
                const fileReader = new FileReader();
                fileReader.onload = () => {
                    const arrayBuffer = fileReader.result;
                    loadWordFile(arrayBuffer);
                };
                fileReader.readAsArrayBuffer(response.data);
            })
            .catch((error) => {
                console.error('Error fetching DOCX file:', error);
            });
        }else{
            testDetails.filter((p)=>p.testcode ===testcode).map((test)=>
            
                setContent(test.EditContent)
            )
        }
       
        setopenpreview(true);
        setedittestcode(testcode)
    };

    const loadWordFile = async (arrayBuffer) => {
        try {
            const { value, messages } = await mammoth.convertToHtml({ arrayBuffer });

            if (messages && messages.length > 0) {
                console.warn('Conversion warnings:', messages);
            }

            setContent(value); // Assuming setContent is a state setter to update your HTML content
        } catch (error) {
            console.error('Error converting DOCX to HTML:', error);
        }
    };
    const handleNext = () => {
        console.log(multipleImages)
        if (currentIndex < multipleImages.length - 2) {
          setCurrentIndex(currentIndex + 2);
        }
      };
    
      const handlePrevious = () => {
        if (currentIndex > 0) {
          setCurrentIndex(currentIndex - 2);
        }
      };
    
      const handleClear = () => {
        setTestDetails((prevDetails) =>
          prevDetails.map((detail) => {
            if (detail.Billing_Invoice === capturedatas.Billing_Invoice) {
              return { ...detail, multipleImages: [] }; // Clear all images
            }
            return detail;
          })
        );
        setCurrentIndex(0)
      };
      const handleFileChange = (event, testcode) => {
        const files = event.target.files;
        if (files.length > 0) {
          const imagesArray = [];
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();
            reader.onloadend = () => {
              imagesArray.push({
                id: Date.now() + i, // Generate a unique ID
                image: reader.result,
              });
              if (imagesArray.length === files.length) {
                // Update the specific test detail with new images
                setTestDetails((prevDetails) =>
                  prevDetails.map((detail) =>
                    detail.testcode === testcode
                      ? {
                          ...detail,
                          multipleImages: [...detail.multipleImages, ...imagesArray],
                        }
                      : detail
                  )
                );
              }
            };
            reader.readAsDataURL(file);
          }
        }
      };
      

    // const sortedImages = [...multipleImages].sort((a, b) => a.id - b.id);


    const handleSavedoc = (testcode) => {
      setTestDetails((prev) =>
          prev.map((item) =>
              item.testcode === testcode ? { ...item, isChecked: true } : item
          )
      );
      setopenpreview((prev) => !prev);
  };
  

    useEffect(() => {
        axios
            .get(
                `${urllink}Billing/getcompletedsample?Billinginvoice=${capturedatas?.Billing_Invoice}&Location=${userRecord?.location}&gender=${capturedatas.Gender}&age=${capturedatas?.Age}&timeperiod=${capturedatas?.Time_Period}&Department=${userRecord?.Department}`
            )
            .then((response) => {
                console.log("------------", response);
                const data = response.data?.filter((p)=> p.MainDepartment === "RADIOLOGY").map((item, index) => ({
                    id: index + 1, // Assigning a unique id based on the item index
                    testcode: item.Test_Code,
                    testname: item.Test_Name,
                    UpdateAt: capturedatas.UpdatedAt,
                    patient_name: capturedatas.Patient_Name,
                    Billing_Invoice: capturedatas.Billing_Invoice,
                    barcodedata: capturedatas.Barcode_id,
                    subdepartment: item.Department,
                    MainDepartment: item.MainDepartment,
                    EditContent: "",
                    multipleImages: [],
                    visit_id : capturedatas?.Visit_Id,
                    patient_id: capturedatas?.Patient_Id,
                    location: userRecord?.location,
                    username : userRecord?.username,
                    isChecked: false
                }));

                setTestDetails(data); // Update state with fetched data

                
            })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });
    }, [capturedatas, urllink, userRecord]);



    const hasConfirmed = useRef(false); // Track if the alert was already shown

    useEffect(() => {
        if (hasConfirmed.current) return; // Prevent re-running

        const confirmAlert = window.confirm(
            `Do you want to proceed with Patient: ${capturedatas?.Patient_Name} and Invoice: ${capturedatas?.Billing_Invoice}?`
        );

        hasConfirmed.current = true; // Mark as confirmed

        if (!confirmAlert) {
            navigate("/Home/MRI-Techy-Que"); // Navigate if user clicks 'Cancel'
        }
    }, [capturedatas, navigate]);

    console.log("content......", content);


    const handleckediter = (data, testcode) =>{
        setTestDetails((prevDetails) =>
            prevDetails.map((detail) =>
              detail.testcode === testcode
                ? {
                    ...detail,
                    EditContent : data,
                  }
                : detail
            )
          );
    }
    

    const handleSubmitAnalyseReport = () => {

      axios.post(`${urllink}Phelobotomist/insert_radiologist_report_entry`,testDetails)
      .then((response)=>{
        // toast.success("Report Entered Successfully")
        navigate('/Home/MRI-Techy-Que')
        
      })
      .catch((error)=>{
        console.log(error);
        
      })


    };



    const handledeleted = (imageIndex, testcode) => {
        setTestDetails((prevDetails) =>
          prevDetails.map((detail) =>
            detail.testcode === testcode
              ? {
                  ...detail,
                  multipleImages: detail.multipleImages.filter((_, ind) => ind !== imageIndex),
                }
              : detail
          )
        );
      };
      
    


    useEffect(() => {
        if (!userRecord || !userRecord.Department) return;

        axios.get(`${urllink}Billing/get_for_pending_result_department`, {
            params: {
                invoice: capturedatas?.Billing_Invoice
            }
        })
            .then((response) => {
                console.log(response);

                // Assuming response.data is an array of departments
                const fetchedDepartments = response.data.map(dept => dept.trim());
                // setDepartment(fetchedDepartments);

                // Filter the fetched departments based on userRecord.Department
                const userDepartments = userRecord.Department.split(',').map(dept => dept.trim());
                const filtered = fetchedDepartments.filter(dept => userDepartments.includes(dept));
                setFilteredDepartments(filtered);

            })
            .catch((error) => {
                console.log(error);
            });
    }, [capturedatas, urllink, userRecord]);



    console.log("multipleImages-------------", multipleImages);
    

    return (
        <>
<div className="new-patient-registration-form">
        <div className="h_head h_head_h_2">
          <h4>Imaging Report</h4>

          
        </div>
        <br />
        
       
        <div className="patientdetails_lab">
          <div className="patientdata">
            <label className="phelobotomist_head">
              <span className="logo_profile">
                <CgProfile />
              </span>
              Patient Name<span>:</span>
            </label>
            <span>{capturedatas?.Patient_Name}</span>
          </div>

          <div className="patientdata">
            <label className="phelobotomist_head">
              <span className="logo_profile">
                <SlCalender />
              </span>
              Date <span>:</span>
            </label>
            <span>{capturedatas?.UpdatedAt}</span>
          </div>

          <div className="patientdata">
            <label className="phelobotomist_head">
              <span className="logo_profile">
                <MdPhonelinkRing />
              </span>
              Phone <span>:</span>
            </label>
            <span>{capturedatas?.Phone}</span>
          </div>

          <div className="patientdata">
            {/* <Barcode value={capturedatas?.Barcode || ''} lineColor="black" height={50} width={1.5} /> */}
            <img src={capturedatas?.Sample_Barcode_Image} alt="barcode" />
          </div>
        </div>
      </div>

            {testDetails.length > 0 &&
                    <div>
                     
                        <div className="samplecapture_component">
                            <div className="Selected-table-container">
                             
                           

                                {
                                    <table className="selected-medicine-table2">
                                        <thead>
                                            <tr>
                                                <th>Test Name</th>
                                                <th>Test Code</th>
                                                <th>Imaging</th>
                                                {/* <th>Format</th> */}
                                                {/* <th>Sensitive Types</th> */}
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {testDetails.filter((p) => p.MainDepartment === "RADIOLOGY").map((detail, index) => (
                                                <tr key={index}>
                                                    <td>{detail.testname}</td>
                                                    <td>{detail.testcode}</td>
                                                    <td>{detail.subdepartment}</td>       
                                                    <td>
                                                        <button onClick={() => handlereportedit(detail)}>
                                                            <EditIcon />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                    </div>

                }

            <div className="Register_btn_con">
                <button className="RegisterForm_1_btns" onClick={handleSubmitAnalyseReport}>
                    Save
                </button>
            </div>

            {openpreview && (
                <div
                    className={
                        isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
                    }
                    onClick={() => { setopenpreview(false) }}
                >
                    <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>



                        <div ref={contentRef} className='editor_life'>


                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    extraPlugins: [uploadPlugin],
                                }}
                                data={content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleckediter(data, edittestcode);
                                }}
                            />

                            
                        </div>
                        <div className="RegisterForm_2">
                      <input
                        type="file"
                        id="CapturedFile"
                        name="CapturedFile"
                        accept="image/*"
                        multiple
                        onChange={
                            (e)=>handleFileChange(e, edittestcode)}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="CapturedFile"
                        className="RegisterForm_1_btns"
                      >
                        Choose File
                      </label>
                    </div>

                        <div className="treatcon_1">
                        <div className="multiple-images-container">
      {  testDetails.filter((p)=> p.testcode === edittestcode).map((test)=>(

test.multipleImages.length !== 0 && (
    test.multipleImages.slice(currentIndex, currentIndex + 6).map((imageData, ind) => (
      <div key={imageData.id} className="treatcon_2">
        <span onClick={() => handledeleted(ind, test.testcode)} style={{ cursor: 'pointer' }}>
          <CancelIcon style={styles.cancelIcon} />
        </span>

        <div className="treatcon_image">
          <div className="treatcon_image_1">
            <img
              src={imageData.image}
              alt={`Captured Image ${imageData.id}`}
              onError={(e) => console.error("Error loading image:", e)}
            />
          </div>
          <div className="treatcon_label">
            <label>{`Captured Image ${imageData.id}`}</label>
          </div>
        </div>
      </div>
    ))
  )
      ))

     }
    </div>
{testDetails.find(detail => detail.Billing_Invoice === capturedatas.Billing_Invoice)?.multipleImages.length > 1 && (

<div className="Register_btn_con">
  {currentIndex > 0 && (
    <button onClick={handlePrevious} className="RegisterForm_1_btns">
      Previous
    </button>
  )}

  {currentIndex < testDetails.find(detail => detail.Billing_Invoice === capturedatas.Billing_Invoice)?.multipleImages.length - 1 && (
    <button onClick={handleNext} className="RegisterForm_1_btns">
      Next
    </button>
  )}

  <button onClick={handleClear} className="RegisterForm_1_btns">
    Clear All
  </button>
</div>
)}

<div>

</div>

</div>


                   


                        <div className="Register_btn_con regster_btn_contsai">
                            <button
                                className="RegisterForm_1_btns"
                                onClick={() => setopenpreview(false)}
                            >
                                Close
                            </button>
                            <button className="RegisterForm_1_btns" onClick={()=>handleSavedoc(edittestcode)}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </>
    );
}

export default MRITechnician;



