import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { saveAs } from "file-saver";
import "../Masters/Master.css";

const muiCache = createCache({
  key: "mui-datatables",
  prepend: true,
});

const theme = createTheme({
  components: {
    MuiDataTable: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-head": {
            backgroundColor: "var(--ProjectColor)",
            textAlign: "center",
          },
          "& .MuiTableCell-body": {
            textAlign: "center",
          },
        },
      },
    },
  },
});

const RatecardLims = () => {
  const [department, setDepartment] = useState("");
  const [franchaisename, setFranchaisename] = useState("");
  const [frandata, setFrandata] = useState([]);
  const [percentValues, setPercentValues] = useState({});
  const [valueValues, setValueValues] = useState({});
  const urllink = useSelector((state) => state.userRecord?.UrlLink);
  const [summa, setsumma] = useState(false);
  const [franchaise, setfranchaises] = useState([]);
  const [SelectedFile1, setSelectedFile1] = useState(null);

  useEffect(() => {
    axios
      .get(`${urllink}mainddepartment/get_ratecard_names_for_billing`)
      .then((response) => {
        console.log(response);
        setfranchaises(response.data.ratecard);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [department, urllink]);

  useEffect(() => {
    axios
      .get(`${urllink}usercontrol/get_for_ratecard?ratecard=${franchaisename}`)
      .then((response) => {
        setFrandata(
          response.data.map((row, index) => ({
            ...row,
            id: index + 1,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [urllink, franchaisename, summa]);

  const handlePercentChange = (event, rowIndex) => {
    const newPercent = event.target.value;
    const newPercentValues = { ...percentValues, [rowIndex]: newPercent };
    setPercentValues(newPercentValues);

    // Perform the calculation and update the Schemecost
    const updatedData = frandata.map((row, index) => {
      if (index === rowIndex) {
        const basicPrice = parseFloat(row.basicprice);
        const percent = parseFloat(newPercent || 0);
        const schemePrice = basicPrice + basicPrice * (percent / 100);
        return { ...row, Schemecost: schemePrice.toFixed(2) };
      }
      return row;
    });

    setFrandata(updatedData);
  };

  const handleValueChange = (event, rowIndex) => {
    const newValue = event.target.value;
    const newValueValues = { ...valueValues, [rowIndex]: newValue };
    setValueValues(newValueValues);

    const updatedData = frandata.map((row, index) => {
      if (index === rowIndex) {
        const percent = parseFloat(newValue || 0);
        const schemePrice = percent;
        return { ...row, Schemecost: schemePrice.toFixed(2) };
      }
      return row;
    });

    setFrandata(updatedData);
  };

  const handleUpdate = (rowData) => {
    console.log("rowData", rowData);
    const postdata = {
      ...rowData,
      franchaisename,
    };
    axios
      .post(`${urllink}usercontrol/update_new_ratecard`, postdata)
      .then((response) => {
        console.log(response);
        setsumma(!summa);
        setPercentValues("");
        setValueValues("");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    { name: "id", label: "Test Id" },
    { name: "testname", label: "Test Name" },
    { name: "basicprice", label: "Basic Price" },
    {
      name: "percent",
      label: "%",
      options: {
        customBodyRender: (value, tableMeta) => (
          <input
            type="number"
            value={percentValues[tableMeta.rowIndex] || ""}
            onChange={(event) => handlePercentChange(event, tableMeta.rowIndex)}
            style={{
              width: "80px",
              border: "1px solid var(--ProjectColor)",
              borderRadius: "5px",
              padding: "5px",
            }}
          />
        ),
      },
    },
    {
      name: "value",
      label: "Value",
      options: {
        customBodyRender: (value, tableMeta) => (
          // <div className="RegisForm_1">
          <input
            type="number"
            value={valueValues[tableMeta.rowIndex] || ""}
            onChange={(event) => handleValueChange(event, tableMeta.rowIndex)}
            style={{
              width: "80px",
              border: "1px solid var(--ProjectColor)",
              borderRadius: "5px",
              padding: "5px",
            }}
          />
          // </div>
        ),
      },
    },
    { name: "Schemecost", label: "Scheme Price" },
    {
      name: "action",
      label: "Update",
      options: {
        customBodyRender: (value, tableMeta) => (
          <div className="Register_btn_con">
            <button
              className="RegisterForm_1_btns"
              onClick={() => handleUpdate(tableMeta.rowData)}
            >
              Update
            </button>
          </div>
        ),
      },
    },
  ];

  const handledownload = () => {
    axios
      .get(`${urllink}usercontrol/getratecardforallfranchise`)
      // .get(`${urllink}usercontrol/createmaster`)
      .then((response) => {
        const data = response.data;

        // Convert JSON data to CSV format
        const csv = convertToCSV(data);

        // Create a Blob from the CSV and save it as a file
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        saveAs(blob, "RateCardData.csv");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileChange1 = (event) => {
    setSelectedFile1(null);
    console.log(event.target.files[0]);
    setSelectedFile1(event.target.files[0]);
  };

  const handleCsvupload1 = () => {
    console.log(SelectedFile1);
    const formData1 = new FormData();
    formData1.append("file", SelectedFile1);

    if (SelectedFile1) {
      axios
        .post(`${urllink}usercontrol/postratecrdcsvfile`, formData1)
        .then((response) => {
          alert("Uploaded Successfully");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const convertToCSV = (data) => {
    const filteredData = data.map(
      ({ CreatedAt, UpdatedAt, Test_id, ...rest }) => {
        return {
          Test_Code: rest.Test_Code,
          Test_Name: rest.Test_Name,
          ...rest,
        };
      }
    );

    const headers = Object.keys(filteredData[0]);

    const array = [headers].concat(filteredData);

    return array
      .map((row) => {
        return Object.values(row)
          .map(String)
          .map((v) => `"${v}"`)
          .join(",");
      })
      .join("\n");
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h4>Ratecard Management</h4>
      </div>

      <br />
      <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
        <div className="con_1">
          <div className="inp_1">
            <label htmlFor="input">
              Department Name <span>:</span>
            </label>
            <select
              className="deprtsele"
              name="Department"
              value={department}
              onChange={(e) => {
                setDepartment(e.target.value);
              }}
              id=""
            >
              <option value="">Select</option>
              <option value="Laboratory">Laboratory</option>
            </select>
          </div>
          <div className="inp_1">
            <label htmlFor="input">
              Franchaise Name <span>:</span>
            </label>
            {department === "Laboratory" && (
              <>
                <input
                  type="text"
                  id="franchaisename"
                  name="franchaisename"
                  list="franchaise"
                  value={franchaisename}
                  onChange={(e) => setFranchaisename(e.target.value)}
                />
                <datalist id="franchaise">
                  {franchaise.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </datalist>
              </>
            )}
          </div>

          <div className="inp_1">
            <label>
              {" "}
              Upload CSV File <span>:</span>{" "}
            </label>
            <input
              type="file"
              accept=".xlsx, .xls, .csv"
              id="Servicechoose"
              required
              style={{ display: "none" }}
              onChange={handleFileChange1}
            />
            <label
              htmlFor="Servicechoose"
              className="RegisterForm_1_btns choose_file_update"
            >
              Choose File
            </label>
            <button
              className="RegisterForm_1_btns choose_file_update"
              onClick={handleCsvupload1}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <br />

      <div className="grid_1gg">
        <CacheProvider value={muiCache}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Rate Card List"}
              data={franchaisename !== "" ? frandata : []}
              columns={columns}
              options={{
                selectableRows: "none",
                print: false,
                download: true,
                search: true,
                viewColumns: false,
                filter: true,
                pagination: true,
                responsive: "standard",
                tableBodyMaxHeight: "300px",
                tableBodyHeight: "auto",
                scroll: "none",
              }}
            />
          </ThemeProvider>
        </CacheProvider>
      </div>

      <div className="Register_btn_con" style={{ marginTop: "20px" }}>
        <button
          onClick={handledownload}
          type="submit"
          className="RegisterForm_1_btns"
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default RatecardLims;
