import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';


function AdvanceRepaymentList() {


    const urllink = useSelector((state) => state.userRecord?.UrlLink);
    const userRecord = useSelector(state => state.userRecord?.UserData)
    const [filteredRows, setFilteredRows] = useState([]);
    const [advanceRows, setAdvanceRows] = useState([]);
    const [rolename, setRolename] = useState([])


    useEffect(() => {
        axios
            .get(
                `${urllink}HRmanagement/getRole_all`
            )
            .then((response) => {
                console.log(response.data);
                setRolename(response.data);
            })
            .catch((error) => {
                console.log(error);
            });


    }, [userRecord?.location]);





    useEffect(() => {
        axios.get(`${urllink}HRmanagement/get_all_repaymentlist?location=${userRecord?.location}`)
            .then((res) => {
                console.log(res);
                const data = res.data
                setAdvanceRows(
                    data.map((row, index) => ({
                        id: row.EmployeeID,
                        ...row
                    }))
                )

            })
            .catch((err) => {
                console.error(err);

            })
    }, [userRecord])



    const [searchQuery, setsearchQuery] = useState('')
    const [searchQuery1, setsearchQuery1] = useState('')

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const lowerCaseQuery1 = searchQuery1.toLowerCase();

        const filterRow = (row) => {
            const lowerCaseEmployeeName = row.EmployeeName.toLowerCase();
            const lowerCaseDesignation = row.Designation.toLowerCase();
            const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
            const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);

            if (!searchQuery && !searchQuery1) {
                // If both search queries are empty, do not filter out any data
                return true;
            }

            return nameMatches && designationMatches;
        };

        const filteredData = advanceRows.filter(filterRow);


        setFilteredRows(filteredData);

    }, [searchQuery, advanceRows, searchQuery1]);


    return (
        <div>

            <div className="con_1 ">
                <div className="inp_1">
                    <label htmlFor="input">Employee Name <span>:</span></label>
                    <input
                        type="text"
                        id="date"
                        name='employeeName'
                        placeholder='Enter Employee Name'
                        value={searchQuery}
                        onChange={(e) => setsearchQuery(e.target.value)}
                    />
                </div>
                <div className="inp_1">
                    <label htmlFor="input">Designation <span>:</span></label>
                    <select
                        name="designation"
                        value={searchQuery1}
                        onChange={(e) => setsearchQuery1(e.target.value)}
                        className="new-custom-input-phone vital_select"
                        required
                    >
                        <option value="select">Select </option>
                        {rolename.map((role) => (
                            <option key={role.role_id} value={role.role_name}>
                                {role.role_name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="Selected-table-container">
                <table className="selected-medicine-table2">
                    <thead>
                        <tr>
                            <th id="slectbill_ins">Employee ID</th>
                            <th id="slectbill_ins">Employee Name</th>
                            <th id="slectbill_ins">Designation</th>
                            <th id="slectbill_ins">Approved Amount</th>
                            <th id="slectbill_ins">Installment Status</th>
                            <th id="slectbill_ins">Paid Amount</th>

                        </tr>
                    </thead>
                    <tbody>
                        {filteredRows.length > 0 && filteredRows.map((leave, index) => (
                            <tr key={index}>
                                <td>{leave.EmployeeID}</td>
                                <td>{leave.EmployeeName}</td>
                                <td>{leave.Designation}</td>
                                <td>{leave.RequestAmount}</td>
                                <td>{leave.Installment_Status}</td>
                                <td>{leave.PaidAmount}</td>

                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    )
}

export default AdvanceRepaymentList;


